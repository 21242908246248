
/*
	©2018 Dany Dhondt
*/


export class LocaleContent {
	_explicitType: string = "LocaleContent";
	id: Number = 0;
	ref: string = '';
	refId: Number = 0;
	field: string = '';
	locale: string = '';
	content: string = '';
}