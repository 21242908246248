export class NieuwsTextPosition {

    //Static constants

    static readonly TOP_LEFT: string = 'tl'
    static readonly TOP_RIGHT: string = 'tr'
    static readonly BOTTOM_LEFT: string = 'bl'
    static readonly BOTTOM_RIGHT: string = 'br'
    static readonly MID_LEFT: string = 'ml'
    static readonly MID_RIGHT: string = 'mr'
    static readonly TOP_CENTER: string = 'tc'
    static readonly BOTTOM_CENTER: string = 'bc'
    static readonly CENTER: string = 'c'

    static readonly SEMANTIC_DATAPROVIDER: Object[] = [
        {text: 'Bovenaan links', value: NieuwsTextPosition.TOP_LEFT, key: NieuwsTextPosition.TOP_LEFT},
        {text: 'Bovenaan rechts', value: NieuwsTextPosition.TOP_RIGHT, key: NieuwsTextPosition.TOP_RIGHT},
        {text: 'Onderaan links', value: NieuwsTextPosition.BOTTOM_LEFT, key: NieuwsTextPosition.BOTTOM_LEFT},
        {text: 'Onderaan rechts', value: NieuwsTextPosition.BOTTOM_RIGHT, key: NieuwsTextPosition.BOTTOM_RIGHT},
        {text: 'Midden links', value: NieuwsTextPosition.MID_LEFT, key: NieuwsTextPosition.MID_LEFT},
        {text: 'Midden rechts', value: NieuwsTextPosition.MID_RIGHT, key: NieuwsTextPosition.MID_RIGHT},
       /* {text: 'Bovenaan midden', value: NieuwsTextPosition.TOP_CENTER, key: NieuwsTextPosition.TOP_CENTER},
        {text: 'Onderaan midden', value: NieuwsTextPosition.BOTTOM_CENTER, key: NieuwsTextPosition.BOTTOM_CENTER},
        {text: 'Gecentreerd', value: NieuwsTextPosition.CENTER, key: NieuwsTextPosition.CENTER},*/
    ]

    //Private variables

    //Public variables


    //public api

    //private methods

    //getters and setters
}
