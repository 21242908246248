
/*
	©2018 Dany Dhondt
*/

export class SignalisatiestrookLogoPositie {
	
	static RECHTS:string = "rechts"
	static AANSLUITEND:string = "aansluitend"
	
	static VALUES:string[] = [
		SignalisatiestrookLogoPositie.AANSLUITEND,
		SignalisatiestrookLogoPositie.RECHTS
	]
	
	static DATAPROVIDER: object[] = SignalisatiestrookLogoPositie.VALUES.map((value, index) => {
		return {key: index, value: value, text: SignalisatiestrookLogoPositie.getSignalisatiestrookPositieLabel(value)}
	})
	
	protected static getSignalisatiestrookPositieLabel(label:string): string {
		
		switch (label){
			case SignalisatiestrookLogoPositie.AANSLUITEND:
				return "Aansluitend"
			case SignalisatiestrookLogoPositie.RECHTS:
				return "Rechts"
			
			default:
				return "geen geldige positie";
		}
	}
}