import React, {useState, Fragment} from 'react'
import {Label, Segment, Button, Icon, Header} from 'semantic-ui-react'
import {PublicatieType} from 'med-class'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'
import _ from 'lodash'

const Parser = require('html-react-parser')
const MAX_EXEMPLAREN = 4

const ExemplaarRenderer = ({zoekresultaat, localeManager, label, onWaarVindIkDitClick, onReserveerClick, arteveldeUser, config}) => {

    const [toonAlle, setToonAlle] = useState(false)
    const [reserveerButtonVisible, setReserveerButtonVisible] = useState(arteveldeUser.loggedIn)


    //geen exemplaren bij tijdschriften
    if (zoekresultaat.publicatietype === PublicatieType.ARTIKEL
        || zoekresultaat.publicatietype === PublicatieType.TIJDSCHRIFT) return null

    //geen exemplaren bij epubs
    if (zoekresultaat.bron === 'epub') return null

    const toonAlleExemplaren = () => {
        setToonAlle(true)
    }

    const onReserveerButtonClick = (exemplaar) => {
        zoekresultaat.isFull = false //zo wordt de cache omzeild
        onReserveerClick(zoekresultaat, exemplaar, setReserveerButtonVisible)
    }

    zoekresultaat.exemplaar = _.orderBy(zoekresultaat.exemplaar, ['plaats', 'awnr'], ['asc', 'desc'])

    let plaats
    const plaatsen = zoekresultaat.exemplaar.reduce((pl, ex) => {
        if (pl.indexOf(ex.plaats) < 0) pl.push(ex.plaats)
        return pl
    }, [])

    return (
        <AbstractZoekresultaatRenderer label={localeManager.getString(label)}>

            {(!zoekresultaat.exemplaar || zoekresultaat.exemplaar.length === 0) && <div>
                {localeManager.getString('GEEN_EXEMPLAREN_BESCHIKBAAR')}
            </div>}

            {zoekresultaat.exemplaar && zoekresultaat.exemplaar.length > 0 && <div style={{backgroundColor: '#e1fcff', padding: '10px 10px 10px'}}>


                {zoekresultaat.exemplaar.map((exemplaar, index) => {

                    let groupLabel

                    if (plaats !== exemplaar.plaats && plaatsen.length > 1){
                        plaats = exemplaar.plaats
                        groupLabel = <Header>{exemplaar.plaats}</Header>
                    }

                    if (index > MAX_EXEMPLAREN - 1 && !toonAlle) return null


                    if (!exemplaar.plaatskenmerk) exemplaar.plaatskenmerk = ''

                    let exemplaarLabel = localeManager.parseBidocString(exemplaar.plaats)
                    if (exemplaar.awnr && exemplaar.awnr !== '') exemplaarLabel += ` | ${exemplaar.awnr}`

                    const statuslabel = exemplaar.uitleenstatus === 'aanwezig' ?
                        <Label basic content={localeManager.getString('AANWEZIG')} color='green'/> :
                        <Label basic content={exemplaar.uitleenstatus} color='grey'/>

                    const reserveerButton = reserveerButtonVisible && zoekresultaat.bron === 'bidoc' && exemplaar.reservable === 1 && config.afhaalmandjes.toonReservatieButton &&
                        <Label as='a' color='red' onClick={() => onReserveerButtonClick(exemplaar)}>
                            <Icon name='shopping basket'/>
                            {localeManager.getString('RESERVEER_DEZE_TITEL')}
                        </Label>

                    return <Fragment key={index}>

                        {groupLabel}

                        <Segment key={index}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <span style={{marginRight: '10px'}}>{exemplaarLabel}</span>

                                {statuslabel}

                                {reserveerButton}

                                <div style={{flexGrow: 1}}/>
                                {(exemplaar.plaatskenmerk && zoekresultaat.bron === 'bidoc') &&
                                <Label as='a'
                                       onClick={() => onWaarVindIkDitClick(exemplaar)}
                                       content={localeManager.getString('WAAR_VIND_IK_DIT')}
                                       color='blue'/>
                                }
                                {exemplaar.plaatskenmerk !== '' &&
                                <Label color='blue' basic>{Parser(exemplaar.plaatskenmerk)}</Label>
                                }
                            </div>
                        </Segment>
                    </Fragment>
                })}
            </div>}

            {zoekresultaat.exemplaar && zoekresultaat.exemplaar.length > MAX_EXEMPLAREN && !toonAlle &&
            <div><p>Nog {zoekresultaat.exemplaar.length - MAX_EXEMPLAREN} meer</p>
                <Button color='blue' content='Toon alle' onClick={toonAlleExemplaren}/>
            </div>}
        </AbstractZoekresultaatRenderer>
    )
}

export default ExemplaarRenderer