export class Rubriek {
	
	_explicitType:string = 'Rubriek'
	
	id:number = 0
	rubriek:string = ''
	label:string = ''

	toString = () => {
		return this.rubriek + ': ' + this.label
	}
}