import React, {Component} from 'react'
import PropTypes from 'prop-types'
import NieuwsPlayer from "./NieuwsPlayer";

export default class WebComponentNieuws extends Component {

    constructor(props) {
        super(props)
        this.state = {
            refreshTimer: setInterval(this.fetchNieuws, 5 * 60000),
            onlineNieuws: null,
        }

    }

    componentDidMount() {
        this.fetchNieuws()
    }

    componentWillUnmount() {
        clearInterval(this.state.refreshTimer)
    }

    fetchNieuws = () => {
        const {nieuwsService} = this.props

        nieuwsService.getOnlineNieuws().then(({onlineNieuws}) => {
            this.setState({onlineNieuws: onlineNieuws})
        })
    }


    render() {
        const {onlineNieuws} = this.state
        const {localeDataService, toonNavigatie, directLink} = this.props

        if (!onlineNieuws || !localeDataService) return null

        return (
            <NieuwsPlayer nieuws={onlineNieuws}
                          localeDataService={localeDataService}
                          toonNavigatie={toonNavigatie}
                          directLink={directLink}/>
        )
    }
}

WebComponentNieuws.propTypes = {
    nieuwsService: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    localeDataService: PropTypes.object.isRequired,
    toonNavigatie: PropTypes.bool,
    directLink: PropTypes.object
}

WebComponentNieuws.defaultProps = {
    toonNavigatie: true
}