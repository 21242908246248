import React, {Component} from 'react'
import {Label} from 'semantic-ui-react'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'

const MAX_ITEMS = 4

class OpleidingRenderer extends Component{

    constructor(props){
        super(props)
        this.state = {displayAll: false, zoekresultaatId: props.zoekresultaat.id}
    }


    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.zoekresultaat.id !== prevState.zoekresultaatId)
            return {displayAll: false, zoekresultaatId: nextProps.zoekresultaat.id}

        return null
    }

    getShowMoreLabel = () => {
        const { zoekresultaat, localeManager } = this.props
        return localeManager.getString('EN_X_MEER').replace('[num]', zoekresultaat.opleidingArray.length - MAX_ITEMS) + '...'
    }

    render(){
        const { displayAll } = this.state
        const { zoekresultaat, localeManager, label, taxonomieService } = this.props

        if (!zoekresultaat.opleiding) return null
        const showMoreButtonVisible = !displayAll && zoekresultaat.opleidingArray.length > MAX_ITEMS

        return (
            <AbstractZoekresultaatRenderer label={localeManager.getString(label)}>
                {zoekresultaat.opleidingArray.map((opleiding, index) => {

                    if (!displayAll && index >= MAX_ITEMS) return null

                    const taxonomieItem = taxonomieService.taxonomieItemLookup[opleiding]
                    if (!taxonomieItem){
                        //console.log('GEEN GELDIG TAXONOMIE ITEM ID', opleiding)
                        return <Label basic key={index} style={{marginBottom: '5px'}} as='a' content={opleiding} icon='graduation cap' color='blue'/>
                    } else {
                        return <Label basic key={index} style={{marginBottom: '5px'}} as='a' content={taxonomieItem.label} icon='graduation cap' color='blue'/>
                    }
                })}

                {showMoreButtonVisible && <Label style={{marginBottom: '5px'}} as='a'
                                                 content={this.getShowMoreLabel()} onClick={() => this.setState({displayAll: true})}/>}

            </AbstractZoekresultaatRenderer>
        )
    }
}

export default  OpleidingRenderer