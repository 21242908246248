import React from 'react'
import PropTypes from 'prop-types'

const AbstractZoekresultaatRenderer = (props) => {
    return (
        <div className='zoekresultaat-form'>
            <div className='zoekresultaat-form-label'>{props.label}</div>
            <div className='zoekresultaat-form-content'>
                {props.children}
            </div>
        </div>
    )
}

export default  AbstractZoekresultaatRenderer

AbstractZoekresultaatRenderer.propTypes = {
    label: PropTypes.string.isRequired,
}

AbstractZoekresultaatRenderer.defaultProps = {

}