import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Checkbox, Segment, Divider} from "semantic-ui-react";
import ChatListItem from "./ChatListItem";
import AdminsAvailable from "./AdminsAvailable";

const ChatList = ({allChatlines, onToggleBeschikbaar, beschikbaar, chats, selectedChat, onSelectChat, adminsAvailable, config}) => {


    const getNotificationWarning = () => {
        if (window.Notification && Notification.permission === 'denied') {
            return <h3>Let op: uw notificatie instellingen staan op 'afgewezen'. <br />U zal dus geen meldingen krijgen als er een nieuwe chat binnenkomt!</h3>
        }

        return null
    }

    return (
        <div className='chat-column' style={{backgroundColor: 'rgba(143,143,143,0.05)', minWidth: '400px', maxWidth: '500px'}}>
            <Segment>
                <Checkbox toggle label='Beschikbaar voor chat' checked={beschikbaar} onChange={(e, {checked}) => onToggleBeschikbaar(checked)}/>

                <Divider/>
                <AdminsAvailable adminsAvailable={adminsAvailable}/>
            </Segment>

            {config && config.nieuw &&<Segment inverted color=' teal'>
                <div>
                    <strong>Nieuw!</strong></div>

                {config.nieuw.map((str, index) => {
                    return <p key={index}>{str}</p>
                })}
            </Segment>}

            <div style={{marginTop: '10px'}}>
                
                {getNotificationWarning()}
                
                {/*Hieronder vind je de chats die momenteel door bezoekers van de website geïnitieerd werden.
            Het is de bedoeling dat, indien je jezelf als beschikbaar hebt ingesteld, je deelneemt aan de chats.<br/>Wanneer de bezoeker de chat afsluit, dan zal die ook automatisch uit deze lijst verdwijnen.*/}</div>


            {beschikbaar && <div style={{overflowY: 'auto', marginTop: '20px', height: '100%'}}>
                {chats && chats.map((chat, index) => {
                    return <ChatListItem key={index} chatlines={allChatlines} onClick={() => onSelectChat(chat)}
                                         chat={chat} selected={selectedChat && selectedChat === chat}/>
                })}
            </div>}
        </div>
    )
}

export default  ChatList

ChatList.propTypes = {
    config: PropTypes.object.isRequired,
    allChatlines: PropTypes.array,
    adminsAvailable: PropTypes.array,
    onToggleBeschikbaar: PropTypes.func,
    beschikbaar: PropTypes.bool,
    chats: PropTypes.array,
    selectedChat: PropTypes.any,
    onSelectChat: PropTypes.func
}

ChatList.defaultProps = {

}