import {BidocReservatie} from "./BidocReservatie";

export class BidocReservatieData {
	
	_explicitType: "BidocReservatieData"
	status: number
	lenersnummer: string
	reservaties: BidocReservatie[]
	
	
}
