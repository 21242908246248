import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {Dropdown, Input, Button} from 'semantic-ui-react'

const shrink = {
    alignSelf: 'center',
    marginRight: '15px'
}

const GeavanceerdZoekenZoeklijn = ({localeManager, isFirstLine, data, showAddButton, onChange, onAdd, onDelete, onZoeklijnKeyDown}) => {

    const [booleanProvider, setBooleanProvider] = useState()
    const [zoekveldProvider, setZoekveldProvider] = useState()
    const [genreProvider, setGenreProvider] = useState()

    const inputRef = useRef()

    useEffect(() => {
        setBooleanProvider([{key: 'and', text: localeManager.getString('AND') + ':', value: 'AND'},
            {key: 'or', text: localeManager.getString('OR') + ':', value: 'OR'},
            {key: 'not', text: localeManager.getString('NOT') + ':', value: 'NOT'}])

        setZoekveldProvider([{key: 'titel', text: localeManager.getString('TITEL'), value: 'titel', type: 'input'},
            {key: 'auteur', text: localeManager.getString('AUTEUR'), value: 'auteur', type: 'input'},
            {key: 'trefwoord', text: localeManager.getString('TREFWOORD'), value: 'trefwoord', type: 'input'},
            {key: 'genre', text: localeManager.getString('GENRE'), value: 'genre', type: 'dropdown'},
            {key: 'tijdschrift', text: localeManager.getString('TIJDSCHRIFT'), value: 'tijdschrift', type: 'input'}])

        const genres = ['Historische verhalen', 'Jeugdboeken 00-03 jaar', 'Jeugdboeken 03-06 jaar', 'Jeugdboeken 07-09 jaar', 'Jeugdboeken 10-12 jaar', 'Jeugdboeken 13-16 jaar', 'Jeugdpoëzie', 'Liederenbundels', 'Poëzie', 'Prentenboeken', 'jeugdboeken met leeftijdsaanduiding', 'Romans', 'Speelfilms', 'Sprookjes', 'Strips', 'Toneel', 'Verhalenbundels', 'Voorleesverhalen']

        setGenreProvider(genres.map(genre => ({key: genre, text: localeManager.getString(genre), value: genre})))
    }, [localeManager])

    useEffect(() => {
        if (!inputRef.current) return
        inputRef.current.focus()
    }, [inputRef])

    const onChangeLocal = (event, {name, value}) => {
        inputRef.current && inputRef.current.focus()
        onChange(data, name, value)
    }


    console.log('DATA', data)
    return (
        <div style={{display: 'flex', marginBottom: '10px', justifyContent: 'center'}}>

            <div style={{width: '150px', marginRight: '15px', display: 'flex', alignItems: 'center'}}>
                {isFirstLine && <div>{localeManager.getString('WAT_WIL_JE_ZOEKEN')}</div>}
                {!isFirstLine &&
                <Dropdown fluid selection options={booleanProvider} name='boolean' value={data.boolean} onChange={onChangeLocal}/>}
            </div>

            <Dropdown style={shrink} size='small' selection options={zoekveldProvider} name='zoekveld' value={data.zoekveld} onChange={onChangeLocal}/>

            {data.zoekveld === 'genre' &&
                <Dropdown style={{flexGrow: 1, alignSelf: 'center', marginRight: '15px'}} size='small' selection options={genreProvider} name='genre' value={data.query} onChange={(event, {value}) => onChange(data, 'query', value)}/>
            }

            {data.zoekveld !== 'genre' &&
            <Input ref={inputRef} fluid focus icon='search'
                   style={{flexGrow: 1, alignSelf: 'center', marginRight: '15px'}}
                   value={data.query}
                   onChange={(event, {value}) => onChange(data, 'query', value)}
                   onKeyDown={onZoeklijnKeyDown}/>}

            <div style={{width: '100px', alignSelf: 'center'}}>
                {!isFirstLine && <Button style={shrink} circular color='red' icon='delete' size='mini' onClick={() => onDelete(data)}/>}
                {showAddButton && <Button style={shrink} circular color='green' icon='add' size='mini' onClick={onAdd}/>}
            </div>

        </div>
    )
}

export default GeavanceerdZoekenZoeklijn

GeavanceerdZoekenZoeklijn.propTypes = {
    localeManager: PropTypes.object.isRequired,
    isFirstLine: PropTypes.bool,
    showAddButton: PropTypes.bool,
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onZoeklijnKeyDown: PropTypes.func
}

GeavanceerdZoekenZoeklijn.defaultProps = {
    isFirstLine: true
}