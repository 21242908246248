import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Label} from 'semantic-ui-react'
import 'moment/locale/nl'
import moment from 'moment'

const TijdsslotDagRenderer = ({dag, localeManager, selected, beschikbareSlots, onClick}) => {

    const [dagMoment, setDagMoment] = useState()

    useEffect(() => {
        setDagMoment(moment(dag).locale(localeManager.locale))
    }, [dag, localeManager.locale])

    if (!dagMoment) return null
    return (
        <div className={`tijdsslot tijdsslot-dag ${selected ? 'selected' : ''} ${beschikbareSlots !== undefined && beschikbareSlots === 0 ? 'inactive' : ''}`}
             onClick={() => onClick && onClick(dag)}>
            <div>{dagMoment.format('dddd')}</div>
            <h1 style={{margin: 0}}>{dagMoment.format('DD')}</h1>
            <div>{dagMoment.format('MMMM')}</div>
            {beschikbareSlots !== undefined && <div className="tijdsslot-beschikbare-slots">
                <Label color={beschikbareSlots <= 2 && beschikbareSlots !== 0 ? 'red' : 'grey'} circular>
                    {beschikbareSlots === 0 ? localeManager.getString('VOLZET') : beschikbareSlots}
                </Label>
            </div>}
        </div>
    )
}

export default  TijdsslotDagRenderer

TijdsslotDagRenderer.propTypes = {
    dag: PropTypes.string.isRequired,
    localeManager: PropTypes.object.isRequired,

    selected: PropTypes.bool,
    beschikbareSlots: PropTypes.number,
    onClick: PropTypes.func
}

TijdsslotDagRenderer.defaultProps = {
    selected: false,
}