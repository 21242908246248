export class Zoekmode {

    static readonly NORMAAL: string = 'normaal'
    static readonly GEAVANCEERD: string = 'geavanceerd'
    static readonly ZOEKSET: string = 'zoekset'
    static readonly ZOEKLIJST: string = 'zoeklijst'
    static readonly SPELWIJZER: string = 'spelwijzer'
    static readonly DATABANKEN: string = 'databanken'
    static readonly BEWAARDE_ZOEKOPDRACHTEN: string = 'bewaardeZoekopdrachten'
}
