import {AbstractZoekresultaat} from "../class/zoekresultaat/AbstractZoekresultaat";
import {LocaleManager} from "../service/LocaleManager";
import {TaxonomieService} from "../service/TaxonomieService";

export class ZoekUtil {

    static readonly metaString = (zoekresultaat: AbstractZoekresultaat, localeManager: LocaleManager, taxonomieService: TaxonomieService) => {

        const msa: string[] = []
        if (zoekresultaat.publicatietype) {
            const isInteger = Number.isInteger(parseInt(zoekresultaat.publicatietype))

            if (isInteger) {
                const ti = taxonomieService.taxonomieItemLookup[parseInt(zoekresultaat.publicatietype)]
                if (ti) msa.push(ti.label)
            } else {
                msa.push(localeManager.getString(zoekresultaat.publicatietype))
            }
                


        }

        if (zoekresultaat.jaar !== null) msa.push(zoekresultaat.jaar)
        if (zoekresultaat.bron) msa.push(localeManager.getString(zoekresultaat.bron))

        return msa.join(' | ')
    }

    static readonly getURLParameter = (sParam) => {
        const sPageURL = window.location.search.substring(1)
        const sURLVariables = sPageURL.split('&')

        for (const variable of sURLVariables) {
            const sParameterName = variable.split('=')
            if (sParameterName[0] === sParam)
            {
                return sParameterName[1]
            }
        }

        return ''
    }
}
