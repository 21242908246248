import React, {Component, createRef, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Divider, Transition, Modal, Image, Button, Header, Label} from 'semantic-ui-react'
import {
    LocaleDataService, ZoekresultaatEPub, ZoekresultaatWeergave, Zoekmode, ZoekQuery,
    ZoekresultaatBidoc, ZoekresultaatEbsco, Cookie, BewaardeZoekopdracht
} from 'med-class'

import Zoekbalk from './component/Zoekbalk'
import Filter from './component/Filter'
import ZoeksetFilter from './component/ZoeksetFilter'
import {ZoekfilterData} from "./class/ZoekfilterData"
import Zoekresultaten from './component/zoekresultaat/Zoekresultaten'
import ZoekresultaatDetail from './component/zoekresultaat/ZoekresultaatDetail'
import Pager from './component/zoekresultaat/Pager'
import {QueryData} from "./class/QueryData"
import uniq from 'lodash/uniq'
import concat from 'lodash/concat'
import intersection from 'lodash/intersection'
import slice from 'lodash/slice'
import _ from 'lodash'
import Parser from 'html-react-parser'

const AUTOSCROLL_DELAY = 500


export default class WebComponentZoek extends Component {

    typeTimer
    baseData
    filterLookup
    filterObject = null
    queryData = null
    filteredData = []
    searchMode = Zoekmode.NORMAAL //spelwijzer | databanken | normaal | geavanceerd
    zoekset
    resetSearchEnabled = false
    currentScrollY
    paginaTotalen = {}


    constructor(props) {
        super(props)

        this.state = {
            view: ZoekresultaatWeergave.LIST, //list | detail
            resultaatWeergave: props.cookies && props.cookies.get(Cookie.AHS_MED_RESULTAAT_WEERGAVE) ? props.cookies.get(Cookie.AHS_MED_RESULTAAT_WEERGAVE) : ZoekresultaatWeergave.TILE, //list | tile
            selectedZoekresultaat: null,
            selectedZoekresultaatIndex: -1,
            searchButtonVisible: false,
            searchLoading: false,
            //queryData: new QueryData(Zoekmode.NORMAAL, props.localeManager),
            zoeksets: null,
            zoekbronnen: [],
            filterVisible: true,
            filterTekst: '',
            searchResultsVisible: true,

            pagedData: null,
            itemsPerPagina: 30,
            itemOffset: 0,
            activePage: 1,
            totalPages: 1,
            currentScrollY: 0,
            coverDetailOpen: false,
            coverDetailLink: '',

            lastItem: null,
            gevondenString: '',
            boodschap: null,
            zoeklijstTitel: undefined,
            zoeklijstBeshrijving: undefined,

            epubLocaleDataService: new LocaleDataService('epub', ['beschrijving', 'opmerking']),

            zoekResultatenNumCols: null,

            trefwoordZoekresultaat: null //dit wordt ingesteld als op een trefwoord geklikt wordt
        }

        this.queryData = new QueryData(Zoekmode.NORMAAL, props.localeManager)

        this.containerDiv = createRef()
        window.addEventListener('resize', this.onResize)
    }

    componentDidMount() {

        const {zoekService} = this.props

        zoekService.getZoeksets().then(zoeksets => {
            this.zoekset = zoeksets[0]
            this.setState({zoeksets})
            this.setBaseData(this.searchMode)

            this.handleDirectLink()
        })
    }

    componentDidUpdate() {
        const {zoekResultatenNumCols} = this.state
        if (!zoekResultatenNumCols && this.containerDiv.current) {
            this.calculateZoekresultaatNumCols(this.containerDiv.current.offsetWidth)
        }

        this.handleDirectLink()
    }

    handleDirectLink = () => {
        const {directLink, zoekService, arteveldeUser} = this.props

        if (!directLink || !directLink.isValid()) return

        directLink.invalidate()

        if (directLink.isDirectSearch()) {
            const src = directLink.getProp('src')
            let zoekresultaat
            switch (src) {
                case 'bidoc':
                    zoekresultaat = new ZoekresultaatBidoc()
                    break
                case 'ebsco':
                    zoekresultaat = new ZoekresultaatEbsco()
                    break
                case 'epub':
                    zoekresultaat = new ZoekresultaatEPub()
                    break
                default:
                    console.log('GEEN SOURCE!')
                    return
            }

            //console.log('ZR', zoekresultaat)
            zoekresultaat.bron = directLink.getProp('src')
            zoekresultaat.id = directLink.getProp('id')

            this.onZoekresultaatSelect(zoekresultaat)
        }

        if (directLink.isDirectLeeslijst()) {
            this.setSearchMode(Zoekmode.ZOEKLIJST) //dit is goed want dan is geen enkele knop bovenaan gehilite
            if (directLink.injectedObject) {
                this.setEigenZoeklijst(directLink.injectedObject)
            } else {
                zoekService.getPubliekeZoeklijst(directLink.getProp('id'), arteveldeUser).then(res => {
                    console.log('getPubliekeZoeklijst', directLink, res)
                    this.setPubliekeZoeklijst(res.zoeklijst, res.zoekresultaten)
                })
            }
        }

        if (directLink.isDirectBewaardeZoekopdracht()) {
            if (directLink.injectedObject) {
                this.setBewaardeZoekopdracht(directLink.injectedObject)
            } else {
                zoekService.getBewaardeZoekopdracht(directLink.getProp('id')).then(res => {
                    this.setBewaardeZoekopdracht(res)
                })
            }
        }

        if (directLink.isDirectSpelwijzer()) {
            this.setSearchMode(Zoekmode.SPELWIJZER)
        }

        if (directLink.isDirectDatabanken()) {
            this.setSearchMode(Zoekmode.DATABANKEN)
        }
    }

    onResetSearch = () => {

        if (this.state.trefwoordZoekresultaat) {
            this.onZoekresultaatSelect(this.state.trefwoordZoekresultaat)
            this.setState({trefwoordZoekresultaat: null})
        } else {
            this.searchMode = Zoekmode.NORMAAL
            this.setBaseData(this.searchMode)
            this.setState({pagedData: null, filterTekst: '', boodschap: null})
            this.resetSearchEnabled = false
        }

    }

    onResize = () => {
        if (this.containerDiv.current) this.calculateZoekresultaatNumCols(this.containerDiv.current.offsetWidth)
    }

    calculateZoekresultaatNumCols = (containerWidth) => {
        const tileWidth = 300
        const numCols = Math.floor(containerWidth / tileWidth)
        if (numCols !== this.state.zoekResultatenNumCols) this.setState({zoekResultatenNumCols: numCols})
    }


    /* setZoekset = (value) => {
         this.searchMode = value
         const {zoekService} = this.props
         this.zoekset = zoekService.zoeksets.reduce((acc, zoekset) => zoekset.label.toLowerCase() === value ? zoekset : acc)
         this.setBaseData(this.searchMode)
     }*/

    setBaseData = (searchMode) => {
        const {spelwijzerService, localeManager, zoekService} = this.props

        this.baseData = []

        switch (searchMode) {
            case Zoekmode.SPELWIJZER:
                this.resetSearchEnabled = true
                this.zoekset = zoekService.zoeksets.reduce((acc, zoekset) => zoekset.label.toLowerCase() === Zoekmode.SPELWIJZER ? zoekset : acc)
                if (!spelwijzerService.initialized) {
                    spelwijzerService.init().then(() => this.buildSpelwijzerBasedata())
                } else this.buildSpelwijzerBasedata()
                break
            case Zoekmode.DATABANKEN:
                this.resetSearchEnabled = true
                this.zoekset = zoekService.zoeksets.reduce((acc, zoekset) => zoekset.label.toLowerCase() === Zoekmode.DATABANKEN ? zoekset : acc)
                if (!zoekService.databanken) {
                    zoekService.getDatabanken().then(() => this.buildDatabankenBasedata())
                } else this.buildDatabankenBasedata()
                break
            case Zoekmode.ZOEKLIJST:
                this.resetSearchEnabled = true
                this.filterObject = new ZoekfilterData(Zoekmode.ZOEKLIJST, localeManager)
                this.setState({activePage: 1, totalPages: 1})
                break
            default:
                this.filterObject = new ZoekfilterData(Zoekmode.NORMAAL, localeManager)
                this.filterObject.data = {
                    /*'collectie': [
                        taxonomieService.getTaxonomieItemByLabel('fysieke_collectie'),
                        taxonomieService.getTaxonomieItemByLabel('digitale_collectie')
                    ]*/
                }
                this.queryData = new QueryData(searchMode, localeManager)
                this.setState({activePage: 1, totalPages: 0})
                //this.filterSearchResults()
                break
        }

    }

    buildSpelwijzerBasedata = () => {
        const {spelwijzerService, localeManager, taxonomieService} = this.props
        this.filterObject = new ZoekfilterData(Zoekmode.SPELWIJZER, localeManager)

        this.baseData = []
        this.filterLookup = {}

        spelwijzerService.zoekresultatenBaseData.forEach(zoekresultaat => {

            this.baseData.push(zoekresultaat)

            if (zoekresultaat.bidocKoppeling.taxonomieItems) {
                let tia = zoekresultaat.bidocKoppeling.taxonomieItems.split(',')
                for (let ti of tia) {
                    if (!this.filterLookup[ti]) this.filterLookup[ti] = []
                    this.filterLookup[ti].push(zoekresultaat)

                    //tijdsduur
                    const taxonomieItem = taxonomieService.taxonomieItemLookup[ti]
                    if (taxonomieItem) {
                        const taxonomie = taxonomieService.taxonomieLookup[taxonomieItem.taxonomie]
                        if (taxonomie.label === 'tijdsduur') zoekresultaat.tijdsduurTaxonomieItems.push(taxonomieItem)
                    }
                }
            }

            //Werkvormen
            zoekresultaat.werkvormen = spelwijzerService.spelwijzerWerkvormLookup[zoekresultaat.bidocKoppeling.id]
        })

        this.baseData = _.orderBy(this.baseData, ['uitgelicht', 'titel'], ['desc', 'asc'])

        this.filterSearchResults(this.state.filterTekst)
    }

    buildDatabankenBasedata = () => {
        const {localeManager, zoekService} = this.props
        this.filterObject = new ZoekfilterData(Zoekmode.DATABANKEN, localeManager)

        this.baseData = []
        this.filterLookup = {}

        zoekService.databanken.forEach(db => {
            const zoekresultaat = new ZoekresultaatEPub(db)
            zoekresultaat.isFull = true
            this.baseData.push(zoekresultaat)

            //domein
            for (const domein of zoekresultaat.domein.split(',')) {
                if (!this.filterLookup[domein]) this.filterLookup[domein] = []
                this.filterLookup[domein].push(zoekresultaat)
            }
            //opleiding
            for (const opleiding of zoekresultaat.opleiding.split(',')) {
                if (!this.filterLookup[opleiding]) this.filterLookup[opleiding] = []
                this.filterLookup[opleiding].push(zoekresultaat)
            }

            //publicatieType
            //console.log('PT', zoekresultaat.publicatietype, taxonomieService)
        })

        this.filterSearchResults(this.state.filterTekst)
    }


    filterSearchResults = (filterTekst) => {

        const {itemsPerPagina} = this.state
        const {zoekService} = this.props


        this.filteredData = this.baseData
        const raAnds = []

        for (let prop in this.filterObject.data) {

            const data = this.filterObject.data[prop]
            let raAnd = []
            switch (prop) {
                case "aantal_spelers":
                    let min = 0, max = 0
                    data.forEach(minmaxprop => {
                        if (minmaxprop.key === 'min') min = minmaxprop.value
                        if (minmaxprop.key === 'max') max = minmaxprop.value
                    })
                    raAnd = this.baseData.filter(spel => {
                        if (min > 0 && spel.bidocKoppeling.minSpelers < min) return false
                        if (min > 0 && spel.bidocKoppeling.maxSpelers < min) return false
                        if (max > 0 && spel.bidocKoppeling.maxSpelers > max) return false
                        if (max > 0 && spel.bidocKoppeling.minSpelers > max) return false
                        if (min > 0 && max > 0 && (min > max || max < min)) return false
                        return true
                    })
                    break
                case "taalonafhankelijk":
                    raAnd = this.baseData.filter(spel => {
                        return spel.bidocKoppeling.taalonafhankelijk === 1
                    })
                    break
                default:
                    for (let taxonomie of data) {
                        raAnd = uniq(concat(raAnd, this.filterLookup[taxonomie.id]))
                    }
                    break
            }
            raAnds.push(raAnd)
        }

        this.filteredData = intersection(this.filteredData, ...raAnds)

        //filterTekst
        if (filterTekst !== '') {

            zoekService.getVerfijnResults(filterTekst).then(res => {

                console.log('FILTER TEKST', filterTekst, this.filteredData)

                this.filteredData = this.filteredData.filter(zoekresultaat => {
                    //alle woorden uit de query moeten matchen
                    let inZoekmatchString = true
                    for (const word of filterTekst.split(' ')) {
                        if (zoekresultaat.zoekmatchString.indexOf(word) < 0) {
                            inZoekmatchString = false
                            break
                        }
                    }
                    //const inZoekmatchString = zoekresultaat.zoekmatchString.indexOf(filterTekst) > -1
                    const inLocaleContent = res.localeContent.indexOf(zoekresultaat.id) > -1
                    const inCommentaar = res.social.indexOf(zoekresultaat.id) > -1

                    let inWerkvorm = false
                    if (zoekresultaat.werkvormen) {
                        inWerkvorm = zoekresultaat.werkvormen.reduce((iwv, wv) => {
                            if (wv.label.indexOf(filterTekst) > -1) return true
                            return iwv
                        }, false)
                    }
                    return inZoekmatchString || inLocaleContent || inCommentaar || inWerkvorm
                })
                this.setState({activePage: 1, totalPages: Math.ceil(this.filteredData.length / itemsPerPagina)})
                this.filterPagedData(this.filteredData, 1)
            })
        } else {
            this.setState({activePage: 1, totalPages: Math.ceil(this.filteredData.length / itemsPerPagina)})
            this.filterPagedData(this.filteredData, 1)
        }


    }

    filterPagedData = (filteredData, activePage) => {

        const {itemsPerPagina} = this.state
        const {localeManager, zoekService} = this.props

        let itemOffset, pagedData, lastItem, gevondenString, boodschap

        switch (this.searchMode) {
            case Zoekmode.SPELWIJZER:
            case Zoekmode.DATABANKEN:
                itemOffset = (activePage - 1) * itemsPerPagina
                pagedData = slice(filteredData, itemOffset, itemsPerPagina + itemOffset)
                lastItem = itemOffset + itemsPerPagina > filteredData.length ? filteredData.length : itemOffset + itemsPerPagina
                gevondenString = `${itemOffset + 1} ${localeManager.getString('TOT')} ${lastItem} ${localeManager.getString('VAN')} ${filteredData.length}`
                //console.log('DATA', pagedData)
                this.setState({pagedData, itemOffset, lastItem, gevondenString, boodschap})
                break
            case Zoekmode.ZOEKLIJST:
                //console.log('FILTER PAGED', filteredData, activePage)
                itemOffset = (activePage - 1) * itemsPerPagina
                pagedData = slice(filteredData, itemOffset, itemsPerPagina + itemOffset)
                lastItem = itemOffset + itemsPerPagina > filteredData.length ? filteredData.length : itemOffset + itemsPerPagina
                gevondenString = `${itemOffset + 1} ${localeManager.getString('TOT')} ${lastItem} ${localeManager.getString('VAN')} ${filteredData.length}`
                this.setState({pagedData, itemOffset, lastItem, gevondenString, boodschap})
                break
            default:
                this.resetSearchEnabled = true
                this.setState({searchButtonVisible: false, searchLoading: true, pagedData: [], boodschap})

                //query object bouwen
                const zoekQuery = new ZoekQuery()
                zoekQuery.query = this.searchMode === Zoekmode.NORMAAL ? this.queryData.basicSearchString : this.queryData.toQueryString()
                zoekQuery.zoekOptieString = this.filterObject.toZoekOptieString()
                zoekQuery.paginanummer = activePage

                zoekService.getZoekresultaat(zoekQuery).then((zoekresultaatDataWrapper) => {
                    const activePage = zoekresultaatDataWrapper.paginanummer

                    this.filteredData = zoekresultaatDataWrapper.zoekresultaten
                    const itemOffset = zoekresultaatDataWrapper.offset.van
                    const lastItem = zoekresultaatDataWrapper.offset.tot
                    const gevondenString = `${itemOffset} ${localeManager.getString('TOT')} ${lastItem} ${localeManager.getString('VAN')} ${zoekresultaatDataWrapper.totaal}`
                    this.setState({
                        activePage,
                        totalPages: Math.ceil(zoekresultaatDataWrapper.totaal / zoekresultaatDataWrapper.zoekresultaten.length),
                        pagedData: zoekresultaatDataWrapper.zoekresultaten,
                        itemOffset, lastItem, gevondenString,
                        searchLoading: false,
                        boodschap: null
                    })
                }, (error) => {
                    console.log('ERROR')
                    this.setState({
                        searchLoading: false,
                        pagedData: null,
                        boodschap: localeManager.getString('GENERAL_ERROR')
                    })
                })

                break
        }

        this.filterObject.bewaarZoekopdrachtEnabled = true
    }

    setSearchMode = (searchMode) => {
        this.searchMode = searchMode
        this.resetSearchEnabled = false
        this.setState({
            filterVisible: true, searchButtonVisible: false,
            pagedData: null,
            searchLoading: false, view: ZoekresultaatWeergave.LIST
        })
        this.setBaseData(searchMode)
    }

    resetSearchData = () => {
        //alles wordt gereset

    }

    setEigenZoeklijst = (zoeklijst) => {
        const {mijnMediatheekService, arteveldeUser} = this.props

        let zoeklijstTitel = zoeklijst.label
        this.setState({zoeklijstTitel, zoeklijstBeschrijving: zoeklijst.beschrijving})
        mijnMediatheekService.getZoeklijstZoekresultaten(zoeklijst, arteveldeUser).then(zoekresultaatDataWrapper => {
            //console.log('ZL RESULTATEN', zoekresultaatDataWrapper)
            this.baseData = zoekresultaatDataWrapper.zoekresultaten
            this.filterSearchResults(this.state.filterTekst)
        })
    }

    setPubliekeZoeklijst = (zoeklijst, zoekresultaten) => {

        let zoeklijstTitel = zoeklijst.label
        if (zoeklijst.userUpn) zoeklijstTitel += ' (' + zoeklijst.userUpn + ')'
        this.setState({zoeklijstTitel, zoeklijstBeschrijving: zoeklijst.beschrijving})
        this.baseData = zoekresultaten
        this.filterSearchResults(this.state.filterTekst)
    }

    setBewaardeZoekopdracht = (bewaardeZoekopdracht) => {
        const {taxonomieService, mediatheekService} = this.props
        this.setBaseData(bewaardeZoekopdracht.mode)
        this.queryData.fromBewaardeZoekopdracht(bewaardeZoekopdracht)
        this.filterObject.fromBewaardeZoekopdracht(bewaardeZoekopdracht, taxonomieService, mediatheekService)
        this.searchMode = bewaardeZoekopdracht.mode

        this.filterPagedData([], 1)
    }

    toggleZoekbron = (event, {name, active}) => {
        const {zoekbronnen} = this.state
        //haal uit zoekbronnen
        let zbs = zoekbronnen.filter(zb => zb !== name)
        if (!active) zbs.push(name)

        this.setState({zoekbronnen: zbs})
    }

    changeFilter = (event, {name, selecteditem, checked, renderMode}) => {
        switch (renderMode) {
            case 'direct':
                //niets doen, filterObject werd al gewijzigd
                break
            default:
                checked ? this.filterObject.addItem(name, selecteditem) : this.filterObject.removeItem(name, selecteditem)
                break
        }

        const searchButtonVisible = this.queryData.basicSearchString !== '' || (this.queryData.data.length > 0 && this.queryData.data[0].query !== '')
        this.setState({searchButtonVisible})
    }

    onFilterTekstChange = (event, {value}) => {
        clearTimeout(this.typeTimer)
        const filterTekst = value.toLowerCase()
        this.setState({filterTekst})
        this.typeTimer = setTimeout(() => this.filterSearchResults(filterTekst), 300)
    }

    onFilterTekstClear = () => {
        this.setState({filterTekst: ''})
        this.filterSearchResults('')
    }

    changeZoeksetFilter = (event, {name, selecteditem, checked}) => {
        checked ? this.filterObject.addItem(name, selecteditem) : this.filterObject.removeItem(name, selecteditem)
        this.filterSearchResults(this.state.filterTekst)
    }

    resetZoeksetFilter = () => {
        this.filterObject = new ZoekfilterData(Zoekmode.ZOEKLIJST, this.props.localeManager)
        this.onFilterTekstClear()
    }

    onResultaatWeergaveChange = (event, {name}) => {
        const {cookies} = this.props
        if (cookies) cookies.set(Cookie.AHS_MED_RESULTAAT_WEERGAVE, name)
        this.setState({resultaatWeergave: name})
    }

    onSearchInputChange = (event, {value}) => {
        this.queryData.basicSearchString = value
        this.setState({filterVisible: true, searchButtonVisible: value !== ''})
    }

    onTrefwoordClick = (trefwoord) => {
        console.log('TREFWOORD CLICK', trefwoord, this.state)

        this.exitDetailView()
        this.onResetSearch()
        const bewaardeZoekopdracht = new BewaardeZoekopdracht()
        bewaardeZoekopdracht.mode = Zoekmode.GEAVANCEERD
        bewaardeZoekopdracht.query = `trefwoord:=${Parser(trefwoord)}=`
        bewaardeZoekopdracht.zoekOptieString = 'collectie:fysieke_collectie,digitale_collectie'

        this.setState({trefwoordZoekresultaat: this.state.selectedZoekresultaat})

        this.setBewaardeZoekopdracht(bewaardeZoekopdracht)
    }


    onQueryDataChange = () => {
        this.setState({searchButtonVisible: true})
    }

    onSearchInputKeyDown = (event) => {
        if (event.keyCode !== 13) return
        this.onSearchClick()
    }

    onZoekresultaatSelect = (selectedZoekresultaat) => {

        //Hier wordt het detail opgehaald
        if (this.state.view === ZoekresultaatWeergave.LIST) this.currentScrollY = window.scrollY

        const {view} = this.state
        const {zoekService, onZoekresultaatClick} = this.props
        const selectedZoekresultaatIndex = this.filteredData.indexOf(selectedZoekresultaat)

        //handig om in de root iets te doen met het zoekresultaat
        onZoekresultaatClick && onZoekresultaatClick(selectedZoekresultaat)

        zoekService.getZoekresultaatDetail(selectedZoekresultaat).then(() => {

            if (view !== ZoekresultaatWeergave.DETAIL) window.scrollTo(0, 0)

            this.setState({
                view: ZoekresultaatWeergave.DETAIL,
                selectedZoekresultaat,
                selectedZoekresultaatIndex
            })

        })
    }

    exitDetailView = () => {
        this.setState({view: ZoekresultaatWeergave.LIST})
        setTimeout(() => window.scrollTo(0, this.currentScrollY), AUTOSCROLL_DELAY)
    }

    onNavigateDetail = (event, {name}) => {
        const {selectedZoekresultaat, itemsPerPagina, activePage} = this.state
        const offset = name === 'next' ? 1 : -1
        const currIndex = this.filteredData.indexOf(selectedZoekresultaat)
        const newIndex = currIndex + offset
        //console.log('INDEX', currIndex, newIndex, this.filteredData)
        if (newIndex > -1 && newIndex < this.filteredData.length) {
            //console.log('NEW PAGE', newIndex)
            const newPage = Math.floor(newIndex / itemsPerPagina) + 1
            if (newPage !== activePage) {
                this.setState({activePage: newPage, currentScrollY: 0})
                this.filterPagedData(this.filteredData, newPage)
            }
            this.onZoekresultaatSelect(this.filteredData[newIndex])
        }
    }

    handlePaginationChange = (event, {activePage}) => {
        switch (this.searchMode) {
            case Zoekmode.SPELWIJZER:
            case Zoekmode.DATABANKEN:
            case Zoekmode.ZOEKLIJST:
                this.setState({activePage})
                this.filterPagedData(this.filteredData, activePage)
                break
            default:
                this.setState({activePage})
                this.filterPagedData(null, activePage)
                break
        }
    }

    onCoverClick = (zoekresultaat) => {
        console.log('COVER CL', zoekresultaat.coverLink)
        this.setState({coverDetailLink: zoekresultaat.coverLink, coverDetailOpen: true})
    }

    closeCoverDetail = () => {
        this.setState({coverDetailOpen: false})
    }

    onSearchClick = () => {
        this.filterPagedData(null, 1) //this.state.activePage)
    }

    bewaarZoekopdracht = () => {
        const {arteveldeUser, mijnMediatheekService, localeManager} = this.props

        const label = window.prompt(localeManager.getString('BEWAAR_ZOEKOPDRACHT_LABEL_PROMPT'))

        if (!label)
            return

        const bestaandeZoekopdracht = mijnMediatheekService.bewaardeZoekopdrachten.reduce((acc, bzo) => {
            if (bzo.label === label) return bzo
            return acc
        }, null)

        if (bestaandeZoekopdracht) {
            let msg = localeManager.getString('BEWAARDE_ZOEKOPDRACHT_BESTAAT_AL').replace('[label]', '"' + label + '"')
            const confirm = window.confirm(msg)

            if (confirm) {
                bestaandeZoekopdracht.query = this.queryData.toQueryString()
                bestaandeZoekopdracht.zoekOptieString = this.filterObject.toZoekOptieString()
                bestaandeZoekopdracht.mode = this.queryData.mode

                mijnMediatheekService.updateBewaardeZoekopdracht(bestaandeZoekopdracht).then(() => {
                    this.setState({mijnMediatheekService})
                })
            }

        } else {
            const bewaardeZoekopdracht = new BewaardeZoekopdracht()
            bewaardeZoekopdracht.query = this.queryData.toQueryString()
            bewaardeZoekopdracht.zoekOptieString = this.filterObject.toZoekOptieString()
            bewaardeZoekopdracht.userID = arteveldeUser.barcode.substr(1, 9)
            bewaardeZoekopdracht.label = label
            bewaardeZoekopdracht.mode = this.queryData.mode

            mijnMediatheekService.createBewaardeZoekopdracht(bewaardeZoekopdracht).then(() => {
                this.setState({mijnMediatheekService})
            })
        }
    }

    render() {

        const {
            zoeksets, zoekbronnen, filterVisible, selectedZoekresultaatIndex, coverDetailOpen, coverDetailLink,
            searchResultsVisible, resultaatWeergave, view, selectedZoekresultaat, filterTekst,
            activePage, totalPages, pagedData, itemOffset, searchButtonVisible, searchLoading, gevondenString, boodschap,
            zoekResultatenNumCols, epubLocaleDataService, zoeklijstTitel, zoeklijstBeschrijving
        } = this.state

        const {
            taxonomieService, mediatheekService, spelwijzerService, socialService, config,
            mijnMediatheekService, localeManager, arteveldeUser, zoekService, rubriceringService, directLink, onCampus
        } = this.props

        if (!zoeksets || !this.filterObject || !this.filteredData) return <div/>


        return (
            <div ref={this.containerDiv}>

                {view === ZoekresultaatWeergave.LIST &&
                <Fragment>
                    <Zoekbalk disabled
                              mijnMediatheekService={mijnMediatheekService}
                              localeManager={localeManager}
                              arteveldeUser={arteveldeUser}
                              searchMode={this.searchMode}
                              onBasicSearchInputChange={this.onSearchInputChange}
                              onBasicSearchInputKeyDown={this.onSearchInputKeyDown}
                              onAdvancedSearchInputKeyDown={this.onSearchInputKeyDown}
                              queryData={this.queryData}
                              onQueryDataChange={this.onQueryDataChange}
                              searchModeChange={this.setSearchMode}
                              zoeksets={zoeksets}
                              zoekset={this.zoekset}
                        /*setZoekset={this.setZoekset}*/
                              zoeklijsten={mijnMediatheekService.zoeklijsten}
                              bewaardeZoekopdrachtDropdownChange={this.setBewaardeZoekopdracht}
                              onResetSearch={this.onResetSearch}
                              resetSearchEnabled={this.resetSearchEnabled}
                    />


                    {this.searchMode === Zoekmode.ZOEKLIJST && <div>
                        <Header><Label style={{marginRight: '10px'}}>{localeManager.getString('LEESLIJST')}</Label>{zoeklijstTitel}</Header>
                        <p>{zoeklijstBeschrijving}</p>
                    </div>}

                    <Transition visible={filterVisible} animation='slide down' duration={500}>
                        <div style={{marginTop: '10px'}}>

                            {(this.searchMode === Zoekmode.NORMAAL || this.searchMode === Zoekmode.GEAVANCEERD) &&
                            <Filter localeManager={localeManager}
                                    zoekbronnen={zoekbronnen}
                                    filterObject={this.filterObject}
                                    onZoekbronToggle={this.toggleZoekbron}
                                    onFilterChange={this.changeFilter}
                                    onBewaarZoekopdracht={this.bewaarZoekopdracht}
                                    taxonomieService={taxonomieService}
                                    mediatheekService={mediatheekService}
                                    advanced={this.searchMode === Zoekmode.GEAVANCEERD}/>
                            }
                            {(this.zoekset && (this.searchMode === Zoekmode.SPELWIJZER || this.searchMode === Zoekmode.DATABANKEN)) &&
                            <ZoeksetFilter localeManager={localeManager}
                                           taxonomieService={taxonomieService}
                                           mediatheekService={mediatheekService}
                                           zoekset={this.zoekset}
                                           filterObject={this.filterObject}
                                           onFilterChange={this.changeZoeksetFilter}
                                           onFilterTekstChange={this.onFilterTekstChange}
                                           filterTekst={filterTekst}
                                           onFilterTekstClear={this.onFilterTekstClear}
                                           resetZoeksetFilter={this.resetZoeksetFilter}
                                           updateFunction={() => this.filterSearchResults(filterTekst)}
                                           directLink={directLink}
                                           arteveldeUser={arteveldeUser}/>
                            }


                            {searchButtonVisible && <Button color='blue' onClick={this.onSearchClick}>{localeManager.getString('ZOEKOPDRACHT_UITVOEREN')}</Button>}

                            <Divider/>
                        </div>
                    </Transition>
                </Fragment>
                }


                {boodschap && <div>{boodschap}</div>}

                {(view === ZoekresultaatWeergave.LIST && pagedData) &&
                <Transition visible={searchResultsVisible} animation='slide down' duration={500}>

                    <div ref={this.zoekresultatenDiv}>
                        {(totalPages > 1 && !searchLoading) && <Pager activePage={activePage}
                                                                      totalPages={totalPages}
                                                                      handlePaginationChange={this.handlePaginationChange}
                                                                      itemOffset={itemOffset}/>
                        }

                        <Zoekresultaten loading={searchLoading}
                                        resultaatWeergave={resultaatWeergave}
                                        onResultaatWeergaveChange={this.onResultaatWeergaveChange}
                                        headerString={gevondenString}
                                        zoekresultaten={pagedData}
                                        localeManager={localeManager}
                                        spelwijzerService={spelwijzerService}
                                        taxonomieService={taxonomieService}
                                        socialService={socialService}
                                        mijnMediatheekService={mijnMediatheekService}
                                        onZoekresultaatSelect={this.onZoekresultaatSelect}
                                        onCoverClick={this.onCoverClick}
                                        arteveldeUser={arteveldeUser}
                                        numCols={zoekResultatenNumCols}
                                        directLink={directLink}/>

                        {(totalPages > 1 && !searchLoading) && <Pager activePage={activePage}
                                                                      totalPages={totalPages}
                                                                      handlePaginationChange={this.handlePaginationChange}
                                                                      itemOffset={itemOffset}/>
                        }


                    </div>
                </Transition>
                }

                {view === ZoekresultaatWeergave.DETAIL &&
                <ZoekresultaatDetail zoekresultaat={selectedZoekresultaat}
                                     config={config}
                                     spelwijzerService={spelwijzerService}
                                     zoekService={zoekService}
                                     rubriceringService={rubriceringService}
                                     mediatheekService={mediatheekService}
                                     socialService={socialService}
                                     mijnMediatheekService={mijnMediatheekService}
                                     taxonomieService={taxonomieService}
                                     localeManager={localeManager}
                                     onExitDetail={this.exitDetailView}
                                     onNavigate={this.onNavigateDetail}
                                     prevButtonEnabled={selectedZoekresultaatIndex > 0}
                                     nextButtonEnabled={selectedZoekresultaatIndex < this.filteredData.length - 1}
                                     onCoverClick={this.onCoverClick}
                                     arteveldeUser={arteveldeUser}
                                     epubLocaleDataService={epubLocaleDataService}
                                     directLink={directLink}
                                     onCampus={onCampus}
                                     onTrefwoordClick={this.onTrefwoordClick}
                                     onReserveerClick={this.props.onReserveerClick}/>
                }


                <Modal dimmer='inverted' open={coverDetailOpen} onClose={this.closeCoverDetail}>
                    <Modal.Content image>
                        <Image size='massive' src={coverDetailLink} onClick={this.closeCoverDetail}/>
                    </Modal.Content>
                </Modal>
            </div>
        )
    }
}

WebComponentZoek.propTypes = {
    localeManager: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    taxonomieService: PropTypes.object.isRequired,
    rubriceringService: PropTypes.object.isRequired,
    zoekService: PropTypes.object.isRequired,
    userService: PropTypes.object.isRequired,
    mediatheekService: PropTypes.object.isRequired,
    spelwijzerService: PropTypes.object.isRequired,
    socialService: PropTypes.object.isRequired,
    mijnMediatheekService: PropTypes.object.isRequired,
    arteveldeUser: PropTypes.object,
    cookies: PropTypes.object,
    onMijnMediatheekChange: PropTypes.func,
    directLink: PropTypes.object,
    onZoekresultaatClick: PropTypes.func,
    onReserveerClick: PropTypes.func,
    onCampus: PropTypes.bool
}

WebComponentZoek.defaultProps = {}
