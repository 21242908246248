import {LocaleContent} from "../class/locale/LocaleContent";
import {API_BASE_URL, API_KEY} from "../domain/path";
const axios = require('axios')


export class LocaleDataService {

    localeDataRef: string = ''
    minimalProps: string[] = []
    locales: string[] = ['nl', 'en']
    localeContentLookup: object = {}


    /*
    * De LocaleDataService klasse houdt een repository bij van localeData en laadt en bewaart die data indien nodig
    * Elke instantie van deze klasse bekommert zich om één ref: bv. user, faq, news, ...
    *
    * De lookup houdt refId's bij
    *
    * Arguments:
    * apiUrl: endpoint naar de centrale api (V1, V2, ...)
    * apiKey: security token
    * localeDataRef: verwijst naar het 'ref' veld in de database. Bv. 'user', 'faq', ...
    * minimalProps: array van properties die elk LocaleData object MOET bevatten. Indien één van deze velden niet
     * bestaat wordt het aangemaakt, één voor elke locale
     *

    * */


    constructor(localeDataRef: string, minimalProps?: string[]) {
        this.localeDataRef = localeDataRef
        if (minimalProps)
            this.minimalProps = minimalProps
    }

    getLocaleData = (refId) => {

        let me = this

        return new Promise(function (resolve, reject) {

            if (me.localeContentLookup[refId]) {
                resolve({localeContent: me.localeContentLookup[refId], source: 'cache'})
            } else {
                //https://www.arteveldehogeschool.be/mediatheken/api/v1/epub/?token=qwertyuiopasdfghjklzxcvbnm&transform=1&order[]=epub
                let url = `${API_BASE_URL}localeContent?filter[]=ref,eq,${me.localeDataRef}&filter[]=refId,eq,${refId}&transform=1&order[]=locale,asc&token=${API_KEY}`
                axios.get(url)
                    .then(response => {
                        //debugger
                        let localeContent = response.data.localeContent;
                        let missingContent = me.getMissingLocaleContent(refId, localeContent);

                        if (missingContent.length > 0) {
                            //create missing items
                            let url2 = `${API_BASE_URL}localeContent?transform=1&token=${API_KEY}`
                            axios.post(url2, missingContent)
                                .then(response2 => {
                                    console.log('IDS', response2);
                                    //we kunnen nu de ids aan de missing content toewijzen:

                                    response2.data.forEach((id, key) => {
                                        missingContent[key].id = id
                                    })

                                    //en dan de 2 arrays samensmelten
                                    localeContent = localeContent.concat(missingContent)
                                    console.log('LOCALE CONTENT', localeContent)

                                    me.localeContentLookup[refId] = localeContent
                                    resolve({refId, localeContent, source: 'server|newLocales'})
                                })
                                .catch(error => console.log('ERROR CREATING LOCALE CONTENT', error))
                        } else {
                            me.localeContentLookup[refId] = localeContent
                            resolve({refId, localeContent, source: 'server'})
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        reject && reject(error)
                    });
            }


        });
    }

    getPropertyValue = (refId, property, locale) => {
        let me = this
        if (me.localeContentLookup[refId]) {
            const obj = me.localeContentLookup[refId].reduce((acc, value) => {
                if (value.field === property && value.locale === locale) return value.content
                return acc
            }, '')
            return obj
        } else {
            return 'no locale data'
        }
    }

    setPropertyValue = (refId, property, locale, value): any => {

        if (this.localeContentLookup[refId]) {
            const data = this.localeContentLookup[refId].reduce((acc, vo) => {
                if (vo.field === property && vo.locale === locale) return vo
                return acc
            }, null)

            if (data) data.content = value
        } else {
            return 'no locale data'
        }
    }

    saveLocaleData = (localeDataArray) => {

        return new Promise((resolve, reject) => {

                let localeIds = localeDataArray.map(item => item.id).join(',')
                let url = `${API_BASE_URL}localeContent/${localeIds}?&token=${API_KEY}`

            console.log('LOCALE DATA URL', url, localeDataArray)
                axios.put(url, localeDataArray)
                    .then(response => {
                        console.log('RESP', response, localeDataArray)
                        resolve(response)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject && reject(error)
                    });
            }
        )
    }

    deleteLocaleData = (refId) => {

        let localeData = this.localeContentLookup[refId]
        return new Promise((resolve, reject) => {

                let localeIds = localeData.map(item => item.id).join(',')
                let url = `${API_BASE_URL}localeContent/${localeIds}?&token=${API_KEY}`

                axios.delete(url)
                    .then(response => {
                        resolve({response, deleted: localeIds})
                    })
                    .catch((error) => {
                        console.log(error)
                        reject && reject(error)
                    });
            }
        )
    }

    /*injectInstanceProperties = (instance: any, locale: string) => {

        this.minimalProps.forEach(prop => {
            const data = this.localeContentLookup[instance.refId]
            if (data) {
                const data2 = data.filter(obj => obj.field === prop && (obj.content === locale || obj.content !== ''))[0]
                console.log('INJECDTING', locale, instance, prop, data, data2)
                if (data2) {
                    instance[prop] = data2.content
                } else {
                    instance[prop] = ''
                }
            }
        })
    }*/

    private getMissingLocaleContent = (refId, availableLocaleContent) => {
        let rv: LocaleContent[] = []

        this.locales.forEach(locale => {
            this.minimalProps.forEach(field => {
                let foundItem = availableLocaleContent.filter(item => item.locale === locale && item.field === field)
                if (foundItem.length === 0) {
                    let lc = {...new LocaleContent(), locale, field, ref: this.localeDataRef, refId: refId}
                    rv.push(lc)
                }
            })
        })

        return rv;
    }

    private substitute = (input) => {
        //ICONS
        /*
        * [[ICON trash]]

[[ICON cog loading]]
* */
        let regex
        regex = /\[\[ICON ([a-z ]*)\]\]/gm
        input = input.replace(regex, '<i aria-hidden="true" class="$1 icon"></i>')

        return input
    }

}
