import {AbstractZoekresultaat} from "./AbstractZoekresultaat";
import {BidocKoppeling} from "../bidoc/BidocKoppeling";
import {TaxonomieItem} from "../taxonomie/TaxonomieItem";
import {LocaleManager} from "../../service/LocaleManager";
import {ApplicationPath} from "../../domain/path";


export class ZoekresultaatSpelwijzer extends AbstractZoekresultaat {

    bidocKoppeling: BidocKoppeling
    spel: any
    titelid: any
    materiaal: any

    constructor(bidocKoppeling: BidocKoppeling, spel: any) {
        super()
        this.bidocKoppeling = bidocKoppeling
        //this.spel = spel
        for (var prop in spel) {
            this[prop] = spel[prop]
            //console.log('PROP', prop, this.spel[prop])
        }
        this.publicatietype = spel.materiaal
    }

    /*
    * DIRECTE LINK
    * */
    get directeLink() {
        return `${ApplicationPath.linkWebportaal}/catalogus/${this.id}`
    }


    //OLD
    get tijdsduur(): string {
        //return this.tijdsduurTaxonomieItems.map(ti => this.localeManager.getString(ti.code)).join(" | ")
        return this.tijdsduurTaxonomieItems.map(ti => ti.code).join(" | ")
    }

    //NEW
    getTijdsduur = (localeManager: LocaleManager) => {
        return this.tijdsduurTaxonomieItems.map(ti => localeManager.getString(ti.code)).join(" | ")
    }

    /*
    * HANDLEIDING URL
    * */
    get handleidingUrl() {
        if (this.bidocKoppeling && this.bidocKoppeling.handleidingUrl)
            return this.bidocKoppeling.handleidingUrl

        return null
    }

    /*
    * WERKVORMEN URL
    * */
    get werkvormenUrl() {
        if (this.bidocKoppeling && this.bidocKoppeling.werkvormenUrl)
            return this.bidocKoppeling.werkvormenUrl

        return null
    }

    tijdsduurTaxonomieItems: TaxonomieItem[] = []

    get aantal_spelers(): string {
        let rs = ""
        if (this.bidocKoppeling.minSpelers) rs += 'Minimum ' + this.bidocKoppeling.minSpelers
        if (this.bidocKoppeling.minSpelers && this.bidocKoppeling.maxSpelers) rs += ' & '
        if (this.bidocKoppeling.maxSpelers) rs += 'Maximum ' + this.bidocKoppeling.maxSpelers

        return rs
    }

    get minSpelers(): string {
        if (!this.bidocKoppeling.minSpelers) return ""
        return this.bidocKoppeling.minSpelers.toString()
    }

    get maxSpelers(): string {
        if (!this.bidocKoppeling.maxSpelers) return ""
        return this.bidocKoppeling.maxSpelers.toString()
    }

    get bron(): string {
        return 'bidoc'
    }

    set bron(_value) {
    }

    get coverLink() {
        return '//www.arteveldehogeschool.be/mediatheken/root/spelwijzerFotos/_800/' + this.bidocKoppeling.bidocId + '_cover.jpg'
    }

    set coverLink(_value) {
    }

    get coverLinkSmall() {
        return '//www.arteveldehogeschool.be/mediatheken/root/spelwijzerFotos/_70/' + this.bidocKoppeling.bidocId + '_cover.jpg'
    }

    set coverLinkSmall(_value) {    //do nothing
    }

    get id() {
        return this.titelid
    }

    set id(_value) {
    }

    get metaString(): string {
        return this._metaString
    }

    set metaString(_value) {
    }

    get uitgelicht(): number {
        return this.bidocKoppeling.uitgelicht
    }

    set uitgelicht(value) {
    }

    getProps() {
        //subtitel en beschrijving worden apart gerendered
        return ["bidocKoppeling", "tijdsduur", "handleidingUrl", "werkvormenUrl", "aantal_spelers", "DIVIDER", "auteurs", "jaar", "uitgever", "jaargang", "jaargangnummer", "bladzijden", "publicatietype", "opleiding", "reeksnummer", "reeks", "tijdschrift", "mediatheek", "exemplaar", "trefwoorden", "annotatie", "categorie", "isbn", "issn", "campus", "directe_Link", "apa"];
    }
}
