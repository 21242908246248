import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {Menu} from "semantic-ui-react";
import {version} from '../version'
import {LocaleDataService, Nieuws, NieuwsType, SLIM_UPLOAD_URL} from "@ahsmediatheek/core";
import {DataList} from '@ahsmediatheek/ui-component'
import {DynamicFilter} from '@ahsmediatheek/med-component-dynamic-filter'
import NieuwsDetail from "./NieuwsDetail";
import UrgentNieuws from "./UrgentNieuws";

const NIEUWS = 'nieuws'
const URGENT_NIEUWS = 'urgentNieuws'

const BoModuleNieuws = ({onLoad, userService, taxonomieService, nieuwsService}) => {

    const [activeMenuItem, setActiveMenuItem] = useState(NIEUWS)
    const [localeContentNieuws, setLocaleContentNieuws] = useState()
    const [localeDataServiceNieuws] = useState(new LocaleDataService('news', ['caption', 'title', 'summary', 'body']))
    const [initialized, setInitialized] = useState(false)
    const [baseCollection, setBaseCollection] = useState()
    const [dynamicFilterCollection, setDynamicFilterCollection] = useState()
    const [filteredCollection, setFilteredCollection] = useState([])
    const [searchString] = useState('')
    const [selectedNieuws, setSelectedNieuws] = useState()


    useEffect(() => {

        Promise.all([nieuwsService.init(), userService.init(), taxonomieService.init()]).then(res => {
            onLoad && onLoad(version)
            setFilteredCollection(nieuwsService.nieuwsList)
            setBaseCollection(nieuwsService.nieuwsList)
            setDynamicFilterCollection(nieuwsService.nieuwsList)

            setInitialized(true)
        })
    }, [nieuwsService, userService, taxonomieService, onLoad])

    const handleMenuItemClick = (e, {name}) => setActiveMenuItem(name)

    const onNieuwsUpdate = (nieuws, changedLocales) => {
        //wijziging data updaten
        nieuws.datum_wijziging = moment().format('YYYY-MM-DD')
        nieuws.user_wijziging = userService.loginUser.id

        nieuwsService.nieuwsLookup[nieuws.id].title = nieuws.title

        if (changedLocales && changedLocales.length > 0) {
            for (let localeContentObject of changedLocales) {
                if (localeContentObject.field === 'title' && localeContentObject.locale === 'nl')
                    nieuws.title = localeContentObject.content.replace(/<[^>]*>/g, '') // localeContentObject.plainText

                delete(localeContentObject.plainText)
            }

            localeDataServiceNieuws.saveLocaleData(changedLocales).then(result => {
                //console.log('SAVED', result)
            })
        }

        nieuwsService.updateNieuws(nieuws).then(({nieuwsList}) => {
            setSelectedNieuws({...nieuws})
            setFilteredCollection(filteredCollection.map(n => {
                if (n.id === nieuws.id) return nieuws
                return n
            }))
            }
        )
    }

    const onNieuwsDelete = () => {
        const warn = window.confirm('Ben je zeker dat je dit nieuws item wil verwijderen?')

        if (!warn) return

        const nieuwsId = selectedNieuws.id
        nieuwsService.deleteNieuws(selectedNieuws).then(() => {
            setFilteredCollection(filteredCollection.filter(n => n.id !== nieuwsId))
            setSelectedNieuws(null)
            setLocaleContentNieuws(null)
        })

        localeDataServiceNieuws.deleteLocaleData(nieuwsId)
            .then((response) => console.log('LOCALE DATA DELETED', response))
    }

    const onNieuwsAdd = () => {

        setSelectedNieuws(null)

        let newNieuws = new Nieuws()
        newNieuws.title = 'Nieuw nieuws item'
        newNieuws.date = moment().format('YYYY-MM-DD')
        newNieuws.datum_wijziging = moment().format('YYYY-MM-DD')
        newNieuws.fromDate = moment().format('YYYY-MM-DD')
        newNieuws.untilDate = moment().add(7, 'days').format('YYYY-MM-DD')
        newNieuws.user_aanmaak = userService.loginUser.id
        newNieuws.user_wijziging = userService.loginUser.id
        newNieuws.type = NieuwsType.IMAGE

        nieuwsService.createNieuws(newNieuws).then(({nieuws}) => {
            //now create and collect locale content
            localeDataServiceNieuws.getLocaleData(nieuws.id)
                .then(({localeContent}) => {
                    setFilteredCollection([newNieuws, ...filteredCollection])
                    setSelectedNieuws(newNieuws)
                    setLocaleContentNieuws(localeContent)
                    }
                )

        })
    }

    const onSearchStringChange = (searchString) => {
        //setSearchString(value.toLowerCase())
        if (searchString === '') {
            setFilteredCollection(dynamicFilterCollection)
        } else {

            setFilteredCollection(dynamicFilterCollection.filter(nieuws => nieuws.title.toLowerCase().indexOf(searchString) > -1))
        }
    }

    const rowRendererStyleFunction = (item) => {

        const isBetween = moment().isBetween(item.fromDate, item.untilDate)

        if (item.online === 1 && isBetween)
            return 'nieuws-is-online'

        return ''
    }

    const onFilteredCollectionChange = (coll) => {

        if (coll.indexOf(selectedNieuws) < 0) setSelectedNieuws(null)
        setDynamicFilterCollection(coll)
        const fc = searchString === '' ? coll : coll.filter(nieuws => nieuws.title.indexOf(searchString) > -1)

        setFilteredCollection(fc)
    }

    const onNieuwsClick = (nieuws) => {
        localeDataServiceNieuws.getLocaleData(nieuws.id)
            .then((response) => {
                    setSelectedNieuws(nieuws)
                    setLocaleContentNieuws(response.localeContent)
                }
            )
    }

    if (!initialized) return <div>Laden...</div>

    return (

        <div className="module-container">


            <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <Menu pointing secondary>
                    <Menu.Item name={NIEUWS} active={activeMenuItem === NIEUWS}
                               onClick={handleMenuItemClick}>Nieuws</Menu.Item>
                    <Menu.Item name={URGENT_NIEUWS} active={activeMenuItem === URGENT_NIEUWS}
                               onClick={handleMenuItemClick}>Dringend nieuws</Menu.Item>
                </Menu>

                {activeMenuItem === NIEUWS &&
                <div className="module-container">

                    <DynamicFilter dataCollection={baseCollection}
                                   filteredCollectionChange={onFilteredCollectionChange}
                                   model={
                                       {
                                           online: {categoryLabelFunction: () => 'Online', labelFunction: () => 'online'},
                                           type: {categoryLabelFunction: () => 'Type'},
                                           /*fromDate: {
                                               categoryLabel: 'Zichtbaar op site',
                                               labelFunction: () => 'Nu online',
                                               dataFunction: (date, nieuws) => {
                                                   const isBetween = moment().isBetween(nieuws.fromDate, nieuws.untilDate)
                                                   console.log('DF', nieuws, nieuws.untilDate, isBetween)
                                                   return (nieuws.online === 1 && isBetween) ? 'Zichtbaar' : ''
                                               }}*/
                                       }
                                   }/>


                    <DataList duplicateable={false} filteredCollection={filteredCollection}
                              onItemSelect={onNieuwsClick}
                              onSearchChange={onSearchStringChange}
                              onAddButtonClick={onNieuwsAdd}
                              onDeleteButtonClick={onNieuwsDelete}
                              addButtonTootltip='Nieuw nieuwsitem'
                              selectedItem={selectedNieuws}
                              itemLabelFunction={(nieuws) => nieuws.title}
                              deleteButtonTooltip='Verwijder nieuws item'
                              rowRendererStyleFunction={rowRendererStyleFunction}/>

                    {selectedNieuws && localeContentNieuws &&
                    <NieuwsDetail
                        selectedNieuws={selectedNieuws}
                        localeContent={localeContentNieuws}
                        onDetailFormUpdate={onNieuwsUpdate}
                        onNieuwsSave={onNieuwsUpdate}
                        userService={userService}
                        taxonomieService={taxonomieService}
                        nieuwsService={nieuwsService}
                        imageUploadUrl={SLIM_UPLOAD_URL}
                    />
                    }

                    {!selectedNieuws &&
                    <div className="no-detail">Selecteer een nieuws item.</div>
                    }

                </div>

                }

                {activeMenuItem === URGENT_NIEUWS &&
                <div className="module-container">
                    <UrgentNieuws nieuwsService={nieuwsService} userService={userService}/>
                </div>
                }


            </div>
        </div>
    )
}

export default BoModuleNieuws

BoModuleNieuws.propTypes = {
    onLoad: PropTypes.func,
    userService: PropTypes.object.isRequired,
    taxonomieService: PropTypes.object.isRequired,
    nieuwsService: PropTypes.object.isRequired

}

BoModuleNieuws.defaultProps = {}
