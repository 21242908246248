import React from 'react'
import PropTypes from 'prop-types'
import {Image} from "semantic-ui-react"
import {ApplicationPath} from 'med-class'

const MediatheekpuntTile = ({mediatheek, onClick, mediatheekService, localeManager}) => {


    if (!mediatheek || !mediatheek.campusCode)
        return null

    return (
        <div style={{
            margin: '10px',
            cursor: 'pointer',
            width: '100%',
            backgroundColor: '#eff6fa',
            fontSize: '1.2em',
            fontWeight: 'bold',
            padding: '10px',
            minHeight: '100px',
            display: 'flex',
            alignItems: 'center', justifyContent: 'center'
        }}
             onClick={() => onClick(mediatheek)}>
            <Image size='tiny' src={ApplicationPath.mediatheekAssetsFolder + 'logos/mediatheek/logo_mediatheekpunt.png'}/>
            <div style={{marginRight: '10px'}}/>
            Mediatheekpunt {mediatheek.naam}
        </div>
    )
}

export default MediatheekpuntTile

MediatheekpuntTile.propTypes = {
    mediatheekService: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    mediatheek: PropTypes.object.isRequired,
    onClick: PropTypes.func,

}

MediatheekpuntTile.defaultProps = {}