import {AbstractZoekresultaat} from "../zoekresultaat/AbstractZoekresultaat";

export class BidocUitlening extends AbstractZoekresultaat{
	
	_explicitType: string = "BidocUitlening"
	
	aanwinstnummer: string
	uitgeleendOp: string
	datumTerug: string
	isTeLaat: boolean
	voorlopigeBoete: number
	titel: string
	auteur: string
	publicatietype: string
	titelId: string
	verlengbaar: boolean
	verlengBoodschap: string
	//aanwinstnummers
	boete: number
	lenersnummer: string
	
}
