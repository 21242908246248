import {ApplicationPath} from "../domain/path";
import {convertObjectToClass} from "../util/ClassConvertor";
import {Faq} from "../class/faq/Faq";
const _ = require('lodash')
const axios = require('axios')


export class FaqService {

    private readonly FAQ_TABLE: string = 'faq'
    private readonly LOCALE_CONTENT_TABLE: string = 'localeContent'

    initialized: boolean = false
    faqs: Array<Faq>
    faqLookup: object
    faqLocaleContentLookup: object
    faqSearchStringLookup: object
    apiUrl: string
    apiKey: string

    constructor(apiUrl: string, apiKey: string) {
        this.apiUrl = apiUrl
        this.apiKey = apiKey
        this.faqs = []
        this.faqLookup = {}
        this.faqSearchStringLookup = {}
    }

    init = () => {

        let me = this

        return new Promise((resolve) => {

                if (me.initialized) {
                    resolve({status: 'ok', faqs: me.faqs})
                    return
                }

                this.getFaqs().then((response: any) => {

                    me.initialized = true
                    me.faqs = response.faqs
                    me.faqLookup = response.faqLookup
                    me.generateFaqSearchStringLookup()

                    resolve({status: 'ok', faqs: me.faqs, faqLookup: me.faqLookup})
                }).catch((error) => {
                    console.log('ERROR IN Faq INIT', error)
                })
            }
        )

    }

    private getFaqs() {

        let me = this

        return new Promise(function (resolve, reject) {
            let url = `${me.apiUrl}${me.FAQ_TABLE}?transform=1&token=${me.apiKey}`
            axios.get(url)
                .then(response => {
                    let rawFaqList = response.data[me.FAQ_TABLE];
                    let faqs = rawFaqList.map((rt: any) => convertObjectToClass(rt, 'Faq'))
                    faqs = _.orderBy(faqs, [faq => faq.question.toLowerCase()])
                    let faqLookup = _.keyBy(faqs, faq => faq.id)
                    resolve({faqs, faqLookup})
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    getFaqLocaleContentList = () => {

        let me = this

        return new Promise(function (resolve, reject) {

            if (me.faqLocaleContentLookup){
                resolve(me.faqLocaleContentLookup)
                return
            }

            let url = `${me.apiUrl}${me.LOCALE_CONTENT_TABLE}?transform=1&token=${me.apiKey}&filter[]=ref,eq,faq&filter[]=field,eq,question`
            axios.get(url)
                .then(response => {
                    let rawLocaleContent = response.data[me.LOCALE_CONTENT_TABLE];
                    let localeContent = rawLocaleContent.map((rt: any) => convertObjectToClass(rt, 'LocaleContent'))
                    let faqLocaleContentLookup = {}
                    for (const localeContentElement of localeContent) {
                        if (!faqLocaleContentLookup[localeContentElement.refId]) faqLocaleContentLookup[localeContentElement.refId] = {}
                        faqLocaleContentLookup[localeContentElement.refId][localeContentElement.locale] = localeContentElement
                    }
                    me.faqLocaleContentLookup = faqLocaleContentLookup

                    me.generateFaqSearchStringLookup()

                    resolve({faqLocaleContentLookup})
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    updateFaq(faq) {

        let me = this

        let url = `${me.apiUrl}${me.FAQ_TABLE}/${faq.id}?&token=${me.apiKey}`

        return new Promise((resolve, reject) => {
                axios.put(url, faq)
                    .then((result) => {
                        //remap the faq
                        faq = convertObjectToClass(faq, 'Faq')
                        me.faqs = me.faqs.map(t => {
                            if (t.id === faq.id)
                                return faq;
                            return t;
                        })
                        me.faqLookup = _.keyBy(me.faqs, faq => faq.id)
                        resolve({faqs: me.faqs, faqLookup: me.faqLookup})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    createFaq(faq) {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.FAQ_TABLE}/?token=${me.apiKey}`
                axios.post(url, faq)
                    .then(response => {
                        faq.id = response.data;
                        me.faqs = _.concat(me.faqs, faq)
                        me.faqs = _.orderBy(me.faqs, [faq => faq.question.toLowerCase()])
                        me.faqLookup = _.keyBy(me.faqs, faq => faq.id)
                        resolve({faqLookup: me.faqLookup, faqs: me.faqs, faq})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    deleteFaq(faq) {

        let me = this

        //let deleteImageUrl = 'https://www.arteveldehogeschool.be/mediatheken/root/boDeleteImage.php'
        //let deleteImageDetail = {fileUrl: faq.link, mode: 'faqImage'}

        return new Promise((resolve, reject) => {
                const url = `${me.apiUrl}${me.FAQ_TABLE}/${faq.id}?token=${me.apiKey}`

                axios.delete(url)
                    .then((response) => {

                        console.log('FAQ DELETE RESP', response)
                        //remove from faqs and lookup
                        me.faqs = _.filter(me.faqs, t => t.id !== faq.id)
                        me.faqLookup = _.keyBy(me.faqs, faq => faq.id)

                        /*axios.post(deleteImageUrl, deleteImageDetail)
                          .then(response => {
                            console.log('DELETE RESPONSE', response)
                          });*/

                        resolve({faqs: me.faqs, faqLookup: me.faqLookup})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }


    generateFaqSearchStringLookup = () => {
        _.each(this.faqs, faq => {

            let lookupString = faq.teaser + faq.question + faq.answer + faq.tags

            if (this.faqLocaleContentLookup) {
                const localeContent = this.faqLocaleContentLookup[faq.id]
                for (const localeContentKey in localeContent) {
                    const content = localeContent[localeContentKey]
                    lookupString += content.content
                }
            }

            this.faqSearchStringLookup[faq.id] = lookupString.toLowerCase()
        })
    }

    getRandomFaq = () => {

        return new Promise(function (resolve, reject) {
            axios.get(ApplicationPath.faqApiUrl)
                .then(response => {
                    const faq = convertObjectToClass(response.data, 'Faq')
                    resolve(faq)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });

        });
    }
}
