export class ArteveldeUser {

    _explicitType: string = "ArteveldeUser"

    //Public variables
    barcode: string = ''
    emailAddress: string = ''
    givenName: string = '' //voornaam
    surname: string = '' //familienaam
    loggedIn: boolean = false
    upn: string = ''
    hasKaart: boolean

    get displayName(){
        return this.givenName + ' ' + this.surname
    }
}
