export class RubriekKast{
	
	_explicitType:string = 'RubriekKast'
	
	id:number = 0
	rubriek:string = ''
	rubriek_id: number = 0
	kast:string = ''
	mediatheek:string = ''

	fillFromKey = (key: string) => {
		const keyArr = key.split('_')
		this.rubriek_id = parseInt(keyArr[0])
		this.kast = keyArr[1]
		this.mediatheek = keyArr[2]
	}
}