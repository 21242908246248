import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import {ApplicationPath} from 'med-class'
import {MapController} from "../class/MapController";

const MiniMap = ({selectedPano, mediatheek, localeManager, panoNodeClick, getYaw}) => {

    const [mapController] = useState(new MapController(localeManager, panoNodeClick))

    useEffect(() => {

        console.log('EFFECT')
        return () => {
            console.log('UNMOUNTING')
            mapController.unmount()
        }
    }, [mapController])

    /*const yawHandler = () => {
        const yaw = getYaw()
        mapController.rotatePanoNode(yaw)
    }*/

    //const yawTimer = setInterval(yawHandler, 500)

    const onSvgLoaded = (error, svg) => {
        if (!mapController) return

        if (error) {
            console.error(error)
            return
        }

        mapController.init(svg)
        if (selectedPano) mapController.selectPano(selectedPano)
    }

    return (
        <div style={{display: 'flex'}}>
            <ReactSVG
                src={`${ApplicationPath.mediatheekSvgMapsFolder}${mediatheek}.svg`}
                className='map-svg'
                afterInjection={onSvgLoaded}/>
        </div>
    )
}

export default  MiniMap


MiniMap.propTypes = {
    localeManager: PropTypes.object.isRequired,
    mediatheek: PropTypes.string.isRequired,
    selectedPano: PropTypes.number,
    panoNodeClick: PropTypes.func,
    getYaw: PropTypes.func
}

MiniMap.defaultProps = {
    mediatheek: 'kat'
}