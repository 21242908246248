import React from 'react'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'
import {Label, Icon} from 'semantic-ui-react'

const TijdsduurRenderer = ({zoekresultaat, localeManager}) => {

    if (!zoekresultaat.tijdsduur) return null

    return (
        <AbstractZoekresultaatRenderer label={localeManager.getString('TIJDSDUUR')}>
            <Label basic color='teal' >
                <Icon name='stopwatch'/>
                {zoekresultaat.tijdsduur}
            </Label>

        </AbstractZoekresultaatRenderer>
    )
}

export default  TijdsduurRenderer