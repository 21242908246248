import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {Image, Button} from 'semantic-ui-react'

import Slim from '../slim/slim.react'
import '../slim/slim.min.css'


//slim auth code: 0108d97c-32ee-48f2-ba33-1f8a35d0b09f

const ImageSlimUploader = (props) => {

    const [mode, setMode] = useState('view')

    const slim = useRef()

    useEffect(() => {
        setMode('view')
    }, [props])

    // called when upload button is pressed or automatically if push is enabled
    const upload = (formdata, progress, success, failure, slim) => {
        const {onSuccess, onError, serviceUrl} = props

        let config = {
            onUploadProgress: (progressEvent) => {
                progress(progressEvent.loaded, progressEvent.total)
            }
        }
        console.log('UPLOADING', formdata, config)

        console.log('POSTING', serviceUrl)

        axios.post(serviceUrl, formdata, config).then(response => {

            console.log('POST POST', response)

            success()
            if (onSuccess) onSuccess(response.data)

            //setTimeout(this.onCancel, 4000)

        }).catch(err => {
            failure(err)
            if (onError) onError()
        })
    }


    const willSave = (data, ready) => {
        const {filenameFunction, uploadMode} = props

        if (filenameFunction) {
            data.meta.filename = filenameFunction(data)
        }

        data.meta.uploadMode = uploadMode

        ready(data)
    }

    /*const didLoad = (...props) => {
        console.log('DID LOAD', ...props)
    }*/

    const onEditButtonClick = () => {
        setMode('edit')
    }

    const onCancel = (...props) => {
        console.log('CANCEL', props)
        setMode('view')
    }

    const {imageUrl, ratio, minWidth, minHeight, forceSize, size, previewSize} = props

    return (

        <div style={{width: '400px', marginBottom: '10px'}}>

            {mode === 'view' &&
            <div>
                <Image style={{marginBottom: '10px'}} src={imageUrl} size={previewSize}/>
                <Button onClick={onEditButtonClick}>Wijzig</Button>
            </div>
            }

            {mode === 'edit' &&
            <Slim ref={slim}
                  label='Klik hier om een bestand te kiezen of sleep een afbeelding hiernaartoe'
                  buttonEditLabel='Wijzig'
                  buttonRemoveLabel='Verwijder'
                  buttonDownloadLabel='Downloaden'
                  buttonUploadLabel='Uploaden'
                  buttonCancelLabel='Annuleer'
                  buttonConfirmLabel='Bevestig'
                  buttonEditTitle='Wijzig'
                  buttonRemoveTitle='Verwijder'
                  buttonDownloadTitle='Downloaden'
                  buttonUploadTitle='Uploaden'
                  buttonCancelTitle='Annuleer'
                  buttonConfirmTitle='Bevestig'
                  statusUnknownResponse='<span class="slim-upload-status-icon"></span> Er heeft zich een onbekende fout voorgedaan'
                  statusUploadSuccess='<span class="slim-upload-status-icon"></span> Bewaard'
                  ratio={ratio}
                  push={true}
                  instantEdit={true}
                  initialImage={imageUrl}
                  minSize={{width: minWidth, height: minHeight}}
                  forceSize={forceSize}
                  size={size}
                  dataDidCancel={onCancel}

                  service={upload}
                  willSave={willSave}
            >
                <input type="file" name="slim"/>
            </Slim>
            }


        </div>
    )
}

export default ImageSlimUploader

ImageSlimUploader.propTypes = {
    imageUrl: PropTypes.string,
    serviceUrl: PropTypes.string.isRequired,
    uploadMode: PropTypes.string.isRequired,
    ratio: PropTypes.string,
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
    forceSize: PropTypes.string,
    size: PropTypes.string,
    onSave: PropTypes.func,
    filenameFunction: PropTypes.func,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    previewSize: PropTypes.oneOf(['mini', 'tiny', 'small', 'medium', 'large', 'big', 'huge', 'massive']),
}

ImageSlimUploader.defaultProps = {
    imageUrl: '',
    ratio: 'free',
    minWidth: 100,
    minHeight: 100,
    size: null,
    forceSize: '',
    previewSize: 'large'
}
