import React, {useEffect, useRef, useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Form, Modal, Button, Divider, Checkbox, Rating, Header, Image, Segment} from "semantic-ui-react";

import {Chatline, ApplicationPath} from 'med-class'
import orderBy from 'lodash/orderBy'
import keyBy from 'lodash/keyBy'
import ChatlineRenderer from "./ChatlineRenderer";
import AdminsAvailable from "./AdminsAvailable";
import IconBar from "./IconBar";
import ChatlineInput from "./ChatlineInput";
import Parser from "html-react-parser";

const newMessageSound = new Audio(ApplicationPath.mediatheekAssetsFolder + 'sound/message.mp3')

const ChatContentWeb = ({
                            client,
                            chat,
                            chatStatus,
                            chatService,
                            arteveldeUser,
                            eigenaar,
                            onCloseChat,
                            onStartRating,
                            adminsAvailable,
                            onChatlineClick,
                            onSubmitChatline,
                            onSelectFile,
                            playSound,
                            processChatlines
                        }) => {

    const [chatlines, setChatlines] = useState()
    const [whosTyping, setWhosTyping] = useState('')
    const [eigenaarRef, setEigenaarRef] = useState()
    const [rating, setRating] = useState(0)
    const [kopieNaarEigenaar, setKopieNaarEigenaar] = useState(false)
    const [ratingModalOpen, setRatingModalOpen] = useState(false)
    const [aanspreking] = useState(arteveldeUser.givenName || eigenaar)

    const chatlinesRef = useRef(null)


    //ISTYPING LISTENER
    useEffect(() => {
        if (!chat) return

        console.info('INIT ISTYPING LISTENER')
        chatService.db
            .collection('chats/' + chat.id + '/whosTyping')
            .onSnapshot(docSnapshot => {
                let wT = ''
                const data = keyBy(docSnapshot.docs, doc => doc.id)
                for (const user in data) {
                    if (user !== eigenaar && data[user].data().isTyping)
                        wT = user
                }
                setWhosTyping(wT)
            })
    }, [chat, chatService, eigenaar])

    //UNLOAD
    useEffect(() => {
        //wanneer het venster of tab gesloten wordt, dan moet ik offline gaan
        const onbeforeunloadFn = () => {
            console.log('BEFORE UNLOAD')
            if (chat) onCloseChat(chatlines, 0, arteveldeUser.loggedIn ? arteveldeUser : eigenaar)
        }

        window.addEventListener('beforeunload', onbeforeunloadFn);
        return () => {
            window.removeEventListener('beforeunload', onbeforeunloadFn);
        }
    }, [chatService])

    //CHATLINES LISTENER
    useEffect(() => {
        if (!chat || !eigenaar) return

        console.info('INIT CHAT LISTENER')
        setEigenaarRef(chatService.db.doc('chats/' + chat.id).collection('whosTyping').doc(eigenaar))
        //chatRef.current && chatRef.current.focus()

        chatService.db
            .collection('chatlines')
            .where('chat', '==', chat.id)
            .onSnapshot(docSnapshot => {
                const cl = orderBy(docSnapshot.docs, [(cl) => cl.data().timestamp, (cl) => cl.data().order], ['asc', 'asc'])
                processChatlines(cl)
                setChatlines(cl)
                if (chatlinesRef.current)
                    chatlinesRef.current.scrollTop = chatlinesRef.current.scrollHeight;
            })
    }, [chat, chatService, eigenaar])

    const onQuitChat = () => {
        setRatingModalOpen(false)
        setRating(0)
        onCloseChat(chatlines, rating, arteveldeUser.loggedIn ? arteveldeUser : eigenaar, kopieNaarEigenaar)
    }

    const forceCloseChat = (idleTime) => {
        chatService.createChatline(chat, null, `Chat werd automatisch afgesloten na ${idleTime / 1000 / 60} minuten inactiviteit`).then(res => {
            console.log('FORCING', res)
            onQuitChat()
        })
    }

    const onChangeRating = (event, {rating}) => {
        setRating(rating)
    }

    const stopButtonClick = () => {
        setRatingModalOpen(true)
        //onStartRating()
    }

    return (
        <Fragment>


            {chatStatus !== 'rating' &&
            <div ref={chatlinesRef} className='chat-line-container'>

                {chatlines && chatlines.map((chatline, index) => {
                    return <ChatlineRenderer key={index} chatline={chatline} onChatlineClick={onChatlineClick} chatService={chatService} eigenaar={eigenaar}/>
                })}

            </div>}

            {chatStatus === 'rating' && <div>
                <Header>Bedankt voor dit gesprek!</Header>
            </div>}

            <Divider/>

            {chatStatus !== 'rating' && <AdminsAvailable adminsAvailable={adminsAvailable}/>}

            {whosTyping !== '' && <div className='is-typing'>
                <Image avatar src={ApplicationPath.avatarApi + eigenaar}/>
                {` ${whosTyping} is aan het typen...`}
            </div>}

            <Form>

                {eigenaar && chatStatus !== 'rating' &&
                <Form.Field>

                    {eigenaar && chatStatus === 'idle' &&
                    <label>{chat ? 'Bericht' : `Hallo ${aanspreking}, we zijn beschikbaar, wat wil je vragen?`}</label>
                    }


                    {eigenaar &&
                    <ChatlineInput client={client}
                                   chat={chat}
                                   eigenaarRef={eigenaarRef}
                                   onSubmitChatline={onSubmitChatline}
                                   onSelectFile={onSelectFile}
                                   onStartRating={onStartRating}
                                   showIconBar={chatStatus !== 'idle' && arteveldeUser.loggedIn}
                                   forceCloseChat={forceCloseChat}/>
                    }

                    {chatStatus === 'active' &&
                    <Fragment>
                        <p>Vergeet niet om de chat af te sluiten als je vraag goed werd beantwoord aub.</p>
                        <Form.Button content='Chat beëindigen' onClick={() => setRatingModalOpen(true)}/>
                    </Fragment>
                    }

                </Form.Field>
                }

                <Modal open={ratingModalOpen} dimmer='inverted'
                       onClose={() => setRatingModalOpen(false)}>
                    <Modal.Header>Chat afsluiten</Modal.Header>

                    <Modal.Content>
                        <Fragment>
                            <p>Als je wil, kan je dit gesprek beoordelen, waarvoor alvast dank!</p>
                            <Rating icon='star' maxRating={5} size='massive' rating={rating} onRate={onChangeRating}/>

                            {arteveldeUser.loggedIn &&
                            <Fragment>
                                <Divider/>
                                <Checkbox label='Stuur me een transcriptie van dit gesprek' checked={kopieNaarEigenaar} onClick={(event, {checked}) => setKopieNaarEigenaar(checked)}/>
                            </Fragment>
                            }
                        </Fragment>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button content='Verder chattten' onClick={() => setRatingModalOpen(false)}/>
                        <Button color='red' content='Afsluiten' onClick={onQuitChat}/>
                    </Modal.Actions>
                </Modal>


            </Form>


        </Fragment>
    )
}

export default ChatContentWeb

ChatContentWeb.propTypes = {
    eigenaar: PropTypes.string,
    chatStatus: PropTypes.string,
    adminsAvailable: PropTypes.array,
    beschikbaar: PropTypes.bool,
    chat: PropTypes.object,
    arteveldeUser: PropTypes.object.isRequired,
    client: PropTypes.string, //web or bo
    onStartRating: PropTypes.func,
    onCloseChat: PropTypes.func,
    chatService: PropTypes.object,
    injectedDefaultAnswer: PropTypes.object,
    onChatlineClick: PropTypes.func,
    onSubmitChatline: PropTypes.func,
    onSelectFile: PropTypes.func,
    playSound: PropTypes.func,
    processChatlines: PropTypes.func
}

ChatContentWeb.defaultProps = {}