import React from 'react'
import PropTypes from 'prop-types'
import {Segment, Button} from 'semantic-ui-react'


const SaveBar = ({onCancelClick, onSaveClick}) => {
    return (
        <Segment className='save-bar' textAlign='center' inverted tertiary>
            <Button size='tiny' onClick={onCancelClick}>Annuleer</Button>
            <Button type='submit' size='tiny' color='green' onClick={onSaveClick}>Bewaar</Button>
        </Segment>
    )
}


SaveBar.propTypes = {
    onCancelClick: PropTypes.func,
    OnSaveClick: PropTypes.func
}

export default SaveBar
