export class SoftwareColor {

  static readonly NONE: string = 'blue'
  static readonly MEDIATHEEK_SOFTWARE_BACKOFFICE: string = '#5c7186'
  static readonly MEDIATHEEK_SOFTWARE_REZERVEJO: string = '#9c9809'
  static readonly MEDIATHEEK_SOFTWARE_WEBSITE: string = '#52886a'
  static readonly MEDIATHEEK_SOFTWARE_DIGITAALARCHIEF: string = '#cb1009'
  static readonly MEDIATHEEK_SOFTWARE_COCOS: string = '#88127d'

  static readonly getSoftwareColor = (software: string): string => {
    if (software === '' || software === undefined) return SoftwareColor.NONE

    return SoftwareColor[software.toUpperCase()]
  }
}
