import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {ChromePicker, GithubPicker, SketchPicker} from 'react-color';
//import styles from '../styles.css'

const ColorPicker = ({name, colors, color, colorChange}) => {

    const [selectedColor, setSelectedColor] = useState()
    const [cpVisible, setCpVisible] = useState(false)

    useEffect(() => {
        setSelectedColor(color)
    }, [color])

    const onTileClick = (event) => {
        setCpVisible(!cpVisible)
    }

    const handleChangeComplete = (color) => {
        setSelectedColor(color)
        setCpVisible(!colors)

        //wijziging 20/9/2019: named props! >> aanpassen in bo-module-huisstijl
        colorChange && colorChange({hex: color.hex, name, color})
    }

    const onCloseColorPicker = (event) => {
        if (event.target === event.currentTarget)
            setCpVisible(false)
    }

    const showChromePicker = false
    const showSketchPicker = cpVisible //cpVisible && !colors
    const showGithubPicker = false //cpVisible && colors

    return (
        <div className='colorPickerWrapper' onClick={onCloseColorPicker}>

            <div className='colorPicker' style={{backgroundColor: selectedColor}} onClick={onTileClick}/>
            <div style={{position: 'absolute', zIndex: '2'}}>
                <div>
                    {showChromePicker &&
                    <ChromePicker
                        color={selectedColor} disableAlpha={false}
                        onChangeComplete={handleChangeComplete}/>
                    }
                    {showGithubPicker &&
                    <GithubPicker color={selectedColor} colors={colors}
                                  onChangeComplete={handleChangeComplete}/>
                    }
                    {showSketchPicker &&
                    <SketchPicker
                        color={selectedColor} disableAlpha={false} presetColors={colors}
                        onChangeComplete={handleChangeComplete}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default ColorPicker

ColorPicker.propTypes = {
    name: PropTypes.string,
    colors: PropTypes.array,
    color: PropTypes.string,
    colorChange: PropTypes.func
}

ColorPicker.defaultProps = {
    colors: [],
    color: "#fff"
}
