import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import {ImageSlimUploader} from '@ahsmediatheek/ui-component'
import {ApplicationPath} from '@ahsmediatheek/core'
import Parser from 'html-react-parser'

const NieuwsDetailImage = ({nieuwsService, nieuws, imageUploadUrl, update}) => {

    const [uploadLog, setUploadLog] = useState()
    const [imageUrl, setImageUrl] = useState()

    useEffect(() => {
        //setImageUrl(nieuws.imagePath + '?time=' + new Date().time)
        setImageUrl(ApplicationPath.nieuwsImagesRootFolder + 'nieuws_' + nieuws.id + '.jpg?time=' + new Date().time)
    }, [nieuws])

    const onUploadAfbeeldingSave = (response) => {
        console.log('AFB SAVE', response)

        nieuws.imagePath = ApplicationPath.nieuwsImagesRootFolder + response.filename
        //onmiddellijk bewaren zonder _isDirty te gebruiken
        nieuwsService.updateNieuws(nieuws).then(resp => {
            console.log('RESP', resp, nieuws)
            setUploadLog(response.log)
        })

    }

    const testDelete = () => {
        console.log('TEST DELETE')
    }

    console.log('NIEUWS RENDER', uploadLog, imageUrl)
    return (
        <Fragment>
            {/*<button onClick={testDelete}>Test delete</button>*/}
            <strong>Wenneer je een beeld toegevoegd of vervangen hebt, dan uw browser refreshen om de preview up te daten aub.</strong>
            <ImageSlimUploader serviceUrl={imageUploadUrl}
                               uploadMode='nieuwsImage'
                               filenameFunction={() => `nieuws_${nieuws.id}`}
                               forceSize='960,540'
                               imageUrl={imageUrl}
                               onSuccess={onUploadAfbeeldingSave}/>

            <p>Test link: <a href={nieuws.link} target='_blank' rel="noopener noreferrer">{nieuws.imagePath}</a></p>

            {uploadLog &&
            <div>{Parser(uploadLog)}</div>
            }

        </Fragment>
    )
}

export default  NieuwsDetailImage

NieuwsDetailImage.propTypes = {
    nieuwsService: PropTypes.object.isRequired,
    nieuws: PropTypes.object.isRequired,
    imageUploadUrl: PropTypes.string.isRequired,
    update: PropTypes.func.isRequired
}

NieuwsDetailImage.defaultProps = {

}
