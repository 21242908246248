export class CustomUrlSearchParams {

    _url
    _params = {}


    constructor(href) {
        this._url = new URL(href)
        this.parse()
    }

    parse = () => {

        this.parseSearch()

        if (this.isValidDl()) return

        this.parseRestful()

        //console.log('PARAMS', this._params)
    }

    parseRestful = () => {
        let rawRestString
        const wsIndex = this._url.pathname.indexOf('/ws/')
        if (wsIndex > -1) rawRestString = this._url.pathname.substr(wsIndex + 4)

        if (!rawRestString || rawRestString === '') return

        const rawRestArr = rawRestString.split('/')
        //structuur: dl/id

        if (rawRestArr.length === 0) return
        this._params.dl = rawRestArr[0]
        if (rawRestArr.length > 1) this._params.id = rawRestArr[1]

        if (this._params.dl === 'catalogus') this._params.dl = 'bidoc'
        switch(this._params.dl){
            case 'bidoc':
            case 'epub':
            case 'ebsco':
                this._params.src = this._params.dl
                this._params.dl = 'search'
                break
            default:
                break
        }
    }

    parseSearch = () => {
        const rawString = this._url.search.substr(1)
        const keyValuePairs = rawString.split('&')
        for (const keyValuePair of keyValuePairs) {
            if (keyValuePair !== '') {
                const propArray = keyValuePair.split('=')
                if (propArray.length === 2) this._params[propArray[0]] = propArray[1]
            }
        }
    }

    isValidDl = () => {
        return this.has('dl')
    }

    get = (prop) => {
        return this._params[prop]
    }

    has = (prop) => {
        return this._params.hasOwnProperty(prop)
    }
}