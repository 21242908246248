import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Card, Image, Icon, Label} from 'semantic-ui-react'
import {ZoekUtil} from 'med-class'
import _ from 'lodash'

const Parser = require('html-react-parser')
const MAX_AUTEURS = 5

const ZoekresultaatCard = ({zoekresultaat, localeManager, taxonomieService, directLink, onCardClick, onCoverClick}) => {

    const [showAllAuteurs, setShowAllAuteurs] = useState(false)
    const [auteursArray, setAuteursArray] = useState([])
    const [isKiosk] = useState(directLink && directLink.isKiosk())

    useEffect(() => {
        setAuteursArray(showAllAuteurs ? zoekresultaat.auteurs : _.take(zoekresultaat.auteurs, 5))
    }, [showAllAuteurs, zoekresultaat])

    const onLocalCardClick = (event) => {
        event.preventDefault()
        onCardClick(zoekresultaat)
    }
    const onLocalCoverClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        onCoverClick(zoekresultaat)
    }

    const getShowMoreLabel = () => {

        if (showAllAuteurs) return null
        if (zoekresultaat.auteurs.length <= MAX_AUTEURS) return null

        const label = localeManager.getString('EN_X_MEER').replace('[num]', zoekresultaat.auteurs.length - MAX_AUTEURS) + '...'
        return <Label style={{marginTop: '10px', cursor: 'pointer'}}
                      size='small'
                      content={label}
                      onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          setShowAllAuteurs(true)
                      }
                      }/>
    }

    return (
        <Card as='div' style={{margin: '15px', cursor: 'pointer', border: zoekresultaat.uitgelicht === 1 ? 'teal solid' : 'none'}} onClick={onLocalCardClick}>

            <Card.Content as="a" href={zoekresultaat.directeLink}>

                {zoekresultaat.exacteMatch === 1 &&
                <div className='zoekresultaat-exacte-match-card'>
                    <Icon name="hand point right outline"/>
                    {localeManager.getString('EXACTE_MATCH')}!
                </div>}

                {zoekresultaat.uitgelicht === 1 &&
                <div className='zoekresultaat-uitgelicht-card'>
                    <Icon name="hand point right outline"/>
                    {localeManager.getString('UITGELICHT')}!
                </div>
                }

                {zoekresultaat.coverLink &&
                <Image style={{marginBottom: '10px'}} src={zoekresultaat.coverLink} onClick={onLocalCoverClick} onError={e => e.target.style.display = 'none'}/>
                }
                <Card.Header style={{marginBottom: '3px'}}>{Parser(zoekresultaat.titel)}</Card.Header>

                <div style={{fontSize: '0.85em', color: 'grey', marginBottom: '5px'}}>
                    {zoekresultaat.subtitelArray && zoekresultaat.subtitelArray.map((subtitel, index) => {
                        return <Fragment key={index}><em>{Parser(subtitel)}</em><br/></Fragment>
                    })}
                </div>

                <Card.Meta>
                    <span>{Parser(ZoekUtil.metaString(zoekresultaat, localeManager, taxonomieService))}</span>
                </Card.Meta>

                {(zoekresultaat.auteurs && zoekresultaat.auteurs.length > 0) &&
                <Card.Description>
                    {auteursArray.map((auteur, index) => {
                        return <Fragment key={index}>{Parser(auteur)}<br/></Fragment>
                    })}
                    {getShowMoreLabel()}
                </Card.Description>
                }
            </Card.Content>

            {!isKiosk &&
            <Card.Content extra>
                {/*<SocialContent compact
                                   zoekresultaat={zoekresultaat}
                                   socialService={socialService}
                                   mijnMediatheekService={mijnMediatheekService}
                                   arteveldeUser={arteveldeUser}
                                   localeManager={localeManager}/>*/}
            </Card.Content>}

        </Card>
    )
}

export default ZoekresultaatCard

ZoekresultaatCard.propTypes = {
    localeManager: PropTypes.object.isRequired,
    socialService: PropTypes.object.isRequired,
    taxonomieService: PropTypes.object.isRequired,
    mijnMediatheekService: PropTypes.object.isRequired,
    zoekresultaat: PropTypes.object.isRequired,
    onCardClick: PropTypes.func,
    onCoverClick: PropTypes.func,
    arteveldeUser: PropTypes.object,
    directLink: PropTypes.object
}

ZoekresultaatCard.defaultProps = {}
