import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {FUCHSIA, DONKERGRIJS, ApplicationPath} from 'med-class'

export default class MijnMediatheekLoginButton extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {

    }

    render() {
        const {localeManager, arteveldeUser, onLogIn} = this.props

        const avatar = (arteveldeUser.loggedIn) ? ApplicationPath.avatarApi + arteveldeUser.emailAddress : null

        if (!localeManager) return null
        console.log('U', arteveldeUser)
        return (
            <Fragment>
                {!arteveldeUser.loggedIn &&
                <div className='mm-login-button' style={{backgroundColor: DONKERGRIJS.css}} onClick={onLogIn}>
                    <h4 style={{padding: 0, margin: 0, marginLeft: '10px', flexGrow: 1}}>{localeManager.getString('INLOGGEN_OP_MIJN_MEDIATHEEK')}</h4>
                </div>
                }
                {arteveldeUser.loggedIn &&
                <div className='mm-login-button' style={{backgroundColor: FUCHSIA.css}}>
                    <img src={avatar} style={{height: '100%'}} alt='avatar'/>
                    <h4 style={{padding: 0, margin: 0, marginLeft: '10px', flexGrow: 1}}>{arteveldeUser.displayName}</h4>
                </div>
                }
            </Fragment>
        )
    }
}

MijnMediatheekLoginButton.propTypes = {
    arteveldeUser: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    onLogIn: PropTypes.func.isRequired,
    onOpenMijnMediatheek: PropTypes.func.isRequired,
}

MijnMediatheekLoginButton.defaultProps = {}