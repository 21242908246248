export class NieuwsType {


    //checken of de wijziging naar strings geen bugs genereert! 19/9/2019
    static readonly VIDEO: string = 'video' //153
    static readonly VIMEO: string = 'vimeo' //154
    //static readonly TEKST: string = 'text' //155
    //static readonly AFBEELDING: string = 'image' //156
    static readonly IMAGE: string = 'image' //156
    static readonly YOUTUBE: string = 'youtubz' //157
    //static readonly HTML: string = 'html' //158
    //static readonly LINK: string = 'link' //159

    static readonly SEMANTIC_DATAPROVIDER: Object[] = [
        {text: 'Afbeelding', value: NieuwsType.IMAGE, key: NieuwsType.IMAGE},
        {text: 'Video', value: NieuwsType.VIDEO, key: NieuwsType.VIDEO},
    ]
}
