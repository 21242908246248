export class Stageverslag {

    id: number
    nummer: string
    academiejaar: string
    student_familienaam: string
    student_voornaam: string
    opleiding_nl: string
    opleiding_en: string
    optie_nl: string
    optie_en: string
    stageplaats_naam: string
    stageplaats_adres: string
    stageplaats_postcode: string
    stageplaats_gemeente: string
    stageplaats_land: string
    stageplaats_telefoon: string
    stageplaats_mail: string
    stageplaats_verantwoordelijke: string
    stageplaats_website: string
    sector: string
    pdf: string
    taal_nl: string
    taal_en: string


    get student_naam():string {
        return this.student_voornaam + ' ' + this.student_familienaam
    }

    get lookupString():string {
        return this.academiejaar + this.student_naam + this.opleiding_nl + this.optie_nl +
            this.stageplaats_adres + this.stageplaats_gemeente + this.stageplaats_land + this.stageplaats_mail +
            this.stageplaats_naam + this.stageplaats_postcode + this.stageplaats_telefoon + this.stageplaats_verantwoordelijke +
            this.sector + this.pdf
    }
}
