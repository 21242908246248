
/*
	©2018 Dany Dhondt & Lennert Holvoet
*/


export class Bijlage {
	
	_explicitType: string = "Bijlage";
	id: number = 0;
	ext: string | undefined = '';
	bestandsnaam: string = '';
	label: string = '';
	datumUpload: string = '';
	url: string = '';
	user_aanmaak: number = 0;
	bytes: number = 0;
	uid: string = '';
	isPubliek: number = 0;
	
	parseFilename = (filename: string) => {
		this.bestandsnaam = filename
		let fArr = filename.split('.');
		this.ext = fArr.pop();
		this.label = fArr.join('.')
	}
}