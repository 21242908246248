import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Button, Header, Input, List, Segment} from "semantic-ui-react";
import {GROEN} from 'med-class'
import QuestionRenderer from "./QuestionRenderer"

const WebComponentFaq = ({faqService, localeManager, localeDataService, selectedFaq, onClose, enableLinks}) => {

    const [faqs, setFaqs] = useState()
    const [filteredFaqs, setFilteredFaqs] = useState()

    useEffect(() => {

        faqService.init().then(({faqs}) => {
            const publicFaqs = faqs.filter(faq => faq.zichtbaarheid === 'publiek')
            setFaqs(publicFaqs)
            faqService.getFaqLocaleContentList().then((res) => {
                setFilteredFaqs([...publicFaqs])
            })
        })
    }, [faqService, localeManager])

    useEffect(() => {
        if (!selectedFaq) return
        const scrollTimeOut = setTimeout(scrollToFaq, 400)

        return () => clearTimeout(scrollTimeOut)
    }, [selectedFaq])

    const scrollToFaq = () => {
        if (!selectedFaq) return

        const el = document.getElementById('FAQ' + selectedFaq.id)
        console.log('SCROLL', selectedFaq, el)
        el.scrollIntoView(false)
    }

    const onTagClick = (tag) => {
        setFilteredFaqs(faqs.filter(faq => faq.tags.indexOf(tag) > -1))
    }

    const onSearchChange = (event, {value}) => {
        const searchString = value.toLowerCase()
        const wordArray = searchString.split(' ')

        const fFaqs = faqs.filter(faq => {
            let rv = true
            for (const word of wordArray) {
                if (faqService.faqSearchStringLookup[faq.id].indexOf(word) < 0) rv = false
            }
            return rv
        })

        setFilteredFaqs(fFaqs)
    }

    if (!filteredFaqs) return null

    return (
        <div>

            <Segment inverted textAlign='center' style={{backgroundColor: GROEN.css}}>
                <Header size='huge' textAlign='center'>{localeManager.getString('VEELGESTELDE_VRAGEN')}</Header>
                <Input icon='search' placeholder={localeManager.getString('ZOEK') + '...'} fluid onChange={onSearchChange}/>
                <Button style={{marginTop: '20px'}} onClick={onClose}>{localeManager.getString('SLUIT')}</Button>
            </Segment>


            <div className='faq-list'>
                <List relaxed>
                    {filteredFaqs.map(faq => {

                        const localeContent = faqService.faqLocaleContentLookup[faq.id]
                        const answerVisible = selectedFaq ? selectedFaq.id === faq.id : false

                        if (answerVisible) console.log('VISIBLE!!', faq.question)

                        return (
                            <List.Item key={faq.id} id={'FAQ' + faq.id}>
                                <List.Content>
                                    <QuestionRenderer faq={faq}
                                                      localeContent={localeContent[localeManager.locale]}
                                                      localeDataService={localeDataService}
                                                      localeManager={localeManager}
                                                      onTagClick={onTagClick}
                                                      answerVisible={answerVisible}
                                                      enableLinks={enableLinks}/>
                                </List.Content>
                            </List.Item>
                        )
                    })}

                </List>
            </div>
        </div>
    )
}

export default  WebComponentFaq


WebComponentFaq.propTypes = {
    faqService: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    localeDataService: PropTypes.object.isRequired,
    selectedFaq: PropTypes.object,
    onClose: PropTypes.func,
    enableLinks: PropTypes.bool
}

WebComponentFaq.defaultProps = {
    enableLinks: true
}