import React, {useState, useEffect} from 'react'
import {Select} from 'semantic-ui-react'
import PropTypes from 'prop-types'

import _ from 'lodash'


const UserKiezer = ({selectedUser, userService, multiple, onSelectUser, controlField, search, fluid, disabled, color}) => {

    const [selectedValue, setSelectedValue] = useState()
    const [dataprovider, setDataprovider] = useState()

    useEffect(() => {

        const parseSelectedValue = (value) => {
            //mogelijke waarden van value: 5 - [1, 2, 3] - ["7", "8"] - 1,2,3 - enz...

            if (!multiple) return value
            if (!value || value === undefined) value = []
            if (!Array.isArray(value)) {
                value = value.split(',')
            }
            let ra = _.compact(_.map(value, item => isNaN(Number(item)) ? item : Number(item)))

            return ra
        }

        const createDataprovider = () => {
            let dp = userService.users.map(user => {
                return {key: user.id, value: user.id, text: `${user.voornaam} ${user.familienaam}`}
            })
            return dp
        }

        setSelectedValue(parseSelectedValue(selectedUser))
        setDataprovider(createDataprovider())

    }, [selectedUser, userService, multiple])

    const onUserSelect = (event, {value}) => {
        let valueString

        if (multiple) {
            valueString = value.join(',')
        } else {
            valueString = value
        }

        onSelectUser({controlField: controlField, selectedValues: value, valueString})

        setSelectedValue(value)
    }

    if (!dataprovider)
        return <div>Geen gebruikers</div>

    return (
        <div>
            <Select style={{backgroundColor: color}}
                    disabled={disabled}
                    fluid={fluid}
                    multiple={multiple}
                    search={search}
                    value={selectedValue}
                    placeholder={`Kies een gebruiker`}
                    options={dataprovider}
                    onChange={onUserSelect}
            />
        </div>
    )
}

export default UserKiezer

UserKiezer.propTypes = {
    selectedUser: PropTypes.any,
    onSelectUser: PropTypes.func,
    userService: PropTypes.object.isRequired,
    valueField: PropTypes.string,
    controlField: PropTypes.string,
    multiple: PropTypes.bool,
    search: PropTypes.bool,
    fluid: PropTypes.bool,
    disabled: PropTypes.bool,
    color: PropTypes.string
}

UserKiezer.defaultProps = {
    valueField: 'id',
    multiple: false,
    search: false,
    fluid: false
}
