import React from 'react'
import PropTypes from 'prop-types'
//import {DONKERGRIJS} from 'med-class'
import LAPTOP from "../../assets/laptop.jpg";
const Parser = require('html-react-parser')

const ReserveerTile = ({localeManager}) => {

    return (

        <a className='small-tile' href='//www.arteveldehogeschool.be/mediatheek/reserveer/' target='_blank' rel="noopener noreferrer">
            <img style={{height: '100%'}} src={LAPTOP} alt='laptop'/>
            <div className='small-tile-text'>
                {Parser(localeManager.getString('RESERVATIE_TILE'))}
            </div>
        </a>
    )
}

export default ReserveerTile

ReserveerTile.propTypes = {
    localeManager: PropTypes.object.isRequired
}

ReserveerTile.defaultProps = {}