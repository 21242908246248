import React, {useState, useEffect} from 'react'
import {Select} from 'semantic-ui-react'
import PropTypes from 'prop-types'
import _ from 'lodash'

const TaxonomieItemKiezer = ({taxonomieService, taxonomieItemString, onSelectTaxonomieItem, multiple, search, fluid, disabled, enkelGeneriek}) => {

    const [dataProvider, setDataProvider] = useState([])
    const [selectedValues, setSelectedValues] = useState()

    useEffect(() => {
        const generateSelectedValues = (taxonomieItemString) => {
            if (!taxonomieItemString || taxonomieItemString === "")
                return []
            const dataArr = taxonomieItemString.split(",")
            return dataArr.map(tiId => parseInt(tiId))
        }

        setSelectedValues(generateSelectedValues(taxonomieItemString))
    }, [taxonomieItemString])

    useEffect(() => {
        const generateDataProvider = (items) => {
            let dp = items.filter(item => {
                let taxonomie = taxonomieService.taxonomieLookup[item.taxonomie]
                return enkelGeneriek ? taxonomie.isGeneriek === 1 : true
            } ).map(item => {
                return {
                    key: item.id,
                    value: item.id,
                    text: `${taxonomieService.taxonomieLookup[item.taxonomie].label.toUpperCase()}: ${item.label}`,
                    taxonomieitem: item
                }
            })

            dp = _.sortBy(dp, [(o) => o.text]);
            return dp
        }

        setDataProvider(generateDataProvider(taxonomieService.taxonomieItems))

    }, [taxonomieService.taxonomieItems, enkelGeneriek, taxonomieService.taxonomieLookup])

    const onLocalSelectTaxonomieItem = (event, {value}) => {
        setSelectedValues(value)
        const taxonomieItemString = value.join(',')
        onSelectTaxonomieItem && onSelectTaxonomieItem(value, taxonomieItemString)
    }

        if (!dataProvider || !selectedValues)
            return <div/>

        return (
            <div style={{maxWidth: '700px'}}>
                <Select
                    disabled={disabled}
                    fluid={fluid}
                    multiple={multiple}
                    search={search}
                    placeholder={`Kies een optie`}
                    options={dataProvider}
                    value={selectedValues}
                    onChange={onLocalSelectTaxonomieItem}
                />
            </div>
        )
}

export default TaxonomieItemKiezer

TaxonomieItemKiezer.propTypes = {
    taxonomieService: PropTypes.object.isRequired,
    taxonomieItemString: PropTypes.string,
    onSelectTaxonomieItem: PropTypes.func,
    multiple: PropTypes.bool,
    search: PropTypes.bool,
    fluid: PropTypes.bool,
    disabled: PropTypes.bool,
    enkelGeneriek: PropTypes.bool
}

TaxonomieItemKiezer.defaultProps = {
    disabled: false,
    multiple: true,
    search: true,
    fluid: false,
    enkelGeneriek: true
}
