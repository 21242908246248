export class TellingDag {

    id: number
    datum: string
    dag: number
    campus: string
    bezoekers: number
}

export class TellingUur {

    id: number
    datum: string
    uur: string
    dag: number
    campus: string
    bezoekers: number
}

