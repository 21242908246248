import {AbstractZoekresultaat} from "../zoekresultaat/AbstractZoekresultaat";

export class BidocReservatie extends AbstractZoekresultaat {
	
	_explicitType:string = "BidocReservatie"
	
	auteur: string
	datumReservatie: string
	publicatietype: string
	titel: string
	titelId: string
	lenersnummer: string
	status: string
}
