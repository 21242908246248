import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Dropdown, Checkbox} from 'semantic-ui-react'
import Slider from 'rc-slider'

import '../styles.css'
//const createSliderWithTooltip = Slider.createSliderWithTooltip;

//const Range = createSliderWithTooltip(Slider.Range);

class MinMaxSliderDropdown extends Component {

    state = {
        open: false,
        checkedVals: {min: false, max: false}
    }

    componentDidMount() {
        const { range } = this.props
        this.setState({min: range[0], max: range[1]})
    }

    mouseEnterHandler = () => {
        this.setState({open: true})
    }

    mouseLeaveHandler = () => {
        this.setState({open: false})
    }

    onChangeMin = (value) => {
        let max = this.state.max
        if (value >= max) max = value
        this.setState({min: value, max})
    }

    onChangeMax = (value) => {
        let min = this.state.min
        if (value <= min) min = value
        this.setState({min, max: value})
    }

    onAfterChange = () => {
        this.updateFilterObject(this.state.checkedVals)
    }

    onOptionChange = (event, {name, checked}) => {
        const { checkedVals } = this.state
        checkedVals[name] = checked
        this.updateFilterObject(checkedVals)
        this.setState({checkedVals})
    }

    updateFilterObject = (checkedVals) => {
        const { min, max } = this.state
        const { filterObject, renderMode, localeManager, fields} = this.props
        const objArr = []
        if (checkedVals.min) objArr.push({key: 'min', field: fields[0], value: min, label: `${localeManager.getString('MINIMUM')} ${min}`})
        if (checkedVals.max) objArr.push({key: 'max', field: fields[1], value: max, label: `${localeManager.getString('MAXIMUM')} ${max}`})
        filterObject.data[this.props.name] = objArr
        if (objArr.length === 0) delete filterObject.data[this.props.name]
        //console.log('UPDATE FILTER', filterObject)
        this.props.onOptionChange(null, {name: this.props.name, renderMode})
    }


    render() {
        const {open, min, max, checkedVals} = this.state
        const {title, name, filterObject, localeManager, range} = this.props
        const somethingSelected = filterObject.data[name] && filterObject.data[name].length > 0

        const colWidth = 300

        return (
            <Dropdown open={open}
                      onMouseEnter={this.mouseEnterHandler}
                      onMouseLeave={this.mouseLeaveHandler}
                      style={{marginBottom: '5px', backgroundColor: somethingSelected ? 'green' : 'lightgrey', color: somethingSelected ? 'white' : 'black'}}
                      text={title}
                      labeled
                      button
                      icon='filter'
                      className='icon'>
                <Dropdown.Menu>
                    <div style={{display: 'flex', width: colWidth, padding: '10px', alignItems: 'center'}}>
                        <Checkbox style={{marginRight: '10px', width: '120px'}} label={localeManager.getString('MINIMUM')} checked={checkedVals.min} name='min' selecteditem={{min}} onChange={this.onOptionChange}/>
                        <div style={{width: '210px'}}>
                            <Slider disabled={!checkedVals.min} min={range[0]} max={range[1]} value={min} included onChange={this.onChangeMin} onAfterChange={this.onAfterChange}/>
                        </div>
                        <span style={{marginLeft: '10px', color: 'black'}}>{min}</span>
                    </div>
                    <div style={{display: 'flex', width: colWidth, padding: '10px', alignItems: 'center'}}>
                        <Checkbox style={{marginRight: '10px', width: '120px'}} label={localeManager.getString('MAXIMUM')} checked={checkedVals.max} name='max' selecteditem={{max}} onChange={this.onOptionChange}/>
                        <div style={{width: '210px'}}>
                            <Slider disabled={!checkedVals.max} min={range[0]} max={range[1]} value={max} included onChange={this.onChangeMax} onAfterChange={this.onAfterChange}/>
                        </div>
                        <span style={{marginLeft: '10px', color: 'black'}}>{max}</span>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}

MinMaxSliderDropdown.propsTypes = {
    localeManager: PropTypes.object.isRequired,
    title: PropTypes.string,
    onOptionChange: PropTypes.func,
    name: PropTypes.string,
    filterObject: PropTypes.object,
    fields: PropTypes.array,
    range: PropTypes.array
}

MinMaxSliderDropdown.defaultProps = {
    filterObject: {},
}

export default MinMaxSliderDropdown