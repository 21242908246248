import {ApplicationPath} from "../domain/path";
import {convertObjectToClass} from "../util/ClassConvertor";
import {Quote} from "../class/quote/Quote";
const axios = require('axios')
const _ = require('lodash')


export class QuoteService {

    private readonly QUOTE_TABLE: string = 'quote'

    initialized: boolean = false
    quotes: Quote[]
    quoteLookup: object
    apiUrl: string
    apiKey: string
    quoteSearchStringLookup: object = {}

    constructor(apiUrl: string, apiKey: string) {
        this.apiUrl = apiUrl
        this.apiKey = apiKey
        this.quotes = []
        this.quoteLookup = {}
    }

    init = () => {

        let me = this

        return new Promise((resolve) => {

                this.getQuotes().then((response: any) => {

                    me.initialized = true
                    me.quotes = response.quotes
                    me.quoteLookup = response.quoteLookup
                    me.generateQuoteSearchStringLookup()
                    resolve({status: 'ok', quotes: me.quotes})
                }).catch((error) => {
                    console.log('ERROR IN Quote INIT', error)
                })
            }
        )

    }

    private getQuotes() {

        let me = this

        return new Promise(function (resolve, reject) {
            let url = `${me.apiUrl}${me.QUOTE_TABLE}?transform=1&token=${me.apiKey}`
            axios.get(url)
                .then(response => {
                    let rawQuotes = response.data[me.QUOTE_TABLE];
                    let quotes = rawQuotes.map((rq: any) => convertObjectToClass(rq, 'Quote'))
                    quotes = _.orderBy(quotes, ['quote'])
                    let quoteLookup = _.keyBy(quotes, quote => quote.quoteId)
                    resolve({quotes, quoteLookup})
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    updateQuote(quote: Quote) {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.QUOTE_TABLE}/${quote.quoteId}?&token=${me.apiKey}`;
                axios.put(url, quote)
                    .then(() => {
                        //remap the quote
                        quote = convertObjectToClass(quote, 'Quote');
                        me.quotes = me.quotes.map(q => {
                            if (q.quoteId === quote.quoteId)
                                return quote;
                            return q;
                        })
                        me.quoteLookup = _.keyBy(me.quotes, quote => quote.quoteId)
                        resolve({quotes: me.quotes, quoteLookup: me.quoteLookup, quote})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    createQuote(quote: Quote) {

        let me = this
        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.QUOTE_TABLE}/?token=${me.apiKey}`
                axios.post(url, quote)
                    .then(response => {
                        quote.quoteId = response.data;
                        me.quotes = _.concat(me.quotes, quote)
                        me.quotes = _.orderBy(me.quotes, ['quote'])
                        me.quoteLookup = _.keyBy(me.quotes, quote => quote.quoteId)
                        resolve({quoteLookup: me.quoteLookup, quotes: me.quotes, quote})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    deleteQuote(quote: Quote) {

        let me = this

        return new Promise((resolve, reject) => {
                const url = `${me.apiUrl}${me.QUOTE_TABLE}/${quote.quoteId}?token=${me.apiKey}`

                axios.delete(url)
                    .then(() => {

                        //remove from quotes and lookup
                        me.quotes = _.filter(me.quotes, q => q.quoteId !== quote.quoteId)
                        me.quoteLookup = _.keyBy(me.quotes, quote => quote.quoteId)

                        resolve({quotes: me.quotes, quoteLookup: me.quoteLookup})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    getRandomQuote = () => {

        return new Promise(function (resolve, reject) {
            axios.get(ApplicationPath.quoteApiUrl)
                .then(response => {
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });

        });
    }

    generateQuoteSearchStringLookup = () => {

        _.each(this.quotes, quote => {
            this.quoteSearchStringLookup[quote.id] = (
                quote.quote +
            quote.auteur).toLowerCase()
        })
    }
}
