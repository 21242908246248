import React, {Fragment} from 'react'
import {Label} from 'semantic-ui-react'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'

const BidocKoppelingRenderer = ({zoekresultaat, localeManager, taxonomieService}) => {

    if (!zoekresultaat.bidocKoppeling) return null

    const taxonomieen = ['thema', 'materiaal', 'spelvorm', 'doelgroep', 'context', 'handleiding']

    const taxonomieItemIds = zoekresultaat.bidocKoppeling.taxonomieItems.split(',')

    return (
        <Fragment>

            {taxonomieen.map(taxonomieLabel => {
                const taxonomie = taxonomieService.taxonomieLabelLookup[taxonomieLabel]
                if (!taxonomie) return null

                const taxonomieItems = taxonomieItemIds.filter(id => {
                    if (!taxonomieService.taxonomieItemLookup[id]) return false
                    return taxonomieService.taxonomieItemLookup[id].taxonomie === taxonomie.id
                })
                    .map(id => taxonomieService.taxonomieItemLookup[id])
                //TODO: sorteren op label
                if (taxonomieItems.length === 0) return null
                return  <AbstractZoekresultaatRenderer key={taxonomieLabel} label={localeManager.getString(taxonomieLabel)}>
                    {taxonomieItems.map(ti => {
                        return <Label basic color='grey' key={ti.id} content={localeManager.getString(ti.code)}/>
                    })}
                </AbstractZoekresultaatRenderer>
            })}
        </Fragment>
    )
}

export default BidocKoppelingRenderer