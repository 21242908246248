import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {Button} from 'semantic-ui-react'
import moment from 'moment'
import orderBy from 'lodash/orderBy'
import ChatList from './ChatList'
import ChatContentBo from './ChatContentBo'
import ChatDefaultAnswers from './ChatDefaultAnswers'
import {SoundPath, Chatline} from 'med-class'
import ChatContentWeb from "./ChatContentWeb";
import ChatFaqSuggestions from "./ChatFaqSuggestions"
import CurrentChatRooster from "./CurrentChatRooster";

const CLIENT = 'bo'


const BoComponentChat = ({loginUser, chatService, userService, notifyHandler, faqService, roosteringService, localeDataService}) => {

    const [newChats, setNewChats] = useState()
    const [beschikbaar, setBeschikbaar] = useState(false)
    const [adminsAvailable, setAdminsAvailable] = useState([])
    const [activeChats, setActiveChats] = useState()
    const [selectedChat, setSelectedChat] = useState()
    const [injectedDefaultAnswer, setInjectedDefaultAnswer] = useState()
    const [relatedFaqs, setRelatedFaqs] = useState([])
    const [soundInitialized, setSoundInitialized] = useState(false)
    const [allChatlines, setAllChatlines] = useState([])
    const [config, setConfig] = useState()



    useEffect(() => {
        chatService.getChatConfig().then(res => {
            setConfig(res)
        })
    }, [chatService])

    //FAQ SERVICE
    useEffect(() => {
        if (!faqService) return
        if (!faqService.initialized) {
            faqService.init().then(() => {
                //console.log('FAQ INIT')
            })
        }
    }, [faqService])

    //LISTENERS

    //ADMINS LISTENER
    useEffect(() => {
        chatService.db.collection('admin').onSnapshot(docSnapshot => {
            const admins = docSnapshot.docs.map(doc => doc.data()).filter(admin => admin.beschikbaar === true)
            setAdminsAvailable(admins)
        })
    }, [chatService])

    //CHATS LISTENER
    useEffect(() => {
        const chatsRef = chatService.db.collection('chats');
        let openChatsRef = chatsRef.where('status', '==', 'open')

        openChatsRef.onSnapshot(docSnapshot => {
            setNewChats(docSnapshot.docChanges())
        })
    }, [chatService])

    //CHATLINES LISTENER
    useEffect(() => {
        const chatlinesRef = chatService.db.collection('chatlines');

        const unsubscribeFunction = chatlinesRef.onSnapshot(docSnapshot => {
            setAllChatlines(docSnapshot.docs)

            if (docSnapshot.docs.length === 0) return
            const firstChange = docSnapshot.docChanges()[0]
            if (firstChange.type === 'added') {
                if (beschikbaar) playSound('newChatline')
            }

        })

        return () => unsubscribeFunction()
    }, [chatService, beschikbaar, soundInitialized])


    //SET BESCHIKBAAR
    useEffect(() => {

        chatService.db.collection('admin').doc(loginUser.upn).get().then(doc => {
            setBeschikbaar(doc.exists && doc.data().beschikbaar)
        })

        //wanneer het venster of tab gesloten wordt, dan moet ik offline gaan
        const onbeforeunloadFn = () => {
            onSetBeschikbaar(false)
        }

        window.addEventListener('beforeunload', onbeforeunloadFn);
        return () => {
            window.removeEventListener('beforeunload', onbeforeunloadFn);
        }
    }, [chatService, loginUser])


    useEffect(() => {
        if (!newChats) return

        let nc = []
        if (!activeChats) {
            newChats.forEach(ndoc => nc.push(ndoc.doc))
        } else {
            newChats.forEach(ndoc => {
                nc = [...activeChats]

                switch (ndoc.type) {
                    case 'added':
                        nc.push(ndoc.doc)
                        notifyHandler && beschikbaar && notifyHandler('added')
                        break
                    case 'modified':
                        nc = nc.filter(chat => chat.id !== ndoc.doc.id)
                        nc.push(ndoc.doc)
                        notifyHandler && beschikbaar && notifyHandler('modified')
                        break
                    case 'removed':
                        if (selectedChat && ndoc.doc.id === selectedChat.id) setSelectedChat(null)
                        nc = nc.filter(chat => chat.id !== ndoc.doc.id)
                        notifyHandler && beschikbaar && notifyHandler('removed')
                        break
                }
            })
        }

        if (beschikbaar) playSound('newChat')
        setActiveChats(orderBy(nc, (doc => doc.data().timestamp), 'desc'))
    }, [newChats])

    /*const getActiveChats = () => {
        return activeChats
    }*/



    const onSetBeschikbaar = (besch) => {
        setBeschikbaar(besch)
        chatService.db.collection('admin').doc(loginUser.upn).set({loginUser: loginUser.mail, beschikbaar: besch, voornaam: loginUser.voornaam})
    }

    const onSelectChat = (chat) => {
        setInjectedDefaultAnswer(null)
        setSelectedChat(chat)

    }

    const onDefaultAnswerClick = (dAnswer) => {
        //substitution
        const userSub = selectedChat.data().voornaam || selectedChat.data().eigenaar || ''
        const dAnswerCopy = {...dAnswer, content: dAnswer.content.replace('[[USER]]', userSub)}

        //
        //console.log('ANS', dAnswerCopy, selectedChat)
        setInjectedDefaultAnswer(dAnswerCopy)
    }


    const onSubmitChatline = (content, status) => {
        const chatline = Chatline()
        chatline.chat = selectedChat.id
        chatline.inhoud = content
        chatline.bron = CLIENT
        chatline.auteur = loginUser.upn

        chatService.createChatline(selectedChat, chatline, status).then(res => {
            //console.log('NEW CHATLINE CREATED', res)
        })
    }

    const onSelectFile = (file, progressFunction) => {

        //console.log('FILE', file)
        return new Promise((resolve) => {
            const chatline = Chatline()
            chatline.chat = selectedChat.id
            chatline.bron = CLIENT
            chatline.auteur = loginUser.upn
            chatService.uploadFile(chatline, file, progressFunction).then(res => {
                resolve(res)
            })
        })
    }

    const onCloseChat = (chatlines) => {

        console.log('CLOSING', chatlines, selectedChat)

        chatService.closeChat(selectedChat, chatlines, 0, null, loginUser.upn).then(res => {
            //console.log('DELETING CHAT')
        })
        setSelectedChat(null)
    }

    const onRelatedFaqsChange = (faqs) => {
        if (!faqs) return
        setRelatedFaqs(faqs.map(faqid => faqService.faqLookup[faqid]))
    }

    const playSound = (snd) => {
        let audio = new Audio(SoundPath[snd]);
        if (soundInitialized) audio.play()
    }

    const initSound = () => {
        if (!soundInitialized) {
            let audio = new Audio(SoundPath.silence);
            audio.play()
            setSoundInitialized(true)
        }
    }

    return (
        <div style={{width: '100%'}}>
            <CurrentChatRooster roosteringService={roosteringService} userService={userService}/>

            <div className="module-container" onClick={initSound}>

                <ChatList onToggleBeschikbaar={onSetBeschikbaar}
                          config={config}
                          allChatlines={allChatlines}
                          adminsAvailable={adminsAvailable}
                          beschikbaar={beschikbaar}
                          chats={activeChats}
                          selectedChat={selectedChat}
                          onSelectChat={onSelectChat}/>

                {beschikbaar && <div style={{flexGrow: 1, minWidth: '600px'}} className='chat-column'>
                    <ChatContentBo client={CLIENT}
                                   chat={selectedChat}
                                   allChatlines={allChatlines}
                                   adminsAvailable={adminsAvailable}
                                   beschikbaar={beschikbaar}
                                   chatService={chatService}
                                   loginUser={loginUser}
                                   eigenaar={loginUser.upn}
                                   voornaam={loginUser.voornaam}
                                   onCloseChat={onCloseChat}
                                   onRelatedFaqsChange={onRelatedFaqsChange}
                                   onSubmitChatline={onSubmitChatline}
                                   onSelectFile={onSelectFile}
                                   injectedDefaultAnswer={injectedDefaultAnswer}
                                   playSound={playSound}/>
                </div>}

                {beschikbaar &&
                <div className='chat-column'
                     style={{width: '500px', backgroundColor: 'rgba(143,143,143,0.05)', height: '100vh', overflow: 'auto', zoom: '0.9'}}>
                    <ChatDefaultAnswers chatService={chatService}
                                        config={config}
                                        relatedFaqs={relatedFaqs}
                                        onDefaultAnswerClick={onDefaultAnswerClick}
                                        eigenaar={loginUser.upn}
                                        chat={selectedChat}/>

                    <ChatFaqSuggestions chatService={chatService}
                                        faqService={faqService}
                                        relatedFaqs={relatedFaqs}
                                        localeDataService={localeDataService}
                                        onDefaultAnswerClick={onDefaultAnswerClick}
                                        eigenaar={loginUser.upn}
                                        chat={selectedChat}/>
                </div>
                }
            </div>
        </div>
    )
}

export default BoComponentChat

BoComponentChat.propTypes = {
    loginUser: PropTypes.object.isRequired,
    localeDataService: PropTypes.object.isRequired,
    chatService: PropTypes.object.isRequired,
    userService: PropTypes.object.isRequired,
    faqService: PropTypes.object.isRequired,
    roosteringService: PropTypes.object.isRequired,
    notifyHandler: PropTypes.func
}

BoComponentChat.defaultProps = {}