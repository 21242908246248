import {AbstractZoekresultaat} from "./AbstractZoekresultaat";

export class ZoekresultaatEbsco extends AbstractZoekresultaat {

    _explicitType: string = "ZoekresultaatEbsco"
    ebscoLink:string

    getProps(){
        return [
            "auteurs",
            "jaar",
            "uitgever",
            "bladzijden",
            "publicatietype",
            "hoofdtitel",
            "reeks",
            "tijdschrift",
            "ebscoLink",
            "mediatheek",
            "exemplaar",
            "siso",
            "trefwoorden",
            "beschrijving",
            "annotatie",
            "categorie",
            "isbn",
            "issn",
            "campus",
            "jaargang",
            "taal",
            "jaargangnummer",
            "fullTextLink",
            "directe_Link"]
    }


}
