import React, {useState, useEffect, useCallback} from 'react'
import PropTypes from 'prop-types'
import sample from 'lodash/sample'
import {LocaleDataService} from 'med-class'
import ZoekresultaatUitgelicht from "./zoekresultaat/ZoekresultaatUitgelicht";


const UitgelichtTile = ({zoekService, localeManager, socialService, spelwijzerService, mijnMediatheekService, taxonomieService, onTileClick}) => {

    const [uitgelicht, setUitgelicht] = useState()
    //const [uitgelichtTimer, setUitgelichtTimer] = useState()
    const [uitgelichtItem, setUitgelichtItem] = useState()
    const [epubLocaleDataService] = useState(new LocaleDataService('epub', ['beschrijving', 'opmerking']))

    const fetchDetails = useCallback((zoekresultaat) => {
        if (zoekresultaat.needLocaleContent) {
            epubLocaleDataService.getLocaleData(zoekresultaat.id).then(res => {

                zoekresultaat.localeContentLoaded = true
                zoekresultaat.localeContent = res
                setUitgelichtItem(zoekresultaat)
            })
        }

        if (!zoekresultaat.isFull){
            zoekService.getZoekresultaatDetail(zoekresultaat).then (res => {
                setUitgelichtItem(res)
            })
        }
    }, [epubLocaleDataService, zoekService])
    //uitgelicht items
    useEffect(() => {
        Promise.all([spelwijzerService.init(), zoekService.getUitgelichteEpubs()]).then(res => {
            const spelen = spelwijzerService.zoekresultatenBaseData.filter(zr => zr.uitgelicht === 1)
            const conc = spelen.concat(res[1])
            setUitgelicht(conc)
            fetchDetails(sample(conc))
        })
    }, [spelwijzerService, zoekService, fetchDetails])


    const onZoekresultaatUitgelichtClick = (uitgelichtItem) => {

        onTileClick(uitgelichtItem)
        fetchDetails(sample(uitgelicht))
    }


    if (!uitgelicht || !uitgelichtItem) return null

    return (
        <div style={{display: 'flex'}}>
            <ZoekresultaatUitgelicht localeManager={localeManager}
                                     socialService={socialService}
                                     taxonomieService={taxonomieService}
                                     mijnMediatheekService={mijnMediatheekService}
                                     zoekresultaat={uitgelichtItem}
                                     epubLocaleDataService={epubLocaleDataService}
                                     onCardClick={onZoekresultaatUitgelichtClick}/>
        </div>
    )
}

export default UitgelichtTile

UitgelichtTile.propTypes = {
    zoekService: PropTypes.object.isRequired,
    taxonomieService: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    socialService: PropTypes.object.isRequired,
    spelwijzerService: PropTypes.object.isRequired,
    mijnMediatheekService: PropTypes.object.isRequired,
    onTileClick: PropTypes.func
}

UitgelichtTile.defaultProps = {}