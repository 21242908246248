import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Form, Header, Modal, Button, Label, Icon, Message} from 'semantic-ui-react'
import {Changelog, SoftwareColor, Release} from '@ahsmediatheek/core'
import TaxonomieKiezer from "./TaxonomieKiezer";

import moment from 'moment'
import ChangelogList from "./ChangelogList";

const NewChangelogModal = ({taxonomieService, adminService, onChange, software}) => {

    const [softwareTaxonomieItem, setSoftwareTaxonomieItem] = useState()
    const [selectedSoftware, setSelectedSoftware] = useState('')
    const [selectedModule, setSelectedModule] = useState('')
    const [moduleTaxonomieItem, setModuleTaxonomieItem] = useState()
    const [content, setContent] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [changelogs, setChangelogs] = useState()

    useEffect(() => {
        if (software) {
            setSoftwareTaxonomieItem(taxonomieService.getTaxonomieItemByLabel(software))
        }
    }, [software, taxonomieService])

    const onOpenModal = () => {
        getChangelogs(softwareTaxonomieItem)
        setModalOpen(true)
    }

    const getChangelogs = (softwareTaxonomieItem) => {
        let changelogs = []
        const release = adminService.getUnreleasedRelease(softwareTaxonomieItem.id)

        if (release) {
            adminService.getChangelogs(softwareTaxonomieItem.id, release.id).then(({changelogs}) => {
                setChangelogs(changelogs)
            })
        } else {
            setChangelogs(changelogs)
        }
    }

    const onSoftwareChange = ({selectedItems, selectedValueString}) => {
        setSoftwareTaxonomieItem(selectedItems)
        setSelectedSoftware(selectedValueString)
        getChangelogs(selectedItems)
    }

    const onModuleChange = ({selectedItems, selectedValueString}) => {
        setModuleTaxonomieItem(selectedItems)
        setSelectedModule(selectedValueString)
    }

    const onCreateChangelog = () => {

        const newChangeLog = new Changelog()
        newChangeLog.datumtijd = moment().format('YYYY-MM-DD HH:mm:ss')
        newChangeLog.software = softwareTaxonomieItem.id
        newChangeLog.module = moduleTaxonomieItem.id
        newChangeLog.content = content

        //Een changelog MOET een release id hebben om gedoe met negatieve id's te vermijden in de andere modules
        const releases = adminService.releases.filter(release => release.released === 0 && release.software === softwareTaxonomieItem.id)

        if (releases.length > 0) {
            newChangeLog.release = releases[0].id

            adminService.createChangelog(newChangeLog).then(({changelog, changelogs}) => {
                setContent('')
                setModuleTaxonomieItem(null)
                setSelectedModule('')
                setChangelogs(changelogs)
                onChange && onChange(changelog)
            })

        } else {
            const release = new Release()
            release.datum = moment().format('YYYY-MM-DD')
            release.software = softwareTaxonomieItem.id
            adminService.createRelease(release).then(({release}) => {
                newChangeLog.release = release.id

                adminService.createChangelog(newChangeLog).then(({changelog, changelogs}) => {

                    setContent('')
                    setModuleTaxonomieItem(null)
                    setSelectedModule('')
                    setChangelogs(changelogs)
                    onChange && onChange(changelog)
                })
            })
        }
    }

    const onDeleteChangelog = (changelog) => {

        //omdat dit mooier is: wanneer de laatste changelog verwijderd wordt, mag ook de release zelf weg!
        const releases = adminService.releases.filter(release => release.released === 0 && release.software === softwareTaxonomieItem.id)

        adminService.deleteChangelog(changelog).then(({changelogs}) => {
            if (changelogs.length === 0) {
                console.log('ABOUT TO DELETE RELEASE', releases)
            }
            getChangelogs(softwareTaxonomieItem)
            onChange && onChange(changelog)
        })
    }

    if (!softwareTaxonomieItem) return null

    return (
        <Fragment>
            <Modal
                dimmer='blurring'
                trigger={<Label as='a' onClick={onOpenModal} style={{backgroundColor: SoftwareColor.getSoftwareColor(software), color: 'white'}}>
                    <Icon name='file code' inverted/>
                    Changelog
                    <Label.Detail>{softwareTaxonomieItem.label}</Label.Detail>
                </Label>}
                open={modalOpen}
            >
                <Header content={'Changelog voor ' + softwareTaxonomieItem.label}/>
                <Message>Message</Message>
                <Modal.Content style={{height: '70vh'}}>
                    <div style={{display: 'flex', height: '100%'}}>

                        <div style={{width: '50%'}}><ChangelogList changelogs={changelogs}
                                                                   taxonomieService={taxonomieService}
                                                                   adminService={adminService}
                                                                   onDeleteChangelog={onDeleteChangelog}/></div>

                        <Form style={{flexGrow: 1, height: '100%'}}>
                            <Form.Field>
                                <label>Software pakket</label>
                                {(software && softwareTaxonomieItem) &&
                                <Fragment>{softwareTaxonomieItem.label}</Fragment>
                                }
                                {!software &&
                                <TaxonomieKiezer
                                    taxonomieService={taxonomieService}
                                    selectedTaxonomieItem={selectedSoftware}
                                    onSelectTaxonomieItem={onSoftwareChange}
                                    taxonomie='mediatheek_software'/>
                                }
                            </Form.Field>

                            {softwareTaxonomieItem &&
                            <Form.Field>
                                <label>Module</label>
                                <TaxonomieKiezer
                                    taxonomieService={taxonomieService}
                                    selectedTaxonomieItem={selectedModule}
                                    onSelectTaxonomieItem={onModuleChange}
                                    taxonomie={softwareTaxonomieItem.label.toLowerCase() + '_modules'}/>
                            </Form.Field>
                            }

                            {moduleTaxonomieItem &&
                            <Form.TextArea style={{minHeight: '200px'}} name='content' value={content} label='Content'
                                           onChange={(event, {value}) => {
                                               setContent(value)
                                           }}/>
                            }

                            {content !== '' &&
                            <Button color='green' onClick={onCreateChangelog} disabled={content === ''}>
                                Voeg toe aan changelog
                            </Button>
                            }
                        </Form>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setModalOpen(false)}>
                        Sluit
                    </Button>
                </Modal.Actions>
            </Modal>
        </Fragment>
    )
}

export default NewChangelogModal

NewChangelogModal.propTypes = {
    taxonomieService: PropTypes.object.isRequired,
    adminService: PropTypes.object.isRequired,
    software: PropTypes.string,
    onChange: PropTypes.func
}

NewChangelogModal.defaultProps = {}
