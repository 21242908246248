export class AfhaalMandje {
    id: number
    eigenaar: string
    tijdsslot: object
    mediatheek: string
    titels: any[] = []
    nietBeschikbaar: any[] = []
    status: string = AfhaalMandjeStatus.AANGEMAAKT
}

export class AfhaalMandjeStatus {
    static readonly AANGEMAAKT: string = 'aangemaakt'
    static readonly IN_BEHANDELING: string = 'inBehandeling'
    static readonly KLAAR_VOOR_OPHALING: string = 'klaarVoorOphaling'
    static readonly OPGEHAALD: string = 'opgehaald'
    static readonly NIET_OPGEHAALD: string = 'nietOpgehaald'

    static readonly SORTED_STATUS: string[] = [AfhaalMandjeStatus.KLAAR_VOOR_OPHALING, AfhaalMandjeStatus.IN_BEHANDELING, AfhaalMandjeStatus.AANGEMAAKT, AfhaalMandjeStatus.OPGEHAALD, AfhaalMandjeStatus.NIET_OPGEHAALD]
}

export class AfhaalMandjeItemStatus {
    static readonly GERESERVEERD: string = 'gereserveerd'
    static readonly IN_MANDJE: string = 'inMandje'
    static readonly NIET_BESCHIKBAAR: string = 'nietBeschikbaar'
    static readonly UITGELEEND: string = 'uitgeleend'
    static readonly TERUGGEBRACHT: string = 'teruggebracht'

    static readonly SORTED_STATUS: string[] = [AfhaalMandjeItemStatus.GERESERVEERD,
    AfhaalMandjeItemStatus.NIET_BESCHIKBAAR, AfhaalMandjeItemStatus.IN_MANDJE, AfhaalMandjeItemStatus.TERUGGEBRACHT]
}

export class AfhaalMandjeQrActions {
    static readonly MARKEER_ALS_NIET_OPGEHAALD: string = '8ef06f04-8afc-4717-959d-2fe7af540246'
}

export const getAfhaalMandjeStatusColor = (status) => {
    switch(status){
        case AfhaalMandjeStatus.IN_BEHANDELING:
            return 'red'
        case AfhaalMandjeStatus.KLAAR_VOOR_OPHALING:
            return 'teal'
        case AfhaalMandjeStatus.OPGEHAALD:
            return 'green'
        default: return 'grey'
    }
}

export const getAfhaalMandjeStatusIcon = (status) => {
    switch(status){
        case AfhaalMandjeStatus.AANGEMAAKT:
            return 'industry'
        case AfhaalMandjeStatus.IN_BEHANDELING:
            return 'hourglass half'
        case AfhaalMandjeStatus.KLAAR_VOOR_OPHALING:
            return 'thumbs up'
        case AfhaalMandjeStatus.OPGEHAALD:
            return 'green'
        default: return 'grey'
    }
}