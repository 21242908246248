(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("semantic-ui-react"), require("med-class"), require("html-react-parser"), require("lodash"), require("moment"), require("react-share"), require("rc-slider"), require("react-copy-to-clipboard"), require("lodash/slice"), require("lodash/sample"), require("axios"), require("web-component-mediatheek-map"), require("lodash/uniq"), require("lodash/concat"), require("lodash/intersection"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "semantic-ui-react", "med-class", "html-react-parser", "lodash", "moment", "react-share", "rc-slider", "react-copy-to-clipboard", "lodash/slice", "lodash/sample", "axios", "web-component-mediatheek-map", "lodash/uniq", "lodash/concat", "lodash/intersection"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("semantic-ui-react"), require("med-class"), require("html-react-parser"), require("lodash"), require("moment"), require("react-share"), require("rc-slider"), require("react-copy-to-clipboard"), require("lodash/slice"), require("lodash/sample"), require("axios"), require("web-component-mediatheek-map"), require("lodash/uniq"), require("lodash/concat"), require("lodash/intersection")) : factory(root["react"], root["semantic-ui-react"], root["med-class"], root["html-react-parser"], root["lodash"], root["moment"], root["react-share"], root["rc-slider"], root["react-copy-to-clipboard"], root["lodash/slice"], root["lodash/sample"], root["axios"], root["web-component-mediatheek-map"], root["lodash/uniq"], root["lodash/concat"], root["lodash/intersection"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__13__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__17__) {
return 