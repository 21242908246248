import React from 'react'
import PropTypes from 'prop-types'
import {Icon} from 'semantic-ui-react'

const GoogleMapMarker = () => {

    return (
        <Icon
            style={{position: 'absolute', transform: 'translate(-50%, -100%)', cursor: 'pointer'}}
            name='map marker alternate' size='big'
        />
    )
}

export default  GoogleMapMarker

GoogleMapMarker.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
}

GoogleMapMarker.defaultProps = {

}