import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import { Card } from 'semantic-ui-react'

const Parser = require('html-react-parser')

const BidocReservatieListItem = ({bidocReservatie, localeManager}) => {

    const metaString = (bidocReservatie, localeManager) => {
        const msa = []
        if (bidocReservatie.publicatietype) msa.push(localeManager.getString(bidocReservatie.publicatietype))
        //if (bidocReservatie.aanwinstnummer) msa.push(`${localeManager.getString('AANWINSTNUMMER')}: ${bidocReservatie.aanwinstnummer}`)

        return msa.join(' | ')
    }

    return (

        <Card link={false} style={{marginBottom: '15px', border: 'none'}} fluid>
            <Card.Content>

                <Card.Header>{Parser(bidocReservatie.titel)}</Card.Header>
                <div style={{fontSize: '0.85em', color: 'grey', margin: '2px 0 2px 0'}}>
                    {bidocReservatie.subtitelArray.map((subtitel, index) => {
                        return <Fragment key={index}><em>{subtitel}</em><br/></Fragment>
                    })}
                </div>
                <Card.Meta>
                    <span>{Parser(metaString(bidocReservatie, localeManager))}</span>
                    <div style={{color: 'black'}}>{Parser(bidocReservatie.auteur)}</div>
                </Card.Meta>

            </Card.Content>
        </Card>

    )
}

export default BidocReservatieListItem

BidocReservatieListItem.propTypes = {
    localeManager: PropTypes.object.isRequired,
    arteveldeUser: PropTypes.object,
    bidocReservatie: PropTypes.object.isRequired
}

BidocReservatieListItem.defaultProps = {}