import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Card, Image, Label, Button, Icon} from 'semantic-ui-react'
import 'moment/locale/nl'
import moment from 'moment'
import orderBy from 'lodash/orderBy'
import Parser from 'html-react-parser'
import {getAfhaalMandjeStatusColor, AfhaalMandjeItemStatus, getAfhaalMandjeStatusIcon} from 'med-class'
import {ZoekresultaatListItem} from "web-component-zoek";
import Spacer from "./Spacer";

const AfhaalMandjeListItem = ({
                                  afhaalMandje, localeManager, mediatheekService, arteveldeUser,
                                  mijnMediatheekService, toonItems, toonInfo, onDeleteAfhaalmandjeItem,
                                  deleteable, onItemClick, topContentFunction, filterFunction, sortFunction, tijdslotWijzigbaarFunction, onWijzigAfhaalmandjeTijdslot
                              }) => {

    const [mediatheek, setMediatheek] = useState()
    const [afhaalDatum, setAfhaalDatum] = useState()
    const [filteredItems, setfilteredItems] = useState()

    useEffect(() => {
        setMediatheek(mediatheekService.mediatheekCampusCodeLookup[afhaalMandje.mediatheek])
        let filteredItems = afhaalMandje.afhaalMandjeItems
        if (filterFunction) filteredItems = filteredItems.filter(filterFunction)
        if (sortFunction) filteredItems = orderBy(filteredItems, sortFunction)

        setfilteredItems(filteredItems)
    }, [afhaalMandje, filterFunction, mediatheekService.mediatheekCampusCodeLookup, sortFunction])


    useEffect(() => {
        const datum = moment(afhaalMandje.slotStart).locale(localeManager.locale).format('DD/MM/YYYY')
        const van = moment(afhaalMandje.slotStart).locale(localeManager.locale).format('HH:mmu')
        const tot = moment(afhaalMandje.slotStart).add(30, 'minutes').locale(localeManager.locale).format('HH:mmu')
        const datumString = <Fragment>
            <strong>{datum}</strong> {localeManager.getString('TUSSEN')} <strong>{van}</strong> {localeManager.getString('EN')} <strong>{tot}</strong>
        </Fragment>

        setAfhaalDatum(datumString)
    }, [afhaalMandje, localeManager, localeManager.locale])


    if (!mediatheek || !filteredItems) return null

    return (
        <Card link={false} style={{marginBottom: '15px', border: 'none', maxWidth: '800px'}} fluid color={getAfhaalMandjeStatusColor(afhaalMandje.status)}>
            <Card.Content>
                <Image floated='left' src={`//www.arteveldehogeschool.be/mediatheken/root/mediatheek-assets/fotos/mediatheken/med-${mediatheek.campusCode}.jpg`} size='tiny'/>

                <Card.Header style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>{`${localeManager.getString('AFHAALMANDJE')} ${localeManager.getString('MEDIATHEEK')} ${mediatheek.naam}`}</div>

                </Card.Header>

                <div>{localeManager.getString('EIGENAAR')}: <strong>{afhaalMandje.voornaam} {afhaalMandje.familienaam}</strong> ({afhaalMandje.email})</div>
                <div>{localeManager.getString('AFHAALDATUM')}: {afhaalDatum}&nbsp;&nbsp;{tijdslotWijzigbaarFunction && onWijzigAfhaalmandjeTijdslot && tijdslotWijzigbaarFunction(afhaalMandje) && <Button size='mini' color='red' onClick={() => onWijzigAfhaalmandjeTijdslot(afhaalMandje)}>{localeManager.getString('HIES_ANDER_TIJDSLOT')}</Button>}</div>

                <Spacer vertical distance={10}/>

                <Label color={getAfhaalMandjeStatusColor(afhaalMandje.status)}>
                    <Icon name={getAfhaalMandjeStatusIcon(afhaalMandje.status)}/>
                    {localeManager.getString(afhaalMandje.status)}
                </Label>


                {/*<Card.Meta>
                    Meta content
                </Card.Meta>*/}

            </Card.Content>

            <Card.Content>
                <Label size='massive' color='teal' content={`${afhaalMandje.mediatheek.toUpperCase()}-${afhaalMandje.id}`}/>
            </Card.Content>

            {toonInfo && <Card.Content>
                {Parser(localeManager.getString('AFHAALMANDJE_STATUS_TEKST_' + afhaalMandje.status))}
            </Card.Content>}


            {toonItems && <Card.Content>
                <Card.Header>{localeManager.getString('INHOUD_VAN_UW_MANDJE')}</Card.Header>
                <Spacer vertical/>
                {filteredItems.map((zr, index) => {
                    const zoekresultaat = JSON.parse(zr.zoekresultaat)
                    //const plaats = zoekresultaat.exemplaar[0].plaatskenmerk
                    const plaats = zoekresultaat.plaatskenmerk
                    return <div key={index} className='cg-zoekresultaat-list-item' onClick={() => onItemClick && onItemClick({afhaalmandjeItem: zr, zoekresultaat})}>
                        <ZoekresultaatListItem clickable={true}
                                               aanwinstNummer={`Aanwinstnummer: ${zr.awnr} - Plaats: ${plaats}`}
                                               topContentFunction={() => topContentFunction && topContentFunction({afhaalmandjeItem: zr, zoekresultaat})}
                                               mijnMediatheekService={mijnMediatheekService}
                                               zoekresultaat={zoekresultaat}
                                               localeManager={localeManager}
                                               onCardClick={() => console.log('KLIKKERDEKLIK')}
                                               arteveldeUser={arteveldeUser}/>

                        {deleteable && zr.status === AfhaalMandjeItemStatus.GERESERVEERD && <div className='buttons'>
                            <Button icon color='red' onClick={() => onDeleteAfhaalmandjeItem(afhaalMandje, zr)}><Icon name='trash'/></Button>
                        </div>}
                    </div>
                })}

                {/*{afhaalMandje.nietBeschikbaar && afhaalMandje.nietBeschikbaar.length > 0 && <Fragment>
                    <Spacer vertical distance={15}/>
                    <Card.Header divided>{localeManager.getString('NIET_BESCHIKBARE_TITELS')}</Card.Header>
                    <Spacer vertical/>
                    {afhaalMandje.nietBeschikbaar.map((titel, index) => {
                        return <div key={index}>
                            {titel}
                        </div>
                    })}
                </Fragment>}*/}


            </Card.Content>}


        </Card>
    )
}

export default AfhaalMandjeListItem

AfhaalMandjeListItem.propTypes = {
    afhaalMandje: PropTypes.object.isRequired,
    arteveldeUser: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    mediatheekService: PropTypes.object.isRequired,
    mijnMediatheekService: PropTypes.object.isRequired,
    toonItems: PropTypes.bool,
    toonInfo: PropTypes.bool,
    onDeleteAfhaalmandjeItem: PropTypes.func,
    deleteable: PropTypes.bool,
    onItemClick: PropTypes.func,
    topContentFunction: PropTypes.func,
    filterFunction: PropTypes.func,
    sortFunction: PropTypes.func,
    tijdslotWijzigbaarFunction: PropTypes.func,
    onWijzigAfhaalmandjeTijdslot: PropTypes.func,
}

AfhaalMandjeListItem.defaultProps = {
    toonItems: true,
    toonInfo: true,
    deleteable: true
}