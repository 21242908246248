import {Nieuws} from "../class/nieuws/Nieuws";
import {NIEUWS_STATUS} from "../class/nieuws/nieuwsStatus";

const moment = require('moment');

export function getNieuwsStatus(nieuws:Nieuws): string {
	const today = moment();
	const geldigVan = moment(nieuws.fromDate, 'YYYY-MM-DD')
	const geldigTot = moment(nieuws.untilDate, 'YYYY-MM-DD')
	
	if (today > geldigTot)
		return NIEUWS_STATUS.VERVALLEN;
	
	if (today < geldigVan)
		return NIEUWS_STATUS.GEPLAND;
	
	if (nieuws.online === 1)
		return NIEUWS_STATUS.ONLINE;
	
	return NIEUWS_STATUS.ONHOLD;
}