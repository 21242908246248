import {Zoekmode} from 'med-class'

export class QueryData {

    data = []
    basicSearchString = ''
    localeManager
    mode

    constructor(mode, localeManager){
        this.localeManager = localeManager
        this.mode = mode
        //we beginnen altijd met één item
        this.addItem()
    }

    addItem = (booleanValue = '', zoekveld = 'titel') => {
        const item = {boolean: booleanValue, zoekveld: zoekveld, query: ''}
        this.data.push(item)
    }

    removeItem = (item) => {
        this.data = this.data.filter(it => it !== item)
    }

    /*toString = () => {
        let rs = ''
        for (var prop in this.data) {
            let ds = ''
            if (rs !== '') rs += ' | '
            rs += this.localeManager.getString(prop) + ': '

            for (let item of this.data[prop]) {
                if (ds !== '') ds += ', '
                ds += this.localeManager.getString(item.label)
            }
            rs += ds
        }

        return rs
    }*/

    toQueryString = () => {

        if (this.mode === Zoekmode.NORMAAL) return this.basicSearchString

        let rs = ''

        //#251: wanneer in data een leeg queryveld zit, dit verwijderen

        const cleanedData = this.data.filter(dl => dl.query !== '')

        cleanedData.forEach((lijn, index) => {
            rs += `${lijn.zoekveld}:${lijn.query}`
            if (lijn.boolean !== '') rs += `:${lijn.boolean.toUpperCase()}`
            if (index < cleanedData.length-1) rs += '|'
        })

        return rs
    }

    fromBewaardeZoekopdracht = (bewaardeZoekopdracht) => {
        this.mode = bewaardeZoekopdracht.mode
        this.data = []
        if (this.mode === Zoekmode.NORMAAL){
            this.basicSearchString = bewaardeZoekopdracht.query
        } else {
            const dataArr = bewaardeZoekopdracht.query.split('|')
            for (const dataArrElement of dataArr) {
                const lijnArr = dataArrElement.split(':')
                const item = {boolean: lijnArr.length > 2 ? lijnArr[2] : '', zoekveld: lijnArr[0], query: lijnArr[1]}
                this.data.push(item)
            }
        }

    }
}