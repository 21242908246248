import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Image, Divider, Header, Button, Icon, Label, Segment, Popup, Modal} from 'semantic-ui-react'
import {ApplicationPath, BLAUW, DONKERGRIJS, TOOLTIP_DELAY, AfhaalMandjeStatus} from 'med-class'
import BidocUitleningListItem from "./component/BidocUitleningListItem";
import BidocReservatieListItem from "./component/BidocReservatieListItem";
import ZoeklijstListItem from "./component/ZoeklijstListItem";
import BewaardeZoekopdrachtListItem from "./component/BewaardeZoekopdrachtListItem"
import AfhaalMandjeListItem from "./component/AfhaalMandjeListItem";
import KiesTijdsslotModal from "./component/KiesTijdsslotModal";
import Parser from 'html-react-parser'
import KiesTijdsslotModal2 from "./component/KiesTijdsslotModal2";


export default class WebComponentMijnMediatheek extends Component {

    constructor(props) {
        super(props)
        this.state = {
            verlengData: null,
            verlengAlleVisible: true,
            uitleningenVisible: false,
            reservatiesVisible: false,
            zoeklijstenVisible: false,
            bewaardeZoekopdrachtenVisible: false,
            afhaalMandjesVisible: false,
            closeButtonVisible: false,
            kiesTijdsslotModalOpen: false,
            serverResponseObjectToegevoegd: false,
            messageModalOpen: false,
            messageModalMessage: '',

            tijdslotKiezerData: null
        }
    }

    componentDidMount() {
        const {localeManager, mijnMediatheekService, arteveldeUser} = this.props
        if (!arteveldeUser.loggedIn) return

        const zoeklijstProvider = mijnMediatheekService.zoeklijsten.map(zoeklijst => {
            return {key: zoeklijst.id, value: zoeklijst.id, text: zoeklijst.label}
        })
        zoeklijstProvider.unshift({key: 0, value: 0, text: localeManager.getString('FAVORIETEN')})
        this.setState({zoeklijstProvider})
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.serverResponseObject !== prevState.serverResponseObject)
            return {serverResponseObject: nextProps.serverResponseObject}

        return null
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.serverResponseObject && this.state.serverResponseObject !== prevState.serverResponseObject) {
            console.log('HANDLING RESP OBJ', this.state.serverResponseObject, prevState.serverResponseObject)
            this.handleServerResponseObject(this.state.serverResponseObject)
            //this.setState({serverResponseObject: null})
        }
    }

    handleServerResponseObject = (serverResponseObject) => {

        const {localeManager, mediatheekService} = this.props

        //reservatie bevat het response object van MMS voegToeAanAfhaalMandje
        //de functie setReserveerButtonVisible werd er in de main app aan toegevoegd zodat de knop 'reserveer' verborgen kan worden
        console.log('SCO', serverResponseObject)


        if (serverResponseObject.error) {
            const error = localeManager.getString(serverResponseObject.error)
            this.setState({messageModalMessage: error})

            switch (serverResponseObject.error) {
                case 'EXEMPLAAR_REEDS_GERESERVEERD':
                case 'DEFAULT_ERROR_CONTACT_ADMIN':
                case 'AFHAALMANDJE_IN_BEHANDELING':
                case 'MAXIMUM_AANTAL_BEREIKT':
                    this.setState({messageModalOpen: true})
                    break

                default:
                    console.error('ERROR', error, serverResponseObject)
            }


        } else if (serverResponseObject.msg) {
            const message = localeManager.getString(serverResponseObject.msg)

            switch (serverResponseObject.msg) {
                case 'GEEN_AFHAALMANDJE':
                    console.info('RESERVATIE', serverResponseObject)

                    const tijdslotKiezerData = {
                        tijdslots: serverResponseObject.timeslots[serverResponseObject.callback.mediatheek],
                        mediatheek: mediatheekService.mediatheekCampusCodeLookup[serverResponseObject.callback.mediatheek],
                        zoekresultaat: serverResponseObject.callback.bidocZoekresultaat,
                    }
                    this.setState({tijdslotKiezerData, kiesTijdsslotModalOpen: true})

                    break
                case 'titelToegevoegdAanUwMandje':
                case 'TITEL_TOEGEVOEGD_AAN_AFHAALMANDJE':
                    this.notifyserverResponseObject()
                    serverResponseObject.setReserveerButtonVisible && serverResponseObject.setReserveerButtonVisible(false)
                    this.setState({messageModalOpen: true, messageModalMessage: message})
                    break
                default:
                    console.info('RESERVATIE', serverResponseObject)
            }
        }

        //return
        /*const mandje = mijnMediatheekService.afhaalMandjesLookup[mediatheek.campusCode]
        if (mandje) {

            //TODO: Check satus 'InBehandeling' en 'Opgehaald'
            //if (mandje.status === AfhaalMandjeStatus.)
            mijnMediatheekService.voegReservatieToeAanAfhaalMandje(reservatie.zoekresultaat, mandje).then(res => {
                //TODO: Check status Maximum ontleningen
                console.log('RES', res)
                window.alert(localeManager.getString('TITEL_TOEGEVOEGD_AAN_AFHAALMANDJE'))
                this.notifyserverResponseObject()
            })
        } else {

        }*/
    }

    notifyserverResponseObject = () => {
        this.setState({serverResponseObjectToegevoegd: true})
        window.setTimeout(() => this.setState({serverResponseObjectToegevoegd: false}), 15000)
    }


    onWijzigAfhaalmandjeTijdslot = (afhaalmandje) => {
        const {mijnMediatheekService, mediatheekService} = this.props

        mijnMediatheekService.getTijdslots(afhaalmandje.mediatheek).then(res => {
            const tijdslotKiezerData = {
                afhaalmandje,
                tijdslots: res.tijdslots,
                mediatheek: mediatheekService.mediatheekCampusCodeLookup[afhaalmandje.mediatheek],
                zoekresultaat: null,
                selectedTijdslot: afhaalmandje.slotStart
            }
            this.setState({tijdslotKiezerData, kiesTijdsslotModalOpen: true})
            //console.log('WIJZIG', afhaalmandje, tijdslotKiezerData)
        })

    }

    onKiesTijdsslot = (slot) => {
        const {serverResponseObject, tijdslotKiezerData} = this.state
        const {mijnMediatheekService, localeManager} = this.props

        if (!slot) { //annuleren
            this.setState({kiesTijdsslotModalOpen: false})
            return
        }

        if (serverResponseObject) {
            serverResponseObject.callback.slotStart = slot.van

            this.setState({kiesTijdsslotModalOpen: false})

            mijnMediatheekService.callCollectService(serverResponseObject.callback).then(res => {
                console.log('RES', res)

                if (res.error && res.error === 'TIJDSSLOT_REEDS_BEZET') {
                    const message = localeManager.getString('TIJDSSLOT_REEDS_BEZET')
                    this.setState({messageModalOpen: true, messageModalMessage: message, serverResponseObject: {...res}})

                } else {
                    this.setState({kiesTijdsslotModalOpen: false})
                    const message = localeManager.getString('AFHAALMANDJE_GEMAAKT_EN_RESERVATIE_TOEGEVOEGD')
                    serverResponseObject.setReserveerButtonVisible && serverResponseObject.setReserveerButtonVisible(false)
                    serverResponseObject.deleteResponseObject && serverResponseObject.deleteResponseObject()
                    this.setState({messageModalOpen: true, messageModalMessage: message, serverResponseObject: null, tijdslotKiezerData: null})
                    this.notifyserverResponseObject()
                }
            })
        } else if (tijdslotKiezerData && tijdslotKiezerData.afhaalmandje) {
            tijdslotKiezerData.afhaalmandje.ophalen = slot.dag
            tijdslotKiezerData.afhaalmandje.slotStart = slot.van
            mijnMediatheekService.wijzigAfhaalmandje(tijdslotKiezerData.afhaalmandje).then(res => {
                //console.log('SELECT SLOT', tijdslotKiezerData, slot)
                this.setState({kiesTijdsslotModalOpen: false})
            })
        }
    }

    verlengBidocUitlening = (bidocUitlening) => {
        const {mijnMediatheekService, arteveldeUser} = this.props
        mijnMediatheekService.verlengBidocUitlening(bidocUitlening, arteveldeUser).then((verlengData) => {
                console.log('VERLENG', verlengData)
                this.setState({verlengData})
            }
        )
    }

    verlengAlleBidocUitleningen = () => {
        const {mijnMediatheekService, arteveldeUser} = this.props
        mijnMediatheekService.verlengAlleBidocUitleningen(mijnMediatheekService.uitleenData, arteveldeUser).then((verlengData) => {
                console.log('VERLENG ALLE', verlengData)
                this.setState({verlengData, verlengAlleVisible: false})
            }
        )
    }

    verwijderLijst = (zoeklijst) => {
        const {mijnMediatheekService, arteveldeUser} = this.props
        mijnMediatheekService.deleteZoeklijst(zoeklijst, arteveldeUser).then(res => {
            console.log('Lijst verwijderen', res)
            this.setState({})
        })
    }

    maakLijstLeeg = (zoeklijst) => {
        const {mijnMediatheekService, arteveldeUser, onMijnMediatheekChange} = this.props
        mijnMediatheekService.maakZoeklijstLeeg(zoeklijst, arteveldeUser).then(res => {
            console.log('Lijst leegmaken', res)
            this.setState({})
            onMijnMediatheekChange && onMijnMediatheekChange()
        })
    }

    bewaarZoeklijst = (zoeklijst) => {
        const {mijnMediatheekService, onMijnMediatheekChange} = this.props
        mijnMediatheekService.updateZoeklijst(zoeklijst).then(result => {
            console.log('ZOEKLIJST UPDATED', result)
            onMijnMediatheekChange && onMijnMediatheekChange()
        })
    }

    onHeaderIconClick = (name) => {

        const {uitleningenVisible, reservatiesVisible, zoeklijstenVisible, bewaardeZoekopdrachtenVisible, afhaalMandjesVisible} = this.state
        let closeButtonVisible = true
        switch (name) {
            case 'uitleningen':
                if (uitleningenVisible) closeButtonVisible = false
                this.setState({uitleningenVisible: !uitleningenVisible, reservatiesVisible: false, zoeklijstenVisible: false, bewaardeZoekopdrachtenVisible: false, afhaalMandjesVisible: false})
                break
            case 'reservaties':
                if (reservatiesVisible) closeButtonVisible = false
                this.setState({uitleningenVisible: false, reservatiesVisible: !reservatiesVisible, zoeklijstenVisible: false, bewaardeZoekopdrachtenVisible: false, afhaalMandjesVisible: false})
                break
            case 'zoeklijsten':
                if (zoeklijstenVisible) closeButtonVisible = false
                this.setState({uitleningenVisible: false, reservatiesVisible: false, zoeklijstenVisible: !zoeklijstenVisible, bewaardeZoekopdrachtenVisible: false, afhaalMandjesVisible: false})
                break
            case 'bewaardeZoekopdrachten':
                if (bewaardeZoekopdrachtenVisible) closeButtonVisible = false
                this.setState({
                    uitleningenVisible: false,
                    reservatiesVisible: false,
                    zoeklijstenVisible: false,
                    bewaardeZoekopdrachtenVisible: !bewaardeZoekopdrachtenVisible,
                    afhaalMandjesVisible: false
                })
                break
            case 'afhaalMandjes':
                if (afhaalMandjesVisible) closeButtonVisible = false
                this.setState({uitleningenVisible: false, reservatiesVisible: false, zoeklijstenVisible: false, bewaardeZoekopdrachtenVisible: false, afhaalMandjesVisible: !afhaalMandjesVisible})
                break
            default:
                break
        }

        this.setState({closeButtonVisible})
    }

    onHideAll = () => {
        this.setState({uitleningenVisible: false, reservatiesVisible: false, zoeklijstenVisible: false, closeButtonVisible: false, bewaardeZoekopdrachtenVisible: false})
    }

    onZoeklijstSelect = (event, {value}) => {
        const {onToonZoeklijstResultaten, mijnMediatheekService} = this.props
        this.onHideAll()
        const zoeklijst = value === 0 ? mijnMediatheekService.favorietenZoeklijst : mijnMediatheekService.zoeklijsten.filter(zl => zl.id === value)[0]
        console.log('ZL select', zoeklijst)
        onToonZoeklijstResultaten(zoeklijst)
    }

    verwijderBewaardeZoekopdracht = (bewaaardeZoekopdracht) => {
        console.log('VERWIJDER')
        const {mijnMediatheekService} = this.props
        mijnMediatheekService.deleteBewaardeZoekopdracht(bewaaardeZoekopdracht).then(res => {
            this.setState({})
        })
    }

    bewaarBewaardeZoekopdracht = (bewaaardeZoekopdracht) => {
        console.log('BEWAAR')
        /*const {mijnMediatheekService, onMijnMediatheekChange} = this.props
        mijnMediatheekService.updateZoeklijst(zoeklijst).then(result => {
            console.log('ZOEKLIJST UPDATED', result)
            onMijnMediatheekChange()
        })*/
    }

    onToonZoeklijstResultaten = (zoeklijst) => {
        this.onHeaderIconClick('zoeklijsten')
        this.props.onToonZoeklijstResultaten(zoeklijst)
    }

    onToonBewaardeZoekopdrachtResultaten = (zoeklijst) => {
        this.onHeaderIconClick('bewaardeZoekopdrachten')
        this.props.onToonBewaardeZoekopdrachtResultaten(zoeklijst)
    }

    onDeleteAfhaalmandjeItem = (afhaalMandje, afhaalMandjeItem) => {
        const {localeManager, mijnMediatheekService} = this.props
        const confirm = window.confirm(localeManager.getString(afhaalMandje.afhaalMandjeItems.length === 1 ? 'VERWIJDER_RESERVATIE_LAATSTE' : 'VERWIJDER_RESERVATIE'))

        if (confirm) {
            mijnMediatheekService.deleteAfhaalmandjeItem(afhaalMandjeItem).then(res => {
                console.log('DELETE ITEM', res)
                const message = localeManager.getString(res.msg)
                this.setState({messageModalOpen: true, messageModalMessage: message})
                this.notifyserverResponseObject()
            })
        }
    }


    render() {
        const {
            verlengData, verlengAlleVisible,
            uitleningenVisible, reservatiesVisible, zoeklijstenVisible, bewaardeZoekopdrachtenVisible, afhaalMandjesVisible, closeButtonVisible,
            serverResponseObject, kiesTijdsslotModalOpen, serverResponseObjectToegevoegd, messageModalOpen, messageModalMessage,
            tijdslotKiezerData
        } = this.state
        const {arteveldeUser, localeManager, mijnMediatheekService, mediatheekService, onAfmelden, onInloggen} = this.props


        /*if (!arteveldeUser.hasKaart) {
            return (
                <div style={{
                    display: 'flex', cursor: 'pointer', height: '60px',
                    alignItems: 'center', minHeight: '30px',
                    backgroundColor: DONKERGRIJS.css, color: 'white', paddingRight: '5px', marginBottom: '15px'
                }}>
                    <h4 style={{padding: 0, margin: 0, marginLeft: '10px', flexGrow: 1}}>{localeManager.getString('INLOGGEN_GEEN_STUDENTENKAART')}</h4>
                </div>
            )
        }*/

        if (!arteveldeUser.loggedIn) {
            return (
                <div style={{
                    display: 'flex', cursor: 'pointer', height: '60px',
                    alignItems: 'center', minHeight: '30px',
                    backgroundColor: DONKERGRIJS.css, color: 'white', paddingRight: '5px', marginBottom: '15px'
                }}
                     onClick={onInloggen}>
                    <h4 style={{padding: 0, margin: 0, marginLeft: '10px', flexGrow: 1}}>{localeManager.getString('INLOGGEN_OP_MIJN_MEDIATHEEK')}</h4>
                </div>
            )
        }

        const avatar = (arteveldeUser.loggedIn) ? ApplicationPath.avatarApi + arteveldeUser.emailAddress : null

        const verlengAlleEnabled = verlengAlleVisible && mijnMediatheekService.uitleenData.uitleningen.filter(bidocUitlening => bidocUitlening.verlengbaar).length > 1
        const isTeLaat = mijnMediatheekService.uitleenData.uitleningen.filter(ul => ul.isTeLaat).length > 0

        const backdropStyle = 'mm-title-bar-backdrop-div'
        const backdropStyleSelected = backdropStyle + ' mm-title-bar-backdrop-div-selected'

        const aantalAfhaalMandjesKlaar = mijnMediatheekService.afhaalMandjes.filter(mandje => mandje && mandje.status === AfhaalMandjeStatus.KLAAR_VOOR_OPHALING).length


        return (
            <div>

                <div style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                    backgroundColor: BLAUW.css, color: 'white', paddingRight: '5px', marginBottom: '15px'
                }}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {avatar && <Image style={{height: '60px'}} src={avatar}/>}
                        <h4 style={{padding: 0, margin: 0, marginLeft: '10px'}}>{localeManager.getString('MIJN_MEDIATHEEK')}</h4>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>

                        <Popup
                            trigger={
                                <div className={uitleningenVisible ? backdropStyleSelected : backdropStyle}
                                     onClick={() => this.onHeaderIconClick('uitleningen')}>
                                    <Icon.Group size="big">
                                        <Icon name="book"/>
                                        {isTeLaat && <Icon name='exclamation circle' corner='bottom left' color='red'/>}
                                    </Icon.Group>
                                    <Label circular size='mini'>{mijnMediatheekService.uitleenData.uitleningen.length}</Label>
                                </div>
                            }
                            content={localeManager.getString('MIJN_UITLENINGEN')}
                            mouseEnterDelay={TOOLTIP_DELAY}/>

                        <Popup
                            trigger={
                                <div className={reservatiesVisible ? backdropStyleSelected : backdropStyle}
                                     onClick={() => this.onHeaderIconClick('reservaties')}>
                                    <Icon name="clock" size="big"/>
                                    <Label circular size='mini'>{mijnMediatheekService.reservatieData.reservaties.length}</Label>
                                </div>
                            }
                            content={localeManager.getString('MIJN_RESERVATIES')}
                            mouseEnterDelay={TOOLTIP_DELAY}/>

                        <Popup
                            trigger={
                                <div className={zoeklijstenVisible ? backdropStyleSelected : backdropStyle}
                                     onClick={() => this.onHeaderIconClick('zoeklijsten')}>
                                    <Icon name="list alternate" size="big"/>
                                    <Label circular size='mini'>{mijnMediatheekService.zoeklijsten.length}</Label>
                                </div>
                            }
                            content={localeManager.getString('LEESLIJSTEN')}
                            mouseEnterDelay={TOOLTIP_DELAY}/>

                        <Popup
                            trigger={
                                <div className={bewaardeZoekopdrachtenVisible ? backdropStyleSelected : backdropStyle}
                                     onClick={() => this.onHeaderIconClick('bewaardeZoekopdrachten')}>
                                    <Icon name="search" size="big"/>
                                    <Label circular size='mini'>{mijnMediatheekService.bewaardeZoekopdrachten.length}</Label>
                                </div>
                            }
                            content={localeManager.getString('BEWAARDE_ZOEKOPDRACHTEN')}
                            mouseEnterDelay={TOOLTIP_DELAY}/>

                        <Popup
                            trigger={
                                <div className={`${afhaalMandjesVisible ? backdropStyleSelected : backdropStyle} ${serverResponseObjectToegevoegd ? 'focus-on-tile' : ''}`}
                                     onClick={() => this.onHeaderIconClick('afhaalMandjes')}>
                                    <Icon.Group size="big">
                                        <Icon name="shopping basket"/>
                                        {aantalAfhaalMandjesKlaar > 0 && <Icon name='thumbs up' corner='bottom left' color='teal'/>}
                                    </Icon.Group>
                                    <Label circular size='mini'>{mijnMediatheekService.afhaalMandjes.length}</Label>
                                </div>
                            }
                            content={localeManager.getString('AFHAALMANDJES')}
                            mouseEnterDelay={TOOLTIP_DELAY}/>

                    </div>

                    <div style={{display: 'flex', justifyContent: 'flex-end', width: '80px'}}>
                        {closeButtonVisible && <Button size='small' icon onClick={this.onHideAll}><Icon name="close"/></Button>}
                        <Button size='small' onClick={onAfmelden}>{localeManager.getString('AFMELDEN')}</Button>
                    </div>
                </div>


                {uitleningenVisible &&
                <Segment style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Header size='huge'>{localeManager.getString('MIJN_UITLENINGEN')}</Header>

                    {mijnMediatheekService.uitleenData.uitleningen.length === 0 &&
                    <div>{localeManager.getString('GEEN_UITLENINGEN')}</div>}

                    <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: '800px'}}>
                        {mijnMediatheekService.uitleenData.uitleningen.map((bidocUitlening, index) => {
                            return (
                                <BidocUitleningListItem key={index}
                                                        bidocUitlening={bidocUitlening}
                                                        localeManager={localeManager}
                                                        verlengData={verlengData}
                                                        verlengBidocUitlening={() => this.verlengBidocUitlening(bidocUitlening)}/>
                            )
                        })}
                    </div>

                    {verlengAlleEnabled && <Button color='blue' onClick={this.verlengAlleBidocUitleningen}>{localeManager.getString('VERLENG_AL_MIJN_UITLENINGEN')}</Button>}

                    <Divider/>
                </Segment>}

                {reservatiesVisible &&
                <Segment style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Header size='huge'>{localeManager.getString('MIJN_RESERVATIES')}</Header>

                    {mijnMediatheekService.reservatieData.reservaties.length === 0 &&
                    <div>{localeManager.getString('GEEN_RESERVATIES')}</div>}

                    <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: '800px'}}>
                        {mijnMediatheekService.reservatieData.reservaties.map((bidocReservatie, index) => {
                            return (
                                <BidocReservatieListItem key={index}
                                                         bidocReservatie={bidocReservatie}
                                                         localeManager={localeManager}/>
                            )
                        })}
                    </div>

                    <Divider/>
                </Segment>}

                {zoeklijstenVisible &&
                <Segment style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                    <Header size='huge'>{localeManager.getString('MIJN_LIJSTEN')}</Header>

                    {mijnMediatheekService.zoeklijsten.length === 0 &&
                    <div>{localeManager.getString('GEEN_LIJSTEN')}</div>}

                    {mijnMediatheekService.zoeklijsten &&
                    <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: '800px', alignSelf: 'center'}}>
                        <ZoeklijstListItem zoeklijst={mijnMediatheekService.favorietenZoeklijst}
                                           localeManager={localeManager}
                                           isFavoriet={true}
                                           onMaakLijstLeeg={this.maakLijstLeeg}
                                           onToonResultaten={this.onToonZoeklijstResultaten}/>

                        {mijnMediatheekService.zoeklijsten.map((zoeklijst, index) => {
                            return (
                                <ZoeklijstListItem key={index}
                                                   zoeklijst={zoeklijst}
                                                   localeManager={localeManager}
                                                   onToonResultaten={this.onToonZoeklijstResultaten}
                                                   onVerwijderLijst={this.verwijderLijst}
                                                   onMaakLijstLeeg={this.maakLijstLeeg}
                                                   onZoeklijstEditEnd={this.bewaarZoeklijst}/>
                            )
                        })}
                    </div>
                    }
                    <Divider/>
                </Segment>}

                {bewaardeZoekopdrachtenVisible &&
                <Segment style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                    <Header size='huge'>{localeManager.getString('MIJN_BEWAARDE_ZOEKOPDRACHTEN')}</Header>

                    {mijnMediatheekService.bewaardeZoekopdrachten.length === 0 &&
                    <div>{localeManager.getString('GEEN_BEWAARDE_ZOEKOPDRACHTEN')}</div>}

                    {mijnMediatheekService.bewaardeZoekopdrachten &&
                    <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: '800px', width: '100%'}}>
                        {mijnMediatheekService.bewaardeZoekopdrachten.map((bewaardeZoekopdracht, index) => {
                            return (
                                <BewaardeZoekopdrachtListItem key={index}
                                                              bewaardeZoekopdracht={bewaardeZoekopdracht}
                                                              localeManager={localeManager}
                                                              onToonResultaten={this.onToonBewaardeZoekopdrachtResultaten}
                                                              onVerwijderBewaardeZoekopdracht={this.verwijderBewaardeZoekopdracht}
                                                              onBewaardeZoekopdrachtEditEnd={this.bewaarBewaardeZoekopdracht}/>
                            )
                        })
                        }
                    </div>}
                    <Divider/>
                </Segment>}

                {afhaalMandjesVisible &&
                <Segment style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                    <Header size='huge'>{localeManager.getString('MIJN_AFHAALMANDJES')}</Header>

                    {mijnMediatheekService.afhaalMandjes.length === 0 &&
                    <div>{localeManager.getString('GEEN_AFHAALMANDJES')}</div>}

                    {/*<Segment>
                        {Parser(localeManager.getString('AFHAALMANDJES_UITLEG'))}
                    </Segment>*/}

                    {mijnMediatheekService.afhaalMandjes &&
                    <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: '800px', width: '100%'}}>
                        {mijnMediatheekService.afhaalMandjes.map((afhaalMandje, index) => {
                            return (
                                <AfhaalMandjeListItem key={index}
                                                      mediatheekService={mediatheekService}
                                                      mijnMediatheekService={mijnMediatheekService}
                                                      arteveldeUser={arteveldeUser}
                                                      afhaalMandje={afhaalMandje}
                                                      localeManager={localeManager}
                                                      tijdslotWijzigbaarFunction={(am) => am.status === AfhaalMandjeStatus.AANGEMAAKT}
                                                      onWijzigAfhaalmandjeTijdslot={this.onWijzigAfhaalmandjeTijdslot}
                                                      onDeleteAfhaalmandjeItem={this.onDeleteAfhaalmandjeItem}/>
                            )
                        })
                        }
                    </div>}
                    <Divider/>
                </Segment>}

                {/* {kiesTijdsslotModalOpen && <KiesTijdsslotModal localeManager={localeManager}
                                                               mijnMediatheekService={mijnMediatheekService}
                                                               serverResponseObject={serverResponseObject}
                                                               mediatheekService={mediatheekService}
                                                               arteveldeUser={arteveldeUser}
                                                               onKiesTijdsslot={this.onKiesTijdsslot}/>}*/}

                {kiesTijdsslotModalOpen && tijdslotKiezerData &&
                <KiesTijdsslotModal2 localeManager={localeManager}
                                     mijnMediatheekService={mijnMediatheekService}
                                     tijdslots={tijdslotKiezerData.tijdslots}
                                     mediatheek={tijdslotKiezerData.mediatheek}
                                     zoekresultaat={tijdslotKiezerData.zoekresultaat}
                                     defaultSelectedSlot={tijdslotKiezerData.selectedTijdslot}
                                     mediatheekService={mediatheekService}
                                     arteveldeUser={arteveldeUser}
                                     onKiesTijdsslot={this.onKiesTijdsslot}/>
                }

                {messageModalOpen && <Modal open={messageModalOpen} dimmer='inverted' onClose={() => this.setState({messageModalOpen: false})}>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>{localeManager.getString('BERICHT')}</Header>
                            <p>
                                {messageModalMessage}
                            </p>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({messageModalOpen: false})}>Sluit</Button>
                    </Modal.Actions>
                </Modal>}
            </div>
        )
    }
}

WebComponentMijnMediatheek.propTypes = {
    arteveldeUser: PropTypes.object.isRequired,
    mijnMediatheekService: PropTypes.object.isRequired,
    mediatheekService: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    onAfmelden: PropTypes.func,
    onInloggen: PropTypes.func,
    onToonZoeklijstResultaten: PropTypes.func,
    onToonBewaardeZoekopdrachtResultaten: PropTypes.func,
    onMijnMediatheekChange: PropTypes.func,
    serverResponseObject: PropTypes.object
}

WebComponentMijnMediatheek.defaultProps = {}
