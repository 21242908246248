import React from 'react'
import PropTypes from 'prop-types'
import InformatieOpMaatDetailTile from "./InformatieOpMaatDetailTile";
import {GROEN, FUCHSIA, BLAUW, ROOD, ROZE} from 'med-class'

const dataprovider = [
    {label: 'ENW_BUSINESS_EN_MANAGEMENT',
        link: 'https://arteveldehogeschool.instructure.com/courses/13989', 
        secretLink: 'https://arteveldehogeschool.instructure.com/enroll/CYBWGR', color: BLAUW.css},
    {label: 'ENW_COMMUNICATIE_MEDIA_EN_DESIGN',
        link: 'https://arteveldehogeschool.instructure.com/courses/13987', 
        secretLink: 'https://arteveldehogeschool.instructure.com/enroll/PPM9WB', color: FUCHSIA.css},
    {label: 'ENW_GEZONDHEID_EN_ZORG',
        link: ' https://arteveldehogeschool.instructure.com/courses/13988', 
        secretLink: 'https://arteveldehogeschool.instructure.com/enroll/7PYL7D', color: ROOD.css},
    {label: 'ENW_ONDERWIJS',
        link: 'https://arteveldehogeschool.instructure.com/courses/13986', 
        secretLink: 'https://arteveldehogeschool.instructure.com/enroll/TADH9E', color: GROEN.css},
    {label: 'ENW_MENS_EN_SAMENLEVING',
        link: 'https://arteveldehogeschool.instructure.com/courses/13985', 
        secretLink: 'https://arteveldehogeschool.instructure.com/enroll/K6FP8J', color: ROZE.css},
]

const InformatieOpMaatDetail = ({localeManager, arteveldeUser}) => {


    return (
        <div>
            <div>Hier vind je de Canvascursussen van de mediatheek op maat van je expertisenetwerk/opleiding.<br/>
                Je leert meer over de algemene mediatheekdiensten: APA, informatievaardigheden, mediatheekportaal,…<br/>
                Daarnaast krijg je specifieke vakgerichte informatie: fysieke en digitale bronnen die interessant zijn voor je opleiding, instructies, nieuws, contactgegevens,… Maatwerk dus!
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}}>

                {dataprovider.map(data => {
                    return <InformatieOpMaatDetailTile key={data.label} data={data} localeManager={localeManager} arteveldeUser={arteveldeUser}/>
                })}
            </div>
        </div>
    )
}

export default  InformatieOpMaatDetail

InformatieOpMaatDetail.propTypes = {
    localeManager: PropTypes.object.isRequired,
    arteveldeUser: PropTypes.object.isRequired,
}

InformatieOpMaatDetail.defaultProps = {

}