import {AbstractZoekresultaat} from "../zoekresultaat/AbstractZoekresultaat"
import {ZoekresultaatDetail} from "../zoekresultaat/ZoekresultaatDetail";
import {ZoekresultaatFormat} from "../zoekresultaat/ZoekresultaatFormat";

export class ZoekQuery {

    _explicitType: string = "ZoekQuery"

    type: string = 'POST'
    zoekresultaatDetail: string = ZoekresultaatDetail.BASIC
    paginanummer: number
    sorteerModus: string
    itemsPerPagina: number
    query: string
    zoekOptieString: string
    upn: string
    sessionId: string
    zoekresultaatFormat: string = ZoekresultaatFormat.JSON
    zoekresultaat: AbstractZoekresultaat
    userID: number
    function: string
    zoeklijstId: number
}
