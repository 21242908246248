import {convertObjectToClass} from "../util/ClassConvertor"
import {Mediatheek} from "../class/mediatheek/Mediatheek"
import {ApplicationPath} from "../domain/path";

const moment = require('moment')
const _ = require('lodash')
const axios = require('axios')


export class MediatheekService {


    private readonly ZOEKSETPANORAMA_CONFIG_JSON_URL: string = ApplicationPath.mediatheekAssetsFolder + 'panorama/panoConfig.json'
    private readonly MEDIATHEEK_TABLE: string = 'mediatheek'
    private readonly TELLING_MINUUT_TABLE: string = 'telling_minuut'

    initialized: boolean = false
    mediatheken: Array<Mediatheek>
    mediatheekpunten: Array<Mediatheek>
    mediatheekLookup: object
    mediatheekCampusCodeLookup: object
    openingsurenLookup: object
    minuutTellersLookup: object
    apiUrl: string
    apiKey: string
    openingsurenApiUrl: string
    lastCheckMoment

    constructor(apiUrl: string, apiKey: string, openingsurenApiUrl: string) {
        this.apiUrl = apiUrl
        this.apiKey = apiKey
        this.openingsurenApiUrl = openingsurenApiUrl
        this.mediatheken = []
        this.mediatheekLookup = {}
        this.openingsurenLookup = {}
    }

    init = () => {

        let me = this

        return new Promise((resolve) => {

                this.getMediatheken().then((response: any) => {

                    me.initialized = true
                    me.mediatheken = response.mediatheken.filter(med => med.is_mediatheek === 1)
                    me.mediatheekpunten = response.mediatheken.filter(med => med.is_mediatheekpunt === 1)

                    me.mediatheekLookup = _.keyBy(response.mediatheken, mediatheek => mediatheek.id)
                    me.mediatheekCampusCodeLookup = _.keyBy(response.mediatheken, mediatheek => mediatheek.campusCode)

                    resolve({status: 'ok'})
                }).catch((error) => {
                    console.log('ERROR IN Mediatheek INIT', error)
                })
            }
        )

    }

    private getMediatheken() {

        let me = this

        return new Promise(function (resolve, reject) {
            let url = `${me.apiUrl}${me.MEDIATHEEK_TABLE}?transform=1&token=${me.apiKey}`
            axios.get(url)
                .then(response => {
                    let rawMediatheken = response.data[me.MEDIATHEEK_TABLE];
                    let mediatheken = rawMediatheken.map((rm: any) => convertObjectToClass(rm, 'Mediatheek'))
                    mediatheken = _.orderBy(mediatheken, ['naam'])

                    resolve({mediatheken})
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    private getMediatheekpunten() {

        let me = this

        return new Promise(function (resolve, reject) {
            let url = `${me.apiUrl}${me.MEDIATHEEK_TABLE}?transform=1&token=${me.apiKey}&filter[]=is_mediatheekpunt,eq,1`
            axios.get(url)
                .then(response => {
                    let rawMediatheken = response.data[me.MEDIATHEEK_TABLE];
                    let mediatheken = rawMediatheken.map((rm: any) => convertObjectToClass(rm, 'Mediatheek'))
                    mediatheken = _.orderBy(mediatheken, ['naam'])
                    resolve({mediatheken})
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    private getOpeningsuren() {

        let me = this

        return new Promise(function (resolve, reject) {

            const now = moment()

            if (me.lastCheckMoment) {
                if (me.lastCheckMoment.isSame(now, 'day')) {
                    resolve(me.openingsurenLookup)
                    return
                }
            }

            let url = `${me.openingsurenApiUrl}`

            axios.get(url)
                .then(response => {
                    let openingsuren = response.data.map((rou: any) => convertObjectToClass(rou, 'Openingsuren'))
                    me.openingsurenLookup = _.groupBy(openingsuren, 'campusCode')
                    me.lastCheckMoment = now
                    resolve(me.openingsurenLookup)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    private getOpeningsurenWeek(mediatheek: string, datumStart: string) {

        let me = this

        return new Promise(function (resolve, reject) {

            const monday = moment(datumStart).day(1)
            const friday = moment(monday).day(5)

            let url = `${me.openingsurenApiUrl}?campus=${mediatheek}&first=${monday.format("YYYY-MM-DD")}&last=${friday.format("YYYY-MM-DD")}`

            axios.get(url)
                .then(response => {
                    let openingsuren = response.data.map((rou: any) => convertObjectToClass(rou, 'Openingsuren'))
                    openingsuren = _.groupBy(openingsuren, 'datum')
                    resolve({openingsuren, weekStart: monday, weekEinde: friday})
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    private getTellersMinuut() {

        let me = this

        return new Promise(function (resolve, reject) {
            const now = moment().format('YYYY-MM-DD')
            let url = `${me.apiUrl}${me.TELLING_MINUUT_TABLE}?transform=1&token=${me.apiKey}&filter[]=datum,eq,${now}`
            axios.get(url)
                .then(response => {
                    let rawTellers = response.data[me.TELLING_MINUUT_TABLE];
                    let tellers = rawTellers.map((rm: any) => convertObjectToClass(rm, 'TellingMinuut'))
                    me.minuutTellersLookup = _.keyBy(tellers, 'campus')
                    resolve(me.minuutTellersLookup)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    getMediatheekFromString = (mediatheekString: string): Mediatheek | null => {
        for (const mediatheek of this.mediatheken) {
            if (mediatheekString.toLowerCase().indexOf(mediatheek.campus) > -1) return mediatheek
        }

        return null
    }

    //==============================================================
    //==============================================================
    //                      PANORAMA
    //==============================================================
    //==============================================================

    getPanoramaConfig = () => {

        let me = this

        return new Promise(function (resolve, reject) {
            axios.get(me.ZOEKSETPANORAMA_CONFIG_JSON_URL)
                .then(response => {
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }
}
