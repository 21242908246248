import {ApplicationPath} from "../../domain/path";

export class AbstractZoekresultaat {

    plaats //dit property wordt gebruikt door ApaConverter maar bestaat toch niet??
    socialData

    //_explicitType: string = "AbstractZoekresultaat"
    //localeContent: LocaleContent[]
    //reservatie: BidocReservatie
    private _annotatie
    private _bladzijden
    private _bron
    private _campus
    private _categorie
    private _coverLinkThumb: string
    private _datumAanmaak: string
    private _datumWijziging: string
    private _deeltitels: object[] | null
    private _didactischeSuggestie
    private _doi
    private _druk
    private _exemplaar
    private _fullTextLink: string[]
    private _fullTitel: string
    private _genre
    private _groep
    private _id
    private _inBestelling: boolean
    private _isbn
    private _isFull: any
    private _issn
    private _jaar
    private _jaargang
    private _jaargangnummer
    private _leverancier
    private _link
    private _licentieBeheerder: string
    private _locale: string
    private _localeContent: object[] | null
    private _localeContentLoaded: boolean = false
    private _mediatheek
    private _needLocaleContent: boolean = false
    private _opleiding
    private _periode
    private _publicatietype
    private _reeks
    private _siso
    private _sourceObject: any
    private _taal
    private _tijdschrift
    private _titel
    private _trefwoorden
    private _uitgelicht
    private _uitgever
    private _zoeklijsten: string[]
    protected _auteurs: string[]
    protected _beschrijving: string
    protected _coverLink: string = ''
    protected _metaString: string
    protected _opmerking: string



    /*
    * AUTEURS
    * */
    get auteurs(): string[] {
        if (!this._auteurs) return []
        return this._auteurs
    }

    set auteurs(value: string[]) {
        this._auteurs = value
    }

    /*
    * ANNOTATIE
    * */
    get annotatie() {
        return this._annotatie;
    }

    set annotatie(value) {
        this._annotatie = value;
    }

    /*
    * BESCHRIJVING
    * */
    get beschrijving(): string {
        return this._beschrijving
    }

    set beschrijving(value: string) {
        this._beschrijving = value
    }

    /*
    * BLADZIJDEN
    * */
    get bladzijden() {
        return this._bladzijden;
    }

    set bladzijden(value) {
        this._bladzijden = value;
    }

    /*
    * BRON
    * */
    get bron() {
        if (!this._bron || this._bron === '') return null
        return this._bron;
    }

    set bron(value) {
        this._bron = value;
    }

    /*
    * CAMPUS
    * */
    get campus() {
        return this._campus;
    }

    set campus(value) {
        this._campus = value;
    }

    /*
    * CATEGORIE
    * */
    get categorie() {
        return this._categorie;
    }

    set categorie(value) {
        this._categorie = value;
    }

    /*
    * COVERLINK
    * */
    get coverLink(): string {
        return this._coverLink
    }

    set coverLink(value: string) {
        this._coverLink = value
    }

    /*
    * COVERLINK SMALL
    * */
    get coverLinkSmall(): string {
        return this._coverLink
    }

    set coverLinkSmall(value: string) {
        this._coverLink = value
    }

    /*
    * COVERLINK THUMB
    * */
    get coverLinkThumb(): string {
        return this._coverLinkThumb;
    }

    set coverLinkThumb(value: string) {
        this._coverLinkThumb = value;
    }

    /*
    * DATUM AANMAAK
    * */
    get datumAanmaak(): string {
        return this._datumAanmaak;
    }

    set datumAanmaak(value: string) {
        this._datumAanmaak = value;
    }

    /*
    * DATUM WIJZIGING
    * */
    get datumWijziging(): string {
        return this._datumWijziging;
    }

    set datumWijziging(value: string) {
        this._datumWijziging = value;
    }

    /*
    * DEELTITELS
    * */
    get deeltitels(): object[] | null {
        return this._deeltitels;
    }

    set deeltitels(value: object[] | null) {
        this._deeltitels = value;
    }

    /*
    * DIDACTISCHE SUGGESTIE
    * */
    get didactischeSuggestie() {
        return this._didactischeSuggestie;
    }

    set didactischeSuggestie(value) {
        this._didactischeSuggestie = value;
    }

    /*
    * DIRECTE LINK
    * */
    get directeLink() {
        return `${ApplicationPath.linkWebportaal}/${this.bron}/${this.id}`
    }

    /*
    * DOI
    * */
    get doi() {
        return this._doi;
    }

    set doi(value) {
        this._doi = value;
    }

    /*
    * DOI LINK
    * */
    get doiLink(): string {
        return "_doi.org/" + this._doi
    }

    /*
    * DRUK
    * */
    get druk() {
        return this._druk;
    }

    set druk(value) {
        this._druk = value;
    }


    /*
    * EXEMPLAAR
    * */
    get exemplaar() {
        return this._exemplaar;
    }

    set exemplaar(value) {
        this._exemplaar = value;
    }

    /*
    * FULL_TEXT_LINK
    * */
    get fullTextLink(): string[] {
        return this._fullTextLink;
    }

    set fullTextLink(value: string[]) {
        this._fullTextLink = value;
    }

    /*
    * FULL TITEL
    * */
    get fullTitel(): string {
        //om titel en subtitel samen te nemen
        if (!this.titel) return ''
        if (this.subtitelArray.length === 0) return this.titel
        return [this.titel, ...this.subtitelArray].join(' : ')
    }

    /*
    * GENRE
    * */
    get genre() {
        if (!this._genre) return []
        return this._genre.split(',');
    }

    set genre(value) {
        this._genre = value;
    }

    /*
    * GROEP
    * */
    get groep() {
        return this._groep;
    }

    set groep(value) {
        this._groep = value;
    }

    /*
    * ID
    * */
    public get id() {
        return this._id;
    }

    public set id(value) {
        this._id = value;
    }

    /*
    * IN_BESTELLING
    * */
    get inBestelling(): boolean {
        return this._inBestelling;
    }

    set inBestelling(value: boolean) {
        this._inBestelling = value;
    }

    /*
    * ISBN
    * */
    get isbn() {
        return this._isbn;
    }

    set isbn(value) {
        this._isbn = value;
    }

    /*
    * ISFULL
    * */
    get isFull(): any {
        return this._isFull === "true" || this._isFull === true
    }

    set isFull(value: any) {
        this._isFull = value
    }

    /*
    * ISSN
    * */
    get issn() {
        return this._issn;
    }

    set issn(value) {
        this._issn = value;
    }

    /*
    * JAAR
    * */
    get jaar() {
        if (!this._jaar || this._jaar === '') return null
        return this._jaar;
    }

    set jaar(value) {
        this._jaar = value;
    }

    /*
    * JAARGANG
    * */
    get jaargang() {
        return this._jaargang;
    }

    set jaargang(value) {
        this._jaargang = value;
    }

    /*
    * JAARGANGNUMMER
    * */
    get jaargangnummer() {
        return this._jaargangnummer;
    }

    set jaargangnummer(value) {
        this._jaargangnummer = value;
    }

    /*
    * LEVERANCIER
    * */
    get leverancier() {
        return this._leverancier;
    }

    set leverancier(value) {
        this._leverancier = value;
    }

    /*
    * LINK
    * */
    get link() {
        return this._link;
    }

    set link(value) {
        this._link = value;
    }

    /*
    * LICENTIEBEHEERDER
    * */
    get licentieBeheerder() {
        if (this._licentieBeheerder) return this._licentieBeheerder
        if (this._sourceObject.licentie_beheerder) return this._sourceObject.licentie_beheerder
        return null
    }

    set licentieBeheerder(value) {
        this._licentieBeheerder = value;
    }

    /*
    * LOCALE
    * */
    get locale(): string {
        return this._locale
    }

    set locale(value: string) {
        this._locale = value
    }

    /*
    * LOCALE CONTENT
    * */
    get localeContent(): object[] | null {
        return this._localeContent
    }

    set localeContent(value: object[] | null) {
        this._localeContent = value
    }

    /*
    * LOCALE_CONTENT_LOADED
    * Zie ook needLocaleContent
    * */
    get localeContentLoaded(): any {
        return this._localeContentLoaded
    }

    set localeContentLoaded(value: any) {
        this._localeContentLoaded = value
    }

    /*
    * MEDIATHEEK
    * */
    get mediatheek() {
        return this._mediatheek;
    }

    set mediatheek(value) {
        this._mediatheek = value;
    }

    /*
    * META_STRING
    * */
    get metaString(): string {
        return this._metaString
    }

    set metaString(value: string) {
        this._metaString = value
    }

    /*
    * NEED_LOCALE_CONTENT
    * Deze flag bepaalt of sommige properties uit localeContent moeten gehaald worden
    * Zie ook localeContentLoaded
    * */
    get needLocaleContent(): any {
        return this._needLocaleContent
    }

    set needLocaleContent(value: any) {
        this._needLocaleContent = value
    }

    /*
    * OPLEIDING
    * */
    get opleiding() {
        return this._opleiding;
    }

    set opleiding(value) {
        this._opleiding = value;
    }

    get opleidingArray(): string[] {
        if (!this._opleiding) return []
        //console.log('OPLEIDING', this._opleiding)
        const oa = this._opleiding.split(',')
        return oa
    }

    set siso(value) {
        this._siso = value;
    }

    /*
    * OPMERKING
    * */
    get opmerking(): string {
        return this._opmerking
    }

    set opmerking(value: string) {
        this._opmerking = value
    }

    /*
    * PERIODE
    * */
    get periode() {
        if (this._sourceObject && this._sourceObject.periode) return this._sourceObject.periode
        return this._periode
    }

    set periode(value) {
        this._periode = value;
    }

    /*
    * PUBLICATIETYPE
    * */
    get publicatietype() {
        if (!this._publicatietype || this._publicatietype === '') return null
        return this._publicatietype;
    }

    set publicatietype(value) {
        this._publicatietype = value;
    }

    /*
    * REF_ID
    * Dit wordt gebruikt door localeContent services
    * Default refId === id
    * */
    get refId() {
        return this.id;
    }

    /*
    * REEKS
    * */
    get reeks() {
        return this._reeks;
    }

    set reeks(value) {
        this._reeks = value;
    }

    /*
    * SISO
    * */
    get siso() {
        return this._siso;
    }

    /*
    * SOURCE_OBJECT
    * */
    get sourceObject(): Object {
        return this._sourceObject;
    }

    set sourceObject(value: Object) {
        this._sourceObject = value;
    }

    /*
    * SUBTITEL
    * */
    get subtitelArray(): string[] {
        if (!this._titel) return []
        const sta = this._titel.split(' : ')
        sta.shift()
        return sta
    }

    /*
    * TAAL
    * */
    get taal() {
        return this._taal;
    }

    set taal(value) {
        this._taal = value;
    }

    /*
    * TIJDSCHRIFT
    * */
    get tijdschrift() {
        return this._tijdschrift;
    }

    set tijdschrift(value) {
        this._tijdschrift = value;
    }

    /*
    * TITEL
    * */
    get titel(): string {
        if (!this._titel) return 'GEEN TITEL'

        //_titel bevat de originele titel, dus met ' : '
        //Wanneer alle letters hoofdletters zijn
        if (this._titel === this._titel.toUpperCase())
            this._titel = this._titel.charAt(0).toUpperCase() + this._titel.slice(1).toLowerCase()

        //Eerste letter altijd hoofdletter
        this._titel = this._titel.substr(0, 1).toUpperCase() + this._titel.substr(1)

        //Subtitels zijn altijd gescheiden dmv ' : '

        return this._titel.split(' : ')[0]
    }

    set titel(value: string) {
        this._titel = value
    }

    /*
    * TREFWOORDEN
    * */
    get trefwoorden() {
        if (!this._trefwoorden) return []
        return this._trefwoorden;
    }

    set trefwoorden(value) {
        this._trefwoorden = value;
    }

    /*
    * TYPE
    * */
    get type(): string {
        return this.constructor.name
    }

    /*
    * UITGELICHT
    * */
    get uitgelicht() {
        return this._uitgelicht;
    }

    set uitgelicht(value) {
        this._uitgelicht = value;
    }

    /*
    * UITGEVER
    * */
    get uitgever() {
        return this._uitgever;
    }

    set uitgever(value) {
        this._uitgever = value;
    }

    /*
    * ZOEKLIJSTEN
    * */
    get zoeklijsten(): string[] {
        return this._zoeklijsten;
    }

    set zoeklijsten(value: string[]) {
        this._zoeklijsten = value;
    }

    /*
    * ZOEKMATCHSTRING
    * */
    get zoekmatchString(): string {
        return (this.titel + this.auteurs.join(' ') + this.trefwoorden.join(' ')).toLowerCase() + this.subtitelArray.join(' ').toLowerCase()
    }


    //hook method
    getProps() {
        return [""]
    }

}
