import {ArteveldeUser} from "../class/artevelde/ArteveldeUser"
import {convertObjectToClass} from "../util/ClassConvertor"
import {ApplicationPath} from "../domain/path"
import {Zoeklijst} from "../class/zoeklijst/Zoeklijst"
import {AbstractZoekresultaat} from "../class/zoekresultaat/AbstractZoekresultaat"
import {ZoeklijstQuery} from "../class/zoeklijst/ZoeklijstQuery"
import {BidocUitleenData} from "../class/bidoc/BidocUitleenData"
import {BidocReservatieData} from "../class/bidoc/BidocReservatieData"
import {BidocUitlening} from "../class/bidoc/BidocUitlening"
import {BidocVerlengData} from "../class/bidoc/BidocVerlengData"
import {BewaardeZoekopdracht} from "../class/zoek/BewaardeZoekopdracht";
import 'moment/locale/nl'
import moment from 'moment'

const _ = require('lodash')
const axios = require('axios')

export class MijnMediatheekService {

    private readonly ZOEKLIJSTEN_TABLE: string = 'zoeklijsten'
    private readonly BEWAARDE_ZOEKOPDRACHTEN_TABLE: string = 'bewaardeZoekopdrachten'
    private readonly AFHAALMANDJES_TABLE: string = 'afhaalMandje'

    initialized: boolean = false
    uitleenData: BidocUitleenData
    reservatieData: BidocReservatieData
    favorietenZoeklijst
    zoeklijsten
    zoeklijstLookup
    zoeklijstItems
    zoeklijstItemLookup = {}
    apiUrl: string
    apiKey: string
    phpsessid: string
    zoeklijstProvider: any[]
    bewaardeZoekopdrachten: BewaardeZoekopdracht[]
    bewaardeZoekopdrachtenProvider: any[]
    afhaalMandjes: any[]

    constructor(apiUrl: string, apiKey: string, phpsessid: string) {
        this.apiUrl = apiUrl
        this.apiKey = apiKey
        this.phpsessid = phpsessid
    }


    getMijnMediatheekData = (arteveldeUser: ArteveldeUser) => {

        let me = this

        return new Promise(function (resolve, reject) {
            let url = `${ApplicationPath.userApiUrl}?function=getMijnMediatheekData&userID=${arteveldeUser.barcode}`
            axios.get(url)
                .then(response => {

                    me.reservatieData = convertObjectToClass(response.data.reservatieData)
                    me.reservatieData.reservaties = me.reservatieData.reservaties.map(r => convertObjectToClass(r))
                    me.uitleenData = convertObjectToClass(response.data.uitleenData)
                    me.uitleenData.uitleningen = me.uitleenData.uitleningen.map(u => convertObjectToClass(u))
                    me.favorietenZoeklijst = convertObjectToClass(response.data.zoeklijsten.favorieten)
                    me.zoeklijsten = response.data.zoeklijsten.zoeklijsten.map(zoeklijst => convertObjectToClass(zoeklijst))
                    me.zoeklijstLookup = _.keyBy(me.zoeklijsten, 'id')
                    me.zoeklijstLookup[0] = me.favorietenZoeklijst
                    me.zoeklijstItems = response.data.zoeklijstItems.map(zoeklijstItem => convertObjectToClass(zoeklijstItem))
                    me.bewaardeZoekopdrachten = response.data.bewaardeZoekopdrachten.map(bewaardeZoekopdracht => convertObjectToClass(bewaardeZoekopdracht))


                    me.afhaalMandjes = response.data.afhaalMandjes

                    me.buildLijstProviders()

                    me.buildZoeklijstLookups()
                    resolve(response)
                })
                .catch((error) => {
                    console.log('MijnMediatheekService', error)
                    reject(error)
                })

        })
    }

    /*
    ====================================================================================
    ====================================================================================
    UITLENEN
    ====================================================================================
    ====================================================================================
    */

    verlengBidocUitlening = (bidocUitlening: BidocUitlening, arteveldeUser: ArteveldeUser) => {

        let me = this

        return new Promise(function (resolve, reject) {
            let url = `${ApplicationPath.userApiUrl}?function=verlengBidoc&userID=${arteveldeUser.barcode}&aanwinstnummers=${bidocUitlening.aanwinstnummer}`
            axios.get(url)
                .then(response => {
                    const bidocVerlengData: BidocVerlengData = convertObjectToClass(response.data)
                    bidocVerlengData.verlengingen = bidocVerlengData.verlengingen.map(bidocVerlenging => convertObjectToClass(bidocVerlenging))
                    //update
                    me.getMijnMediatheekData(arteveldeUser)
                    resolve(bidocVerlengData)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })

        })
    }

    verlengAlleBidocUitleningen = (bidocUitleenData: BidocUitleenData, arteveldeUser: ArteveldeUser) => {

        let me = this

        return new Promise(function (resolve, reject) {
            let url = `${ApplicationPath.userApiUrl}?function=verlengBidoc&userID=${bidocUitleenData.lenersnummer}&aanwinstnummers=${bidocUitleenData.verlengAlle}`
            axios.get(url)
                .then(response => {
                    const bidocVerlengData: BidocVerlengData = convertObjectToClass(response.data)
                    bidocVerlengData.verlengingen = bidocVerlengData.verlengingen.map(bidocVerlenging => convertObjectToClass(bidocVerlenging))
                    //update
                    me.getMijnMediatheekData(arteveldeUser)
                    resolve(bidocVerlengData)
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })

        })
    }

    /*
    ====================================================================================
    ====================================================================================
    RESERVEREN
    ====================================================================================
    ====================================================================================
    */


    /*
    ====================================================================================
    ====================================================================================
    ZOEKLIJST
    ====================================================================================
    ====================================================================================
    */

    buildLijstProviders = () => {
            const zoeklijstProvider = this.zoeklijsten.map((zoeklijst, index) => {
                return {key: index, value: zoeklijst.id, text: zoeklijst.label}
            })
            zoeklijstProvider.unshift({key: -1, value: 0, text: 'Favorieten'})
            this.zoeklijstProvider = zoeklijstProvider

            const bewaardeZoekopdrachtenProvider = this.bewaardeZoekopdrachten.map((bewaardeZoekopdracht, index) => {
                return {key: index, value: bewaardeZoekopdracht.id, text: bewaardeZoekopdracht.label}
            })
            this.bewaardeZoekopdrachtenProvider = bewaardeZoekopdrachtenProvider
    }

    buildZoeklijstLookups = () => {
        this.zoeklijstItemLookup = {}

        for (let zoeklijstItem of this.zoeklijstItems) {
            if (!this.zoeklijstItemLookup[zoeklijstItem.titelID]) this.zoeklijstItemLookup[zoeklijstItem.titelID] = []
            this.zoeklijstItemLookup[zoeklijstItem.titelID].push(zoeklijstItem)
        }
    }

    updateZoeklijst = (zoeklijst: Zoeklijst) => {

        let me = this

        let url = `${me.apiUrl}${me.ZOEKLIJSTEN_TABLE}/${zoeklijst.id}?&token=${me.apiKey}`

        console.log('UPDATE ZOEKLIJST')

        return new Promise((resolve, reject) => {
                axios.put(url, zoeklijst)
                    .then((_result) => {
                        this.buildLijstProviders()
                        resolve(me.zoeklijsten)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    createZoeklijst = (newListLabel: string, arteveldeUser: ArteveldeUser) => {

        let me = this

        return new Promise((resolve, reject) => {

                const query = new ZoeklijstQuery(this.phpsessid)
                query.function = ZoeklijstQuery.CREATE_ZOEKLIJST
                query.zoeklijstLabel = newListLabel
                query.arteveldeUser = arteveldeUser

                //console.log('QUERY', query)
                axios.post(ApplicationPath.userApiUrl, query)
                    .then(response => {
                        const zoeklijst = convertObjectToClass(response.data)
                        me.zoeklijsten.push(zoeklijst)
                        this.buildLijstProviders()
                        resolve(zoeklijst)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    maakZoeklijstLeeg = (zoeklijst: Zoeklijst, arteveldeUser: ArteveldeUser) => {

        let me = this

        return new Promise((resolve, reject) => {

                const query = new ZoeklijstQuery(this.phpsessid)
                query.function = ZoeklijstQuery.MAAK_ZOEKLIJST_LEEG
                query.zoeklijst = zoeklijst
                query.arteveldeUser = arteveldeUser

                //console.log('QUERY', query)
                axios.post(ApplicationPath.userApiUrl, query)
                    .then(response => {
                        zoeklijst.aantal = 0
                        me.zoeklijstItems = me.zoeklijstItems.filter(zli => zli.zoeklijstId !== zoeklijst.id)
                        me.buildZoeklijstLookups()
                        console.log('RESPONSE', response)
                        resolve(response)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    deleteZoeklijst = (zoeklijst: Zoeklijst, arteveldeUser: ArteveldeUser) => {

        let me = this

        return new Promise((resolve, reject) => {

                const query = new ZoeklijstQuery(this.phpsessid)
                query.function = ZoeklijstQuery.DELETE_ZOEKLIJST
                query.zoeklijst = zoeklijst
                query.arteveldeUser = arteveldeUser

                //console.log('QUERY', query)
                axios.post(ApplicationPath.userApiUrl, query)
                    .then(response => {
                        me.zoeklijsten = me.zoeklijsten.filter(zl => zl.id !== zoeklijst.id)
                        me.zoeklijstItems = me.zoeklijstItems.filter(zli => zli.zoeklijstID !== zoeklijst.id)
                        me.buildZoeklijstLookups()
                        this.buildLijstProviders()
                        console.log('RESPONSE', response)
                        resolve(response)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    getZoeklijstZoekresultaten = (zoeklijst: Zoeklijst, arteveldeUser: ArteveldeUser) => {

        return new Promise((resolve, reject) => {

                const query = new ZoeklijstQuery(this.phpsessid)
                query.function = ZoeklijstQuery.GET_ZOEKLIJST_ZOEKRESULTATEN
                query.zoeklijst = zoeklijst
                query.arteveldeUser = arteveldeUser

                console.log('QUERY', query)
                axios.post(ApplicationPath.userApiUrl, query)
                    .then(response => {
                        console.log('RESPONSE', response)
                        const zoekresultaatDataWrapper = convertObjectToClass(response.data)
                        zoekresultaatDataWrapper.zoekresultaten = zoekresultaatDataWrapper.zoekresultaten.map(zr => convertObjectToClass(zr))
                        resolve(zoekresultaatDataWrapper)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        )
    }

    zoeklijstBestaat = (zoeklijstLabel: string) => {
        return this.zoeklijsten.filter(zoeklijst => zoeklijst.label.toLowerCase() === zoeklijstLabel.toLowerCase()).length > 0
    }

    toggleZoekresultaatInZoeklijst = (zoekresultaat: AbstractZoekresultaat, zoeklijst: Zoeklijst, huidigeStatus: boolean, arteveldeUser: ArteveldeUser) => {

        let me = this


        return new Promise((resolve, reject) => {

                let items = this.zoeklijstItemLookup[zoekresultaat.id]
                if (!items) items = []

            console.log('DELETE ZLI', zoekresultaat,  this.zoeklijstItemLookup, items)
                const query = new ZoeklijstQuery(this.phpsessid)


                if (huidigeStatus === true) {
                    //huidige status === true dus wordt false dus verwijderen uit item
                    console.log('VERWIJDEREN', items)
                    //alles naar ints zetten
                    const zoeklijstItem = items.filter(zoeklijstItem => zoeklijstItem.titelID.toString() === zoekresultaat.id.toString())[0]
                    items = items.filter(zoeklijstItem => zoeklijstItem.zoeklijstID !== zoeklijst.id)

                    query.function = ZoeklijstQuery.DELETE_ZOEKLIJST_ITEM
                    query.zoeklijstItem = zoeklijstItem
                    query.arteveldeUser = arteveldeUser

                    console.log('QUERY', query)
                    axios.post(ApplicationPath.userApiUrl, query)
                        .then(response => {
                            console.log('RESPONSE', response)

                            me.zoeklijstItemLookup[zoekresultaat.id] = items
                            resolve(items)
                        })
                        .catch((error) => {
                            console.log(error)
                            reject(error)
                        })


                } else {

                    query.function = ZoeklijstQuery.CREATE_ZOEKLIJST_ITEM
                    query.zoeklijst = zoeklijst
                    query.zoekresultaatId = zoekresultaat.id
                    query.bron = zoekresultaat.bron
                    query.arteveldeUser = arteveldeUser

                    console.log('QUERY', query, items)

                    axios.post(ApplicationPath.userApiUrl, query)
                        .then(response => {
                            const zoeklijstItem = convertObjectToClass(response.data)
                            console.log('RESPONSE', response)
                            items.push(zoeklijstItem)

                            me.zoeklijstItemLookup[zoekresultaat.id] = items
                            resolve(items)
                        })
                        .catch((error) => {
                            console.log(error)
                            reject(error)
                        })
                }
            }
        )


    }




    /*
    ====================================================================================
    ====================================================================================
    BEWAARDE ZOEKOPDRACHTEN
    ====================================================================================
    ====================================================================================
    */


    updateBewaardeZoekopdracht = (bewaardeZoekopdracht: BewaardeZoekopdracht) => {

        let me = this

        let url = `${me.apiUrl}${me.BEWAARDE_ZOEKOPDRACHTEN_TABLE}/${bewaardeZoekopdracht.id}?&token=${me.apiKey}`
        console.log('URL', url)

        return new Promise((resolve, reject) => {
                axios.put(url, bewaardeZoekopdracht)
                    .then((_result) => {
                        //remap the faq
                        bewaardeZoekopdracht = convertObjectToClass(bewaardeZoekopdracht, 'BewaardeZoekopdracht')
                        me.bewaardeZoekopdrachten = me.bewaardeZoekopdrachten.map(t => {
                            if (t.id === bewaardeZoekopdracht.id)
                                return bewaardeZoekopdracht;
                            return t;
                        })
                        resolve({bewaardeZoekopdrachten: me.bewaardeZoekopdrachten})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    createBewaardeZoekopdracht = (bewaardeZoekopdracht: BewaardeZoekopdracht) => {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.BEWAARDE_ZOEKOPDRACHTEN_TABLE}/?token=${me.apiKey}`
                axios.post(url, bewaardeZoekopdracht)
                    .then(response => {
                        bewaardeZoekopdracht.id = response.data;
                        me.bewaardeZoekopdrachten = _.concat(me.bewaardeZoekopdrachten, bewaardeZoekopdracht)
                        me.bewaardeZoekopdrachten = _.orderBy(me.bewaardeZoekopdrachten, [bzo => bzo.label.toLowerCase()])
                        me.buildLijstProviders()
                        resolve({bewaardeZoekopdrachten: me.bewaardeZoekopdrachten, bewaardeZoekopdracht})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    deleteBewaardeZoekopdracht = (bewaardeZoekopdracht: BewaardeZoekopdracht, arteveldeUser: ArteveldeUser) => {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.BEWAARDE_ZOEKOPDRACHTEN_TABLE}/${bewaardeZoekopdracht.id}/?token=${me.apiKey}`
                axios.delete(url, bewaardeZoekopdracht)
                    .then(response => {
                        bewaardeZoekopdracht.id = response.data;
                        me.bewaardeZoekopdrachten = me.bewaardeZoekopdrachten.filter(bzo => bzo.id !== bewaardeZoekopdracht.id)
                        me.buildLijstProviders()
                        resolve({bewaardeZoekopdrachten: me.bewaardeZoekopdrachten})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    /*
    ====================================================================================
    ====================================================================================
    AFHAALMANDJES
    ====================================================================================
    ====================================================================================
    */

    /*getAllAfhaalmandjes = () => {
        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.AFHAALMANDJES_TABLE}/?transform=1&token=${me.apiKey}&filter[]=status,neq,opgehaald&filter[]=status,neq,nietOpgehaald`
                axios.get(url)
                    .then(response => {
                        //const alleMandjes = response.data[me.AFHAALMANDJES_TABLE]
                        resolve(response.data[me.AFHAALMANDJES_TABLE])
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }*/

    getTijdslots = (mediatheekCampusCode) => {
        return new Promise((resolve, reject) => {

            const postObject = {} as any
            postObject.function = 'getTijdslots'
            postObject.mediatheek = mediatheekCampusCode

            console.group()
            console.info('CALL getTijdslots', postObject)
            axios.post(ApplicationPath.collectAndGoApiUrl, postObject)
                .then(response => {
                    console.info('SERVER RESPONSE', response)

                    if (response.data.error){
                        console.warn(response.data.error)
                    }

                    resolve(response.data)
                    console.groupEnd()
                })
                .catch((error) => {
                    console.error('ERROR', error)
                    reject(error)
                });
        })
    }

    wijzigAfhaalmandje = (afhaalmandje) => {
        return new Promise((resolve, reject) => {

            const postObject = {} as any
            postObject.function = 'wijzigAfhaalMandje'
            postObject.afhaalMandje = afhaalmandje

            console.group()
            console.info('CALL wijzigAfhaalMandje', postObject)
            axios.post(ApplicationPath.collectAndGoApiUrl, postObject)
                .then(response => {
                    console.info('SERVER RESPONSE', response)

                    if (response.data.error){
                        console.warn(response.data.error)
                    }
                    this.afhaalMandjes = this.afhaalMandjes.map(am => ({...am}))
                    resolve(response.data)
                    console.groupEnd()
                })
                .catch((error) => {
                    console.error('ERROR', error)
                    reject(error)
                });
        })
    }

    voegToeAanAfhaalMandje = (zoekresultaat, exemplaar, arteveldeUser) => {
        return new Promise((resolve, reject) => {

            const props = ['id', 'exemplaar', 'titel', 'coverLink', 'coverLinkThumb', 'coverLinkSmall', 'subtitelArray', 'auteurs', 'publicatietype', 'jaar', 'bron']

            const zoekresultaatDto = {}
            for (const prop of props) {
                zoekresultaatDto[prop] = zoekresultaat[prop]
            }

            zoekresultaatDto['plaatskenmerk'] = exemplaar.plaatskenmerk

            const postObject = {} as any
            postObject.function = 'voegToeAanAfhaalMandje'
            postObject.bidocId = zoekresultaat.id
            postObject.awnr = exemplaar.awnr
            postObject.bidocZoekresultaat = zoekresultaatDto
            postObject.arteveldeUser = arteveldeUser

            console.group()
            console.info('TEST voegToeAanAfhaalMandje', postObject)
            axios.post(ApplicationPath.collectAndGoApiUrl, postObject)
                .then(response => {
                    console.info('SERVER RESPONSE', response)

                    if (response.data.error){
                        console.warn(response.data.error)
                    }

                    if (response.data.afhaalMandjes) this.afhaalMandjes = response.data.afhaalMandjes

                    resolve(response.data)
                    console.groupEnd()
                })
                .catch((error) => {
                    console.error('ERROR', error)
                    reject(error)
                });
        })
    }

    callCollectService = (postObject) => {
        return new Promise((resolve, reject) => {


            console.group()
            console.info('CALL COLLECT SERVICE', postObject)
            axios.post(ApplicationPath.collectAndGoApiUrl, postObject)
                .then(response => {
                    console.info('SERVER RESPONSE', response, response.data['0'])

                    if (response.data.error){
                        console.warn(response.data.error)
                    }

                    if (response.data.afhaalMandjes) this.afhaalMandjes = response.data.afhaalMandjes

                    resolve(response.data)
                    console.groupEnd()
                })
                .catch((error) => {
                    console.error('ERROR', error)
                    reject(error)
                });
        })
    }

    getAfhaalMandjesVoorMediatheek = (mediatheekCampusCode: string) => {
        return new Promise((resolve, reject) => {

            const postObject = {} as any
            postObject.function = 'getAfhaalMandjesVoorMediatheek'
            postObject.mediatheek = mediatheekCampusCode

            console.group()
            console.info('CALL getAfhaalMandjesVoorMediatheek', postObject)
            axios.post(ApplicationPath.collectAndGoApiUrl, postObject)
                .then(response => {
                    console.info('SERVER RESPONSE', response)

                    if (response.data.error){
                        console.warn(response.data.error)
                    }

                    resolve(response.data)
                    console.groupEnd()
                })
                .catch((error) => {
                    console.error('ERROR', error)
                    reject(error)
                });
        })
    }

    wijzigAfhaalMandjeItemStatus = (afhaalMandjeItem, status) => {
        return new Promise((resolve, reject) => {

            const postObject = {} as any
            postObject.function = 'wijzigAfhaalMandjeItemStatus'
            postObject.tabelId = afhaalMandjeItem.tabelId
            postObject.status = status

            console.log('AFM', afhaalMandjeItem)
            console.group()
            console.info('CALL wijzigAfhaalMandjeItemStatus', postObject)
            axios.post(ApplicationPath.collectAndGoApiUrl, postObject)
                .then(response => {
                    console.info('SERVER RESPONSE', response)

                    if (response.data.error){
                        console.warn(response.data.error)
                    }

                    resolve(response.data)
                    console.groupEnd()
                })
                .catch((error) => {
                    console.error('ERROR', error)
                    reject(error)
                });
        })
    }

    wijzigAfhaalMandjeStatus = (afhaalMandje, status) => {
        return new Promise((resolve, reject) => {

            const postObject = {} as any
            postObject.function = 'wijzigAfhaalMandjeStatus'
            postObject.mandjeId = afhaalMandje.id
            //postObject.mandjeUuid = afhaalMandje.uuid
            postObject.status = status

            console.group()
            console.info('CALL wijzigAfhaalMandjeStatus', postObject)
            axios.post(ApplicationPath.collectAndGoApiUrl, postObject)
                .then(response => {
                    console.info('SERVER RESPONSE', response)

                    if (response.data.error){
                        console.warn(response.data.error)
                    }

                    resolve(response.data)
                    console.groupEnd()
                })
                .catch((error) => {
                    console.error('ERROR', error)
                    reject(error)
                });
        })
    }

    deleteAfhaalmandjeItem = (afhaalmandjeItem) => {
        return new Promise((resolve, reject) => {

            //Op de server wordt automatisch het afhaalmandje verwijdert als dit het laatste item is!

            const postObject = {} as any
            postObject.function = 'verwijderTitelUitAfhaalmandje'
            postObject.mandjeId = afhaalmandjeItem.mandjeId
            //TODO tableId >> tabelId
            postObject.tabelId = afhaalmandjeItem.tabelId

            console.group()
            console.info('CALL verwijderTitelUitAfhaalmandje', postObject)
            axios.post(ApplicationPath.collectAndGoApiUrl, postObject)
                .then(response => {
                    console.info('SERVER RESPONSE', response.data)

                    if (response.data.afhaalMandjes){
                        this.afhaalMandjes = response.data.afhaalMandjes
                    }

                    resolve(response.data)
                    console.groupEnd()
                })
                .catch((error) => {
                    console.error('ERROR', error)
                    reject(error)
                });
        })
    }

    printLabels = (afhaalmandjes, mediatheek, mediatheekService) => {

        return new Promise((resolve, reject) => {
            const serviceUrl = ApplicationPath.boServerCollectService

            //https://stackoverflow.com/questions/41457181/axios-posting-params-not-read-by-post

            const postObject = {} as any

            postObject.function = 'genereerAfhaalmandjeLabelsPdf'
            postObject.mediatheek = mediatheek
            postObject.afhaalmandjes = afhaalmandjes.map(afhaalmandje => {
                //Teksten hier voorbereiden want ik heb geen mediatheek object op de server
                const ro = {...afhaalmandje}
                const mediatheek = mediatheekService.mediatheekCampusCodeLookup[afhaalmandje.mediatheek]
                ro.mediathaakHeader = 'Afhaalmandje Mediatheek ' + mediatheek.naam
                ro.titels = afhaalmandje.afhaalMandjeItems.map(item => {
                    const airo = {...item, zoekresultaat:  JSON.parse(item.zoekresultaat)}
                    return airo
                    })

                ro.datum = moment(afhaalmandje.slotStart).format('DD/MM/YYYY')
                ro.van = moment(afhaalmandje.slotStart).format('HH:mmu')
                ro.tot = moment(afhaalmandje.slotStart).add(30, 'minutes').format('HH:mmu')

                return ro
            })
            postObject.showQR = true

            console.log('POST OBJECT', postObject)

            //https://github.com/axios/axios/issues/113
            axios.post(serviceUrl, postObject).then(response => {
                resolve(response)
            })

        })



    }

    returnAfhaalMandjeItem = (aanwinstnummer) => {
        return new Promise((resolve, reject) => {

            const postObject = {} as any

            postObject.function = 'returnAfhaalMandjeItem'
            postObject.awnr = aanwinstnummer

            console.log('POST OBJECT', postObject)

            //https://github.com/axios/axios/issues/113
            axios.post(ApplicationPath.collectAndGoApiUrl, postObject).then(response => {
                resolve(response)
            })

        })

    }
}
