import React, {Component} from 'react'
import {Button, Segment} from 'semantic-ui-react'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'

const {CopyToClipboard} = require('react-copy-to-clipboard')

class HandleidingUrlRenderer extends Component {

    state = {linkCopied: false}

    render() {
        const {linkCopied} = this.state
        const {zoekresultaat, localeManager, isKiosk} = this.props

        if (isKiosk) return null
        if (!zoekresultaat.handleidingUrl) return null

        return (
            <AbstractZoekresultaatRenderer label={localeManager.getString('HANDLEIDING')}>
                <Segment style={{backgroundColor: '#e1fcff', display: 'flex', alignItems: 'center'}}>
                    <a href={zoekresultaat.handleidingUrl} target='_blank' rel='noopener noreferrer'>{zoekresultaat.handleidingUrl}</a>
                    <div style={{flexGrow: 1}}/>
                    <CopyToClipboard text={zoekresultaat.handleidingUrl}
                                     onCopy={() => this.setState({linkCopied: true})}>
                        <Button circular color={linkCopied ? 'green' : 'grey'} icon={linkCopied ? 'check' : 'clipboard'}/>
                    </CopyToClipboard>
                </Segment>
            </AbstractZoekresultaatRenderer>
        )
    }
}

export default HandleidingUrlRenderer