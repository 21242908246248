import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {Header, Image, Label} from "semantic-ui-react";
import {ApplicationPath} from 'med-class'

const ChatListItem = ({chat, chatlines, selected, onClick}) => {

    //const [webChatlines, setWebChatlines] = useState()
    //const [boChatlines, setBoChatlines] = useState()
    const [numWebLines, setNumWebLines] = useState(0)
    const [numBoLines, setNumBoLines] = useState(0)
    const [webLabelVisible, setWebLabelVisible] = useState(true)
    const [boLabelVisible, setBoLabelVisible] = useState(true)
    //const [labelsVisible, setLabelsVisible] = useState()

    useEffect(() => {
        const webLines = chatlines.filter(chatline => chatline.data().chat === chat.id && chatline.data().bron === 'web')
        const boLines = chatlines.filter(chatline => chatline.data().chat === chat.id && chatline.data().bron === 'bo')

        if (webLines.length !== numWebLines){
            setNumWebLines(webLines.length)
            setWebLabelVisible(false)
            setTimeout(() => setWebLabelVisible(true), 5)
        }
        if (boLines.length !== numBoLines){
            setNumBoLines(boLines.length)
            setBoLabelVisible(false)
            setTimeout(() => setBoLabelVisible(true), 5)
        }

    }, [chatlines])
    
    if (!chat.data().timestamp) return null

    return (
        <div className={selected ? 'chat-list-item selected' : 'chat-list-item'} onClick={onClick}>

            <Image avatar src={ApplicationPath.avatarApi + chat.data().eigenaar}/>

            <div style={{display: 'flex', flexDirection: 'column', marginLeft: '10px', flexGrow: 1, wordBreak: 'break-word'}}>
                <Header as='h4'>{chat.data().eigenaar}</Header>
                {moment(chat.data().timestamp.seconds*1000).format('DD-MM-YYYY HH:mm')}
            </div>

            <Fragment>
                {webLabelVisible && <Label className='new-chatline' circular>{numWebLines}</Label>}
                {boLabelVisible && <Label className='new-chatline' circular color='teal'>{numBoLines}</Label>}
            </Fragment>

        </div>
    )
}

export default  ChatListItem

ChatListItem.propTypes = {
    chat: PropTypes.object.isRequired,
    chatlines: PropTypes.array,
    selected: PropTypes.bool,
    onClick: PropTypes.func
}

ChatListItem.defaultProps = {

}