import React, {Component} from 'react'
import {Form, Segment} from 'semantic-ui-react'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'
const Parser = require('html-react-parser')

class HoofdtitelRenderer extends Component{


    render(){
        const { zoekresultaat, localeManager, label } = this.props

        if (!zoekresultaat.hoofdtitel) return null

        const link = zoekresultaat.directeLink

        return (
            <AbstractZoekresultaatRenderer label={localeManager.getString(label)}>

                <Segment>
                    <div style={{marginBottom: '20px'}}>{localeManager.getString('DEELTITEL_INFO')}</div>
                    <Form>
                        <Form.Field inline>
                            <a style={{fontSize: '1.5em'}} href={link}>{Parser(zoekresultaat.hoofdtitel.titel)}</a>
                        </Form.Field>
                    </Form>
                </Segment>

            </AbstractZoekresultaatRenderer>
        )
    }
}

export default  HoofdtitelRenderer