import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Button, Segment, Icon, Image, Divider, Header, Card, Modal} from 'semantic-ui-react'

import {PublicatieType} from 'med-class'
import {MediatheekMap} from "web-component-mediatheek-map";

import {RendererFactory} from "./detailRenderers/RendererFactory";
import SocialContent from "./SocialContent"
import {BidocExemplaarToRubriekConverter} from "../../class/BidocPlaatkenmerkToRubriekConverter";

import Parser, {domToReact} from 'html-react-parser'
import ExemplaarRenderer from "./detailRenderers/ExemplaarRenderer";
import EbscolinkRenderer from "./detailRenderers/EbscolinkRenderer";
import MediatheekRenderer from "./detailRenderers/MediatheekRenderer";

const ZoekresultaatDetail = ({zoekresultaat, config, zoekService, spelwijzerService, socialService, rubriceringService, taxonomieService, mijnMediatheekService, mediatheekService, onExitDetail, onNavigate, localeManager, prevButtonEnabled, nextButtonEnabled, onCoverClick, arteveldeUser, epubLocaleDataService, directLink, onTrefwoordClick, onReserveerClick, onCampus}) => {

    const [localZoekresultaat, setLocalZoekresultaat] = useState()
    const [zoekresultaatResolved, setZoekresultaatResolved] = useState(false)
    const [showImage, setShowImage] = useState(true)
    const [mediatheekMapOpen, setMediatheekMapOpen] = useState(false)
    const [mediatheekMapSelectedRubriek, setMediatheekMapSelectedRubriek] = useState()
    const [mediatheekMapMediatheek, setMediatheekMapMediatheek] = useState()
    const [size] = useState('medium')
    const [isKiosk, setIsKiosk] = useState(false)
    const [beschrijving, setBeschrijving] = useState('')
    const [werkvormen, setWerkvormen] = useState()
    const [localeDataFetched, setLocaleDataFetched] = useState(false)

    //RESOLVING
    useEffect(() => {
        if (!zoekresultaat) return

        if (zoekresultaat.publicatietype === PublicatieType.SPELMATERIAAL) {

            spelwijzerService.init().then(res => {

                setZoekresultaatResolved(true)
                const spel = spelwijzerService.zoekresultatenBaseDataLookup[zoekresultaat.id]
                //wanneer een spel niet behandeld is in de backoffice bestaat 'spel' niet!!

                if (!spel){
                    setLocalZoekresultaat(zoekresultaat)
                    setWerkvormen(null)
                } else {
                    spelwijzerService.getSpelwijzerWerkvormen(spel.bidocKoppeling).then(werkvormen => {

                        setWerkvormen(werkvormen)

                        if (!spel.isFull) {
                            zoekService.getZoekresultaatDetail(spel).then(res => {
                                setLocalZoekresultaat(spel)
                            })
                        } else {
                            setLocalZoekresultaat(spel)
                        }
                    })
                }

            })
        } else {
            setLocalZoekresultaat(zoekresultaat)
            setZoekresultaatResolved(true)
            setWerkvormen(null)
        }

    }, [zoekresultaat, spelwijzerService, zoekService])

    //LOCALE DATA
    useEffect(() => {
        if (!localZoekresultaat) return

        localZoekresultaat.locale = localeManager.locale
        if (localZoekresultaat.needLocaleContent && !localZoekresultaat.localeContentLoaded) {
            epubLocaleDataService.getLocaleData(localZoekresultaat.id).then(res => {
                localZoekresultaat.localeContentLoaded = true
                localZoekresultaat.localeContent = res
                setLocaleDataFetched(true)
            })
        }
    }, [localZoekresultaat, epubLocaleDataService, localeManager.locale])

    //BESCHRIJVING
    useEffect(() => {
        if (!localZoekresultaat) return


        const getLocaleContentFieldValue = (field) => {
            //localeContent zit nu standaard in het localZoekresultaat


            if (epubLocaleDataService && localZoekresultaat.needLocaleContent && localZoekresultaat.localeContentLoaded) {
                const content = epubLocaleDataService.getPropertyValue(localZoekresultaat.id, field, localeManager.locale)
                return content
            }
            return localZoekresultaat[field]
        }

        const beschrijving = localZoekresultaat.bron === 'epub' ?
            convertNewLines(getLocaleContentFieldValue('beschrijving'))
            :
            convertNewLines(localZoekresultaat.beschrijving)

        setBeschrijving(beschrijving)
    }, [localZoekresultaat, epubLocaleDataService, localeManager.locale, localeDataFetched])

    //KIOSK
    useEffect(() => {
        if (!directLink) return
        setIsKiosk(directLink.isKiosk())
    }, [directLink])

    //SPELWIJZER WERKVORMEN
    useEffect(() => {

    }, [localZoekresultaat])


    const onCoverClickHandler = (event) => {
        event.stopPropagation()
        event.preventDefault()
        onCoverClick(localZoekresultaat)
    }

    const convertNewLines = (source) => {
        if (!source) return ''
        return source.replace(/\n/gm, '<br />');
    }

    const onImageError = (event) => {
        if (showImage) setShowImage(false)
    }

    const onWaarVindIkDitClick = (exemplaar) => {
        const zitInArchief = exemplaar.plaats.indexOf('archief') > -1

        if (zitInArchief) {
            window.alert(localeManager.getString('TITEL_ZIT_IN_ARCHIEF'))
            return
        }

        const rubriekLabel = BidocExemplaarToRubriekConverter.convert(exemplaar)

        const rubriek = rubriceringService.rubriekenRubriekLookup[rubriekLabel]
        const mediatheek = mediatheekService.getMediatheekFromString(exemplaar.plaats)


        if (rubriek && mediatheek) {
            setMediatheekMapOpen(true)
            setMediatheekMapSelectedRubriek(rubriek)
            setMediatheekMapMediatheek(mediatheek.campusCode)
        }
    }

    const onModalClose = () => {
        setMediatheekMapOpen(false)
    }

    if (!zoekresultaatResolved) return null

    if (!localZoekresultaat) return (
        <Header inverted size='large'>{localeManager.getString('TITEL_NIET_BESCHIKBAAR')}</Header>
    )

    return (
        <Fragment>
            <Segment>
                <div style={{display: 'flex'}}>
                    <Button onClick={onExitDetail} icon><Icon name='left arrow'/> {localeManager.getString('TERUG')}</Button>
                    <div style={{flexGrow: 1}}/>
                    <Button disabled={!prevButtonEnabled} icon name='prev' onClick={onNavigate}><Icon name='arrow alternate circle left outline'/> {localeManager.getString('VORIGE')}</Button>
                    <Button disabled={!nextButtonEnabled} icon name='next' onClick={onNavigate}>{localeManager.getString('VOLGENDE')} <Icon name='arrow alternate circle right outline'/></Button>
                </div>
                <Divider/>

                <Segment textAlign='center' inverted style={{backgroundColor: 'teal'}}>
                    <Header inverted size='large'>{Parser(localZoekresultaat.titel)}</Header>

                    {localZoekresultaat.subtitelArray && localZoekresultaat.subtitelArray.map((subtitel, index) => {
                        return <div key={index} className='subtitel'>{Parser(subtitel)}</div>
                    })}
                </Segment>


                <div style={{display: 'flex'}}>
                    {(localZoekresultaat.coverLink && showImage) &&
                    <Image style={{cursor: 'pointer', alignSelf: 'flex-start', marginRight: '15px'}} bordered src={localZoekresultaat.coverLink} size={size}
                           onClick={onCoverClickHandler} onError={onImageError}/>
                    }

                    {beschrijving &&
                    <div>{Parser(beschrijving, {replace: ({attribs, children}) => isKiosk && attribs && attribs.href && <strong>{domToReact(children)}</strong>})}</div>
                    }
                </div>

                {!isKiosk && <Card fluid>
                    <Card.Content extra>
                        <SocialContent zoekresultaat={localZoekresultaat}
                                       socialService={socialService}
                                       mijnMediatheekService={mijnMediatheekService}
                                       arteveldeUser={arteveldeUser}
                                       localeManager={localeManager}
                                       socialIconsDirection='row'/>
                    </Card.Content>
                </Card>}

                <Divider/>

                <ExemplaarRenderer label='exemplaar'
                                   zoekresultaat={localZoekresultaat}
                                   localeManager={localeManager}
                                   arteveldeUser={arteveldeUser}
                                   config={config}
                                   onWaarVindIkDitClick={onWaarVindIkDitClick}
                                   onReserveerClick={onReserveerClick}/>

                {localZoekresultaat.mediatheek &&
                <MediatheekRenderer label='mediatheek' zoekresultaat={localZoekresultaat} localeManager={localeManager}/>
                }

                <EbscolinkRenderer label='ebscolink' zoekresultaat={localZoekresultaat} localeManager={localeManager} isKiosk={isKiosk}/>

                {localZoekresultaat.getProps().map((property, index) => {

                    return RendererFactory.create(property, {
                        key: index,
                        localeManager,
                        zoekresultaat: localZoekresultaat,
                        werkvormen,
                        label: property,
                        taxonomieService,
                        zoekService,
                        arteveldeUser,
                        onWaarVindIkDitClick,
                        onReserveerClick,
                        onTrefwoordClick,
                        epubLocaleDataService,
                        isKiosk,
                        onCampus
                    })
                })

                }
            </Segment>

            {mediatheekMapSelectedRubriek &&
            <Modal size='large' dimmer='inverted' open={mediatheekMapOpen} onClose={onModalClose} closeIcon>

                <Modal.Header>
                    {mediatheekMapSelectedRubriek.rubriek + ': ' + mediatheekMapSelectedRubriek.label}
                </Modal.Header>
                <Modal.Content>
                    <MediatheekMap rubriceringService={rubriceringService}
                                   localeManager={localeManager}
                                   mediatheek={mediatheekMapMediatheek}
                                   selectedRubriek={mediatheekMapSelectedRubriek}
                                   showLegende={false}/>
                </Modal.Content>

            </Modal>
            }
        </Fragment>
    )
}

export default ZoekresultaatDetail

ZoekresultaatDetail.propTypes = {
    zoekresultaat: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    zoekService: PropTypes.object.isRequired,
    spelwijzerService: PropTypes.object.isRequired,
    socialService: PropTypes.object.isRequired,
    rubriceringService: PropTypes.object.isRequired,
    taxonomieService: PropTypes.object.isRequired,
    mijnMediatheekService: PropTypes.object.isRequired,
    mediatheekService: PropTypes.object.isRequired,
    onExitDetail: PropTypes.func,
    onNavigate: PropTypes.func,
    localeManager: PropTypes.object.isRequired,
    prevButtonEnabled: PropTypes.bool,
    nextButtonEnabled: PropTypes.bool,
    onCoverClick: PropTypes.func,
    arteveldeUser: PropTypes.object,
    epubLocaleDataService: PropTypes.object,
    localeDataService: PropTypes.object,
    directLink: PropTypes.object,
    onTrefwoordClick: PropTypes.func,
    onReserveerClick: PropTypes.func,
    onCampus: PropTypes.bool
}

ZoekresultaatDetail.defaultProps = {
    prevButtonEnabled: true,
    nextButtonEnabled: true,
}
