//import {BidocExemplaarToRubriekConverter} from './BidocExemplaarToRubriekConverter'

export class BidocExemplaarToRubriekConverter {

    static convert(bidocExemplaar){

        const wa = bidocExemplaar.plaatskenmerk.split(" ");

        //cursussen >> CURS
        if (wa.indexOf("CURS") > -1)
            return "Cursussen";

        //case auteursstring = 4 hoofdletters
        //Wanneer er enkel een auteurstring is, zonder prefix, dan gaat het om een Nederlandstalige Roman
        //Dus bv. BADE >> Romans B
        if (wa.length === 1 && wa[0].length === 4 && wa[0] === wa[0].toUpperCase()){
            return "Romans " + wa[0].substr(0, 1);
        }

        //Jeugd
        //Eerste woord is J en daarna auteursstring
        //bv. J DRAG >> J D
        if (wa.length === 2 && wa[0] === "J" && BidocExemplaarToRubriekConverter.stringIsFourCapitalizedLetters(wa[1]))
            return "J " + wa[1].substr(0,1);

        //Jeugd 12+
        //Eerste woord is J, tweede 12+ en daarna auteursstring
        //bv. J 12+ FOLL >> J 12+ F
        if (wa.length === 3 && wa[0] === "J" && wa[1] === "12+" && BidocExemplaarToRubriekConverter.stringIsFourCapitalizedLetters(wa[2]))
            return "J 12+ " + wa[2].substr(0,1);

        //spel
        if (wa[0].toLowerCase() === "spel")
            return "SPEL";

        //cd
        if (wa[0].toLowerCase() === "cd")
            return "CD";

        //dvd
        if (wa[0].toLowerCase() === "dvd")
            return "DVD";

        //sommige 'gewone' rubrieken hebben nog een suffix bv. 306.7 DIVE
        if (!isNaN(wa[0]))
            return wa[0]

        return bidocExemplaar.plaatskenmerk;
    }

    static stringIsFourCapitalizedLetters(str) {
        return (str.length === 4 && str === str.toUpperCase());
    }
}