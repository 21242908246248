export class Cookie {

    static readonly AHS_MED_RESULTAAT_WEERGAVE: string = "ahs-med-resultaat-weergave"
    static readonly LOGINDATA_BARCODE: string = "loginData_barcode"
    static readonly LOGINDATA_EMAIL_ADDRESS: string = "loginData_emailAddress"
    static readonly LOGINDATA_GIVENNAME: string = "loginData_givenName"
    static readonly LOGINDATA_SURNAME: string = "loginData_surname"
    static readonly LOGINDATA_UPN: string = "loginData_upn"
    static readonly LOGINDATA_ADMIN: string = "loginData_admin"
    static readonly LOGINDATA_PERSONEEL: string = "loginData_personeel"
    static readonly PHPSESSID: string = "PHPSESSID"
    static readonly LOGINDATA_KAART: string = "loginData_kaart"
}
