import {FeedbackStatus} from "./FeedbackStatus";

export class Feedback {

    id: number
    datum: string
    auteurMail: string = ''
    behandelaar: number
    feedback: string = ''
    context: string
    toestemmingPubliceren: number = 1
    publiceren: number
    status: string = FeedbackStatus.NIEUW
    githubIssueId: number
    faqId: number
}
