import {ApplicationPath} from "../domain/path";
import axios from 'axios'
import {convertObjectToClass} from "../util/ClassConvertor";
import {Social} from "../class/social/Social";
//const axios = require('axios')
const _ = require('lodash')


export class SocialService {

    private readonly SOCIAL_TABLE: string = 'social'

    initialized: boolean = false
    socials: Array<Social>
    socialLookup: object //key = bron/ref, bv. bron=bidoc, ref=12345
    apiUrl: string
    apiKey: string

    constructor(apiUrl: string, apiKey: string) {
        this.apiUrl = apiUrl
        this.apiKey = apiKey
        this.socials = []
        this.socialLookup = {}
    }

    getSocialContent = (searchResult: any) => {

        let me = this

        return new Promise((resolve, reject) => {
            const bron = searchResult.bron, ref = searchResult.id

            if (!this.socialLookup[bron]) this.socialLookup[bron] = {}
            if (this.socialLookup[bron][ref]) {
                resolve(this.socialLookup[bron][ref])
            }

            const url = `${me.apiUrl}${me.SOCIAL_TABLE}?transform=1&token=${me.apiKey}&filter[]=bron,eq,${bron}&filter[]=ref,eq,${ref}`
            axios.get(url)
                .then(response => {
                    me.socialLookup[bron][ref] = response.data[me.SOCIAL_TABLE]
                    resolve(me.socialLookup[bron][ref])
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        })
    }

    updateSocial = (social) => {

        let me = this

        let url = `${me.apiUrl}${me.SOCIAL_TABLE}/${social.id}?&token=${me.apiKey}`
        //console.log('URL', url)

        return new Promise((resolve, reject) => {
                axios.put(url, social)
                    .then((result) => {
                        //remap the social
                        //console.log('SAVED', result, social)
                        social = convertObjectToClass(social, 'Social');
                        me.socials = me.socials.map(t => {
                            if (t.id === social.id)
                                return social;
                            return t;
                        })
                        me.socialLookup = _.keyBy(me.socials, social => social.id)
                        resolve({socials: me.socials, socialLookup: me.socialLookup})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    createSocial = (social) => {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.SOCIAL_TABLE}/?token=${me.apiKey}`
                axios.post(url, social)
                    .then(response => {
                        social.id = response.data;

                        //redactie
                        const redactieUrl = ApplicationPath.socialApiUrl + "newComment/" + social.id
                        axios.get(redactieUrl).then(() => {
                        }).catch(error => console.log(error))

                        me.socialLookup[social.bron][social.ref].push(social)
                        resolve(me.socialLookup[social.bron][social.ref])
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    deleteSocial = (social) => {

        let me = this

        return new Promise((resolve, reject) => {
                const url = `${me.apiUrl}${me.SOCIAL_TABLE}/${social.id}?token=${me.apiKey}`

                axios.delete(url)
                    .then(() => {
                        //const socials = me.socialLookup[social.bron][social.ref]
                        _.remove(me.socialLookup[social.bron][social.ref], s => s.id === social.id)
                        resolve(me.socialLookup[social.bron][social.ref])
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    deleteCommentaar = (social) => {

        let me = this

        return new Promise((resolve, reject) => {
                const url = `${me.apiUrl}${me.SOCIAL_TABLE}/${social.id}?token=${me.apiKey}`

                axios.delete(url)
                    .then(() => {
                        resolve('ok')
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    getNietReviewedCommentaar = () => {

        let me = this

        return new Promise((resolve, reject) => {

            const url = `${me.apiUrl}${me.SOCIAL_TABLE}?transform=1&token=${me.apiKey}&
            filter[]=context,eq,commentaar&filter[]=status,eq,nieuw`
            axios.get(url)
                .then(response => {
                    const socials = response.data[this.SOCIAL_TABLE].map(sc => convertObjectToClass(sc, 'Social'))
                    resolve(convertObjectToClass(socials))
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        })
    }
}
