import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Form, Segment, Header, Checkbox, Menu, Rating, Table, Select, Label} from 'semantic-ui-react'
import {DatePickerInput, SaveBar, ColorPicker} from '@ahsmediatheek/ui-component'
import {NieuwsTextPosition, NieuwsType, getSwatchValues} from "@ahsmediatheek/core";
import NieuwsDetailImage from "./NIeuwsDetailImage"
import {LocaleContentViewer} from '@ahsmediatheek/med-component-locale-content'
import NieuwsViewer from "./viewer/NieuwsViewer";

const NieuwsDetail = ({userService, taxonomieService, nieuwsService, selectedNieuws, localeContent, onDetailFormUpdate, imageUploadUrl}) => {

    const [nieuws, setNieuws] = useState()
    const [isDirty, setIsDirty] = useState(false)
    //const [selectedCampus, setSelectedCampus] = useState()
    const [changedLocales, setChangedLocales] = useState([])
    const [activeMenuItem, setActiveMenuItem] = useState('titelcaption')
    const [style, setStyle] = useState()

    const [aanmaakString, setAanmaakString] = useState('')
    const [wijzigingString, setWijzigingString] = useState('')
    const [zichtbaarVanMoment, setZichtbaarVan] = useState()
    const [zichtbaarTotMoment, setZichtbaarTot] = useState()
    //const [contentMatches, setContentMatches] = useState(false)

    useEffect(() => {
        if (!selectedNieuws) return
        setIsDirty(false)
        setNieuws({...selectedNieuws})
        //setContentMatches(matchContent())

        if (!selectedNieuws.style || selectedNieuws.style === ''){
            setStyle(defaultStyle())
        } else {
            const style = JSON.parse(selectedNieuws.style)
            if (style.titlePadding === undefined) style.titlePadding = 10
            if (style.captionPadding === undefined) style.captionPadding = 10
            setStyle(style)
        }

    }, [selectedNieuws, localeContent])

    useEffect(() => {
        if (!nieuws) return
        let aanmaakUser, wijzigingUser, aanmaakString, wijzigingString

        if (nieuws.user_aanmaak > 0) aanmaakUser = userService.userIdLookup[nieuws.user_aanmaak]
        if (nieuws.user_wijziging > 0) wijzigingUser = userService.userIdLookup[nieuws.user_wijziging]
        aanmaakString = nieuws.date + (aanmaakUser ? ` door ${aanmaakUser.voornaam} ${aanmaakUser.familienaam}` : '')
        wijzigingString = nieuws.datum_wijziging + (wijzigingUser ? ` door ${wijzigingUser.voornaam} ${wijzigingUser.familienaam}` : '')
        setAanmaakString(aanmaakString)
        setWijzigingString(wijzigingString)

        setZichtbaarVan(nieuws.fromDate)
        setZichtbaarTot(nieuws.untilDate)

    }, [nieuws, userService.userIdLookup])


    /*const matchContent = () => {
        if (!localeContent || !nieuws) return false
        if (localeContent.length === 0) return false
        return nieuws.id === localeContent[0].refId
    }*/

    const handleMenuItemClick = (e, {name}) => setActiveMenuItem(name)


    const onEditStart = () => {
        if (!isDirty) {
            setIsDirty(true)
        }
    }

    /*const onUserChange = ({controlField, valueString}) => {
        onEditStart()
        const nws = {...nieuws, [controlField]: valueString}
        setNieuws(nws)
    }*/

    const onChange = (event, {name, value}) => {
        onEditStart()
        const nws = {...nieuws, [name]: value}
        setNieuws(nws)
    }

    const onLocaleContentChange = (localeContentObject, data, plainText) => {

        //parameters moeten nu correct zijn. LocaleContentViewer moet correct werken

        localeContentObject.plainText = plainText
        localeContentObject.content = data
        onEditStart()
        if (changedLocales.indexOf(localeContentObject.id) < 0)
            changedLocales.push(localeContentObject.id)
    }

    /*const onChangeGeldigVan = (date) => {
        onEditStart()
        const nws = {...nieuws, fromDate: date.format('YYYY-MM-DD')}
        setNieuws(nws)
    }*/

    /*const onChangeGeldigTot = (date) => {
        onEditStart()
        const nws = {...nieuws, untilDate: date.format('YYYY-MM-DD')}
        setNieuws(nws)
    }*/

    const onCancel = () => {
        setIsDirty(false)
        setNieuws({...selectedNieuws})
    }

    const onSubmit = (...args) => {
        setIsDirty(false)
        nieuws.style = JSON.stringify(style)
        let cLocales = localeContent.filter(item => changedLocales.indexOf(item.id) > -1)
        onDetailFormUpdate(nieuws, cLocales)
        setChangedLocales([])

    }

    const onStyleChange = (event, {name, value}) => {
        onEditStart()
        const stl = {...style, [name]: value}
        setStyle(stl)
    }
    const onChangeZichtbaarVan = (dateString) => {
        onEditStart()
        const nws = {...nieuws, fromDate: dateString}
        setNieuws(nws)
    }

    const onChangeZichtbaarTot = (dateString) => {
        onEditStart()
        const nws = {...nieuws, untilDate: dateString}
        setNieuws(nws)
    }

    const changeColor = ({name, hex, color}) => {
        onEditStart()
        const newHex = hex + Math.round(color.rgb.a * 255).toString(16)
        const stl = {...style, [name]: newHex}
        setStyle(stl)
    }

    const changeStyleCheckbox = (event, {name, checked}) => {
        onEditStart()
        const stl = {...style, [name]: checked ? 1 : 0}
        setStyle(stl)
    }

    const changeTextPosition = (event, {name, value}) => {
        onEditStart()
        const stl = {...style, [name]: value}
        setStyle(stl)
    }

    const onNieuwsTypeChange = (event, {name, value}) => {
        nieuws.type = value
        onSubmit()
        setNieuws(nieuws)
    }

    const onPriorityChange = (event, {rating}) => {
        nieuws.priority = rating
        onSubmit()
        setNieuws(nieuws)
    }

    const onOnlineCheckBoxChange = (event, {name, checked}) => {
        nieuws.online = checked ? 1 : 0
        onSubmit()
        setNieuws(nieuws)
    }

    const defaultStyle = () => {

        return {
            backgroundColor: "#f0b407ff",

            showTitle: 1,
            titleColor: 'white',
            showTitleDropShadow: 1,
            showTitleBackground: 1,
            titleBackgroundColor: "#00000099",
            titlePosition: NieuwsTextPosition.TOP_LEFT,
            justifyTitle: 0,
            titlePadding: 20,

            showCaption: 1,
            captionColor: 'white',
            showCaptionDropShadow: 1,
            showCaptionBackground: 1,
            captionBackgroundColor: "#00000099",
            captionPosition: NieuwsTextPosition.BOTTOM_RIGHT,
            justifyCaption: 0,
            captionPadding: 20
        }
    }



    if (!nieuws) return null

    return (
        <div className='detail-group'>
            <Header className='titleHeader' size='medium'>{nieuws.title}</Header>
                <Label>{`Aangemaakt op ${aanmaakString} | Laatst gewijzigd op ${wijzigingString}`}</Label>

            <Header as='h3'>Voorvertoning</Header>


            <Segment style={{width: '400px', minHeight: '240px', overflow: 'hidden'}}>
                <NieuwsViewer nieuws={nieuws} style={style} localeContent={localeContent} locale={'nl'} onDuration={(...props) => console.log('DUR', props)}/>
            </Segment>

            <Menu pointing secondary>
                <Menu.Item name='titelcaption' active={activeMenuItem === 'titelcaption'}
                           onClick={handleMenuItemClick}>Titel en caption</Menu.Item>
                <Menu.Item name='instellingen' active={activeMenuItem === 'instellingen'}
                           onClick={handleMenuItemClick}>Instellingen</Menu.Item>
                <Menu.Item name='layout' active={activeMenuItem === 'layout'}
                           onClick={handleMenuItemClick}>Layout</Menu.Item>
            </Menu>

            {activeMenuItem === 'titelcaption' &&
            <div className='detail-overflow segment'>
                <Form style={{width: '100%'}} size='tiny' onSubmit={onSubmit}>
                    <Form.Field>
                        <label>Titel</label>
                        {localeContent &&
                        <LocaleContentViewer placeholder='Titel'
                                             localeContent={localeContent}
                                             referenceId={nieuws.id}
                                             dataField='title'
                                             onChange={onLocaleContentChange}/>}
                    </Form.Field>

                    <Form.Field>
                        <label>Caption</label>
                        {localeContent &&
                        <LocaleContentViewer placeholder='Caption'
                                             localeContent={localeContent}
                                             referenceId={nieuws.id}
                                             dataField='caption'
                                             onChange={onLocaleContentChange}/>}
                    </Form.Field>

                </Form>
            </div>
            }

            {activeMenuItem === 'instellingen' &&
            <div className='detail-overflow segment'>
                <Form style={{width: '100%'}} size='tiny' onSubmit={onSubmit}>
                    <Form.Field>
                        <label>Type</label>
                        <Select name='type' options={NieuwsType.SEMANTIC_DATAPROVIDER} value={nieuws.type} onChange={onNieuwsTypeChange}/>
                    </Form.Field>

                    <Form.Group inline>
                        <label>Online</label>
                        <Checkbox size='tiny'
                                  checked={nieuws.online === 1}
                                  name='online'
                                  label='Dit item is online'
                                  onChange={onOnlineCheckBoxChange}/>
                    </Form.Group>

                     <Form.Field>
                            <label>Prioriteit</label>
                            <Rating name='priority' maxRating={3} rating={nieuws.priority}
                                    onRate={onPriorityChange}/>
                        </Form.Field>

                    <Form.Field>
                        <label>Zichtbaar</label>
                        <div className='breathe'>
                            <span>Van:&nbsp;&nbsp;</span>
                            <DatePickerInput dateString={zichtbaarVanMoment}
                                             onChange={onChangeZichtbaarVan}/>
                            <span>&nbsp;&nbsp;Tot:&nbsp;&nbsp;</span>
                            <DatePickerInput dateString={zichtbaarTotMoment}
                                             onChange={onChangeZichtbaarTot}/>
                        </div>
                    </Form.Field>

                    {(nieuws.type === NieuwsType.IMAGE) &&
                    <Segment>
                        <Form.Field>
                            <label>Afbeelding</label>
                            <NieuwsDetailImage nieuws={nieuws}
                                               nieuwsService={nieuwsService}
                                               imageUploadUrl={imageUploadUrl} update={onDetailFormUpdate}/>
                        </Form.Field>
                    </Segment>
                    }

                    {nieuws.type === NieuwsType.VIDEO &&
                    <Segment>
                        <Form.Field>
                            <Form.Input label='Video link' name='link' value={nieuws.link} onChange={onChange}/>
                        </Form.Field>
                    </Segment>
                    }

                </Form>
            </div>}

            {activeMenuItem === 'layout' &&
            <div className='detail-overflow segment'>

                <Form size='tiny' onSubmit={onSubmit}>

                    <Form.Field>
                        <label>Achtergrondkleur</label>
                        <ColorPicker name='backgroundColor' colorChange={changeColor} color={style.backgroundColor}/>
                    </Form.Field>

                    <Form.Field>

                        <Table celled padded>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell singleLine width={3}></Table.HeaderCell>
                                    <Table.HeaderCell width={5}>Titel</Table.HeaderCell>
                                    <Table.HeaderCell width={5}>Caption</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell><Header as='h4'>Toon</Header></Table.Cell>
                                    <Table.Cell>
                                        <Checkbox name='showTitle' checked={style.showTitle === 1} onChange={changeStyleCheckbox}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox name='showCaption' checked={style.showCaption === 1} onChange={changeStyleCheckbox}/>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><Header as='h4'>Tekstpositie</Header></Table.Cell>
                                    <Table.Cell>
                                        <Select name='titlePosition' options={NieuwsTextPosition.SEMANTIC_DATAPROVIDER} value={style.titlePosition} onChange={changeTextPosition}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Select name='captionPosition' options={NieuwsTextPosition.SEMANTIC_DATAPROVIDER} value={style.captionPosition} onChange={changeTextPosition}/>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><Header as='h4'>Uitvullen</Header></Table.Cell>
                                    <Table.Cell>
                                        <Checkbox name='justifyTitle' checked={style.justifyTitle === 1} onChange={changeStyleCheckbox}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox name='justifyCaption' checked={style.justifyCaption === 1} onChange={changeStyleCheckbox}/>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><Header as='h4'>Tekstkleur</Header></Table.Cell>
                                    <Table.Cell>
                                        <ColorPicker name='titleColor' colorChange={changeColor} color={style.titleColor} colors={getSwatchValues()}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <ColorPicker name='captionColor' colorChange={changeColor} color={style.captionColor} colors={getSwatchValues()}/>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><Header as='h4'>Slagschaduw</Header></Table.Cell>
                                    <Table.Cell>
                                        <Checkbox name='showTitleDropShadow' checked={style.showTitleDropShadow === 1} onChange={changeStyleCheckbox}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox name='showCaptionDropShadow' checked={style.showCaptionDropShadow === 1} onChange={changeStyleCheckbox}/>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><Header as='h4'>Toon tekst achtergrond</Header></Table.Cell>
                                    <Table.Cell>
                                        <Checkbox name='showTitleBackground' checked={style.showTitleBackground === 1} onChange={changeStyleCheckbox}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Checkbox name='showCaptionBackground' checked={style.showCaptionBackground === 1} onChange={changeStyleCheckbox}/>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><Header as='h4'>Kleur tekst achtergrond</Header></Table.Cell>
                                    <Table.Cell>
                                        <ColorPicker name='titleBackgroundColor' colorChange={changeColor} color={style.titleBackgroundColor} colors={getSwatchValues()}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <ColorPicker name='captionBackgroundColor' colorChange={changeColor} color={style.captionBackgroundColor} colors={getSwatchValues()}/>
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell><Header as='h4'>Padding</Header></Table.Cell>
                                    <Table.Cell>
                                        <input type="number" name="titlePadding" min={0} value={style.titlePadding} onChange={e => onStyleChange(e, {name: e.target.name, value: parseInt(e.target.value)})}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <input type="number" name="captionPadding" min={0} value={style.captionPadding} onChange={e => onStyleChange(e, {name: e.target.name, value: parseInt(e.target.value)})}/>

                                    </Table.Cell>
                                </Table.Row>

                            </Table.Body>
                        </Table>
                    </Form.Field>
                </Form>
            </div>
            }

            {isDirty &&
            <SaveBar onCancelClick={onCancel} onSaveClick={onSubmit}/>
            }

        </div>
    )
}

export default NieuwsDetail

NieuwsDetail.propTypes = {
    userService: PropTypes.object.isRequired,
    taxonomieService: PropTypes.object.isRequired,
    nieuwsService: PropTypes.object.isRequired,
    selectedNieuws: PropTypes.object.isRequired,
    localeContent: PropTypes.array.isRequired,
    onDetailFormUpdate: PropTypes.func.isRequired,
    imageUploadUrl: PropTypes.string.isRequired
}

NieuwsDetail.defaultProps = {}
