export class Quote {
	
	_explicitType:string = 'Quote'
	
	quoteId:number = 0
	auteur:string = ''
	bron:string = ''
	committer:string = ''
	hits:number = 0
	quote:string = ''
	
	get id(){
	  return this.quoteId
  }
}
