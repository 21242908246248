import React, {useState, useEffect, useCallback} from 'react'
import PropTypes from 'prop-types'
import {Table, Button, Icon} from 'semantic-ui-react'
import moment from 'moment'
import 'moment/locale/nl'

const Parser = require('html-react-parser')

const MediatheekDetailOpeningsuren = ({mediatheekService, localeManager, config, mediatheek}) => {

    const [openingsuren, setOpeningsuren] = useState()
    const [momentStart] = useState(moment())
    const [headerString, setHeaderString] = useState(localeManager.getString('DEZE_WEEK'))
    //const [weekStart, setWeekStart] = useState()
    //const [weekEinde, setWeekEinde] = useState()

    const getOpeningsuren = useCallback((mediatheek, momentStart) => {
        mediatheekService.getOpeningsurenWeek(mediatheek.campusCode, momentStart.format("YYYY-MM-DD")).then(({openingsuren, weekStart, weekEinde}) => {

            moment.locale(localeManager.locale)
            const now = moment()
            const headerString = weekStart < now && weekEinde > now ? localeManager.getString('DEZE_WEEK') :
                `${weekStart.format('DD-MM-YYYY')} ${localeManager.getString('TOT')} ${weekEinde.format('DD-MM-YYYY')}`
            setOpeningsuren(openingsuren)
            //setWeekStart(weekStart)
            //setWeekEinde(weekEinde)
            setHeaderString(headerString)
        })
    }, [localeManager, mediatheekService])

    useEffect(() => {
        moment.locale('nl')
    }, [])

    useEffect(() => {
        getOpeningsuren(mediatheek, momentStart)
    }, [mediatheek, momentStart, getOpeningsuren])



    const onWeekShift = (offset) => {
        momentStart.add(offset, 'week')
        getOpeningsuren(mediatheek, momentStart)
    }

    if (!openingsuren) return null


    return (
        <div>
            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan='2' textAlign='center'>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Button icon onClick={() => onWeekShift(-1)} color='orange'>
                                    <Icon name='arrow circle left' size='large'/>
                                </Button>
                                <h2>{headerString}</h2>
                                <Button icon onClick={() => onWeekShift(1)} color='orange'>
                                    <Icon name='arrow circle right' size='large'/>
                                </Button>
                            </div>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                    {Object.keys(openingsuren).map(key => {
                        const op = openingsuren[key]

                        let opString = localeManager.getString(op.join(' / '))

                        //let mediatheekClass = ''

                        switch (config.mediatheken.openingsurenStatus) {
                            case "custom":
                                //mediatheekClass = 'mediatheek-custom'
                                opString = config.mediatheken.openingsurenStatusOptions.custom.label
                                break
                            case "gesloten":
                                //mediatheekClass = 'mediatheek-gesloten'
                                opString = 'Gesloten'
                                break
                            default: //live
                                //mediatheekClass = 'mediatheek-open'
                                break
                        }


                        return <Table.Row key={key}>

                            <Table.Cell width={5}>
                                {moment(key).format("dddd DD-MM")}
                            </Table.Cell>
                            <Table.Cell singleLine>{Parser(localeManager.getString(opString))}</Table.Cell>
                        </Table.Row>
                    })

                    }
                </Table.Body>

            </Table>
        </div>
    )
}

export default MediatheekDetailOpeningsuren

MediatheekDetailOpeningsuren.propTypes = {
    mediatheek: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    mediatheekService: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
}

MediatheekDetailOpeningsuren.defaultProps = {}