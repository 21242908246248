import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Card, Icon, Form} from "semantic-ui-react"
import {DirectLink} from 'med-class'
import '../styles.css'
const Parser = require('html-react-parser')
const {CopyToClipboard} = require('react-copy-to-clipboard')

export default class BewaardeZoekopdrachtListItem extends Component {

    constructor(props) {
        super(props)
        this.state = {
            edit: false,
            bewaardeZoekopdrachtLabel: props.bewaardeZoekopdracht.label,
            labelSaveButtonVisible: false,
            linkCopied: false
        }
    }

    componentDidMount() {

    }

    onWijzigButtonClick = () => {
        const {edit} = this.state
        const {bewaardeZoekopdracht} = this.props
        this.setState({bewaardeZoekopdrachtLabel: bewaardeZoekopdracht.label, edit: !edit})
    }

    onGereedButtonClick = () => {
        const {bewaardeZoekopdrachtLabel} = this.state
        const {bewaardeZoekopdracht, onBewaardeZoekopdrachtEditEnd} = this.props
        this.setState({labelSaveButtonVisible: false})
        bewaardeZoekopdracht.label = bewaardeZoekopdrachtLabel
        onBewaardeZoekopdrachtEditEnd(bewaardeZoekopdracht)
    }

    onVerwijderBewaardeZoekopdrachtButtonClick = () => {
        const {localeManager, onVerwijderBewaardeZoekopdracht, bewaardeZoekopdracht} = this.props
        const warning = window.confirm(localeManager.getString('VERWIJDER_BEWAARDE_ZOEKOPDRACHT_WARNING'))
        if (warning) {
            onVerwijderBewaardeZoekopdracht(bewaardeZoekopdracht)
            this.setState({labelSaveButtonVisible: false, edit: false})
        }
    }

    onLabelChange = (event, {value}) => {
        this.setState({bewaardeZoekopdrachtLabel: value, labelSaveButtonVisible: true})
    }

    render() {
        const {edit, bewaardeZoekopdrachtLabel, labelSaveButtonVisible, linkCopied} = this.state
        const {bewaardeZoekopdracht, localeManager, onToonResultaten} = this.props
        const directLink = DirectLink.buildDirectLinkString('bewaardezoekopdracht', bewaardeZoekopdracht.id)

        return (
            <Card link={false} style={{marginBottom: '5px', border: 'none'}} fluid>
                <Card.Content style={{display: 'flex', alignItems: 'center'}}>

                    {(!edit) && <Card.Header as='a' onClick={() => onToonResultaten(bewaardeZoekopdracht)}>{Parser(bewaardeZoekopdracht.label)}</Card.Header>}
                    {(edit) && <Form.Input style={{marginRight: '5px'}} value={bewaardeZoekopdrachtLabel} onChange={this.onLabelChange}/>}

                    {labelSaveButtonVisible && <Button size='tiny' icon color='green' onClick={this.onGereedButtonClick}>
                        <Icon name="check"/>
                    </Button>}

                    <div style={{flexGrow: 1}}/>

                    <Button size='tiny' icon onClick={this.onWijzigButtonClick}>
                        <Icon name="cog"/>
                    </Button>

                </Card.Content>

                {edit &&
                <Card.Content>
                    <Form>
                        <Form.Field inline>
                            <label>{localeManager.getString('DIRECTE_LINK')}:</label>
                            <a href={directLink} target='_blank' rel='noopener noreferrer' style={{marginRight: '15px'}}>{directLink}</a>
                            <CopyToClipboard text={directLink}
                                             onCopy={() => this.setState({linkCopied: true})}>
                                <Button circular size='mini' color={linkCopied ? 'green' : 'grey'} icon={linkCopied ? 'check' : 'clipboard'}/>
                            </CopyToClipboard>
                        </Form.Field>
                        <Form.Group inline>
                            <Button onClick={this.onVerwijderBewaardeZoekopdrachtButtonClick} color='red'>{localeManager.getString('VERWIJDER_BEWAARDE_ZOEKOPDRACHT')}</Button>
                        </Form.Group>
                    </Form>
                </Card.Content>
                }
            </Card>
        )
    }
}

BewaardeZoekopdrachtListItem.propTypes = {
    bewaardeZoekopdracht: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    onToonResultaten: PropTypes.func.isRequired,
    onVerwijderBewaardeZoekopdracht: PropTypes.func,
    onBewaardeZoekopdrachtEditEnd: PropTypes.func
}

BewaardeZoekopdrachtListItem.defaultProps = {
}