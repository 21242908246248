import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Input, Divider, Button, Icon} from 'semantic-ui-react'
import {Zoekmode} from 'med-class'
import GeavanceerdZoeken from "./GeavanceerdZoeken";

class Zoekbalk extends Component {

    inputRef = React.createRef()

    componentDidUpdate() {
        if (this.inputRef.current) this.inputRef.current.focus()
    }

    bewaardeZoekopdrachtDropdownChange = (event, {value}) => {
        this.setState({bewaardeZoekopdrachtId: value})
    }

    laadBewaardeZoekopdrachtButtonClick = () => {
        const {bewaardeZoekopdrachtId} = this.state
        const {bewaardeZoekopdrachtDropdownChange} = this.props
        this.setState({bewaardeZoekopdrachtId: null})
        bewaardeZoekopdrachtDropdownChange(bewaardeZoekopdrachtId)
    }


    render() {
        const {
            searchMode, searchModeChange,
            onBasicSearchInputChange, onBasicSearchInputKeyDown, onAdvancedSearchInputKeyDown, queryData,
            onQueryDataChange, localeManager,
            onResetSearch, resetSearchEnabled
        } = this.props

        return (
            <div>

                <div style={{display: 'flex'}}>
                    <Button color={searchMode === Zoekmode.NORMAAL ? 'green' : null}
                    onClick={() => searchModeChange(Zoekmode.NORMAAL)}>
                        <Icon name='search' />
                        {localeManager.getString('ZOEKEN_EENVOUDIG')}
                    </Button>
                    <Button color={searchMode === Zoekmode.GEAVANCEERD ? 'green' : null}
                            onClick={() => searchModeChange(Zoekmode.GEAVANCEERD)}>
                        <Icon.Group>
                            <Icon name='search' />
                            <Icon corner='top right' name='add' />
                        </Icon.Group>
                        {localeManager.getString('ZOEKEN_GEAVANCEERD')}
                    </Button>
                    <Button color={searchMode === Zoekmode.SPELWIJZER ? 'green' : null}
                            onClick={() => searchModeChange(Zoekmode.SPELWIJZER)}>
                        <Icon name='game' />
                        {localeManager.getString('SPELWIJZER')}
                    </Button>
                    <Button color={searchMode === Zoekmode.DATABANKEN ? 'green' : null}
                            onClick={() => searchModeChange(Zoekmode.DATABANKEN)}>
                        <Icon name='database' />
                        {localeManager.getString('DATABANKEN')}
                    </Button>

                    <div style={{flexGrow: 1}}/>

                    {resetSearchEnabled && <Button content={localeManager.getString('ZOEKOPDRACHT_AFBREKEN')} onClick={onResetSearch} color='grey'/>}
                </div>

                <Divider/>

                {searchMode === Zoekmode.NORMAAL &&
                <Input style={{fontSize: '1.5em', flexGrow: 1, marginRight: '5px'}} ref={this.inputRef} fluid focus icon='search' placeholder={localeManager.getString('WAT_WIL_JE_ZOEKEN')}
                       value={queryData.basicSearchString}
                       onChange={onBasicSearchInputChange} onKeyDown={onBasicSearchInputKeyDown}/>
                }

                {searchMode === Zoekmode.GEAVANCEERD &&
                <GeavanceerdZoeken localeManager={localeManager} queryData={queryData}
                                   onQueryDataChange={onQueryDataChange}
                                   onAdvancedSearchInputKeyDown={onAdvancedSearchInputKeyDown}/>
                }

            </div>
        )
    }
}

Zoekbalk.propTypes = {
    mijnMediatheekService: PropTypes.object,
    localeManager: PropTypes.object.isRequired,
    arteveldeUser: PropTypes.object.isRequired,
    searchMode: PropTypes.string.isRequired,
    searchModeChange: PropTypes.func,
    queryData: PropTypes.object.isRequired,
    onQueryDataChange: PropTypes.func.isRequired,
    onBasicSearchInputChange: PropTypes.func,
    onBasicSearchInputKeyDown: PropTypes.func,
    onAdvancedSearchInputKeyDown: PropTypes.func,
    zoeksets: PropTypes.array,
    zoekset: PropTypes.object,
    zoeklijsten: PropTypes.array,
    setZoekset: PropTypes.func,
    resetSearchEnabled: PropTypes.bool,
    onResetSearch: PropTypes.func,
}

Zoekbalk.defaultProps = {
    resetSearchEnabled: false
}

export default Zoekbalk
