/*
	©2018 Dany Dhondt
*/

export class SignalisatiestrookLayout {
	
	static PLAATS_KENMERK: string = "plaatsKenmerk"
	static WIT_EEN_REGEL: string = "witEenRegel"
	static WIT_TWEE_REGELS: string = "witTweeRegels"
	static BLAUW_EEN_REGEL: string = "blauwEenRegel"
	static BLAUW_TWEE_REGELS: string = "blauwTweeRegels"
	
	static VALUES: string[] = [
		SignalisatiestrookLayout.PLAATS_KENMERK,
		SignalisatiestrookLayout.WIT_EEN_REGEL,
		SignalisatiestrookLayout.WIT_TWEE_REGELS,
		SignalisatiestrookLayout.BLAUW_EEN_REGEL,
		SignalisatiestrookLayout.BLAUW_TWEE_REGELS
	]
	
	static DATAPROVIDER: object[] = SignalisatiestrookLayout.VALUES.map((value, index) => {
		return {key: index, value: value, text: SignalisatiestrookLayout.getSignalisatiestrookLayoutLabel(value)}
	})
	
	protected static getSignalisatiestrookLayoutLabel(label:string): string {
		
		switch (label){
			case SignalisatiestrookLayout.BLAUW_EEN_REGEL:
				return "Blauw met één regel"
			case SignalisatiestrookLayout.BLAUW_TWEE_REGELS:
				return "Blauw met twee regels"
			case SignalisatiestrookLayout.PLAATS_KENMERK:
				return "Plaatskenmerk"
			case SignalisatiestrookLayout.WIT_EEN_REGEL:
				return "Wit met één regel"
			case SignalisatiestrookLayout.WIT_TWEE_REGELS:
				return "Wit met twee regels"
			
			default:
				return "geen geldige layout";
		}
	}
}