
const moment = require('moment')

export class Openingsuren {

    campus: string
    campusCode: string
    datum: string
    open: string
    close: string
    type: string

    _openMoment
    get openMoment(){
        if (!this._openMoment) this._openMoment = moment(this.datum + ' ' + this.open)
        return this._openMoment
    }

    _closeMoment
    get closeMoment(){
        if (!this._closeMoment) this._closeMoment = moment(this.datum + ' ' + this.close)
        return this._closeMoment
    }

    get mediatheekIsOpen(){
        const now = moment()
        return now > this.openMoment && now < this.closeMoment
    }

    toString = () => {
        if (this.type !== 'normal') return 'GESLOTEN'
        return this.openMoment.format('HH:mm') + '-' + this.closeMoment.format('HH:mm')
    }
}
