import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Modal, Button, Divider, Header} from 'semantic-ui-react'
import TijdsslotRenderer from "./TijdsslotRenderer";
import {ZoekresultaatListItem} from "web-component-zoek";
import 'moment/locale/nl'
import moment from 'moment'
import Spacer from "./Spacer";

const KiesTijdsslotModal = ({localeManager, serverResponseObject, mediatheekService, mijnMediatheekService, arteveldeUser, onKiesTijdsslot}) => {

    const [tijdsslots, setTijdsslots] = useState()
    const [mediatheek, setMediatheek] = useState()
    const [modalOpen] = useState(true)
    const [slotButtonString, setSlotButtonString] = useState('')
    const [selectedSlot, setSelectedSlot] = useState()
    //const [tijdsslot, setTijdsslot] = useState()

    useEffect(() => {


        setMediatheek(mediatheekService.mediatheekCampusCodeLookup[serverResponseObject.callback.mediatheek])
        setTijdsslots(serverResponseObject.timeslots[serverResponseObject.callback.mediatheek])
        console.log('MED', serverResponseObject)

    }, [serverResponseObject, mediatheekService.mediatheekCampusCodeLookup])

   /* const buildTijdsslots = (serverResponseObject) => {

    }*/

    const handleClose = () => {
        onKiesTijdsslot(null)
    }

    const onSlotSelect = (slot) => {
        setSelectedSlot(slot)
        const van = moment(slot.van).locale(localeManager.locale)
        const tot = moment(slot.tot).locale(localeManager.locale)
        //mandje.tijdsslot = slot
        setSlotButtonString(`${localeManager.getString('AFHAALMANDJE')} ${van.format('ddd DD MMM YYYY')} ${localeManager.getString('TUSSEN')} ${van.format('HH:mm')} ${localeManager.getString('EN')} ${tot.format('HH:mm')}`)

    }

    if (!tijdsslots || !mediatheek) return null

    return (
        <Modal
            open={modalOpen}
            onClose={handleClose}
            dimmer='inverted'

        >
            <Header icon='shopping basket' content={`${localeManager.getString('AFHAALMANDJE_MAKEN_VOOR_MEDIATHEEK')} ${mediatheek.naam}`}/>
             <Modal.Content>
                <TijdsslotRenderer tijdsslots={tijdsslots} localeManager={localeManager} onSlotSelect={onSlotSelect}/>

                <Divider/>
                {localeManager.getString('TITEL_ZAL_TOEGEVOEGD_WORDEN_AAN_AFHAALMANDJE')}
                <ZoekresultaatListItem zoekresultaat={serverResponseObject.callback.bidocZoekresultaat}
                                   arteveldeUser={arteveldeUser} mijnMediatheekService={mijnMediatheekService} localeManager={localeManager}
                                   onCardClick={() => console.log('CARD CLICK')}/>
            </Modal.Content>
            <Modal.Actions>
                <div>
                    <div><h3>{slotButtonString}</h3></div>
                    <Spacer vertical distance={10}/>
                </div>

                <Button onClick={handleClose}>
                    {localeManager.getString('ANNULEER')}
                </Button>
                <Button color='green' onClick={() => onKiesTijdsslot(selectedSlot)} disabled={slotButtonString === ''}>
                    {localeManager.getString('SELECTEER_DIT_SLOT')}
                </Button>
            </Modal.Actions>
        </Modal>

    )
}

export default KiesTijdsslotModal

KiesTijdsslotModal.propTypes = {
    serverResponseObject: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    mijnMediatheekService: PropTypes.object.isRequired,
    mediatheekService: PropTypes.object.isRequired,
    arteveldeUser: PropTypes.object.isRequired,
    onKiesTijdsslot: PropTypes.func.isRequired,
}

KiesTijdsslotModal.defaultProps = {}