import {Event} from '../class/roostering/Event'
import {BidocReservatieData} from '../class/bidoc/BidocReservatieData'
import {BidocReservatie} from '../class/bidoc/BidocReservatie'
import {BidocUitleenData} from '../class/bidoc/BidocUitleenData'
import {BidocUitlening} from '../class/bidoc/BidocUitlening'
import {BidocVerlengData} from '../class/bidoc/BidocVerlengData'
import {BidocVerlenging} from '../class/bidoc/BidocVerlenging'
import {BidocKoppeling} from "../class/bidoc/BidocKoppeling";
import {BidocExemplaar} from "../class/bidoc/BidocExemplaar";
import {Bijlage} from '../class/bijlage/Bijlage'
import {Changelog} from "../class/admin/Changelog";
import {EPublicatieProcedure} from "../class/epub/EPublicatieProcedure";
import {EPublicatie} from "../class/epub/EPublicatie";
import {Faq} from "../class/faq/Faq";
import {Feedback} from '../class/feedback/Feedback'
import {LocaleContent} from "../class/locale/LocaleContent";
import {Mediatheek} from "../class/mediatheek/Mediatheek";
import {Nieuws} from "../class/nieuws/Nieuws";
import {Openingsuren} from "../class/mediatheek/Openingsuren";
import {Preference} from "../class/preference/Preference";
import {Quote} from "../class/quote/Quote";
import {Release} from "../class/admin/Release";
import {RubriekKast} from "../class/rubricering/RubriekKast";
import {Rubriek} from "../class/rubricering/Rubriek";
import {TellingMinuut} from "../class/mediatheek/TellingMinuut"
import {Tag} from '../class/tag/Tag'
import {TaxonomieItem} from "../class/taxonomie/TaxonomieItem";
import {Taxonomie} from "../class/taxonomie/Taxonomie";
import {TellingUur, TellingDag} from "../class/statistiek/Telling";
import {User} from "../class/user/User";
import {ZoeklijstItem} from "../class/zoeklijst/ZoeklijstItem";
import {Zoeklijst} from "../class/zoeklijst/Zoeklijst";
import {ZoekresultaatBidoc} from "../class/zoekresultaat/ZoekresultaatBidoc";
import {ZoekresultaatEbsco} from "../class/zoekresultaat/ZoekresultaatEbsco";
import {ZoekresultaatEPub} from "../class/zoekresultaat/ZoekresultaatEPub";
import {ZoekresultaatSpelwijzer} from "../class/zoekresultaat/ZoekresultaatSpelwijzer";
import {ZoekresultaatDataWrapper} from "../class/zoekresultaat/ZoekresultaatDataWrapper";
import {BewaardeZoekopdracht} from "../class/zoek/BewaardeZoekopdracht";
import {Balie} from "../class/roostering/Balie";
import {Instelling} from "../class/roostering/Instelling";
import {Stageverslag} from "../class/stageverslag/Stageverslag";
import {Social} from "../class/social/Social";


export function convertObjectToClass(object: object, className?: string) {

    if (className)
        return convertByClassName(object, className);

    if (object.hasOwnProperty('_explicitType')) {
        return convertByClassName(object, (object as any)['_explicitType']);
    }

    return object;
}

function convertByClassName(object: object, className: string) {
    let inst: any = new (factory as any)[className](object);

    return copyProps(inst, object);
}

const factory = {
    Event: Event,
    BidocKoppeling: BidocKoppeling,
    BidocReservatie: BidocReservatie,
    BidocReservatieData: BidocReservatieData,
    BidocUitleenData: BidocUitleenData,
    BidocUitlening: BidocUitlening,
    BidocVerlengData: BidocVerlengData,
    BidocVerlenging: BidocVerlenging,
    BidocExemplaar: BidocExemplaar,
    Bijlage: Bijlage,
    Changelog: Changelog,
    EPublicatie: EPublicatie,
    EPublicatieProcedure: EPublicatieProcedure,
    Faq: Faq,
    Feedback: Feedback,
    LocaleContent: LocaleContent,
    Mediatheek: Mediatheek,
    Nieuws: Nieuws,
    Openingsuren: Openingsuren,
    Preference: Preference,
    Quote: Quote,
    Release: Release,
    Rubriek: Rubriek,
    RubriekKast: RubriekKast,
    Tag: Tag,
    Taxonomie: Taxonomie,
    TaxonomieItem: TaxonomieItem,
    TellingMinuut: TellingMinuut,
    User: User,
    Zoeklijst: Zoeklijst,
    ZoeklijstItem: ZoeklijstItem,
    ZoekresultaatBidoc: ZoekresultaatBidoc,
    ZoekresultaatEbsco: ZoekresultaatEbsco,
    ZoekresultaatEPub: ZoekresultaatEPub,
    ZoekresultaatSpelwijzer: ZoekresultaatSpelwijzer,
    ZoekresultaatDataWrapper: ZoekresultaatDataWrapper,
    BewaardeZoekopdracht: BewaardeZoekopdracht,
    Balie: Balie,
    Instelling: Instelling,
    Stageverslag: Stageverslag,
    Social: Social,
    TellingUur: TellingUur,
    TellingDag: TellingDag
}

function copyProps(instance: any, props: object) {
    for (let key in props) {
        const descr = Object.getOwnPropertyDescriptor(props, key)
        if (descr && descr.writable){
            instance[key] = (props as any)[key];
        } else {
            console.log('READONLY PROP!', key)
        }
    }
    return instance;
}
