import React, {useState, useEffect, useCallback, Fragment} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

const STATUS_OPEN = 'open'
const STATUS_CLOSED = 'closed'


const DynamicFilter = ({dataCollection, model, filteredCollectionChange}) => {


    const [filteredCollection, setFilteredCollection] = useState()
    const [filterObject, setfilterObject] = useState()
    const [properties, setProperties] = useState()


    const resetObjectFilters = useCallback((fo) => {

        const filterObj = fo || filterObject
        //alle filtered arrays resetten
        const allCategoryObjects = []
        for (const filterObjectKey in filterObj) {
            const category = filterObj[filterObjectKey]
            const values = category.values
            for (const valuesKey in values) {
                const value = values[valuesKey]
                value.filtered = value.objects
                allCategoryObjects.push(value)
            }
        }

        //loopen door elk selected object en die filter toepassen op ALLE objecten van een andere category

        for (const categoryObject of allCategoryObjects) {
            if (categoryObject.selected) {
                const propModel = model[categoryObject.groupKey]
                const allOtherObjects = allCategoryObjects.filter(co => co.groupKey !== categoryObject.groupKey)

                for (const otherObject of allOtherObjects) {

                    //console.log('COMPARING', categoryObject, 'TO', otherObject, otherObject.label)

                    if (otherObject.filtered.length > 0) {
                        otherObject.filtered = otherObject.filtered.filter((obj, index) => {
                            //en hier komt het er op aan :)
                            const propVal = propModel && propModel.dataFunction ? propModel.dataFunction(obj[categoryObject.groupKey]) : obj[categoryObject.groupKey]

                            if (propVal) {
                                if (Array.isArray(propVal)) {
                                    return propVal.indexOf(categoryObject.key) > -1
                                } else {
                                    return propVal === categoryObject.key
                                }
                            }

                            return false
                        })
                    }
                }
            }
        }
    }, [filterObject, model])


    useEffect(() => {
        if (!dataCollection) return
        let fc = [...dataCollection]//dataCollection.filter(o => true)
        setFilteredCollection(fc)

        const props = []
        for (const modelKey in model) props.push(modelKey)
        setProperties(props)
    }, [dataCollection, model])

    useEffect(() => {
        if (!filteredCollection) return

        /**
         * @private
         * this method will create an object containting all properties with their possible values AND matches
         */
        const buildfilterObject = () => {
            const fo = {}

            for (const dataObject of filteredCollection) {

                for (const modelProperty in model) {
                    if (!fo[modelProperty]) {

                        fo[modelProperty] = {
                            values: {},
                            key: modelProperty,
                            status: STATUS_CLOSED,
                            model: model[modelProperty],
                            label: model[modelProperty].categoryLabel
                                ? model[modelProperty].categoryLabel
                                : model[modelProperty].categoryLabelFunction
                                    ? model[modelProperty].categoryLabelFunction(modelProperty)
                                    : modelProperty
                        }
                    }

                    const foprop = fo[modelProperty]
                    const propModel = foprop.model
                    const propVal = propModel && propModel.dataFunction ? propModel.dataFunction(dataObject[modelProperty], dataObject) : dataObject[modelProperty]


                    if (propVal) {
                        if (Array.isArray(propVal)) {
                            propVal.forEach(val => {
                                if (val === '') return

                                if (!foprop.values[val]) {
                                    const newObject = {objects: [], selected: false, groupKey: modelProperty}
                                    foprop.values[val] = newObject
                                    newObject.label = propModel && propModel.labelFunction ? propModel.labelFunction(val) : val
                                    newObject.key = val
                                }
                                foprop.values[val].objects.push(dataObject)

                            })

                        } else {
                            if (!foprop.values[propVal]) {
                                foprop.values[propVal] = {objects: [], selected: false, groupKey: modelProperty}
                                foprop.values[propVal].label = propModel && propModel.labelFunction ? propModel.labelFunction(propVal) : propVal
                                foprop.values[propVal].key = propVal
                            }
                            foprop.values[propVal].objects.push(dataObject)

                        }
                    }
                }
            }

            resetObjectFilters(fo)
            setfilterObject(fo)
        }


        if (!filterObject) buildfilterObject()
    }, [filteredCollection, filterObject, model, resetObjectFilters])



    const getActiveFiltersString = (filter) => {

        let rs = []
        for (const value in filter.values) {
            const vo = filter.values[value]
            if (vo.selected) rs.push(vo.label)
        }
        return rs.join(' & ')
    }

    const headerToggleIconClick = (value) => {
        value.status = value.status === STATUS_OPEN ? STATUS_CLOSED : STATUS_OPEN
        setfilterObject({...filterObject})
    }

    const filterCheckboxClick = (event, {collection}) => {
        collection.selected = event.target.checked
        buildFilteredCollection()
    }

    const clearFilterIconClick = (filter) => {
        for (const value in filter.values) {
            const vo = filter.values[value]
            vo.selected = false
        }
        buildFilteredCollection()
    }

    const buildFilteredCollection = () => {

        resetObjectFilters()

        //console.log('DATA', filterObject)
        const filters = []
        for (const filterObjectKey in filterObject) {
            const filter = []
            const filterGroupObject = filterObject[filterObjectKey]

            for (const filterObjectValueKey in filterGroupObject.values) {
                const valueObject = filterGroupObject.values[filterObjectValueKey]
                if (valueObject.selected) {
                    filter.push(valueObject)
                }
            }
            if (filter.length > 0) filters.push(filter)
        }

        if (filters.length === 0) {
            setFilteredCollection(dataCollection)
            filteredCollectionChange && filteredCollectionChange(dataCollection)
            return
        }


        const results = []

        for (const filter of filters) {
            let unionObjects = []
            for (const filterElement of filter) {
                unionObjects = _.union(unionObjects, filterElement.filtered)
            }
            results.push(unionObjects)
        }

        const intersection = _.intersectionBy(...results, 'id')

        setFilteredCollection(intersection)
        filteredCollectionChange && filteredCollectionChange(intersection)
    }



    if (!filterObject || filteredCollection.length === 0) return null

    return (
        <div>
            <div className='df-result-count'>Results: {filteredCollection.length}</div>

            <div className='df-filter-container'>
                {properties.map((prop, index) => {
                    const val = filterObject[prop]
                    const objectCollection = _.orderBy(_.values(val.values), 'label')
                    const somethingSelected = objectCollection.reduce((acc, o) => o.selected ? true : acc, false)
                    const classString = 'df-filter-group-header' + (somethingSelected ? ' selected' : '')
                    const activeFiltersString = getActiveFiltersString(val)
                    return <div key={index} className='df-filter-group'>
                        <div className={classString}>
                            <div className='df-filter-group-header-label'>
                                <div>{val.label}</div>
                                <div className='df-header-toggle-icon' onClick={() => headerToggleIconClick(val)}>{val.status === STATUS_OPEN ? '-' : '+'}</div>
                            </div>
                            {activeFiltersString !== '' && <div className='df-filter-group-header-label df-filter-group-header-active-filters'>
                                <div>{activeFiltersString}</div>
                                <div className='df-header-toggle-icon' onClick={() => clearFilterIconClick(val)}>x</div>
                            </div>}
                        </div>

                        {val.status === STATUS_OPEN &&
                        <Fragment>
                            {objectCollection.map((coll, index) => {
                                if (coll.filtered.length === 0) return null
                                return <div key={index} className='df-option'>
                                    <input type="checkbox" id={coll.key} checked={coll.selected}
                                           name={coll.key} value={coll.key}
                                           onChange={(e) => filterCheckboxClick(e, {collection: coll})}/>
                                    <label htmlFor={coll.key}><span className='df-option-label'> {coll.label}</span> <span
                                        className='df-option-count'>({coll.objects.length}) <strong>({coll.filtered.length})</strong></span></label><br/>

                                </div>
                            })}
                        </Fragment>
                        }
                    </div>
                })}
            </div>
        </div>
    )
}

export default DynamicFilter

DynamicFilter.propTypes = {
    dataCollection: PropTypes.array.isRequired,
    model: PropTypes.object.isRequired,
    filteredCollectionChange: PropTypes.func
}

DynamicFilter.defaultProps = {
    properties: [],
    excludedProperties: ['id']
}
