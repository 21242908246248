/*
	©2018 Dany Dhondt & Lennert Holvoet
*/


export class EPublicatie {

    _explicitType: string = "EPublicatie"
    ahs_trefwoorden: string = ''
    auteur: string = ''
    beschrijving: string = ''
    campus: string = ''
    datum_aanmaak: string
    datum_wijziging: string
    domein: string = ''
    thema: string = ''
    gebruikersnaam: string = ''
    geldig_tot: string = ''
    geldig_tot_ts: number = 0
    groep: string = ''
    id: number = 0
    isbnissn: string = ''
    jaar: string = ''
    leverancier: string = ''
    licentie_beheerder: string = ''
    licentie_beheerders: string = ''
    lid_abonneenummer: string = ''
    link: string = ''
    onbeperkt_geldig: number = 0
    opleiding: string = ''
    opmerking: string = ''
    opmerking_intern: string = ''
    paswoord: string = ''
    periode: string = ''
    plaats_van_uitgave: string = ''
    procedure_id: number = 0
    reeks: string = ''
    status: string = ''
    taal: string = ''
    titel: string = ''
    type: string = ''
    uitgever: string = ''
    user_aanmaak: number = 0
    user_wijziging: number = 0

    sortTitel: string = ''
    uitgelicht: number = 0

    toon_toegang_platform_button: number = 0
    link_toegang_platform: string
}
