import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {SizeMe} from 'react-sizeme'

const BaseList = ({dataprovider, selectedItem, idField, onSelectItem, labelFunction, newElementCallback}) => {

    const [height, setHeight] = useState()
    //const [scrollElement, setScrollElement] = useState()
    const scrollerRef = useRef()

    console.info('Baselist is deprecated, please switch to ui-component/DataList')

    useEffect(() => {
        console.log('SEL IT', scrollerRef, isElementInViewport(scrollerRef.current))
        //selectedItem && delete selectedItem.isNewElement
        if (scrollerRef.current && !isElementInViewport(scrollerRef.current)) scrollerRef.current.scrollIntoView({behavior: "smooth", block: "nearest"})
    }, [selectedItem])

    const isElementInViewport = (el) => {

        if (!el) return false

        const rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    }
    return (
        <SizeMe monitorHeight>
            {({size}) => {
                if (size.height && !height) setHeight(size.height - 10)

                return <div className='list' style={{height: height, padding: '10px'}}>
                    {dataprovider.map((item, index) => {
                        const itemIsSelected = selectedItem && item[idField] === selectedItem[idField]
                        const cn = `virtualListItemRenderer ${itemIsSelected && 'itemRendererSelected'}`
                        let refer
                        if (selectedItem){
                            refer = itemIsSelected ? scrollerRef : null
                        } else {
                            refer = index === 0 ? scrollerRef : null
                        }
                        return <div ref={refer} key={item[idField]} className={cn} onClick={() => onSelectItem(item)}>
                            {labelFunction(item)}
                        </div>
                    })}
                </div>
            }
            }
        </SizeMe>
    )
}

export default BaseList

BaseList.propTypes = {
    dataprovider: PropTypes.array.isRequired,
    selectedItem: PropTypes.object,
    idField: PropTypes.string,
    onSelectItem: PropTypes.func,
    labelFunction: PropTypes.func,
    newElementCallback: PropTypes.func,
}

BaseList.defaultProps = {}
