import {Mediatheek} from "../class/mediatheek/Mediatheek";
import {CampusCode} from "../domain/campus/campusCode";
import * as Kleur from '../domain/huisstijl/kleur';

export function getMediatheekKleur(mediatheek:Mediatheek): object {
	
	switch (mediatheek.campusCode){
		case CampusCode.KAT:
			return Kleur.GROEN;
		case CampusCode.KNT:
			return Kleur.DONKERGRIJS;
		case CampusCode.MAR:
			return Kleur.FUCHSIA;
		case CampusCode.SAB:
			return Kleur.ORANJE;
		case CampusCode.SAP:
			return Kleur.BLAUW;
		default:
			return Kleur.ZWART;
	}
}