import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import {List, Divider} from 'semantic-ui-react'
import moment from 'moment'

import styles from '../styles.css'

const ChangelogList = ({taxonomieService, adminService, editable, changelogs, onDeleteChangelog}) => {

    const [dataprovider, setDataprovider] = useState()

    useEffect(() => {

        const createDataprovider = (changelogs) => {
            return adminService.createHierarchicalChangelogWrappers(changelogs, taxonomieService)
        }

        setDataprovider(createDataprovider(changelogs))
    }, [changelogs, taxonomieService, adminService])


    const getChangelogContent = (content) => {
        return (content.split('\n').map((line, key) => {
            return <Fragment key={key}>{line}<br/></Fragment>
        }))
    }

    const onChangelogDelete = (changelog) => {
        onDeleteChangelog && onDeleteChangelog(changelog)
    }

    if (!dataprovider)
        return <div></div>

    return (
        <List
            style={{overflowY: 'auto', paddingRight: '15px', marginRight: '10px', maxHeight: '70vh'}}>
            {dataprovider.map((module) => {


                return <List.Item key={module.key}>
                    <List.Content>
                        <List.Header>{module.label}</List.Header>
                        <List.List>
                            {module.children.map(changelog => {
                                return <List.Item key={changelog.id}>
                                    {/*<List.Icon name='file' />*/}
                                    <List.Content>
                                        <List.Header className={styles.dateHeader}>{moment(changelog.datumtijd, 'YYYY-MM-DD HH:mm:ss').format('ddd D MMM YYYY - HH:mmu')}</List.Header>
                                        <List.Description>{getChangelogContent(changelog.content)}</List.Description>
                                        {editable &&
                                        <div style={{fontSize: '0.7em', cursor: 'pointer'}} onClick={() => onChangelogDelete(changelog)}>Verwijder</div>
                                        }
                                    </List.Content>
                                </List.Item>
                            })}
                        </List.List>
                        <Divider/>
                    </List.Content>
                </List.Item>
            })}
        </List>
    )
}

export default ChangelogList

ChangelogList.propTypes = {
    taxonomieService: PropTypes.object.isRequired,
    adminService: PropTypes.object.isRequired,
    editable: PropTypes.bool,
    changelogs: PropTypes.array,
    onDeleteChangelog: PropTypes.func
}

ChangelogList.defaultProps = {
    editable: true
}
