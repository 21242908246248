import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'semantic-ui-react'
import { ApplicationPath } from 'med-class'

const ZoeksetBanner = (props) => {

    return (
        <div style={{position: 'relative'}}>
            <Image fluid src={ApplicationPath.mediatheekAssetsFolder + 'zoeksetBanners/' + props.zoekset.banner}/>
            <div className='zoekset-bannertext'>{props.localeManager.getString(props.zoekset.label)}</div>
        </div>
    )
}

export default  ZoeksetBanner

ZoeksetBanner.propTypes = {
    zoekset: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired
}

ZoeksetBanner.defaultProps = {

}