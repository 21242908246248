import {RubriekKast} from "../class/rubricering/RubriekKast";

const axios = require('axios')
const _ = require('lodash')

export class RubriceringService {

    private readonly ZONES: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']
    private readonly MAX_KAST: number = 100

    initialized: boolean = false
    apiUrl: string
    apiKey: string
    rubrieken: object[]
    rubriekenIdLookup: object
    rubriekenRubriekLookup: object
    rubriekKasten: object[]
    rubriekKastenLookup: object
    rubriekKastenRubriekLookup: object
    rubriekKastenKastLookup: object
    kasten: string[]
    mediatheekZonesLookup: object


    constructor(apiUrl: string, apiKey: string) {
        this.apiUrl = apiUrl
        this.apiKey = apiKey
        this.rubrieken = []
        this.rubriekenIdLookup = {}
        this.rubriekenRubriekLookup = {}
        this.kasten = []
        this.rubriekKasten = []
        this.rubriekKastenLookup = {}
        this.rubriekKastenRubriekLookup = {}
        this.rubriekKastenKastLookup = {}
        this.mediatheekZonesLookup = {}
    }

    init = () => {

        //KASTEN
        for (let zone in this.ZONES) {
            for (let i: number = 1; i <= this.MAX_KAST; i++) {
                let label: string = this.ZONES[zone] + '.' + i
                let kast: any = {id: label, label}
                this.kasten.push(kast)
            }
        }


        return new Promise((resolve) => {
                Promise.all([this.getRubrieken(), this.getRubriekKasten()]).then(() => {
                    this.initialized = true
                    resolve({rubrieken: this.rubrieken, rubriekKasten: this.rubriekKasten})
                })

                /*this.getRubrieken().then(() => {
                    if (this.rubrieken && this.rubriekKasten)
                        this.initialized = true
                    resolve({rubrieken: this.rubrieken, rubriekKasten: this.rubriekKasten})
                })
                this.getRubriekKasten().then(() => {
                    if (this.rubrieken && this.rubriekKasten)
                        this.initialized = true
                    resolve({rubrieken: this.rubrieken, rubriekKasten: this.rubriekKasten})
                })*/
            }
        )

    }

    private getRubrieken = () => {
        let url = `${this.apiUrl}rubricering?transform=1&order[]=rubriek,asc&token=${this.apiKey}`
        return new Promise((resolve, reject) => {
                axios.get(url)
                    .then(response => {
                        this.rubrieken = response.data.rubricering
                        this.rubriekenIdLookup = _.keyBy(this.rubrieken, rubriek => rubriek.id)
                        this.rubriekenRubriekLookup = _.keyBy(this.rubrieken, rubriek => rubriek.rubriek)
                        resolve({rubrieken: this.rubrieken, kasten: this.kasten})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error)
                    });
            }
        )
    }

    saveRubriek = (rubriek) => {
        let url = `${this.apiUrl}rubricering/${rubriek.id}?token=${this.apiKey}`

        return new Promise((resolve, reject) => {
                axios.put(url, rubriek)
                    .then(response => {
                        this.rubrieken = this.rubrieken.map((r: any) => {
                            if (r.id === rubriek.id)
                                return rubriek
                            return r
                        })
                        this.rubriekenIdLookup[rubriek.id] = rubriek
                        this.rubriekenRubriekLookup[rubriek.rubriek] = rubriek
                        resolve({
                            rubrieken: this.rubrieken,
                            rubriek,
                            rubriekenIdLookup: this.rubriekenIdLookup,
                            rubriekenRubriekLookup: this.rubriekKastenRubriekLookup,
                            response
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error)
                    });
            }
        )
    }

    createRubriek = (rubriek) => {
        return new Promise((resolve, reject) => {
                let url = `${this.apiUrl}rubricering/?token=${this.apiKey}`
                axios.post(url, rubriek)
                    .then(response => {
                        rubriek.id = response.data
                        this.rubrieken.push(rubriek)
                        this.rubriekenIdLookup[rubriek.id] = rubriek
                        this.rubriekenRubriekLookup[rubriek.rubriek] = rubriek
                        this.rubriekKastenRubriekLookup[rubriek.id] = {}
                        resolve({
                            rubrieken: this.rubrieken,
                            rubriek,
                            rubriekenIdLookup: this.rubriekenIdLookup,
                            rubriekenRubriekLookup: this.rubriekKastenRubriekLookup,
                            response
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    });
            }
        )
    }

    deleteRubriek(rubriek) {
        //TODO: also delete rubriekKasten for this rubriek
        return new Promise((resolve, reject) => {
                let url = `${this.apiUrl}rubricering/${rubriek.id}?token=${this.apiKey}`
                axios.delete(url)
                    .then(response => {
                        this.rubrieken = this.rubrieken.filter((r: any) => r.id !== rubriek.id)
                        this.rubriekenIdLookup = _.keyBy(this.rubrieken, rubriek => rubriek.id)
                        resolve({
                            rubriek,
                            rubrieken: this.rubrieken,
                            rubriekenIdLookup: this.rubriekenIdLookup,
                            rubriekenRubriekLookup: this.rubriekKastenRubriekLookup,
                            response
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    get rubriekenProvider(): object[] {
        return this.rubrieken.map((rubriek: any) => ({key: rubriek.id, value: rubriek.id.toString(), text: rubriek.label}))
    }

    private getRubriekKasten = () => {
        let url = `${this.apiUrl}rubriek_kast?transform=1&order[]=kast,asc&token=${this.apiKey}`;
        return new Promise((resolve, reject) => {
                axios.get(url)
                    .then(response => {
                        this.rubriekKasten = response.data.rubriek_kast

                        //lookup
                        this.rubriekKasten.forEach((rubriekKast: any) => {
                            this.addRubriekKastToLookups(rubriekKast)
                        })

                        resolve({rubrieken: this.rubrieken, kasten: this.kasten})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error)
                    });
            }
        )
    }

    /*saveRubriekKast = (rubriekKast) => {
        let url = `${this.apiUrl}rubriek_kast/${rubriek.id}?token=${this.apiKey}`

        return new Promise((resolve, reject) => {
                axios.put(url, rubriekKast)
                    .then(response => {
                        this.rubrieken = this.rubrieken.map((r: any) => {
                            if (r.id === rubriek.id)
                                return rubriek
                            return r
                        })
                        this.rubriekenIdLookup[rubriek.id] = rubriek
                        resolve({rubrieken: this.rubrieken, response, rubriek, rubriekenIdLookup:
                         this.rubriekenIdLookup})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error)
                    });
            }
        )
    }*/

    /*updateRubriekKastenForMediahteek = (rubriekId: number, mediatheek: string, newRubriekKasten: any[]) => {
        //we verwijderen alle rubriekKasten en maken daarna nieuwe aan
    }*/

    toggleRubriekKast = (key) => {

        return new Promise((resolve, reject) => {
                const rubriekKast = this.rubriekKastenLookup[key]

                if (rubriekKast) {
                    this.deleteRubriekKast(rubriekKast).then(() => resolve('remove'))
                } else {
                    const newRubriekKast = new RubriekKast()
                    newRubriekKast.fillFromKey(key)
                    const rubriek = this.rubriekenIdLookup[newRubriekKast.rubriek_id]
                    newRubriekKast.rubriek = rubriek.rubriek
                    this.createRubriekKast(newRubriekKast).then(() => resolve('add'))
                }
            }
        )
    }

    createRubriekKast = (rubriekKast) => {
        return new Promise((resolve, reject) => {
                let url = `${this.apiUrl}rubriek_kast/?token=${this.apiKey}`
                axios.post(url, rubriekKast)
                    .then(response => {
                        rubriekKast.id = response.data
                        this.rubriekKasten.push(rubriekKast)
                        this.addRubriekKastToLookups(rubriekKast)
                        resolve({rubriekKast, response})
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    });
            }
        )
    }

    deleteRubriekKast(rubriekKast) {
        return new Promise((resolve, reject) => {
                let url = `${this.apiUrl}rubriek_kast/${rubriekKast.id}?token=${this.apiKey}`
                axios.delete(url)
                    .then(response => {
                        this.rubriekKasten = this.rubriekKasten.filter((rk: any) => rk.id !== rubriekKast.id)
                        this.removeRubriekKastFromLookups(rubriekKast)
                        resolve({rubriekKast, rubriekKasten: this.rubriekKasten, response})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    getRubriekKastenFromRubriekLookup = (rubriekId: number, mediatheek: string) => {
        if (!this.rubriekKastenRubriekLookup[rubriekId])
            this.rubriekKastenRubriekLookup[rubriekId] = {}
        if (!this.rubriekKastenRubriekLookup[rubriekId][mediatheek])
            this.rubriekKastenRubriekLookup[rubriekId][mediatheek] = []

        return this.rubriekKastenRubriekLookup[rubriekId][mediatheek]
    }

    getRubriekKastenFromKastLookup = (kast: string, mediatheek: string) => {
        if (!this.rubriekKastenKastLookup[kast])
            this.rubriekKastenKastLookup[kast] = {}
        if (!this.rubriekKastenKastLookup[kast][mediatheek])
            this.rubriekKastenKastLookup[kast][mediatheek] = []

        return this.rubriekKastenKastLookup[kast][mediatheek]
    }

    private addRubriekKastToLookups = (rubriekKast: any) => {
        const rkKey: string = rubriekKast.rubriek_id + '_' + rubriekKast.kast + '_' + rubriekKast.mediatheek
        this.rubriekKastenLookup[rkKey] = rubriekKast

        if (!this.rubriekKastenRubriekLookup[rubriekKast.rubriek_id])
            this.rubriekKastenRubriekLookup[rubriekKast.rubriek_id] = {}
        if (!this.rubriekKastenKastLookup[rubriekKast.kast])
            this.rubriekKastenKastLookup[rubriekKast.kast] = {}


        if (!this.rubriekKastenRubriekLookup[rubriekKast.rubriek_id][rubriekKast.mediatheek])
            this.rubriekKastenRubriekLookup[rubriekKast.rubriek_id][rubriekKast.mediatheek] = []
        if (!this.rubriekKastenKastLookup[rubriekKast.kast][rubriekKast.mediatheek])
            this.rubriekKastenKastLookup[rubriekKast.kast][rubriekKast.mediatheek] = []

        this.rubriekKastenRubriekLookup[rubriekKast.rubriek_id][rubriekKast.mediatheek].push(rubriekKast)
        this.rubriekKastenKastLookup[rubriekKast.kast][rubriekKast.mediatheek].push(rubriekKast)

        //zones

        let kastArr: string[] = rubriekKast.kast.split('.')
        let zone: string = kastArr[0]
        let kast: number = parseInt(kastArr[1])
        if (!this.mediatheekZonesLookup[rubriekKast.mediatheek])
            this.mediatheekZonesLookup[rubriekKast.mediatheek] = {}

        if (!this.mediatheekZonesLookup[rubriekKast.mediatheek][zone])
            this.mediatheekZonesLookup[rubriekKast.mediatheek][zone] = {minKast: kast, maxKast: kast, kasten: []}

        this.mediatheekZonesLookup[rubriekKast.mediatheek][zone].minKast = Math.min(this.mediatheekZonesLookup[rubriekKast.mediatheek][zone].minKast, kast)
        this.mediatheekZonesLookup[rubriekKast.mediatheek][zone].maxKast = Math.max(this.mediatheekZonesLookup[rubriekKast.mediatheek][zone].maxKast, kast)
        if (this.mediatheekZonesLookup[rubriekKast.mediatheek][zone].kasten.indexOf(kast) < 0)
            this.mediatheekZonesLookup[rubriekKast.mediatheek][zone].kasten.push(kast)
    }

    private removeRubriekKastFromLookups = (rubriekKast: any) => {
        //rubriekKast MOET in de lookups zitten!
        const rkKey: string = rubriekKast.rubriek_id + '_' + rubriekKast.kast + '_' + rubriekKast.mediatheek
        delete this.rubriekKastenLookup[rkKey]
        this.rubriekKastenRubriekLookup[rubriekKast.rubriek_id][rubriekKast.mediatheek] = this.rubriekKastenRubriekLookup[rubriekKast.rubriek_id][rubriekKast.mediatheek].filter(rk => rk.id !== rubriekKast.id)
        this.rubriekKastenKastLookup[rubriekKast.kast][rubriekKast.mediatheek] = this.rubriekKastenKastLookup[rubriekKast.kast][rubriekKast.mediatheek].filter(rk => rk.id !== rubriekKast.id)
    }

}
