import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Image, Card, Icon, Label} from 'semantic-ui-react'
import {ZoekUtil} from 'med-class'
import _ from 'lodash'

const Parser = require('html-react-parser')
const MAX_AUTEURS = 5

const ZoekresultaatListItem = ({zoekresultaat, taxonomieService, localeManager, directLink, clickable, onCardClick, onCoverClick, topContentFunction, aanwinstNummer}) => {

    const [showAllAuteurs, setShowAllAuteurs] = useState(false)
    const [auteursArray, setAuteursArray] = useState([])
    const [isKiosk] = useState(directLink && directLink.isKiosk())

    useEffect(() => {
        setAuteursArray(showAllAuteurs ? zoekresultaat.auteurs : _.take(zoekresultaat.auteurs, 5))
    }, [showAllAuteurs, zoekresultaat])

    const onLocalCardClick = (event) => {
        event.preventDefault()
        if (!clickable) return
        onCardClick(zoekresultaat)
    }
    const onLocalCoverClick = (event) => {
        event.preventDefault()
        event.stopPropagation()
        onCoverClick(zoekresultaat)
    }

    const getShowMoreLabel = () => {

        if (showAllAuteurs) return null
        if (zoekresultaat.auteurs.length <= MAX_AUTEURS) return null

        const label = localeManager.getString('EN_X_MEER').replace('[num]', zoekresultaat.auteurs.length - MAX_AUTEURS) + '...'
        return <Label style={{marginLeft: '15px', cursor: 'pointer'}}
                      size='small'
                      content={label}
                      onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          setShowAllAuteurs(true)
                      }
                      }/>
    }

    return (

        <Card as='div' style={{border: zoekresultaat.uitgelicht === 1 ? 'teal solid' : 'none', cursor: clickable ? 'pointer' : 'default'}} fluid onClick={onLocalCardClick}>

            {topContentFunction && topContentFunction({zoekresultaat})}

            <Card.Content as={clickable ? 'a' : ''} href={zoekresultaat.directeLink}>

                {zoekresultaat.uitgelicht === 1 &&
                <div className='zoekresultaat-uitgelicht-list'>
                    <Icon name="hand point right outline"/>
                    {localeManager.getString('UITGELICHT')}!
                </div>
                }

                {zoekresultaat.coverLinkSmall &&
                <Image floated='left' size='tiny' bordered src={zoekresultaat.coverLinkSmall} onClick={onLocalCoverClick} onError={e => e.target.style.display = 'none'}/>
                }

                <Card.Header>{Parser(zoekresultaat.titel)}</Card.Header>
                <div style={{fontSize: '0.85em', color: 'grey', margin: '2px 0 2px 0'}}>
                    {zoekresultaat.subtitelArray && zoekresultaat.subtitelArray.map((subtitel, index) => {
                        return <Fragment key={index}><em>{Parser(subtitel.toLowerCase())}</em><br/></Fragment>
                    })}
                </div>
                <Card.Meta>
                    <span>{Parser(ZoekUtil.metaString(zoekresultaat, localeManager, taxonomieService))}</span>
                    {(zoekresultaat.auteurs && zoekresultaat.auteurs.length > 0) &&
                    <div style={{color: 'black'}}>
                        {Parser(auteursArray.join(' | '))}
                        {getShowMoreLabel()}
                    </div>
                    }
                </Card.Meta>


            </Card.Content>

            {aanwinstNummer && <Card.Content extra><Card.Header>{aanwinstNummer}</Card.Header></Card.Content>}

            {!isKiosk &&
            <Card.Content extra>
                {/*<SocialContent zoekresultaat={zoekresultaat}
                                   socialService={socialService}
                                   mijnMediatheekService={mijnMediatheekService}
                                   arteveldeUser={arteveldeUser}
                                   localeManager={localeManager}
                                   socialIconsDirection='row'/>*/}
            </Card.Content>}

        </Card>

    )
}

export default ZoekresultaatListItem

ZoekresultaatListItem.propTypes = {
    localeManager: PropTypes.object.isRequired,
    socialService: PropTypes.object.isRequired,
    mijnMediatheekService: PropTypes.object.isRequired,
    taxonomieService: PropTypes.object.isRequired,
    zoekresultaat: PropTypes.object.isRequired,
    onCardClick: PropTypes.func,
    onCoverClick: PropTypes.func,
    arteveldeUser: PropTypes.object,
    directLink: PropTypes.object,
    clickable: PropTypes.bool,
    topContentFunction: PropTypes.func,
    aanwinstNummer: PropTypes.string, //hack om te kunnen weergeven in afhaalmandjes
}

ZoekresultaatListItem.defaultProps = {
    clickable: true
}
