export const ORANJE = {label:"Oranje", code:"oranje", pantone:"PANTONE 1585 C", cmyk:"0-60-100-0", rgb:"245-135-50", css:"#58732", hex:0xEE9900, textColor:"0x000000", textColorName:"black"};
export const GROEN = {label:"Groen", code:"groen", pantone:"PANTONE 361 C", cmyk:"68-0-100-0", rgb:"90-185-70", css:"#5ab946", hex:0xBBCC00, textColor:"0x000000", textColorName:"black"};
export const FUCHSIA = {label:"Fuchsia", code:"fuchsia", pantone:"PANTONE 7662 C", cmyk:"60-73-0-0", rgb:"126-87-197", css:"#7e57c5", hex:0xCC0077, textColor:"0xFFFFFF", textColorName:"white"};
export const BLAUW = {label:"Blauw", code:"blauw", pantone:"PANTONE 7460 C", cmyk:"99-0-5-5", rgb:"0-165-217", css:"#00a5d9", hex:0x00AACC, textColor:"0x000000", textColorName:"black"};
export const ROOD = {label:"Rood", code:"rood", pantone:"PANTONE 199 C", cmyk:"0-100-72-0", rgb:"237-0-52", css:"#ed0034", hex:0x00AACC, textColor:"0x000000", textColorName:"black"};
export const ROZE = {label:"Roze", code:"roze", pantone:"PANTONE 233 C", cmyk:"12-100-0-0", rgb:"210-0-130", css:"#d20082", hex:0x00AACC, textColor:"0x000000", textColorName:"black"};
export const LICHTGRIJS = {label:"Lichtgrijs", code:"lichtgrijs", pantone:"PANTONE COOL GRAY 3 C", cmyk:"0-0-0-15", rgb:"221-221-221", css:"#dddddd", hex:0xDDDDDD, textColor:"0x000000", textColorName:"black"};
export const MIDDENGRIJS = {label:"Middengrijs", code:"middengrijs", pantone:"PANTONE COOL GRAY 7 C", cmyk:"0-0-0-40", rgb:"170-170-170", css:"#aaaaaa", hex:0xAAAAAA, textColor:"0x000000", textColorName:"black"};
export const DONKERGRIJS = {label:"Donkergrijs", code:"donkergrijs", pantone:"PANTONE COOL GRAY 10 C", cmyk:"0-0-0-60", rgb:"119-119-119", css:"#777777", hex:0x777777, textColor:"0xFFFFFF", textColorName:"white"};
export const ZWART = {label:"Zwart", code:"zwart", pantone:"PANTONE BLACK C", cmyk:"0-0-0-100", rgb:"0-0-0", css:"#000000", hex:0x000000, textColor:"0xFFFFFF", textColorName:"white"};

export const DIEP_PAARS = {label:"Diep paars", code:"dieppaars", pantone:"", cmyk:"85-100-15-4", rgb:"87-8-124", css:"#57087c", hex:0x57087C, textColor:"0xFFFFFF", textColorName:"white"};

export const VALUES = [
	ORANJE, GROEN, FUCHSIA, BLAUW, LICHTGRIJS, MIDDENGRIJS, DONKERGRIJS, ZWART, DIEP_PAARS, ROOD, ROZE
]

export function getKleurByName(kleur:String) {
	
	let rColor = DONKERGRIJS
	VALUES.forEach(color => {
		if (color.code === kleur)
			rColor = color; //return color;
	});
	
	return rColor;
}

export function getKleurByHex(hex:number) {
	
	let rColor = DONKERGRIJS
	VALUES.forEach(color => {
		if (color.hex === hex)
			rColor = color; //return color;
	});
	
	return rColor;
}

export function getKleurByCss(css:string) {

	let rColor = DONKERGRIJS
	VALUES.forEach(color => {
		if (color.css === css)
			rColor = color; //return color;
	});

	return rColor;
}

export function getKleurByRgb(rgb:String) {
	
	let rColor = DONKERGRIJS
	VALUES.forEach(color => {
		if (color.rgb === rgb)
			rColor = color; //return color;
	});
	
	return rColor;
}

export function getKleurByCmyk(cmyk:String) {
	
	let rColor = DONKERGRIJS
	VALUES.forEach(color => {
		if (color.cmyk === cmyk)
			rColor = color; //return color;
	});
	
	return rColor;
}

export function getMediatheekKleurHexArray(): Array<string> {
	let ra:Array<string> = []
	
	VALUES.forEach(color => {
		ra.push(color.css)
	});
	
	return ra
}