import React, {useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import ReserveerTile from "./reserveer/ReserveerTile";
import InformatievaardigTile from "./reserveer/InformatievaardigTile";
import InformatieOpMaatTile from "./informatieOpMaat/InformatieOpMaatTile";
import FaqTile from "./faq/FaqTile";
import InformatieOpMaatDetail from "./informatieOpMaat/InformatieOpMaatDetail";

const Tiles = ({localeManager, arteveldeUser, faqService, faqLocaleDataService, onFaqTileClick}) => {

    const [informatieOpMaatDetailVisible, setInformatieOpMaatDetailVisible] = useState(false)

    const onInformatieOpMaatClick = () => {
        setInformatieOpMaatDetailVisible(!informatieOpMaatDetailVisible)
    }

    return (
        <Fragment>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}}>
                <ReserveerTile localeManager={localeManager}/>
                <InformatievaardigTile localeManager={localeManager}/>
                <InformatieOpMaatTile localeManager={localeManager} onClick={onInformatieOpMaatClick}/>
                {/*<WebComponentFeedback localeManager={localeManager} feedbackService={feedbackService} arteveldeUser={arteveldeUser}/>*/}
                <FaqTile faqService={faqService}
                         localeDataService={faqLocaleDataService}
                         localeManager={localeManager}
                         onClick={onFaqTileClick}/>
            </div>

            {informatieOpMaatDetailVisible &&
            <InformatieOpMaatDetail localeManager={localeManager} arteveldeUser={arteveldeUser}/>
            }
        </Fragment>
    )
}

export default  Tiles

Tiles.propTypes = {
    localeManager: PropTypes.object.isRequired,
    arteveldeUser: PropTypes.object.isRequired,
    faqService: PropTypes.object.isRequired,
    faqLocaleDataService: PropTypes.object.isRequired,
    onFaqTileClick: PropTypes.func.isRequired
}

Tiles.defaultProps = {

}