import React from 'react'
import {Label} from 'semantic-ui-react'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'

const MediatheekRenderer = ({zoekresultaat, localeManager, label}) => {

    if (!zoekresultaat.mediatheek) return null
    if (zoekresultaat.mediatheek[0] === '') return null
    return (
        <AbstractZoekresultaatRenderer label={localeManager.getString(label)}>
            {zoekresultaat.mediatheek.map((mediatheek, index) => {
                return <Label basic key={index} style={{marginBottom: '5px'}} as='a' size='small' content={mediatheek} icon='home' color='blue'/>
            })}
        </AbstractZoekresultaatRenderer>
    )
}

export default  MediatheekRenderer