export class BidocExemplaar {

    _explicitType: string = "BidocExemplaar"

    awnr: string;
    plaats: string;
    uitleenstatus: string;
    plaatskenmerk: string;

    /*toString = (localeModelInstance):string => {
        if (!localeModelInstance)
            return "";

        //Arteveldehs - mediatheek Kantienberg eindwerkenafdeling | Logopedie-2015-37 | aanwezig | 2015/4893.9 ()
        let rs = "";
        if (this.plaats) rs += this.plaats + " | ";
        if (this.plaatskenmerk) rs += localeModelInstance.getLocale("PLAATS") + ": " + this.plaatskenmerk + " | ";
        if (this.uitleenstatus) rs += localeModelInstance.getLocale(this.uitleenstatus.toUpperCase()) + " | ";
        if (this.awnr) rs += this.awnr + " | ";


        return rs.replace(" :", ":");
    }*/


}
