import React from 'react'
import PropTypes from 'prop-types'
import {Image, Popup} from "semantic-ui-react";
import {ApplicationPath} from 'med-class'

const AdminsAvailable = ({adminsAvailable}) => {

    return (
        <div style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
            <div style={{width: '130px'}}>Momenteel online:</div>

            {adminsAvailable && adminsAvailable.map((admin, index) => {
                return <Popup key={index} mouseEnterDelay={500} size='mini' trigger={<Image avatar src={ApplicationPath.avatarApi + admin.loginUser}/>}>
                    {admin.voornaam}
                </Popup>
            })}

        </div>
    )
}

export default  AdminsAvailable

AdminsAvailable.propTypes = {
    adminsAvailable: PropTypes.array
}

AdminsAvailable.defaultProps = {

}