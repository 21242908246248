import {LocaleManager} from "../../service/LocaleManager"
import {PublicatieType} from "../zoek/PublicatieType";
import {AbstractZoekresultaat} from "../zoekresultaat/AbstractZoekresultaat";

const moment = require('moment')
require('moment/locale/nl');

export class ApaConverter {

    localeManager: LocaleManager
    zoekresultaat: AbstractZoekresultaat

    constructor(zoekresultaat, localeManager) {
        this.localeManager = localeManager
        this.zoekresultaat = zoekresultaat
    }

    /*
        titel
        auteurs;
        jaar
        uitgever
        bladzijden
        publicatietype
        groep
        reeks
        tijdschrift
        mediatheek
        exemplaar
        siso
        trefwoorden
        beschrijving;
        annotatie;
        categorie
        bron;
        isbn
        issn
        campus
        jaargang
        periode
        opleiding
        didactischeSuggestie;
        leverancier;
        opmerking;
    */
    private useAuteur: boolean = false
    private useJaar: boolean = false
    private useTitel: boolean = false
    private useUitgever: boolean = false
    private usePlaats: boolean = false
    private useDoi: boolean = false
    private useLink: boolean = false

    private auteur: string
    private jaar: string
    private titel: string
    private uitgever: string
    private plaats: string
    private doi: string
    private link: string
    private druk: string

    public convert() {

        const zoekresultaat = this.zoekresultaat
        let apaString = ""
        this.auteur = this.buildAuteur(zoekresultaat.auteurs)
        this.jaar = zoekresultaat.jaar
        this.titel = this.buildTitel(zoekresultaat)
        this.uitgever = zoekresultaat.uitgever
        this.plaats = zoekresultaat.plaats
        this.doi = zoekresultaat.doi
        this.link = zoekresultaat.link

        switch (zoekresultaat.publicatietype) {
            case PublicatieType.ARTIKEL:
                this.useAuteur = this.useJaar = this.useTitel = this.useDoi = true
                apaString = this.assemble()
                break
            case PublicatieType.WEBSITE_DATABANK:
                this.useTitel = this.useLink = true
                apaString = this.assemble()
                break
            default:
                if (!this.uitgever) this.uitgever = ""
                this.useAuteur = this.useJaar = this.useTitel = this.useUitgever = this.useDoi = true
                apaString = this.assemble()
                break
        }

        apaString = apaString.replace(new RegExp(" :", 'g'), ":"); //spaties voor dubbele punten weghalen

        return apaString
    }

    private assemble() {


        if (this.useUitgever && this.uitgever !== null) {
            if (this.uitgever === "") {
                this.uitgever = this.localeManager.getString("APA_ZONDER_UITGEVER_PLAATS")
                this.uitgever += " : " + this.localeManager.getString("APA_ZONDER_UITGEVER")
            } else {
                this.uitgever = this.uitgever.split("<br />")[0]; //indien meer dan 1 uitgever enkel de eerste tonen
                //geen plaats
                let split = this.uitgever.split(" : ")
                if (split.length === 1) {
                    this.uitgever = this.localeManager.getString("APA_ZONDER_UITGEVER_PLAATS") + " : " + split[0]
                }

                this.uitgever += "."
            }
        }

        //enkel een punt indien laatste karakter geen leesteken is
        //wordt niet gerendered indien null
        if (this.useTitel && this.titel && this.titel !== "") {
            if (!this.titel.match(/[.,:!?]$/)) {
                this.titel += "."
            }
        }

        let assArr: string[] = []
        let auteurFound = this.auteur && this.auteur !== ""

        //auteurs of titel
        if (auteurFound) {
            assArr.push(this.auteur)
        } else {
            assArr.push(this.titel)
        }

        //jaar
        //jaar wordt altijd meegegeven
        if (!this.jaar || this.jaar === "") this.jaar = this.localeManager.getString("APA_ZONDER_JAAR")
        assArr.push(`(${this.jaar}).`)

        //titel
        if (auteurFound) {
            assArr.push(this.titel)
        }

        //uitgever
        if (this.uitgever)
            assArr.push(this.uitgever)

        //doi
        if (this.useDoi && this.doi && this.doi !== "")
            assArr.push("doi: " + this.doi)

        //link
        if (this.useLink && this.link && this.link !== '') {
            moment.locale(this.localeManager.locale);
            const dateString = moment().format('D MMMM YYYY')
            assArr.push(this.localeManager.getString('APA_GERAADPLEEGD_OP').replace('%datum%', dateString))
            assArr.push(`<a href="${this.link}" target="_blank" rel="nofollow noreferrer">${this.link}</a>`)
        }

        return assArr.join(" ")
    }

    private buildTitel(zoekresultaat): string {

        let tss: string = ""

        if (zoekresultaat.publicatietype !== PublicatieType.ARTIKEL){
            tss = `<em>${zoekresultaat.fullTitel}</em>`
            if (zoekresultaat.druk) tss += ` (${zoekresultaat.druk})`
            return tss
        }

        if (zoekresultaat.fullTitel) tss += zoekresultaat.fullTitel + ". "



        if (zoekresultaat.tijdschrift) tss += `<em>${zoekresultaat.tijdschrift}</em>`
        if (zoekresultaat.jaargang) tss += `, <em>${zoekresultaat.jaargang}</em>`
        if (zoekresultaat.jaargangnummer) tss += "(" + zoekresultaat.jaargangnummer + ")"
        if (zoekresultaat.bladzijden) {
            //soms staat er 3-5, soms p. 3-5
            let blza = zoekresultaat.bladzijden.replace("p. ", "")
            blza = blza.split(" ")
            tss += ", " + blza[0]
        }

        if (tss !== "") tss += "."

        return tss
    }

    private buildAuteur(baseArray) {

        if (!baseArray) return ''

        let rs = ""

        //we moeten eerste alle converteren en dan pas aaneenplakken
        let convArr: Array<string> = []

        baseArray.forEach(value => {
            value = this.convertVoornamen(value)
            if (value !== "") convArr.push(value)
        })

        const l = convArr.length
        //wanneer er meer dan 7 auteurs zijn:
        // meer dan 7 auteurs de eerste zes vermeld worden &, 3 puntjes en dan de laatste auteur

        convArr.forEach((value, index) => {
            if (l > 7) {
                if (index < 6) rs += value + ", "; //1 tot en met 6
                if (index === l - 2) rs += "& ... "
                if (index === l - 1) rs += value
            } else if (l > 1) {
                rs += value
                if (index < l - 2) rs += ", "
                if (index === l - 2) rs += ", & "
            } else {
                rs += value
            }
        })


        //punt na rs indien eindigt op functie
        if (rs.charAt(rs.length - 1) === ")") rs += "."

        //
        /*if (rs === "")
            rs = this.localeManager.getString("APA_ZONDER_NAAM");*/

        return rs
    }

    private convertVoornamen(auteur) {
        if (!auteur || auteur === "")
            return ""

        //et al
        if (auteur.indexOf("et al") > -1 || auteur.indexOf("E.a.") > -1)
            return ""; //"et al."

        let naamZonderFunctie = auteur.replace(/\(.*\)/g, ""); //alles tussen haakjes weg
        let naamArray = naamZonderFunctie.split(",")

        if (naamArray.length < 2)
            return auteur

        let functie = this.functieAfkorten(auteur.match(/\(.*\)/g))

        let voornaam = naamArray[1]
        //let eersteLetters = voornaam.match(/\b(\w)/g); //eerste letters van de naam
        //let afgekorteVoornaam = eersteLetters ? eersteLetters.join('. ') + "." : ""

        //voornamen kunnen al afgekort zijn en zonder spaties, bv. J.M.
        //dat moeten we vermijden, dus spaties na punten
        voornaam = voornaam.replace(/\./g, ". ")

        let voornamen = voornaam.split(" ")
        let eersteLetters: Array<string> = []
        voornamen.forEach(function (vn) {
            let eersteLetter: string = vn.charAt(0)
            if (eersteLetter !== "")
                eersteLetters.push(eersteLetter)
        })
        let afgekorteVoornaam = eersteLetters ? eersteLetters.join('. ') + "." : ""
        let naamOutput = naamArray[0] + (afgekorteVoornaam ? ", " + afgekorteVoornaam : "") + (functie ? " " + functie : "");


        return naamOutput
    }

    private functieAfkorten(functie) {

        if (!functie)
            return null

        if (functie.length === 0)
            return null

        functie = functie[0]

        let afk = this.localeManager.getString("APA_REDACTEUR")
        if (functie.indexOf("redacteur") > -1) return "(" + afk + ")"

        return "";
    }
}
