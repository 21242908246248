import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Image, Modal, Segment, Icon, Popup} from "semantic-ui-react";
import moment from 'moment'
import Parser from 'html-react-parser'
import {ApplicationPath} from 'med-class'

const BUBBLES = ['thumbs', 'heart', 'smile']

const ChatlineRenderer = ({chatline, onChatlineClick, chatService, eigenaar}) => {

    const [modalOpen, setModalOpen] = useState(false)
    const [bubblesVisible, setBubblesVisible] = useState(false)
    const [roleClassName, setRoleClassName] = useState()
    const [bubbleData, setBubbleData] = useState()

    useEffect(() => {
        let cn
        switch (chatline.data().bron) {
            case 'web':
                cn = 'chat-line left'
                break
            case 'bo':
            case 'faq':
                cn = 'chat-line right'
                break
            case 'bot':
                cn = 'chat-line-bot chat-line right'
                break
            default:
                cn = 'chat-status-message'
                break

        }

        setRoleClassName(cn)
    }, [chatline])

    useEffect(() => {
        let bubbleData = {thumbs: {eigenaars: [], count: 0}, heart: {eigenaars: [], count: 0}, smile: {eigenaars: [], count: 0}, frown: {eigenaars: [], count: 0}}
        const bubbleProp = chatline.data().bubble
        if (bubbleProp) {
            for (const bubbleKey in bubbleProp) {
                const bubbleVal = bubbleProp[bubbleKey]
                bubbleData[bubbleVal]['count']++
                bubbleData[bubbleVal].eigenaars.push(bubbleKey)
            }
        }
        setBubbleData(bubbleData)
    }, [chatline])

    const onMouseEnter = () => {
        //if (bubblesVisible) return
        setBubblesVisible(true)
        //setTimeout(() => setBubblesVisible(true), 500)
    }

    const onMouseLeave = () => {
        setBubblesVisible(false)
    }

    const onBubbleClick = (event, {name}) => {
        setBubblesVisible(false)
        chatService.toggleBubble(chatline, name.split(' ')[0], eigenaar)
    }

    const getBubbleIconName = (bubble) => {
        switch (bubble) {
            case 'thumbs':
                return 'thumbs up'
            default:
                return bubble
        }
    }

    const getBubbleIconColor = (bubble) => {
        switch (bubble) {
            case 'thumbs':
                return 'teal'
            case 'heart':
                return 'red'
            case 'smile':
                return 'yellow'
            case 'frown':
                return 'orange'
            default:
                return ''
        }
    }

    const getBubbleOverview = (bubble) => {
        return <div>
            <Icon name={getBubbleIconName(bubble)}
                  color={getBubbleIconColor(bubble)} size='small'><span style={{marginLeft: '5px'}}>{bubble}</span></Icon>
            {bubbleData[bubble].eigenaars.map((e, index) => {
                return <p key={index}>{e}</p>
            })}
        </div>
    }

    if (chatline.data().bron === 'status') return <div className='chat-status-message'>{chatline.data().inhoud}</div>

    const isImage = chatline && chatline.data().type && chatline.data().type.indexOf('image') > -1 || chatline.data().type.indexOf('pdf') > -1
    const tijd = chatline.data().timestamp && moment(chatline.data().timestamp.milliseconds).format('HH:mm')


    return (
        <div className={roleClassName} onMouseLeave={onMouseLeave}>

            {chatline.data().bron !== 'bot' && <Image avatar src={ApplicationPath.avatarApi + chatline.data().auteur}/>}
            {chatline.data().bron === 'bot' && <Image size='mini' src={ApplicationPath.mediatheekAssetsFolder + 'icon/icon-chat.png'}/>}

            <div style={{width: '10px'}}/>

            <div style={{position: 'relative'}} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>

                <div className='chat-line-content'>


                    <div className='chat-bubble-content'>

                        {BUBBLES.map((bubble, index) => {
                            if (!(bubbleData && bubbleData[bubble].count)) return null
                            return (
                                <Popup key={index} basic mouseEnterDelay={500} position="bottom right" trigger={
                                    <div>
                                        <Icon
                                            size='large'
                                            name={getBubbleIconName(bubble)}
                                            color={getBubbleIconColor(bubble)} />
                                        <span style={{marginRight: '3px'}}>{bubbleData[bubble].count}</span>
                                    </div>
                                }>
                                    {getBubbleOverview(bubble)}
                                </Popup>

                            )
                        })}

                    </div>

                    {tijd && <div className='chat-line-tijd'>{tijd}</div>}

                    {!isImage && !chatline.data().url && <p onClick={() => onChatlineClick && onChatlineClick(chatline)}>{Parser(chatline.data().inhoud)}</p>}
                    {!isImage && chatline.data().url && <p><a href={chatline.data().url} download>{Parser(chatline.data().inhoud)}</a></p>}
                </div>

                {isImage && <Image size='medium' src={chatline.data().url} onClick={() => setModalOpen(true)}/>}

                <div className="chat-bubble-icons" style={{visibility: bubblesVisible ? 'visible' : 'hidden'}}>

                    {BUBBLES.map((bubble, index) => {
                        return <Icon key={index} className='chat-bubble-icon' circular inverted link
                                     name={getBubbleIconName(bubble)}
                                     color={getBubbleIconColor(bubble)} size='small'
                                     onClick={onBubbleClick}/>
                    })}
                </div>
            </div>


            <Modal open={modalOpen} dimmer='inverted'
                   onClose={() => setModalOpen(false)}>
                <Modal.Content>
                    <Image wrapped src={chatline.data().url}/>
                    <Segment><a href={chatline.data().url} download>{Parser(chatline.data().inhoud)}</a></Segment>
                </Modal.Content>
            </Modal>
        </div>
    )
}

export default ChatlineRenderer

ChatlineRenderer.propTypes = {
    chatline: PropTypes.object.isRequired,
    chatService: PropTypes.object.isRequired,
    eigenaar: PropTypes.string.isRequired,
    onChatlineClick: PropTypes.func
}

ChatlineRenderer.defaultProps = {}