import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Form, Checkbox, Segment} from 'semantic-ui-react'
import {SaveBar} from '@ahsmediatheek/ui-component'
import {LocaleDataService} from "@ahsmediatheek/core";
import {LocaleContentViewer} from '@ahsmediatheek/med-component-locale-content'

const UrgentNieuws = ({userService, nieuwsService}) => {

    const [isDirty, setIsDirty] = useState()
    const [urgentNieuws, setUrgentNieuws] = useState()
    const [body, setBody] = useState('')
    const [online, setOnline] = useState(false)
    const [user, setUser] = useState()
    const [localeDataServiceUrgentNieuws] = useState(new LocaleDataService('urgentNieuws', ['body']))
    const [localeContent, setLocaleContent] = useState()
    const [changedLocales] = useState([])

    useEffect(() => {
        nieuwsService.getUrgentNieuws().then(res => {
            setUrgentNieuws(res)
            setBody(res.body)
            setOnline(res.online === 1)
            setUser(userService.userIdLookup[res.user_wijziging].naam)

            localeDataServiceUrgentNieuws.getLocaleData(res.id)
                .then(({localeContent}) => {
                    console.log('LC', localeContent)
                    setLocaleContent(localeContent)
                    }
                )

        })
    }, [nieuwsService, userService, localeDataServiceUrgentNieuws])

    /*const onChange = (event, {name, value}) => {
        setIsDirty(true)
        setBody(value)
    }*/

    const onOnlineCheckBoxChange = (event, {name, checked}) => {
        setIsDirty(true)
        setOnline(checked)
    }



    const onLocaleContentChange = (localeContentObject, data, plainText) => {

        //parameters moeten nu correct zijn. LocaleContentViewer moet correct werken

        localeContentObject.plainText = plainText
        localeContentObject.content = data
        setIsDirty(true)
        if (changedLocales.indexOf(localeContentObject.id) < 0)
            changedLocales.push(localeContentObject.id)
    }



    const onCancel = () => {
        setIsDirty(false)
        setBody(urgentNieuws.body)
        setOnline(urgentNieuws.online)
    }

    const onSubmit = (...args) => {
        setIsDirty(false)
        setUser(userService.userIdLookup[urgentNieuws.user_wijziging].naam)


        if (changedLocales && changedLocales.length > 0) {
            for (let localeContentObject of changedLocales) {
                delete(localeContentObject.plainText)
            }

        let cLocales = localeContent.filter(item => changedLocales.indexOf(item.id) > -1)
        console.log('SAVE', localeContent)
            localeDataServiceUrgentNieuws.saveLocaleData(cLocales).then(result => {
                console.log('SAVED', result)
            })
        }

        urgentNieuws.body = body
        urgentNieuws.online = online ? 1 : 0
        urgentNieuws.user_wijziging = userService.loginUser.id
        nieuwsService.updateUrgentNieuws(urgentNieuws)
    }
    return (
        <div className='detail-group'>
            <Form style={{width: '100%'}} size='tiny' onSubmit={onSubmit}>
                <Form.Field>
                    <h3>Dringend nieuws wordt bovenaan op ons webplatform prominent getoond. Gebruik dit enkel om <strong>tijdelijk</strong> iets aan te kondigen.</h3>
                </Form.Field>

                {/*<Form.TextArea name='body' value={body} label='Tekst' onChange={onChange}/>*/}



                {localeContent &&
                <Segment color='teal' padded='very'>
                    <Form.Field>
                    <label>Tekst</label>
                    <LocaleContentViewer placeholder='Body'
                                         localeContent={localeContent}
                                         referenceId={urgentNieuws.id}
                                         dataField='body'
                                         onChange={onLocaleContentChange}/>
                </Form.Field></Segment>
                }

                <Form.Field>
                    <label>Online</label>
                    <Checkbox size='tiny'
                              checked={online}
                              name='online'
                              label='Nieuws is online'
                              onChange={onOnlineCheckBoxChange}/>
                </Form.Field>

                <Form.Field>
                    <label>Laatst gewijzigd door {user}</label>
                </Form.Field>
            </Form>


            {isDirty &&
            <SaveBar onCancelClick={onCancel} onSaveClick={onSubmit}/>
            }

        </div>
    )
}

export default  UrgentNieuws

UrgentNieuws.propTypes = {

}

UrgentNieuws.defaultProps = {
    userService: PropTypes.object.isRequired,
    nieuwsService: PropTypes.object.isRequired,
}
