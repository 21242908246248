import {BidocUitlening} from "./BidocUitlening";

export class BidocUitleenData {
	
	_explicitType: "BidocUitleendata"

	status: number
	lenersnummer: string
	uitleningen: BidocUitlening[]
	verlengAlle: string
	boete: number
	
	
}
