export class TaxonomieItem {

	_explicitType:string = 'TaxonomieItem'

	id: number = 0
	taxonomie: number = 0
	code: string = ''
	wdCode: string = ''
	label: string = ''
    toelichting: string = ''
    sort: number = 0

    get value(){
	    return this.code !== '' ? this.code : this.label
    }
}
