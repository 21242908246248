

//const BACKGROUND = 'bg'
const RECT_ORIG = 'rOrig'
//const RECT_COPY = 'rCopy'
//const RECT_ORIG_ROTATE = 'rOrigRotate'
//const RECT_COPY_ROTATE = 'rCopyRotate'



export class SvgController {

    svg

    /*constructor() {
    }*/

    init = (svg) => {
        this.svg = svg

        const degrees = 10
        this.rotate(RECT_ORIG, degrees)
        //this.rotate(RECT_COPY, degrees)
        //this.rotate(RECT_ORIG_ROTATE, degrees)
        //this.rotate(RECT_COPY_ROTATE, degrees)
    }

    rotate = (rect, degrees) => {
        const rectOrig = this.getLayerOrElement(rect)
        const mid = this.getMid(rectOrig, this.svg)
        //const transform = d3.select(rectOrig).attr('transform')
        //d3.select(rectOrig).attr('transform', `rotate(${degrees} ${mid.x} ${mid.y})`)
        const matrix = rectOrig.transform.baseVal.consolidate().matrix
        const midX = mid.x - matrix.e
        const midY = mid.y - matrix.f
            console.log('RO', rectOrig, matrix, midX, midY, mid)
        //rectOrig.matrixTransform(this.svg.getCTM().inverse().multiply(ctm))
        rectOrig.setAttribute('transform', `rotate(${degrees} ${midX} ${midY})`)
    }

    getLayerOrElement = (layername) => {
        return this.svg.getElementById(layername)
    }

    showLayer = (layername) => {
        const el = this.getLayerOrElement(layername)
        el.style.display = ''
    }

    hideLayer = (layername) => {
        const el = this.getLayerOrElement(layername)
        el.style.display = 'none'
    }

    //magic methods
    getMid(rect, svg) {
        const point = svg.createSVGPoint();
        point.x = rect.x.baseVal.value + rect.width.baseVal.value / 2;
        point.y = rect.y.baseVal.value + rect.height.baseVal.value / 2;
        //lifesaver: https://stackoverflow.com/questions/47990205/connect-2-svg-elements/47993299#47993299
        return point.matrixTransform(this.svg.getCTM().inverse().multiply(rect.getCTM()));
    }

    getMidOfGroup(group, svg) {
        const point = svg.createSVGPoint();
        const bbox = group.getBBox()
        const ctm = group.getCTM()
        point.x = bbox.x + bbox.width / 2;
        point.y = bbox.y + bbox.height / 2;
        //lifesaver: https://stackoverflow.com/questions/47990205/connect-2-svg-elements/47993299#47993299
        return point.matrixTransform(this.svg.getCTM().inverse().multiply(ctm));
    }
}