import React from 'react'
import {Divider} from 'semantic-ui-react'
import AbstractZoekresultaatRenderer from "./AbstractZoekresultaatRenderer";
import AuteursRenderer from "./AuteursRenderer";
import TrefwoordenRenderer from "./TrefwoordenRenderer";
import ApaRenderer from "./ApaRenderer";
import MinMaxRenderer from "./MinMaxRenderer";
import TijdsduurRenderer from "./TijdsduurRenderer";
import LinkRenderer from "./LinkRenderer";
import OpleidingRenderer from "./OpleidingRenderer";
import GebruikersgegevensRenderer from "./GebruikersgegevensRenderer";
import GenreRenderer from "./GenreRenderer";
import HoofdtitelRenderer from "./HoofdtitelRenderer";
import DeeltitelsRenderer from "./DeeltitelsRenderer";
import OpmerkingRenderer from "./OpmerkingRenderer";
import BidocKoppelingRenderer from "./BidocKoppelingRenderer";
import HandleidingUrlRenderer from "./HandleidingUrlRenderer";
import WerkvormenUrlRenderer from "./WerkvormenUrlRenderer";
import JaargangnummerRenderer from "./JaargangnummerRenderer";
const Parser = require('html-react-parser')

export class RendererFactory {

    static create = (property, props) => {
        //{key, localeManager, zoekresultaat}

        switch(property){
            case 'DIVIDER':
                return <Divider key={props.key}/>
            case 'aantal_spelers':
                return <MinMaxRenderer key={props.key} {...props}/>
            case 'tijdsduur':
                return <TijdsduurRenderer key={props.key} {...props}/>
            case 'auteurs':
                return <AuteursRenderer key={props.key} {...props}/>
            case 'mediatheek':
                //voorlopig uitgeschakeld omdat deze info dezelfde is als in exemplaar!
                return null //<MediatheekRenderer key={props.key} {...props}/>
            case 'ebscoLink':
                //return <EbscolinkRenderer key={props.key} {...props}/>
                return null
            case 'exemplaar':
                //wordt apart gerendered
                return null  //<ExemplaarRenderer key={props.key} {...props}/>
            case 'trefwoorden':
                return <TrefwoordenRenderer key={props.key} {...props}/>
            case 'genre':
                return <GenreRenderer key={props.key} {...props}/>
            case 'directe_Link':
                //uitgeschakeld want zit allemaal in SocialContent
                return null // <DirecteLinkRenderer key={props.key} {...props}/>
            case 'jaargangnummer':
                return <JaargangnummerRenderer key={props.key} {...props}/>
            case 'link':
                return <LinkRenderer key={props.key} {...props}/>
            case 'apa':
                return <ApaRenderer key={props.key} {...props}/>
            case 'beschrijving':
                return null //beschrijving wordt naast de afbeelding getoond
            case 'opleiding':
                return <OpleidingRenderer key={props.key} {...props}/>
            case 'hoofdtitel':
                return <HoofdtitelRenderer key={props.key} {...props}/>
            case 'deeltitels':
                return <DeeltitelsRenderer key={props.key} {...props}/>
            case 'opmerking':
                return <OpmerkingRenderer key={props.key} {...props}/>

                //Gebruikergegevens: gebruikersnaam en paswoord samen renderen in één component
            case 'titel': //wordt bovenaan apart gerendered
            case 'paswoord':
            case 'wachtwoord':
                return null
            case 'gebruikersnaam':
                return <GebruikersgegevensRenderer key={props.key} {...props}/>
            case 'bidocKoppeling':
                return <BidocKoppelingRenderer key={props.key} {...props}/>
            case 'handleidingUrl':
                return <HandleidingUrlRenderer key={props.key} {...props}/>
            case 'werkvormenUrl':
                return <WerkvormenUrlRenderer key={props.key} {...props}/>
            default:
                if (!props.zoekresultaat[property] || props.zoekresultaat[property] === '') return null
                const type = typeof props.zoekresultaat[property]
                let value = type === 'string' || type === 'number' ? props.zoekresultaat[property] : 'GEEN RENDERER'
                value = value.replace(/\n/gm, '<br />')
                const localizedValue = props.localeManager.propertyExists(value) ? props.localeManager.getString(value) : value
                return <AbstractZoekresultaatRenderer key={props.key} label={props.localeManager.getString(property)}>{Parser(localizedValue)}</AbstractZoekresultaatRenderer>
        }
    }
}