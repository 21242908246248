export class Instelling {

    id: number = 0
    userId: number = 0
    mail: string = ''
    tewerkstellingspercentage: number = 100
    baliepercentage: number = 100
    ankerbalie: string = ''
    balies: string = ''
    //templates: string = "{}"
    chatpercentage: number = 100

    //niet in database
    gepresteerdeUren: number = 0
    tePresterenUren: number = 0
    prestatieFactor: number = 0
    prestatieFactorIndex: number = 0

    chatPrestatieFactor: number = 1
    gepresteerdeChatUren: number = 0
    tePresterenChatUren: number = 0
    gemiddeldPerWeek: number = 0


    /*getTemplate = (day) => {
        const templateObject = JSON.parse(this.templates)
        if (templateObject[day]) return templateObject[day]
        return null
    }*/

    /*setTemplate = (day, dateString) => {
        const templateObject = JSON.parse(this.templates)
        templateObject[day] = dateString
        this.templates = JSON.stringify(templateObject)
    }*/

    get baliePrestatiePercentage(){
        if (this.baliepercentage === 0) return 0
        return this.tewerkstellingspercentage*this.baliepercentage/100
    }

    get chatPrestatiePercentage(){
        if (this.chatpercentage === 0) return 0
        return this.tewerkstellingspercentage*this.chatpercentage/100
    }
}
