import {ApplicationPath} from "../domain/path";

import moment from 'moment'
const axios = require('axios')
const _ = require('lodash')


export class StatistiekService {


    private readonly TELLING_TABLE: string = 'telling'
    private readonly TELLING_DAG_TABLE: string = 'telling_dag'
    private readonly TELLING_UUR_TABLE: string = 'telling_uur'
    private readonly TELLING_MINUUT_TABLE: string = 'telling_minuut'

    apiKey: string
    apiUrl: string
    initialized: boolean = false

    constructor(apiUrl, apiKey) {
        this.apiKey = apiKey
        this.apiUrl = apiUrl
    }

    getFormBuildData = () => {
        return new Promise((resolve, reject) => {
            const url = `${ApplicationPath.tellerStatsApiUrl}?function=getFormBuildData`
            console.log('URL', url)
            axios.get(url)
                .then(response => {
                    resolve(response.data.data)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        })
    }

    getGraphData = (first?, last?, campus?, group?, method?) => {

        /*
        *
        * first=date
last=date
campus=csv-list (knt,kat)
group=[hour,day,month,year]
method=[average,absolute]
* */

        return new Promise((resolve, reject) => {

            if (!last) last = moment().format('YYYY-MM-DD')
            if (!first) first = moment().startOf('month').format('YYYY-MM-DD')
            if (!campus) campus = 'kat,knt,sap,mar'
            if (!group) group = 'month'
            if (!method) method = 'absolute'

            let url = `${ApplicationPath.tellerStatsApiUrl}?function=getGraphData&first=${first}&last=${last}&campus=${campus}&group=${group}&method=${method}`


            axios.get(url)
                .then(response => {
                    resolve(response.data.data)

                })
                .catch(error => {
                    console.error('GET GRAPH DATA ERROR', error)
                    reject(error)
                })
        })
    }

    getTellingBetween = (from, to) => {

        let me = this

        return new Promise(function (resolve, reject) {
            //https://www.arteveldehogeschool.be/mediatheken/api/v1/quote/?token=qwertyuiopasdfghjklzxcvbnm&transform=1&order[]=quote
            //from and to are moments

            from = from.format('YYYY-MM-DD');
            to = to.format('YYYY-MM-DD');

            let url = `${me.apiUrl}${me.TELLING_DAG_TABLE}?transform=1&token=${me.apiKey}&filter[]=datum,ge,${from}&filter[]=datum,lt,${to}`;
            axios.get(url)
                .then(response => {
                    const data = response.data.telling_dag;
                    const tellingLookup = {};

                    for (const telling of data) {
                        if (!tellingLookup[telling.campus])
                            tellingLookup[telling.campus] = {campus: telling.campus, total: 0, data: [], fill: 'red'};

                        tellingLookup[telling.campus].total += telling.bezoekers;
                        //tellingLookup[telling.campus].data.push(convertObjectToClass(telling));
                    }

                    let chartDataArray = [];
                    _.forOwn(tellingLookup, (value) => chartDataArray.push(value))
                    chartDataArray = _.orderBy(chartDataArray, 'campus')
                    resolve(chartDataArray)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });

        });
    }
}