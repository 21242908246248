import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Input, Segment, Header, Icon, Popup} from 'semantic-ui-react'

const DataList = ({filteredCollection, onSearchChange, onAddButtonClick, onDeleteButtonClick, addButtonTootltip,
                      selectedItem, onItemSelect, itemIdField, itemLabelFunction, deleteButtonTooltip, rowRendererStyleFunction}) => {

    const [searchString, setSearchString] = useState('')


    const onSearchStringChange = (event, {value}) => {
        setSearchString(value.toLowerCase())
        onSearchChange && onSearchChange(value.toLowerCase())
    }

    const onSearchFieldClear = () => {
        setSearchString('')
        onSearchChange && onSearchChange('')
    }

    return (
        <div style={{height: '100%', overflow: 'auto', paddingTop: '10px', width: '300px', minWidth: '300px', fontSize: '0.9em'}}>


            <Input fluid placeholder='Typ om te filteren'
                   value={searchString}
                   onChange={onSearchStringChange}
                   icon={<Icon name='close' circular link onClick={onSearchFieldClear}/>}/>

            <Segment>
                <Popup inverted size='mini' mouseEnterDelay={500} trigger={
                    <Icon name='plus' link circular inverted onClick={onAddButtonClick}/>
                }>
                    {addButtonTootltip}
                </Popup>


            </Segment>

            <Header as='h3' dividing>Resultaten: {filteredCollection.length}</Header>

            {filteredCollection.map(item => {
                const isSelected = selectedItem && item[itemIdField] === selectedItem[itemIdField]
                const cn = `virtualListItemRenderer ${isSelected && 'itemRendererSelected'} ${rowRendererStyleFunction && rowRendererStyleFunction(item)}`

                return <div key={item[itemIdField]} style={{display: 'flex', justifyContent: 'space-between'}}
                            className={cn} onClick={() => onItemSelect(item)}>
                    {itemLabelFunction(item)}

                    {isSelected && <Popup inverted size='mini' mouseEnterDelay={500} trigger={
                        <Icon name='trash' color='red' link circular inverted onClick={onDeleteButtonClick}/>
                    }>
                        {deleteButtonTooltip}
                    </Popup>}

                </div>
            })}
        </div>
    )
}

export default  DataList

DataList.propTypes = {
    filteredCollection: PropTypes.array.isRequired,
    onSearchChange: PropTypes.func,
    onItemSelect: PropTypes.func,
    onAddButtonClick: PropTypes.func,
    onDeleteButtonClick: PropTypes.func,
    addButtonTootltip: PropTypes.string,
    selectedItem: PropTypes.object,
    itemIdField: PropTypes.string,
    itemLabelFunction: PropTypes.func,
    deleteButtonTooltip: PropTypes.string,
    rowRendererStyleFunction: PropTypes.func
}

DataList.defaultProps = {
    addButtonTootltip: 'Voeg item toe',
    deleteButtonTooltip: 'Verwijder item',
    itemIdField: 'id'
}