import {convertObjectToClass} from "../util/ClassConvertor";
import {Nieuws} from "../class/nieuws/Nieuws";
const axios = require('axios')
const _ = require('lodash')
const moment = require('moment')


export class NieuwsService {

    private readonly NIEUWS_TABLE: string = 'news'
    private readonly URGENT_NIEUWS_TABLE: string = 'urgentNews'

    initialized: boolean = false
    nieuwsList: Array<Nieuws>
    nieuwsLookup: object
    apiUrl: string
    apiKey: string
    onlineNieuws: Array<Nieuws>

    constructor(apiUrl: string, apiKey: string) {
        this.apiUrl = apiUrl
        this.apiKey = apiKey
        this.nieuwsList = []
        this.nieuwsLookup = {}
    }


    //=====================================================================================
    //=====================================================================================
    //     BO
    //=====================================================================================
    //=====================================================================================

    init = () => {

        //moet enkel voor de bo, niet voor de website!

        let me = this

        return new Promise((resolve) => {

                this.getNieuwsList().then((response: any) => {

                    me.initialized = true
                    me.nieuwsList = response.nieuwsList
                    me.nieuwsLookup = response.nieuwsLookup

                    resolve({status: 'ok'})
                }).catch((error) => {
                    console.log('ERROR IN Nieuws INIT', error)
                })
            }
        )

    }

    private getNieuwsList() {

        let me = this

        return new Promise(function (resolve, reject) {
            let url = `${me.apiUrl}${me.NIEUWS_TABLE}?transform=1&token=${me.apiKey}`
            axios.get(url)
                .then(response => {
                    console.log('NIEUWS', response)
                    let rawNieuwsList = response.data[me.NIEUWS_TABLE];
                    let nieuwsList = rawNieuwsList.map((rt: any) => convertObjectToClass(rt, 'Nieuws'))
                    nieuwsList = _.orderBy(nieuwsList, [nieuws => nieuws.title.toLowerCase()])
                    let nieuwsLookup = _.keyBy(nieuwsList, nieuws => nieuws.id)
                    resolve({nieuwsList, nieuwsLookup})
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    updateNieuws(nieuws) {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.NIEUWS_TABLE}/${nieuws.id}?&token=${me.apiKey}`;
                axios.put(url, nieuws)
                    .then(() => {
                        //remap the nieuws
                        nieuws = convertObjectToClass(nieuws, 'Nieuws');
                        me.nieuwsList = me.nieuwsList.map(t => {
                            if (t.id === nieuws.id)
                                return nieuws;
                            return t;
                        })
                        me.nieuwsLookup = _.keyBy(me.nieuwsList, nieuws => nieuws.id)
                        resolve({nieuwsList: me.nieuwsList, nieuwsLookup: me.nieuwsLookup})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    createNieuws(nieuws) {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.NIEUWS_TABLE}/?token=${me.apiKey}`
                axios.post(url, nieuws)
                    .then(response => {
                        nieuws.id = response.data;
                        me.nieuwsList = _.concat(me.nieuwsList, nieuws)
                        me.nieuwsList = _.orderBy(me.nieuwsList, [nieuws => nieuws.title.toLowerCase()])
                        me.nieuwsLookup = _.keyBy(me.nieuwsList, nieuws => nieuws.id)
                        resolve({nieuwsLookup: me.nieuwsLookup, nieuwsList: me.nieuwsList, nieuws})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    deleteNieuws(nieuws) {

        let me = this

        let deleteImageUrl = 'https://www.arteveldehogeschool.be/mediatheken/root/boDeleteImage.php'
        let deleteImageDetail = {fileUrl: nieuws.imagePath, mode: 'nieuwsImage', id: nieuws.id}

        return new Promise((resolve, reject) => {
                const url = `${me.apiUrl}${me.NIEUWS_TABLE}/${nieuws.id}?token=${me.apiKey}`

                axios.delete(url)
                    .then(() => {

                        //remove from nieuwsList and lookup
                        me.nieuwsList = _.filter(me.nieuwsList, t => t.id !== nieuws.id)
                        me.nieuwsLookup = _.keyBy(me.nieuwsList, nieuws => nieuws.id)

                        axios.post(deleteImageUrl, deleteImageDetail)
                            .then(response => {
                                console.log('DELETE RESPONSE', response)
                            });

                        resolve({nieuwsList: me.nieuwsList, nieuwsLookup: me.nieuwsLookup})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    deleteNieuwsImage = (nieuws) => {

    }



    //=====================================================================================
    //=====================================================================================
    //     URGENT NEWS
    //=====================================================================================
    //=====================================================================================

    private getUrgentNieuws = () => {

        let me = this

        return new Promise(function (resolve, reject) {
            let url = `${me.apiUrl}${me.URGENT_NIEUWS_TABLE}/1?transform=1&token=${me.apiKey}`
            axios.get(url)
                .then(response => {
                    resolve(response.data)
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    updateUrgentNieuws = (urgentNieuws) => {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.URGENT_NIEUWS_TABLE}/1?&token=${me.apiKey}`;
                axios.put(url, urgentNieuws)
                    .then(() => {
                        resolve('ok')
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    //=====================================================================================
    //=====================================================================================
    //     WEBSITE
    //=====================================================================================
    //=====================================================================================



    private getOnlineNieuws() {

        let me = this

        return new Promise(function (resolve, reject) {
            const now = moment().format('YYYY-MM-DD')
            let url = `${me.apiUrl}${me.NIEUWS_TABLE}?transform=1&token=${me.apiKey}&filter[]=online,eq,1&filter[]=fromDate,lte,${now}&filter[]=untilDate,gt,${now}`
            //url = `${me.apiUrl}${me.NIEUWS_TABLE}?transform=1&token=${me.apiKey}&filter[]=date,gt,2019-09-18`
            axios.get(url)
                .then(response => {
                    let rawNieuwsList = response.data[me.NIEUWS_TABLE];
                    let onlineNieuws = rawNieuwsList.map((rt: any) => convertObjectToClass(rt, 'Nieuws'))
                    resolve({onlineNieuws})
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }
}
