import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import {Segment} from 'semantic-ui-react'
import {ApplicationPath} from 'med-class'
import {VirtualBaseList, ListFilter} from 'med-react-component'
import {MapController} from "../class/MapController";


export default class MediatheekMap extends Component {


    constructor(props) {
        super(props)
        this.state = {
            selectedRubriek: null,
            mapController: null
        }
    }

    static getDerivedStateFromProps({selectedRubriek}, {mapController}){
        if (mapController && selectedRubriek) mapController.selectedRubriek = selectedRubriek
        if (selectedRubriek) return {selectedRubriek}

        return null
    }

    onSvgLoaded = (error, svg) => {

        const {rubriceringService, mediatheek, localeManager} = this.props

        if (error) {
            console.error(error)
            return
        }

        const mapController = new MapController(
            svg,
            mediatheek,
            rubriceringService.rubrieken,
            rubriceringService.rubriekKasten,
            localeManager)
        mapController.onFilteredRubriekenUpdate = this.updateFilteredRubrieken
        this.setState({mapController})
    }

    updateFilteredRubrieken = (rubrieken) => {
        this.setState({mapController: this.state.mapController})
    }

    onRubriekSelect = (rubriek) => {
        this.setState({selectedRubriek: rubriek})
    }

    onItemHoverIn = (item) => {
        const { mapController } = this.state
        mapController.selectedRubriek = item
    }

    onItemHoverOut = () => {
        const { mapController } = this.state
        mapController.selectedRubriek = null
    }

    rubriekLabelFunction = (rubriek) => {
        return `${rubriek.rubriek} - ${rubriek.label}`
    }

    searchFieldSourceFunction = (rubriek) => {
        return this.rubriekLabelFunction(rubriek).toLowerCase()
    }

    render() {
        const {selectedRubriek, mapController} = this.state
        const {mediatheek, showLegende} = this.props

        if (!mediatheek) return null

        return (
            <div style={{display: 'flex', height: '100%', minHeight: '600px'}}>
                <Segment style={{width: '100%', padding: '20px'}}>
                    <ReactSVG
                        src={`${ApplicationPath.mediatheekSvgMapsFolder}${mediatheek}.svg`}
                        onClick={this.onSvgClick}
                        className='map-svg'
                        afterInjection={this.onSvgLoaded}/>
                </Segment>
                {(mapController && showLegende) &&
                <div className='map-kast' style={{minWidth: '400px', transform: 'scale(0.9)'}}>
                    <VirtualBaseList
                        dataProvider={mapController.filteredRubrieken}
                        labelFunction={this.rubriekLabelFunction}
                        selectedItem={selectedRubriek}
                        onItemSelect={this.onRubriekSelect}
                        deleteableFunction={() => false}
                        searchFieldSourceFunction={this.searchFieldSourceFunction}
                        filterOpenOnStart
                        onItemHoverIn={this.onItemHoverIn}
                        onItemHoverOut={this.onItemHoverOut}
                    >

                        <ListFilter/>

                    </VirtualBaseList></div>}
            </div>
        )
    }
}

MediatheekMap.propTypes = {
    rubriceringService: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    mediatheek: PropTypes.string,
    showLegende: PropTypes.bool,
    selectedRubriek: PropTypes.object
}

MediatheekMap.defaultProps = {
    mediatheek: 'kat',
    showLegende: true
}