import React, {useState, useEffect} from 'react'
import {Select} from 'semantic-ui-react'
import PropTypes from 'prop-types'

import _ from 'lodash'

const MediatheekKiezer = ({selectedMediatheek, mediatheekService, onSelectMediatheek, includeAll}) => {

    const [dataProvider, setDataProvider] = useState()
    const [selectedValue, setSelectedValue] = useState(selectedMediatheek || 'all')

    useEffect(() => {

        if (!mediatheekService) return

        let dataProvider = mediatheekService.mediatheken.map(mediatheek => {
            return {key: mediatheek.id, value: mediatheek.campusCode, text: mediatheek.naam}
        })

        if (includeAll)
            dataProvider.unshift({key: 0, value: 'all', text: 'Alle mediatheken'})

        console.log('DP', dataProvider)
        setDataProvider(dataProvider)
    }, [mediatheekService, includeAll])

    const selectMediatheek = (event, {value}) => {
        if (!onSelectMediatheek)
            return
        if (value === 'all')
            onSelectMediatheek(mediatheekService.mediatheken);
        onSelectMediatheek(_.find(mediatheekService.mediatheken, mediatheek => mediatheek.campusCode === value))

        setSelectedValue(value)
    }

    return (
            <div style={{width: '250px'}}>
                <Select
                    fluid
                    value={selectedValue}
                    placeholder='Selecteer een mediatheek'
                    options={dataProvider}
                    onChange={selectMediatheek}
                />
            </div>
        )
}

export default MediatheekKiezer

MediatheekKiezer.propTypes = {
    selectedMediatheek: PropTypes.string,
    onSelectMediatheek: PropTypes.func,
    mediatheekService: PropTypes.object.isRequired,
    includeAll: PropTypes.bool
}

MediatheekKiezer.defaultProps = {
    selectedMediatheek: 'all',
    includeAll: true
}
