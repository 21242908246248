import React from 'react'
import PropTypes from 'prop-types'
import FAQ from '../../assets/faq.jpg'


const FaqTile = ({localeManager, onClick}) => {

    const onTileClick = () => {
        onClick && onClick()
    }

    return (
        <div className='small-tile' onClick={onTileClick}>
            <img src={FAQ} height='100%' alt='faq'/>
            <div className='small-tile-text'>{localeManager.getString('KLIK_VOOR_VEELGESTELDE_VRAGEN')}</div>
        </div>
    )
}

export default FaqTile

FaqTile.propTypes = {
    localeManager: PropTypes.object.isRequired,
    onClick: PropTypes.func
}

