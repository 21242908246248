import * as path from '../domain/path'
const axios = require('axios')
const _ = require('lodash')

export function getUserPrefs(userName: string) {
	return new Promise(function (resolve, reject) {
		
		let url = `${path.API_BASE_URL}preferences?filter=userName,eq,${userName}&transform=1&token=${path.API_KEY}`;
		axios.get(url)
			.then(response => {
				//we sturen de prefs terug als dictionary
				const lookup = _.keyBy(response.data.preferences, pref => pref.prefKey)
				resolve(lookup)
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
		
	});
}

export function getPref(key: string, userName: string) {
	return new Promise(function (resolve, reject) {
		
		let url = `${path.API_BASE_URL}preferences?filter[]=userName,eq,${userName}&filter[]=prefKey,eq,${key}&transform=1&token=${path.API_KEY}`;
		axios.get(url)
			.then(response => {
				resolve(response.data.preferences[0])
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
		
	});
}

export function setPref(key: string, value: string, userName: string, context: string) {
	//first delete the previous one
	return new Promise(function (resolve, reject) {
		const pref: any = {
			"userName": userName,
			"prefKey": key,
			"prefValue": value,
			"prefContext": context
		}
		
		deletePref(key, userName).then(() => {
			
			let url = `${path.API_BASE_URL}preferences?transform=1&token=${path.API_KEY}`;
			axios.post(url, pref)
				.then(response => {
					pref.id = response.data
					resolve(pref)
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		})
		
	});
}

export function deletePref(key: string, userName: string) {
	//je kan niet deleten by query dus eerst ophalen en dan deleten met string
	return new Promise(function (resolve, reject) {
		
		let url = `${path.API_BASE_URL}preferences?filter[]=userName,eq,${userName}&filter[]=prefKey,eq,${key}&transform=1&token=${path.API_KEY}`;
		axios.get(url)
			.then(response => {
				//we hebben nu alle prefs met deze key
				const delString = _.map(response.data.preferences, (pref) => pref.id).join(',')
				
				let delUrl = `${path.API_BASE_URL}preferences/${delString}?token=${path.API_KEY}`;
				
				axios.delete(delUrl).then(response => {
					resolve(response)
				})
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
		
	});
}
