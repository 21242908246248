import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Feed, Icon, Transition, Divider, TextArea, Form, Popup, Button} from 'semantic-ui-react'
import moment from 'moment'
import _ from 'lodash'
import {
    FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton,
    FacebookIcon, TwitterIcon, WhatsappIcon, EmailIcon
} from 'react-share';
import {Social, ApplicationPath} from 'med-class'
import CommentaarFeed from "./CommentaarFeed";
import ZoeklijstViewer from "./ZoeklijstViewer";

const {CopyToClipboard} = require('react-copy-to-clipboard')


class SocialContent extends Component {

    inputRef = React.createRef()

    state = {
        socialData: null,
        likes: null,
        likedByMe: false,
        comments: null,
        bekeken: 0,
        leeslijstData: null,
        showComments: false,
        commentCount: 2,
        commentCountStep: 2,
        commentInputVisible: false,
        commentText: '',
        showSharing: false,
        showLeeslijsten: false,
        linkCopied: false
    }

    componentDidMount() {

        const {localeManager} = this.props
        moment.updateLocale('nl', {
            relativeTime: {
                future: "binnen %s",
                past: "%s geleden",
                s: 'een paar seconden',
                ss: '%d seconden',
                m: "een minuut",
                mm: "%d minuten",
                h: "een uur",
                hh: "%d uren",
                d: "een dag",
                dd: "%d dagen",
                M: "een maand",
                MM: "%d maanden",
                y: "een jaar",
                yy: "%d jaar"
            }
        });
        moment.locale(localeManager.locale)
        this.getSocialContent()
        this.getLeeslijstContent()
    }

    componentDidUpdate(props) {
        if (props.zoekresultaat !== this.props.zoekresultaat) {
            this.getSocialContent()
            this.getLeeslijstContent()
        }

        if (this.inputRef.current) this.inputRef.current.focus()
    }

    getSocialContent = () => {
        const {socialService, zoekresultaat, arteveldeUser} = this.props
        socialService.getSocialContent(zoekresultaat).then(socialData => {
            socialData = socialData.filter(sd => {
                if (sd.context !== 'commentaar') return true
                if (sd.user === arteveldeUser.emailAddress) return true
                return sd.status === 'live'
            })
            this.setState({socialData})
            this.buildData(socialData)
        })
    }

    getLeeslijstContent = () => {
        const {mijnMediatheekService, zoekresultaat} = this.props
        this.setState({leeslijstData: mijnMediatheekService.zoeklijstItemLookup[zoekresultaat.id]})
    }

    buildData = (socialData) => {
        const {arteveldeUser} = this.props
        const likes = socialData.filter(data => data.context === 'like')
        const comments = _.orderBy(socialData.filter(data => data.context === 'commentaar'), ['datum'], ['desc'])
        const likedByMe = arteveldeUser.loggedIn
            && likes.reduce((acc, like) => like.context === 'like' && like.user === arteveldeUser.emailAddress, false)

        const bekekenRec = socialData.filter(data => data.context === 'bekeken')[0]
        if (bekekenRec) this.setState({bekeken: bekekenRec.teller})
        this.setState({likes, comments, likedByMe})
    }

    onIconClick = (event, {name}) => {
        //event.stopPropagation()

        const {likedByMe, socialData, showComments, showSharing, showLeeslijsten, commentCountStep} = this.state
        const {zoekresultaat, arteveldeUser, socialService, localeManager} = this.props
        switch (name) {
            case 'like':
                if (!arteveldeUser) return
                if (!arteveldeUser.loggedIn){
                    window.alert(localeManager.getString('SOCIAL_NIET_INGELOGD'))
                    return
                }
                if (likedByMe) {
                    const social = socialData.filter(like => like.user === arteveldeUser.emailAddress && like.context === 'like')[0]
                    if (social) {
                        socialService.deleteSocial(social).then(socialData => {
                            this.setState({socialData})
                            this.buildData(socialData)
                        })
                    }
                } else {
                    const social = new Social()
                    social.context = 'like'
                    social.bron = zoekresultaat.bron
                    social.ref = zoekresultaat.id
                    social.user = arteveldeUser.emailAddress
                    social.datum = moment().format('YYYY-MM-DD HH:mm:ss')
                    socialService.createSocial(social).then(socialData => {
                        this.setState({socialData})
                        this.buildData(socialData)
                    })
                }
                break
            case 'comment':
                if (showComments) this.setState({commentCount: commentCountStep})
                this.setState({showComments: !showComments, showSharing: false, showLeeslijsten: false})
                break
            case 'share':
                this.setState({showSharing: !showSharing, showComments: false, showLeeslijsten: false, linkCopied: false})
                break
            case 'leeslijst':
                this.setState({showLeeslijsten: !showLeeslijsten, showComments: false, showSharing: false})
                break
            default:
                break
        }
    }

    getLikedLabel = () => {
        const {likes, likedByMe} = this.state
        const {compact} = this.props

        if (compact)
            return likes.length

        const {localeManager} = this.props
        if (likes.length === 0) return localeManager.getString('WEES_DE_EERSTE_OM_DIT_TE_LIKEN')
        const numLikesByOthers = likedByMe ? likes.length - 1 : likes.length
        let label = ''
        if (likedByMe) label = localeManager.getString('JIJ')
        if (likedByMe && numLikesByOthers > 0) label += ` ${localeManager.getString('EN')} `
        if (numLikesByOthers > 0) label += `${numLikesByOthers} ${localeManager.getString('ANDEREN')}`

        return label
    }

    showCommentInput = (event) => {
        this.setState({commentInputVisible: true})
    }

    onChange = (event, {name, value}) => {
        this.setState({[name]: value})
    }

    addComment = () => {
        const {commentText} = this.state
        const {zoekresultaat, socialService, arteveldeUser} = this.props
        const social = new Social()
        social.context = 'commentaar'
        social.bron = zoekresultaat.bron
        social.ref = zoekresultaat.id
        social.user = arteveldeUser.emailAddress
        social.commentaar = commentText
        social.datum = moment().format('YYYY-MM-DD HH:mm:ss')
        social.status = 'nieuw'
        socialService.createSocial(social).then(socialData => {
            this.setState({socialData, commentText: '', commentInputVisible: false})
            this.buildData(socialData)
        })
    }

    deleteComment = (comment) => {
        const {socialService} = this.props
        socialService.deleteSocial(comment).then(socialData => {
            this.setState({socialData})
            this.buildData(socialData)
        })
    }

    loadMore = () => {
        const {commentCount, commentCountStep} = this.state
        this.setState({commentCount: commentCount + commentCountStep})
    }

    onZoeklijstViewerMouseLeave = () => {
        this.setState({showLeeslijsten: false})
    }

    onZoeklijstChange = (leeslijstData) => {
        this.setState({leeslijstData})
    }

    render() {
        const {
            socialData, likes, comments, likedByMe, showComments, commentCount, linkCopied,
            bekeken, commentInputVisible, commentText, showSharing, showLeeslijsten, leeslijstData
        } = this.state
        const {arteveldeUser, localeManager, zoekresultaat, mijnMediatheekService, compact, socialIconsDirection} = this.props

        if (!socialData || !likes) return <div/>
        const loggedIn = arteveldeUser && arteveldeUser.loggedIn === true

        const directeLink = ApplicationPath.linkWebportaal + '/' + (zoekresultaat.bron).replace('bidoc', 'catalogus') + '/' + zoekresultaat.id

        const starColor = (leeslijstData && leeslijstData.length > 0) ? 'yellow' : 'grey'

        return (
            <div onClick={(event) => event.stopPropagation()}>

                <div style={{display: 'flex', alignContent: 'center'}}>

                    <Popup
                    trigger={
                        <Feed.Like style={{marginRight: '15px'}}>
                            <Icon name='like' color={likedByMe ? 'red' : null} onClick={this.onIconClick}/>
                            {this.getLikedLabel()}
                        </Feed.Like>
                    }
                    content='Like'/>

                    <Feed.Like style={{marginRight: '15px'}}>
                        <Icon name='comment' onClick={this.onIconClick}/>
                        {comments.length}
                    </Feed.Like>

                    {bekeken > 0 &&
                    <Feed.Like style={{marginRight: '15px'}}>
                        <Icon name='eye'/>
                        {bekeken}
                    </Feed.Like>
                    }

                    <Feed.Like style={{marginRight: '15px'}} onClick={(event) => this.onIconClick(event, {name: 'share'})}>
                        <Icon name='share' onClick={this.onIconClick}/>
                        {!compact && localeManager.getString('DELEN')}
                    </Feed.Like>

                    {loggedIn &&
                    <Feed.Like style={{marginRight: '15px'}} onClick={(event) => this.onIconClick(event, {name: 'leeslijst'})}>
                        <Icon name='star' onClick={this.onIconClick} color={starColor}/>
                        {!compact && localeManager.getString('LEESLIJSTEN')}
                    </Feed.Like>
                    }
                </div>


                <Transition.Group animation='slide down' duration={500}>
                    {showComments &&
                    <div>
                        <Divider/>

                        {loggedIn &&
                        <div>
                            {!commentInputVisible &&
                            <span onClick={this.showCommentInput}>{localeManager.getString('VOEG_COMMENTAAR_TOE')}</span>
                            }
                            {commentInputVisible &&
                            <Form>
                                <TextArea ref={this.inputRef} style={{marginTop: '5px', marginBottom: '5px'}} name='commentText' placeholder={localeManager.getString('VERTEL_ONS_JOUW_MENING')}
                                          value={commentText}
                                          onChange={this.onChange}/>
                                <Form.Group inline>
                                    <Form.Button size='mini' disabled={commentText === ''} content={localeManager.getString('VERSTUUR')} onClick={this.addComment}/>

                                </Form.Group>
                            </Form>
                            }
                        </div>
                        }
                        {!loggedIn &&
                        <span>{localeManager.getString('SOCIAL_NIET_INGELOGD')}</span>

                        }
                        <Feed size='small'>
                            {comments.map((comment, index) => {
                                if (index > commentCount - 1) return null

                                return <CommentaarFeed key={index} comment={comment} localeManager={localeManager} arteveldeUser={arteveldeUser} deleteComment={this.deleteComment}/>

                            })}

                            {commentCount < comments.length &&
                            <span onClick={this.loadMore}>{localeManager.getString('TOON_MEER')}...</span>
                            }
                        </Feed>
                    </div>
                    }

                    {showSharing &&
                    <Fragment>
                        <Divider/>
                        <div style={{display: 'flex', flexDirection: socialIconsDirection}}>


                            <FacebookShareButton url={directeLink}
                                                 style={{display: 'flex', alignItems: 'center', marginBottom: '5px', cursor: 'pointer', marginRight: '15px'}}>
                                <FacebookIcon size={24} round/>
                                <span style={{marginLeft: '5px'}}>Facebook</span>
                            </FacebookShareButton>

                            <TwitterShareButton url={directeLink}
                                                style={{display: 'flex', alignItems: 'center', marginBottom: '5px', cursor: 'pointer', marginRight: '15px'}}>
                                <TwitterIcon size={24} round/>
                                <span style={{marginLeft: '5px'}}>Twitter</span>
                            </TwitterShareButton>

                            <WhatsappShareButton url={directeLink}
                                                 style={{display: 'flex', alignItems: 'center', marginBottom: '5px', cursor: 'pointer', marginRight: '15px'}}>
                                <WhatsappIcon size={24} round/>
                                <span style={{marginLeft: '5px'}}>Whatsapp</span>
                            </WhatsappShareButton>

                            <EmailShareButton url={directeLink} subject={'Mediatheken Arteveldehogeschool: ' + zoekresultaat.titel}
                                              style={{display: 'flex', alignItems: 'center', marginBottom: '5px', cursor: 'pointer', marginRight: '15px'}}>
                                <EmailIcon size={24} round/>
                                <span style={{marginLeft: '5px'}}>Mail</span>
                            </EmailShareButton>

                            <CopyToClipboard text={directeLink}
                                             onCopy={() => this.setState({linkCopied: true})}>
                                <div style={{cursor: 'pointer', marginRight: '15px'}}>
                                    <Button circular style={{width: '24px', height: '24px', padding: 0}}
                                            color={linkCopied ? 'green' : 'grey'} icon={linkCopied ? 'check' : 'clipboard'}/>
                                    <span style={{marginLeft: '2px'}}>{localeManager.getString('COPY_LINK')}</span>
                                </div>

                            </CopyToClipboard>
                        </div>
                    </Fragment>
                    }

                    {showLeeslijsten &&
                    <ZoeklijstViewer mijnMediatheekService={mijnMediatheekService}
                                     zoekresultaat={zoekresultaat}
                                     localeManager={localeManager}
                                     arteveldeUser={arteveldeUser}
                                     onMouseLeave={this.onZoeklijstViewerMouseLeave}
                                     onZoeklijstChange={this.onZoeklijstChange}/>
                    }

                </Transition.Group>
            </div>
        )
    }
}

export default SocialContent

SocialContent.propTypes = {
    zoekresultaat: PropTypes.object.isRequired,
    socialService: PropTypes.object.isRequired,
    mijnMediatheekService: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    arteveldeUser: PropTypes.object,
    compact: PropTypes.bool,
    socialIconsDirection: PropTypes.string
}

SocialContent.defaultProps = {
    compact: false,
    socialIconsDirection: 'column'
}