/*
	©2018 Dany Dhondt
*/


export class Signalisatiestrook {
	
	static XMLMODEL: string =
		`<element type="signalisatieStrook" layout="witTweeRegels" aantal="1">
			<plaatsKenmerk color="0x000000">
				<label></label>
			</plaatsKenmerk>
			<regels color="" backgroundColor="">
				<regel1 small="false">
					<label></label>
				</regel1>
				<regel2 small="true">
					<label></label>
				</regel2>
			</regels>
			<logos visible="true" position="aansluitend">
				<logo type=""/>
			</logos>
		</element>`
}