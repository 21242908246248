export class Taxonomie {

    _explicitType: string = 'Taxonomie'

    id: number = 0
    wdCode: string = ''
    label: string = ''
    isGeneriek: number = 0
    adminOnly: number = 0
    sorteerOpNummer: number = 0
}
