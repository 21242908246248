import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Segment, Button, List, Divider, Icon} from 'semantic-ui-react'
import ZoekresultaatCard from "./ZoekresultaatCard";
import ZoekresultaatListItem from "./ZoekresultaatListItem";

const Zoekresultaten = ({socialService, mijnMediatheekService, taxonomieService, localeManager, zoekresultaten, headerString, resultaatWeergave, onResultaatWeergaveChange, onZoekresultaatSelect, onCoverClick, arteveldeUser, loading, directLink, numCols}) => {

    const [cols, setCols] = useState()

    useEffect(() => {

        const getCols = () => {
            const cols = []
            for (let i = 0; i < numCols; i++) {
                cols.push(i)
            }
            return cols
        }

        setCols(getCols())
    }, [zoekresultaten, numCols])


    const onCardClick = (zoekresultaat) => {
        onZoekresultaatSelect(zoekresultaat)
    }

    if (!zoekresultaten || !cols) return <div/>

    if (zoekresultaten.length === 0) {
        return <div>
            <Segment loading={loading}>{localeManager.getString('GEEN_RESULTATEN_GEVONDEN')}</Segment>
        </div>
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                {localeManager.getString('ZOEKRESULTATEN') + ' ' + headerString}
                <div style={{flexGrow: 1}}/>
                <Button.Group floated='right'>
                    <Button icon name='list' color={resultaatWeergave === 'list' ? 'green' : 'grey'} onClick={onResultaatWeergaveChange}>
                        <Icon style={{display: 'inline'}} name='th list'/>
                    </Button>
                    <Button icon name='tile' color={resultaatWeergave === 'tile' ? 'green' : 'grey'} onClick={onResultaatWeergaveChange}>
                        <Icon style={{display: 'inline'}} name='th'/>
                    </Button>
                </Button.Group>
            </div>

            <Divider/>

            {resultaatWeergave === 'tile' &&
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {cols.map(colnum => {
                    return <div key={colnum}>
                        {zoekresultaten.map((zoekresultaat, index) => {
                            if (!zoekresultaat.id) return null
                            const colIndex = index % cols.length
                            if (colIndex === colnum) return <ZoekresultaatCard key={index}
                                                                               socialService={socialService}
                                                                               mijnMediatheekService={mijnMediatheekService}
                                                                               taxonomieService={taxonomieService}
                                                                               zoekresultaat={zoekresultaat}
                                                                               localeManager={localeManager}
                                                                               onCardClick={onCardClick}
                                                                               onCoverClick={onCoverClick}
                                                                               arteveldeUser={arteveldeUser}
                                                                               directLink={directLink}/>
                            return null
                        })}
                    </div>
                })}
            </div>
            }

            {resultaatWeergave === 'list' &&
            <List relaxed='very'>
                {zoekresultaten.map((zoekresultaat, index) => {
                    if (!zoekresultaat.id) return null
                    return <ZoekresultaatListItem key={index}
                                                  socialService={socialService}
                                                  mijnMediatheekService={mijnMediatheekService}
                                                  taxonomieService={taxonomieService}
                                                  zoekresultaat={zoekresultaat}
                                                  localeManager={localeManager}
                                                  onCardClick={onCardClick}
                                                  onCoverClick={onCoverClick}
                                                  arteveldeUser={arteveldeUser}
                                                  directLink={directLink}/>
                })}
            </List>
            }
        </div>
    )
}

export default Zoekresultaten

Zoekresultaten.propTypes = {
    resultaatWeergave: PropTypes.string,
    onResultaatWeergaveChange: PropTypes.func,
    headerString: PropTypes.string,
    zoekresultaten: PropTypes.array.isRequired,
    localeManager: PropTypes.object.isRequired,
    spelwijzerService: PropTypes.object.isRequired,
    taxonomieService: PropTypes.object.isRequired,
    socialService: PropTypes.object.isRequired,
    mijnMediatheekService: PropTypes.object.isRequired,
    onZoekresultaatSelect: PropTypes.func,
    onCoverClick: PropTypes.func,
    arteveldeUser: PropTypes.object,
    loading: PropTypes.bool,
    numCols: PropTypes.number.isRequired,
    directLink: PropTypes.object
}

Zoekresultaten.defaultProps = {
    resultaatWeergave: 'list',
    loading: false
}