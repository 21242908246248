const WRAPPER = 'wrapper'
const AANDUIDING = 'aanduiding'
//const AANDUIDING_TEKST = 'aanduidingTekst'
//const AANDUIDING_KADER = 'aanduidingKader'
const BEWEGWIJZERING = 'bewegwijzering'
const CONTOUR = 'contour'
const KASTEN = 'kasten'
//const KASTNUMMERS = 'kastnummers'
//const LABEL = 'label'
const TAFELS = 'tafels'
const TOOLTIP = 'tooltip'
const ZONELABELS = 'zoneLabels'
const ZONES = 'zones'
const PANOS = 'panos'
const KOMPASNAALD = 'kompasnaald'
//const NAALD = 'naald'
//const NAALD_N = 'naald_N'
//const NAALD_Z = 'naald_Z'

//const TOOLTIP_KADER = 'tooltipKader'
//const TOOLTIP_TEKST = 'tooltipTekst'
//const INFO_KADER = 'infoKader'
//const INFO_TEKST = 'infoTekst'

/*
*
* OPLOSSING FIREFOX BUG
*
* Probleem: bij het outer element (<svg>) werkt getCTM() niet in FF. Deze bug is al jaren oud en nog steeds niet opgelost.
* Oplossing: op root niveau in illustrator 2 layers maken: één = foo en mag NIET leeg zijn. De tweede heet 'wrapper' en bevat alle andere gegevens.
* In plaats van svg.getCTM() kan ik nu this.getLayerOrElement('wrapper').getCTM() aanroepen. Probleem opgelost!
*
* Zie GitHub #219
* */


export class MapController {

    svg
    rubrieken
    rubriekKasten
    filteredRubrieken
    onFilteredRubriekenUpdate
    localeManager
    _selectedRubriek
    panoNodeClick
    selectedPano

    constructor(localeManager, panoNodeClick) {
        this.localeManager = localeManager
        this.panoNodeClick = panoNodeClick
    }

    init = (svg) => {
        this.svg = svg
        this.initBewegwijzering()
        this.initStyles()
        this.hideLayer(TOOLTIP)
        this.hideLayer(ZONES)
        this.hideLayer(ZONELABELS)
        this.hideLayer(AANDUIDING)
        this.hideLayer(BEWEGWIJZERING)
        this.initPanos()

        this.hideLayer(KOMPASNAALD)
    }
    
    unmount = () => {
        const panos = this.getLayerOrElement(PANOS)
        for (const pano of panos.childNodes) {
            console.log('REMOVING EV L')
            pano.removeEventListener('click', this.onPanoClick)
        }
    }

    mapClick = (event) => {
    }

    onPanoClick = (event) => {

        const pano = event.target.parentElement
        const nodeId = pano.id.split('_')[1]
        //this.selectPano(nodeId)
        this.panoNodeClick(parseInt(nodeId))
    }

    rotatePanoNode = (yaw) => {
        return
        /*if (!this.selectedPano || !this.getLayerOrElement(WRAPPER)) return
        const naald = this.getLayerOrElement(NAALD)
        const rect = this.getLayerOrElement(KOMPASNAALD).getElementsByTagName('rect')[0]
        const naaldPos = this.getMidOfGroup(naald, this.svg)
        const nodePos = this.getMidOfGroup(this.selectedPano, this.svg)
        const rectMid = this.getMid(rect, this.svg)

        //const offsetX = nodePos.x - naaldPos.x
        //const offsetY = nodePos.y - naaldPos.y
        console.log('POSS', naaldPos, nodePos, rectMid)
        naald.setAttribute('transform', `rotate(${180})`)
        console.log('ROTATING', this.selectedPano.id, yaw)*/
    }

    selectPano = (number) => {
        //reset rotation
        if (this.selectedPano) this.rotatePanoNode(0)
        this.removeStyles(PANOS, 'map-pano-selected', false)
        const pl = this.getLayerOrElement('panobody_' + number)

        if (!pl) return
        console.log('PL', pl)
        pl.classList.add('map-pano-selected')

        //position the needle
        const naald = this.getLayerOrElement(KOMPASNAALD)
        const nodePos = this.getMidOfGroup(pl, this.svg)
        const naaldPos = this.getMidOfGroup(naald, this.svg)
        //const newX = nodePos.x - naaldPos.x
        //const newY = nodePos.y - naaldPos.y
        //naald.setAttribute('transform', `translate(${newX}, ${newY})`)
        naald.setAttribute('transform', `translate(${-naaldPos.x}, ${-naaldPos.y})`)
        console.log('nodePos', nodePos, naaldPos)

        this.selectedPano = pl
    }

    getLayerOrElement = (layername) => {
        return this.svg.getElementById(layername)
    }

    showLayer = (layername) => {
        const el = this.getLayerOrElement(layername)
        el.style.display = ''
    }

    hideLayer = (layername) => {
        const el = this.getLayerOrElement(layername)
        el.style.display = 'none'
    }

    initStyles = () => {
        //eerste alle standaard stijlen wissen
        const defs = this.svg.getElementsByTagName('defs')[0]
        defs.removeChild(defs.childNodes[0])

        this.applyStyles(CONTOUR, 'map-contour')
        this.applyStyles(ZONES, 'map-zone')
        this.applyStyles(KASTEN, 'map-kast', false)
        this.applyStyles(TAFELS, 'map-tafel')
        this.applyStyles(ZONELABELS, 'map-zone-label')
        this.applyStyles(BEWEGWIJZERING, 'map-bewegwijzering')

        const panos = this.getLayerOrElement(PANOS)
        for (const element of panos.childNodes) {
            element.classList.add('map-pano')
        }

        /*this.getLayerOrElement(NAALD_N).classList.add('map-kompasnaald-N')
        this.getLayerOrElement(NAALD_Z).classList.add('map-kompasnaald-Z')*/
    }

    applyStyles = (layername, className, applyToLayerOnly = true) => {

        const layer = this.getLayerOrElement(layername)

        if (!layer) return

        if (applyToLayerOnly) {
            layer.classList.add(className)
        } else {
            for (const element of layer.childNodes) {
                element.classList.add(className)
            }
        }
    }

    removeStyles = (layername, className, applyToLayerOnly = true) => {

        const layer = this.getLayerOrElement(layername)

        if (!layer) return

        if (applyToLayerOnly) {
            layer.classList.remove(className)
        } else {
            for (const element of layer.childNodes) {
                element.classList.remove(className)
            }
        }
    }

    initPanos = () => {
        const panos = this.getLayerOrElement(PANOS)
        for (const pano of panos.childNodes) {
            if (pano.id === 'kompasnaald') continue
            pano.addEventListener('click', this.onPanoClick)
            const id = pano.id.split('_')[1]
            const t = pano.getElementsByTagName('text')[0]
            t.textContent = id
        }
    }

    initBewegwijzering = () => {
        const aanduidingen = this.getLayerOrElement(BEWEGWIJZERING)
        for (const aanduiding of aanduidingen.childNodes) {
            aanduiding.textContent = this.localeManager.getString(aanduiding.id)
        }
    }

    //magic methods
    getMid(rect, svg) {
        const point = svg.createSVGPoint();
        point.x = rect.x.baseVal.value + rect.width.baseVal.value / 2;
        point.y = rect.y.baseVal.value + rect.height.baseVal.value / 2;
        //lifesaver: https://stackoverflow.com/questions/47990205/connect-2-svg-elements/47993299#47993299
        return point.matrixTransform(this.getLayerOrElement(WRAPPER).getCTM().inverse().multiply(rect.getCTM()));
    }

    getMidOfGroup(group, svg) {
        const point = svg.createSVGPoint();
        const bbox = group.getBBox()
        const ctm = group.getCTM()
        point.x = bbox.x + bbox.width / 2;
        point.y = bbox.y + bbox.height / 2;
        //lifesaver: https://stackoverflow.com/questions/47990205/connect-2-svg-elements/47993299#47993299
        return point.matrixTransform(this.getLayerOrElement(WRAPPER).getCTM().inverse().multiply(ctm));
    }
}