import {convertObjectToClass} from "../util/ClassConvertor";
import {Taxonomie} from "../class/taxonomie/Taxonomie";
import {TaxonomieItem} from "../class/taxonomie/TaxonomieItem";

const axios = require('axios')
const _ = require('lodash')


export class TaxonomieService {

    private readonly TAXONOMIE_TABLE: string = 'taxonomie'
    private readonly TAXONOMIE_ITEM_TABLE: string = 'taxonomieItem'

    initialized: boolean = false
    taxonomieen: Array<Taxonomie>
    taxonomieLookup: object
    taxonomieLabelLookup: object
    taxonomieItems: Array<TaxonomieItem>
    taxonomieItemsLookup: object
    taxonomieItemLookup: object
    apiUrl: string
    apiKey: string

    constructor(apiUrl: string, apiKey: string) {
        this.apiUrl = apiUrl
        this.apiKey = apiKey
        this.taxonomieen = []
        this.taxonomieLookup = {}
        this.taxonomieLabelLookup = {}

        this.taxonomieItems = []
        this.taxonomieItemsLookup = {}
        this.taxonomieItemLookup = {}
    }

    getTaxonomieItemByLabel = (label: string): TaxonomieItem | undefined => {
        //sommige codes in taxonomieen zijn gewijzigd :(. Bv. ba_be_ma >> ba_bema
        let noUnderscoreLabel: string = label.replace(/_/g, '')
        for (let ti of this.taxonomieItems) {
            if (ti.label === label || ti.code === label)
                return ti
            if (ti.code) {
                let noUnderscoreTi: string = ti.code.replace(/_/g, '')
                if (noUnderscoreLabel === noUnderscoreTi) return ti
            }
        }

        return undefined
    }

    init = () => {

        let me = this
        return new Promise((resolve) => {

                if (me.initialized) {
                    resolve('already initialized')
                    return
                }

                this.getTaxonomieen().then((response: any) => {
                    me.taxonomieen = response.taxonomieen
                    me.taxonomieLookup = response.taxonomieLookup
                    me.taxonomieLabelLookup = response.taxonomieLabelLookup
                    me.taxonomieItemsLookup = response.taxonomieItemsLookup

                    this.getTaxonomieItems(me.taxonomieItemsLookup).then((response2: any) => {
                        me.initialized = true
                        me.taxonomieItems = response2.taxonomieItems
                        me.taxonomieItemLookup = response2.taxonomieItemLookup

                        resolve({status: 'ok'})
                    })
                }).catch((error) => {
                    console.log('ERROR IN TAXONOMIE INIT', error)
                })
            }
        )

    }

    private getTaxonomieen() {

        let me = this

        return new Promise(function (resolve, reject) {
            let url = `${me.apiUrl}${me.TAXONOMIE_TABLE}?&transform=1&order[]=label,asc&token=${me.apiKey}`

            axios.get(url)
                .then(response => {
                    let rawTaxonomieen = response.data.taxonomie, taxonomieItemsLookup = {}
                    let taxonomieen = rawTaxonomieen.map((rt: object) => convertObjectToClass(rt));
                    taxonomieen.forEach(taxonomie => taxonomieItemsLookup[taxonomie.id] = [])

                    taxonomieen = _.orderBy(taxonomieen, [taxonomie => taxonomie.label.toLowerCase()])
                    let taxonomieLookup = _.keyBy(taxonomieen, taxonomie => taxonomie.id);
                    let taxonomieLabelLookup = _.keyBy(taxonomieen, taxonomie => taxonomie.label);
                    resolve({taxonomieen, taxonomieLookup, taxonomieLabelLookup, taxonomieItemsLookup})
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    private getTaxonomieItems(taxonomieItemsLookup) {

        let me = this

        return new Promise(function (resolve, reject) {
            let url = `${me.apiUrl}${me.TAXONOMIE_ITEM_TABLE}?&transform=1&order[]=label,asc&token=${me.apiKey}`
            axios.get(url)
                .then(response => {
                    let taxonomieItems: TaxonomieItem[] = [], taxonomieItemLookup = {}

                    let rawTaxonomieItems = response.data.taxonomieItem;
                    _.each(rawTaxonomieItems, (rti: any) => {
                        //if (!taxonomieItemsLookup[rti.taxonomie]) taxonomieItemsLookup[rti.taxonomie] = []
                        let taxonomieItem: TaxonomieItem = convertObjectToClass(rti)
                        taxonomieItems.push(taxonomieItem)
                        taxonomieItemsLookup[rti.taxonomie].push(taxonomieItem)
                        taxonomieItemLookup[rti.id] = taxonomieItem
                    });
                    resolve({taxonomieItems, taxonomieItemLookup})
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });

        });
    }

    updateTaxonomie(taxonomie) {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.TAXONOMIE_TABLE}/${taxonomie.id}?&token=${me.apiKey}`;
                axios.put(url, taxonomie)
                    .then(() => {
                        //remap the taxonomie
                        taxonomie = convertObjectToClass(taxonomie);
                        me.taxonomieen = me.taxonomieen.map(t => {
                            if (t.id === taxonomie.id)
                                return taxonomie;
                            return t;
                        })
                        me.taxonomieen = _.orderBy(me.taxonomieen, [taxonomie => taxonomie.label.toLowerCase()])
                        me.taxonomieLookup = _.keyBy(me.taxonomieen, taxonomie => taxonomie.id);
                        me.taxonomieLabelLookup = _.keyBy(me.taxonomieen, taxonomie => taxonomie.label);
                        resolve({taxonomieen: me.taxonomieen, taxonomieLookup: me.taxonomieLookup})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    updateTaxonomieItem(taxonomieItem) {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.TAXONOMIE_ITEM_TABLE}/${taxonomieItem.id}?&token=${me.apiKey}`;
                axios.put(url, taxonomieItem)
                    .then(() => {
                        //remap the taxonomieItem
                        taxonomieItem = convertObjectToClass(taxonomieItem);

                        //regenerate the taxonomieItemsLookup
                        me.taxonomieItemsLookup[taxonomieItem.taxonomie] = me.taxonomieItemsLookup[taxonomieItem.taxonomie].map(ti => {
                            if (ti.id === taxonomieItem.id)
                                return taxonomieItem;
                            return ti;
                        })

                        //replace taxonomieItem in taxonomieItemLookup
                        me.taxonomieItemLookup[taxonomieItem.id] = taxonomieItem;

                        resolve({taxonomieItem, taxonomieItemsLookup: me.taxonomieItemsLookup});
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    createTaxonomie(taxonomie) {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.TAXONOMIE_TABLE}/?token=${me.apiKey}`
                axios.post(url, taxonomie)
                    .then(response => {
                        taxonomie.id = response.data;
                        me.taxonomieen = _.concat(me.taxonomieen, taxonomie)
                        me.taxonomieen = _.orderBy(me.taxonomieen, [taxonomie => taxonomie.label.toLowerCase()])
                        me.taxonomieLookup = _.keyBy(me.taxonomieen, taxonomie => taxonomie.id)
                        me.taxonomieLabelLookup = _.keyBy(me.taxonomieen, taxonomie => taxonomie.label)
                        me.taxonomieItemsLookup[taxonomie.id] = [];
                        resolve({taxonomieLookup: me.taxonomieLookup, taxonomieItemsLookup: me.taxonomieItemsLookup, taxonomieen: me.taxonomieen, taxonomie})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    createTaxonomieItem(taxonomieItem) {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.TAXONOMIE_ITEM_TABLE}/?token=${me.apiKey}`;
                axios.post(url, taxonomieItem)
                    .then(response => {
                        taxonomieItem.id = response.data
                        me.taxonomieItemsLookup[taxonomieItem.taxonomie] = _.concat(me.taxonomieItemsLookup[taxonomieItem.taxonomie], taxonomieItem);
                        me.taxonomieItemsLookup[taxonomieItem.taxonomie] = _.orderBy(me.taxonomieItemsLookup[taxonomieItem.taxonomie], ['label']);
                        me.taxonomieItemLookup[taxonomieItem.id] = taxonomieItem;
                        resolve({taxonomieItem, taxonomieItemsLookup: me.taxonomieItemsLookup, taxonomieItemLookup: me.taxonomieItemLookup});
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    deleteTaxonomie(taxonomie) {

        let me = this

        //items:

        const idString = _.map(me.taxonomieItemsLookup[taxonomie.id], item => item.id).join(',')
        const deleteUrl = `${me.apiUrl}${me.TAXONOMIE_ITEM_TABLE}/${idString}?token=${me.apiKey}`

        return new Promise((resolve, reject) => {
                const url = `${me.apiUrl}${me.TAXONOMIE_TABLE}/${taxonomie.id}?token=${me.apiKey}`
                axios.delete(url)
                    .then(() => {

                        //remove taxonomie items from database
                        axios.delete(deleteUrl)
                            .catch((error) => {
                                console.log('ERROR DELETING TAXONOMIE ITEMS', error);
                                reject(error);
                            });

                        //remove from taxonomieen and lookup
                        me.taxonomieen = _.filter(me.taxonomieen, t => t.id !== taxonomie.id)
                        me.taxonomieLookup = _.keyBy(me.taxonomieen, taxonomie => taxonomie.id)
                        me.taxonomieLabelLookup = _.keyBy(me.taxonomieen, taxonomie => taxonomie.label)

                        resolve({taxonomieen: me.taxonomieen, taxonomieLookup: me.taxonomieLookup})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    deleteTaxonomieItem(taxonomieItem) {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.TAXONOMIE_ITEM_TABLE}/${taxonomieItem.id}?token=${me.apiKey}`;
                axios.delete(url)
                    .then(() => {

                        _.pull(me.taxonomieItemsLookup[taxonomieItem.taxonomie], taxonomieItem);

                        me.taxonomieItemsLookup[taxonomieItem.taxonomie] = _.filter(me.taxonomieItemsLookup[taxonomieItem.taxonomie], ti => ti.id !== taxonomieItem.id)
                        delete (me.taxonomieItemLookup[taxonomieItem.id])
                        resolve({taxonomieItemsLookup: me.taxonomieItemsLookup})

                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    getTaxonomieItemsByCode = (code: string) => {
        return this.taxonomieItems.filter(ti => ti.code === code)
    }
}
