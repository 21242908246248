
/*
	©2018 Dany Dhondt
*/


export class User {
	
	_explicitType: string = "User";
	id: Number = 0;
	upn: string = '';
	barcode: string = '';
	userName: string = '';
	voornaam: string = '';
	familienaam: string = '';
	geslacht: string = '';
	mail: string = '';
	roles: string = '';
	boToegang: number = 0;
	boToegangModules: string = '';
	affiliatie: string = '';
	_profielFotoUrl: string = '';
	toestemmingPubliekProfiel: number = 0;
	campus: string = '';
	outlookUrl: string = '';
	keyVerify: string = '';
	
	get naam():string{
		return this.voornaam + ' ' + this.familienaam
	}

	get profielFotoUrl(){
		return this._profielFotoUrl //.replace('pf_', 'pfn_')
	}

	set profielFotoUrl(value){
		this._profielFotoUrl = value
	}
}
