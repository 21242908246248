export class Preference{
	
	static readonly ALGEMENE_OPMERKING_PDF_EBOOKS: string = 'algemeneOpmerkingenPdfEBooks'
	static readonly ALGEMENE_OPMERKING_PDF_EDATABANKEN: string = 'algemeneOpmerkingenPdfEDatabanken'
	static readonly ALGEMENE_OPMERKING_PDF_EHANDBOEKEN_OLO: string = 'algemeneOpmerkingenPdfEHandboekenOlo'
	static readonly ALGEMENE_OPMERKING_PDF_EHANDBOEKEN_OSO: string = 'algemeneOpmerkingenPdfEHandboekenOso'
	static readonly ALGEMENE_OPMERKING_PDF_ETIJDSCHRIFTEN: string = 'algemeneOpmerkingenPdfETijdschriften'
	static readonly ALGEMENE_OPMERKING_PDF_EPLATFORMEN: string = 'algemeneOpmerkingenPdfEPlatformen'
	static readonly SCOODLE_LINK: string = 'scoodleLink'
	static readonly TITEL_LANG_EBOOKS: string = 'titelLangEBooks'
	static readonly TITEL_LANG_EDATABANKEN: string = 'titelLangEDatabanken'
	static readonly TITEL_LANG_EHANDBOEKEN_OLO: string = 'titelLangEHandboekenOlo'
	static readonly TITEL_LANG_EHANDBOEKEN_OSO: string = 'titelLangEHandboekenOso'
	static readonly TITEL_LANG_ETIJDSCHRIFTEN: string = 'titelLangETijdschriften'
	static readonly TITEL_LANG_EPLATFORMEN: string = 'titelLangEPlatformen'
	static readonly TELLING_CAMPUS: string = 'tellingCampus'
	static readonly OPENINGSUREN_CALENDAR_CAMPUS: string = 'openingsurenCalendarCampus'
	static readonly SIGNALISATIESTROKEN_XML: string = 'signalisatieStrokenXml'
	static readonly TELLING_EDOCUMENTEN: string = 'titelLangEDocumenten'
	static readonly LAST_CAMPUS: string = 'lastCampus'
	static readonly TERUBRENGBOX_XML: string = 'terugbrengboxXml'
	static readonly LABELSTICKERS_XML: string = 'labelStickersXml'
	static readonly BOEKBAKKEN_XML: string = 'boekbakkenXml'
	static readonly AANKONDIGING_XML: string = 'aankondigingXml'
	static readonly PANCARTES_XML: string = 'pancartesXml'
	
	static readonly CONTEXT_ALGEMEEN: string = 'algemeen'
	
	id: number = 0
	prefContext: string = ''
	prefKey: string = ''
	prefValue: string = ''
	userName: string = ''
}