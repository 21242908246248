import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Header, Segment, Button, Modal, Input, Icon, Image} from "semantic-ui-react";
import {Chatline} from 'med-class'
import Parser from 'html-react-parser'

const ChatFaqSuggestions = ({chatService, faqService, chat, relatedFaqs, eigenaar, localeDataService}) => {

    const [modalOpen, setModalOpen] = useState(false)
    const [modalContent, setModalContent] = useState('')
    const [modalTitle, setModalTitle] = useState('')
    const [selectedFaq, setSelectedFaq] = useState()
    const [faqFilterText, setFaqFilterText] = useState()
    const [filteredFaqs, setFilteredFaqs] = useState()

    useEffect(() => {
        setFilteredFaqs(relatedFaqs)
        setFaqFilterText('')
    }, [relatedFaqs])

    const onFaqSuggestieClick = () => {

        setModalOpen(false)

        if (!selectedFaq) return

        const chatline = Chatline()
        chatline.chat = chat.id
        chatline.inhoud = `Misschien helpt deze faq u verder: <span class="chat-line-faq">${selectedFaq.question}</span>`
        chatline.bron = 'bo'
        chatline.type = 'faq'
        chatline.auteur = eigenaar
        chatline.faqId = selectedFaq.id

        chatService.createChatline(chat, chatline).then(res => {
            //console.log('NEW CHATLINE CREATED', res)
        })
    }

    const onChangeFaqFilterText = (event, {value}) => {
        setFaqFilterText(value)

        if (value === ''){
            clearFaqFilterText()
            return
        }

        const searchString = value.toLowerCase()
        const wordArray = searchString.split(' ')

        const fFaqs = faqService.faqs.filter(faq => {
            let rv = true
            for (const word of wordArray) {
                if (faqService.faqSearchStringLookup[faq.id].indexOf(word) < 0) rv = false
            }
            return rv
        })

        setFilteredFaqs(fFaqs)

    }

    const clearFaqFilterText = () => {
        setFaqFilterText('')
        setFilteredFaqs(relatedFaqs)
    }

    const showFaqContent = (faq) => {
        localeDataService.getLocaleData(faq.id).then(() => {
            setModalOpen(true)
            setSelectedFaq(faq)
            setModalContent(localeDataService.getPropertyValue(faq.id, 'answer', 'nl'))
            setModalTitle(faq.question)
        })
    }



    if (!chat) return null

    return (
        <div style={{marginTop: '10px'}}>

            <Header>Faq suggesties</Header>

            <Input name='faqFilterInput'
                   value={faqFilterText}
                   onChange={onChangeFaqFilterText}
                   fluid
                   icon={<Icon name='cancel' link={true} onClick={clearFaqFilterText}/>}
                   placeholder='typ hier om manueel een faq te zoeken'/>

            {relatedFaqs && relatedFaqs.length > 0 && <Segment>

                {filteredFaqs.map((faq, index) => {
                    if (!faq) return null
                    return <div style={{flexFlow: 'row', cursor: 'pointer', marginRight: '5px'}} className='chat-line right' key={index} onClick={() => showFaqContent(faq)}>
                        <div className='chat-line-content'><p>{faq.question}</p></div>
                    </div>
                })}
            </Segment>
            }

            <Modal open={modalOpen} dimmer='inverted'
                   onClose={() => setModalOpen(false)}>
                <Modal.Header>
                    {modalTitle}
                </Modal.Header>
                <Modal.Content>
                    {Parser(modalContent)}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setModalOpen(false)}>Sluit</Button>
                    <Button color='green' onClick={onFaqSuggestieClick}>Voeg toe aan chat</Button>
                </Modal.Actions>
            </Modal>
        </div>
    )
}

export default ChatFaqSuggestions

ChatFaqSuggestions.propTypes = {
    chatService: PropTypes.object.isRequired,
    faqService: PropTypes.object.isRequired,
    localeDataService: PropTypes.object.isRequired,
    chat: PropTypes.object,
    relatedFaqs: PropTypes.array,
    eigenaar: PropTypes.string
}

ChatFaqSuggestions.defaultProps = {}