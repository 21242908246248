import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Icon, Button, Modal, Popup, Progress, Segment, Form} from "semantic-ui-react";
import 'emoji-mart/css/emoji-mart.css'
import {Picker} from "emoji-mart";
import FileInputIcon from "./FileInputIcon";
import Parser from "html-react-parser";

const IconBar = ({onSelectEmoji, onSelectFile, showImage, showFile, showLink}) => {

    const [emojoPopupOpen, setEmojoPopupOpen] = useState(false)
    const [progressVisible, setProgressVisible] = useState(false)
    const [progressPercent, setProgressPercent] = useState()
    const [linkModalOpen, setLinkModalOpen] = useState(false)
    const [linkUrl, setLinkUrl] = useState('')


    const selectEmoji = (props) => {
        setEmojoPopupOpen(false)
        onSelectEmoji && onSelectEmoji(props)
    }

    const fileChange = (event) => {

        if (event.target.files[0]) {
            const file = event.target.files[0];
            setProgressVisible(true)
            onSelectFile(file, setProgressPercent).then(res => {
                setProgressVisible(false)
            })
        }

    }

    const onChange = (event, {value}) => {
        setLinkUrl(value)
    }

    const createLink = () => {
        setLinkModalOpen(false)
        console.log('CREATING LINK')
    }

    return (
        <Segment>
            <Popup on='click'
                   open={emojoPopupOpen}
                   onOpen={() => setEmojoPopupOpen(true)}
                   onClose={() => setEmojoPopupOpen(false)}
                   trigger={<Icon name='smile outline' size='large' link/>}>
                <Picker set='apple' sheetSize={16} showSkinTones={false}
                        title='' onSelect={selectEmoji}/>
            </Popup>

            {showImage && <FileInputIcon accept='image/*' icon='image outline' onFileSelected={fileChange}/>}
            {showFile && <FileInputIcon accept='' icon='attach' onFileSelected={fileChange}/>}
            {showLink && <Icon name='linkify' size='large' link onClick={() => setLinkModalOpen(true)}/>}


            {progressVisible && <Progress style={{marginTop: '10px'}} size='tiny' percent={progressPercent} indicating/>}

            <Modal open={linkModalOpen} dimmer='inverted'
                   onClose={() => setLinkModalOpen(false)}>
                <Modal.Header>Link toevoegen</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Input focus label='url' name='urlInput' onChange={onChange} value={linkUrl}/>
                    </Form>

                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setLinkModalOpen(false)}>Annuleer</Button>
                    <Button color='green' onClick={createLink}>Voeg toe</Button>
                </Modal.Actions>
            </Modal>

        </Segment>
    )
}

export default IconBar

IconBar.propTypes = {
    onSelectEmoji: PropTypes.func,
    onSelectFile: PropTypes.func,
    showImage: PropTypes.bool,
    showFile: PropTypes.bool,
    showLink: PropTypes.bool,
}

IconBar.defaultProps = {
    image: false,
    file: false,
    showLink: false,
}