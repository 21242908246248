import {convertObjectToClass} from "../util/ClassConvertor"
import {EPublicatie} from "../class/epub/EPublicatie"
const _ = require('lodash')
const axios = require('axios')


export class EPublicatieService {

  private readonly E_PUBLICATIE_TABLE: string = 'e_publicaties'

  initialized: boolean = false
  ePublicaties: Array<EPublicatie>
  ePublicatieLookup: object
  epubSearchStringLookup: object
  apiUrl: string
  apiKey: string

  constructor(apiUrl: string, apiKey: string) {
    this.apiUrl = apiUrl
    this.apiKey = apiKey
    this.ePublicaties = []
    this.ePublicatieLookup = {}
    this.epubSearchStringLookup = {}
  }

  init = () => {

    let me = this

    return new Promise((resolve) => {

        if (me.initialized){
            resolve({status: 'already initialized'})
            return
        }

        this.getEPublicaties().then((response: any) => {
          me.initialized = true
          me.ePublicaties = response.ePublicaties
          me.ePublicatieLookup = response.ePublicatieLookup
          me.generateEPubSearchStringLookup()

          resolve({status: 'ok'})
        }).catch((error) => {
          console.log('ERROR IN EPublicatie INIT', error)
        })
      }
    )

  }

  private getEPublicaties() {

    let me = this

    return new Promise(function (resolve, reject) {
      let url = `${me.apiUrl}${me.E_PUBLICATIE_TABLE}?transform=1&token=${me.apiKey}`
      axios.get(url)
        .then(response => {
          let rawEPublicaties = response.data[me.E_PUBLICATIE_TABLE]
          let ePublicaties = rawEPublicaties.map((rt: any) => convertObjectToClass(rt, 'EPublicatie'))
          ePublicaties = _.orderBy(ePublicaties, [epub => epub.titel.toLowerCase()])
          let ePublicatieLookup = _.keyBy(ePublicaties, ePublicatie => ePublicatie.id)
          resolve({ePublicaties, ePublicatieLookup})
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })

    })
  }

  updateEPublicatie(ePublicatie) {

    let me = this

    return new Promise((resolve, reject) => {
        let url = `${me.apiUrl}${me.E_PUBLICATIE_TABLE}/${ePublicatie.id}?&token=${me.apiKey}`
        axios.put(url, ePublicatie)
          .then(() => {
            //remap the ePublicatie
            ePublicatie = convertObjectToClass(ePublicatie, 'EPublicatie')
            me.ePublicaties = me.ePublicaties.map(t => {
              if (t.id === ePublicatie.id)
                return ePublicatie
              return t
            })
            me.ePublicatieLookup = _.keyBy(me.ePublicaties, ePublicatie => ePublicatie.id)
            this.generateEPubSearchStringLookup()
            resolve({ePublicaties: me.ePublicaties, ePublicatieLookup: me.ePublicatieLookup})
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      }
    )
  }

  createEPublicatie(ePublicatie) {

    let me = this

    return new Promise((resolve, reject) => {
        let url = `${me.apiUrl}${me.E_PUBLICATIE_TABLE}/?token=${me.apiKey}`
        axios.post(url, ePublicatie)
          .then(response => {
            ePublicatie.id = response.data
            me.ePublicaties = _.concat(me.ePublicaties, ePublicatie)
            me.ePublicaties = _.orderBy(me.ePublicaties, [epub => epub.titel.toLowerCase()])
            me.ePublicatieLookup = _.keyBy(me.ePublicaties, ePublicatie => ePublicatie.id)
            this.generateEPubSearchStringLookup()
            resolve({ePublicatieLookup: me.ePublicatieLookup, ePublicaties: me.ePublicaties, ePublicatie})
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      }
    )
  }

  deleteEPublicatie(ePublicatie) {

    let me = this

    return new Promise((resolve, reject) => {
        const url = `${me.apiUrl}${me.E_PUBLICATIE_TABLE}/${ePublicatie.id}?token=${me.apiKey}`

        axios.delete(url)
          .then(() => {

            //remove from ePublicaties and lookup
            me.ePublicaties = _.filter(me.ePublicaties, t => t.id !== ePublicatie.id)
            me.ePublicatieLookup = _.keyBy(me.ePublicaties, ePublicatie => ePublicatie.id)

            resolve({ePublicaties: me.ePublicaties, ePublicatieLookup: me.ePublicatieLookup})
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      }
    )
  }

  generateEPubSearchStringLookup = () => {

    _.each(this.ePublicaties, epub => {
      this.epubSearchStringLookup[epub.id] = (epub.opleiding +
        epub.reeks +
        epub.titel +
        epub.leverancier +
        epub.periode +
        epub.gebruikersnaam +
        epub.paswoord +
        epub.link +
        epub.lid_abonneenummer +
        epub.registratienummer +
        epub.ahs_trefwoorden +
        epub.opmerking +
        epub.beschrijving).toLowerCase()
    })
  }
}
