import React, {useState} from 'react'
import {Segment, Button} from 'semantic-ui-react'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'
import _ from 'lodash'

const MAX_EXEMPLAREN = 2

const JaargangnummerRenderer = ({zoekresultaat, localeManager, label, onWaarVindIkDitClick, onReserveerClick, arteveldeUser, config}) => {

    const [toonAlle, setToonAlle] = useState(false)


    const toonAlleExemplaren = () => {
        setToonAlle(true)
    }

    zoekresultaat.jaargangnummer = _.orderBy(zoekresultaat.jaargangnummer)

    return (
        <AbstractZoekresultaatRenderer label={localeManager.getString(label)}>

            {zoekresultaat.jaargangnummer && zoekresultaat.jaargangnummer.length > 0 && <div style={{backgroundColor: '#e1fcff', padding: '10px 10px 10px'}}>


                {zoekresultaat.jaargangnummer.map((plaats, index) => {


                    if (index > MAX_EXEMPLAREN - 1 && !toonAlle) return null

                    return <Segment key={index}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <span style={{marginRight: '10px'}}>{plaats}</span>


                            </div>
                        </Segment>
                })}
            </div>}

            {zoekresultaat.jaargangnummer && zoekresultaat.jaargangnummer.length > MAX_EXEMPLAREN && !toonAlle &&
            <div><p>Nog {zoekresultaat.jaargangnummer.length - MAX_EXEMPLAREN} meer</p>
                <Button color='blue' content='Toon alle' onClick={toonAlleExemplaren}/>
            </div>}
        </AbstractZoekresultaatRenderer>
    )
}

export default JaargangnummerRenderer