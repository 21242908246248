import React, {useState, useEffect, useRef, useCallback} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import 'moment/locale/nl';
import {LocaleDataService} from 'med-class'
import MediatheekTile from "./MediatheekTile"
import MediatheekDetail from "./MediatheekDetail"
import MediatheekpuntTile from "./MediatheekpuntTile";

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const WebComponentMediatheken = ({mediatheekService, userService, roosteringService, taxonomieService, localeManager, rubriceringService, enableLinks, config}) => {

    const [openingsurenInitialized, setOpeningsurenInitialized] = useState(false)
    const [selectedMediatheek, setSelectedMediatheek] = useState()
    const [users, setUsers] = useState()
    const [userLocaleDataService, setUserLocaleDataService] = useState()
    const [panoramaConfig, setPanoramaConfig] = useState()
    const [currentRoosteringEvents, setCurrentRoosteringEvents] = useState()



    const updateData = useCallback(() => {
        mediatheekService.getTellersMinuut() //.then(resp => this.setState({mediatheekService}))

        roosteringService.getRoosteringEvents(new Date(), true).then(res => {
            const now = moment().format("YYYY-MM-DD HH:mm:ss")
            const currentRoosteringEvents = res.events.filter(event => event.start <= now && event.einde > now)
            setCurrentRoosteringEvents(currentRoosteringEvents)
        })
    }, [mediatheekService, roosteringService])

    useInterval(() => {
        // Your custom logic here
        console.log('UPDATE TIMER')
        updateData()
    }, 60000);

    useEffect(() => {

        const fetchData = () => {
            mediatheekService.getOpeningsuren().then(resp => {
                setOpeningsurenInitialized(true)
            })
            updateData()
            mediatheekService.getPanoramaConfig().then(panoramaConfig => setPanoramaConfig(panoramaConfig))
        }

        setUserLocaleDataService(new LocaleDataService('user', ['profieltekst']))
        fetchData()
    }, [mediatheekService, updateData])


    const onMediatheekClick = (mediatheek) => {
        const campusTaxonomie = taxonomieService.taxonomieLabelLookup['campus']
        const campussen = taxonomieService.taxonomieItemsLookup[campusTaxonomie.id]
        const mediatheekTaxonomieId = campussen.reduce((acc, campus) => {
            if (campus.code === mediatheek.campusCode) return campus.id
            return acc
        }, 0)

        const users = userService.users.filter(user => user.campus.split(',').indexOf(mediatheekTaxonomieId.toString()) > -1 && user.toestemmingPubliekProfiel === 1)

        //deze hack is nodig om pannellum te doen werken :(
        if (mediatheek !== selectedMediatheek) setTimeout(() => setSelectedMediatheek(mediatheek), 200)

        setUsers(users)
        setSelectedMediatheek(null)

    }

    const onMediatheekpuntClick = (mediatheekpunt) => {
        if (mediatheekpunt.mediatheekpunt_link !== '') {
            window.open(mediatheekpunt.mediatheekpunt_link, '_blank')
        } else {
            window.alert('In voorbereiding')
        }
    }

    if (!openingsurenInitialized || !currentRoosteringEvents || !userLocaleDataService) return null

    return (
        <div>

            <div style={{display: 'flex', flexDirection: 'row'}}>
                {mediatheekService.mediatheken.map((med, index) => {

                        const currentRoosteringEvent = currentRoosteringEvents.reduce((acc, event) => {
                            if (event.mediatheek === med.campusCode) return event
                            return acc
                        }, null)

                        const currentBalieMedewerker = currentRoosteringEvent ? userService.userIdLookup[currentRoosteringEvent.medewerker] : null

                        return <MediatheekTile key={index}
                                               config={config}
                                               mediatheek={med}
                                               localeManager={localeManager}
                                               mediatheekService={mediatheekService}
                                               selected={selectedMediatheek ? selectedMediatheek === med : true}
                                               currentRoosteringEvent={currentRoosteringEvent}
                                               currentBalieMedewerker={currentBalieMedewerker}
                                               onClick={onMediatheekClick}/>
                    }
                )}


            </div>

            {selectedMediatheek &&
            <MediatheekDetail mediatheek={selectedMediatheek}
                              config={config}
                              mediatheekService={mediatheekService}
                              rubriceringService={rubriceringService}
                              users={users}
                              localeManager={localeManager} userLocaleDataService={userLocaleDataService}
                              enableLinks={enableLinks}
                              panoramaConfig={panoramaConfig}/>}

            <div style={{display: 'flex', flexDirection: 'row'}}>
                {mediatheekService.mediatheekpunten.map((med, index) => {
                        return <MediatheekpuntTile key={index} mediatheekService={mediatheekService} localeManager={localeManager} mediatheek={med} onClick={onMediatheekpuntClick}/>
                    }
                )}


            </div>
        </div>
    )
}

export default WebComponentMediatheken

WebComponentMediatheken.propTypes = {
    mediatheekService: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    rubriceringService: PropTypes.object.isRequired,
    roosteringService: PropTypes.object.isRequired,
    userService: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    taxonomieService: PropTypes.object.isRequired,
    enableLinks: PropTypes.bool
}

WebComponentMediatheken.defaultProps = {}