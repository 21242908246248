import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {Icon, Card, Button} from 'semantic-ui-react'

const Parser = require('html-react-parser')

const BidocUitleningListItem = ({bidocUitlening, localeManager, verlengBidocUitlening, verlengData}) => {

    const metaString = (bidocUitlening, localeManager) => {
        const msa = []
        if (bidocUitlening.publicatietype) msa.push(localeManager.getString(bidocUitlening.publicatietype))
        if (bidocUitlening.aanwinstnummer) msa.push(`${localeManager.getString('AANWINSTNUMMER')}: ${bidocUitlening.aanwinstnummer}`)

        return msa.join(' | ')
    }

    const uitgeleendString = (bidocUitlening, localeManager) => {
        const usa = []
        if (bidocUitlening.uitgeleendOp) usa.push(`${localeManager.getString('UITGELEEND_OP')}: ${bidocUitlening.uitgeleendOp}`)
        if (bidocUitlening.datumTerug) usa.push(`${localeManager.getString('TERUG_OP')}: ${bidocUitlening.datumTerug}`)
        if (bidocUitlening.verlengBoodschap) usa.push(`${localeManager.getString(bidocUitlening.verlengBoodschap.replace(/ /g, '_'))}`)
        if (bidocUitlening.voorlopigeBoete) usa.push(`${localeManager.getString('VOORLOPIGE_BOETE')}: ${bidocUitlening.voorlopigeBoete}`)

        return usa.join(' | ')
    }

    const verlenging = verlengData ?
        verlengData.verlengingen.filter(verlenging => verlenging.aanwinstnummer === bidocUitlening.aanwinstnummer)[0]
        :
        null

    const verlengString = (verlenging, localeManager) => {
        if (verlenging.verlengdTot) return `${localeManager.getString('VERLENGD_TOT')}: ${verlenging.verlengdTot}`
        if (verlenging.statusBericht) return `${localeManager.getUitleningStatusBericht(verlenging.status)}`

        return ''
    }

    return (

        <Card link={false} style={{marginBottom: '15px', border: 'none'}} color={bidocUitlening.isTeLaat ? 'red' : 'green'} fluid>
            <Card.Content>

                <Card.Header>{bidocUitlening.isTeLaat && <Icon name='exclamation circle' color='red'/>}{Parser(bidocUitlening.titel)}</Card.Header>
                <div style={{fontSize: '0.85em', color: 'grey', margin: '2px 0 2px 0'}}>
                    {bidocUitlening.subtitelArray.map((subtitel, index) => {
                        return <Fragment key={index}><em>{subtitel}</em><br/></Fragment>
                    })}
                </div>
                <Card.Meta>
                    <span>{Parser(metaString(bidocUitlening, localeManager))}</span>
                    <div style={{color: 'black'}}>{Parser(bidocUitlening.auteur)}</div>
                </Card.Meta>

            </Card.Content>

            <Card.Content extra>

                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{flexGrow: 1}}>{uitgeleendString(bidocUitlening, localeManager)}</div>

                    {verlenging && <div>
                        {verlenging.status > 0 && <Icon name='exclamation circle' color='red'/>}
                        {verlenging.status === 0 && <Icon name='check circle' inverted color='green'/>}
                        {verlengString(verlenging, localeManager)}
                    </div>}

                    {(!verlenging && bidocUitlening.verlengbaar) &&
                    <Button color='green' onClick={verlengBidocUitlening}>
                        {localeManager.getString('VERLENG')}
                    </Button>
                    }


                </div>

            </Card.Content>
        </Card>

    )
}

export default BidocUitleningListItem

BidocUitleningListItem.propTypes = {
    localeManager: PropTypes.object.isRequired,
    onCardClick: PropTypes.func,
    arteveldeUser: PropTypes.object,
    bidocUitlening: PropTypes.object.isRequired,
    verlengBidocUitlening: PropTypes.func,
    verlengData: PropTypes.any
}

BidocUitleningListItem.defaultProps = {}