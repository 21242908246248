import React from 'react'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'
import {Label} from 'semantic-ui-react'

const MinMaxRenderer = ({zoekresultaat, localeManager}) => {

    if (!zoekresultaat.aantal_spelers) return null

    return (
        <AbstractZoekresultaatRenderer label={localeManager.getString('AANTAL_SPELERS')}>
            {zoekresultaat.minSpelers &&
            (<span style={{marginRight: '15px'}}>Minimum <Label circular color='blue'>
                {zoekresultaat.minSpelers}
            </Label></span>)
            }
            {zoekresultaat.maxSpelers &&
            (<span>Maximum <Label circular color='green'>
                {zoekresultaat.maxSpelers}
            </Label></span>)
            }
        </AbstractZoekresultaatRenderer>
    )
}

export default  MinMaxRenderer