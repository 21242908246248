import React from 'react'
import PropTypes from 'prop-types'
import { Pagination } from 'semantic-ui-react'

const Pager = ({activePage, totalPages, handlePaginationChange}) => {

    return (
            <div style={{display: 'flex', justifyContent: 'center', padding: '10px'}}>
                <Pagination activePage={activePage}
                        onPageChange={handlePaginationChange}
                        totalPages={totalPages}/>
            </div>
    )
}

Pager.propTypes = {
    activePage: PropTypes.number.isRequired,
    itemOffset: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    handlePaginationChange: PropTypes.func.isRequired
}

Pager.defaultProps = {}

export default Pager