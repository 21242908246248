import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import ReactPannellum, {loadScene, addScene, getYaw} from 'react-pannellum'
import {ApplicationPath} from 'med-class'
import MiniMap from "./MiniMap";
const { detect } = require('detect-browser');
const browser = detect();

const panoFolder = ApplicationPath.mediatheekAssetsFolder + 'panorama/panos/'

const PanoramaViewer = ({medConfig, mainConfig, mediatheek, localeManager}) => {

    const [currentScene, setCurrentScene] = useState(medConfig.scenes[medConfig.firstScene])

    /*console.log('MC', mainConfig)
    mainConfig.autoRotate = 0*/


    useEffect(() => {

        //initializing
        for (const key in medConfig.scenes) {
            const scene = medConfig.scenes[key]
            /*console.log('SCENE', scene)
            scene.config.yaw = 0*/
            scene.config.hotSpots.forEach(hotSpot => {
                hotSpot.clickHandlerFunc = handleHotspotClick
                hotSpot.clickHandlerArgs = hotSpot.target
            })
            scene.config.imageSource = panoFolder + scene.image
            addScene(scene.name, {...mainConfig, ...scene.config})
        }

        setCurrentScene(medConfig.scenes[medConfig.firstScene])

    }, [medConfig, mainConfig])


    const handleHotspotClick = (event, sceneLabel) => {
        setCurrentScene(medConfig.scenes[sceneLabel])
        loadScene(sceneLabel)
    }

    const handleMapPanoNodeClick = (nodeId) => {
        for (const scenesKey in medConfig.scenes) {
            const scene = medConfig.scenes[scenesKey]
            if (scene.nodeNum === nodeId) {
                setCurrentScene(scene)
                loadScene(scene.name)
            }
        }
    }

    return (
        <div style={{position: 'relative', minHeight: '714px'}}>


            <ReactPannellum
                id='rPannellum'
                sceneId={currentScene.name}
                imageSource={panoFolder + currentScene.image}
                config={{...mainConfig, ...currentScene.config}}
                style={{
                    width: '100%',
                    height: '714px'
                }}
            />
            <div style={
                {
                    position: 'absolute',
                    bottom: 0, left: 0, right: 0,
                    color: 'white', backgroundColor: 'rgba(0,0,0, 0.3)',
                    textAlign: 'center',
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'flexStart',
                    alignItems: 'center'
                }}>
                <img style={{marginRight: '5px'}} src={ApplicationPath.mediatheekAssetsFolder + '/logos/mediatheek/icoon-mediatheek-60.png'} height='30px' alt='logo mediatheek'/>
                <span>{currentScene.title}</span>
            </div>


            {(mediatheek !== 'sab' && browser.name !== 'firefox') &&
            <div className={'map-wrapper'}>
                <MiniMap localeManager={localeManager} mediatheek={mediatheek}
                         selectedPano={currentScene.nodeNum} panoNodeClick={handleMapPanoNodeClick}
                         getYaw={getYaw}/>
            </div>
            }
        </div>
    )
}

export {PanoramaViewer}

PanoramaViewer.propTypes = {
    medConfig: PropTypes.object.isRequired,
    mainConfig: PropTypes.object.isRequired,
    mediatheek: PropTypes.string.isRequired,
    localeManager: PropTypes.object.isRequired
}

PanoramaViewer.defaultProps = {}