
/*
	©2018 Lennert Holvoet & Dany Dhondt
*/


export class Mediatheek {

	_explicitType: string = "Mediatheek"
	id: Number = 0
	naam: string = ''
	campus: string = ''
	campusCode: string = ''
	domein: string = ''
	contact: string = ''
	lat: Number = 0
	lng: Number = 0
    is_mediatheek: number
    maxBezetting: number

	get label(){
	    return this.naam
    }
    get value(){
        return this.campusCode
    }
    get code(){
	    return this.campusCode
    }
}
