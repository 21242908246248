import React, {Component, Fragment} from 'react'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'
import {Label} from 'semantic-ui-react'
import _ from 'lodash'

const Parser = require('html-react-parser')
const MAX_AUTEURS = 5

export default class AuteursRenderer extends Component {

    state = {showAllAuteurs: false}

    getShowMoreLabel = () => {
        const {showAllAuteurs} = this.state
        const {zoekresultaat, localeManager} = this.props

        if (showAllAuteurs) return null
        if (zoekresultaat.auteurs.length <= MAX_AUTEURS) return null

        const label = localeManager.getString('EN_X_MEER').replace('[num]', zoekresultaat.auteurs.length - MAX_AUTEURS) + '...'
        return <Label style={{marginTop: '10px', cursor: 'pointer'}}
                      size='small'
                      content={label}
                      onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          this.setState({showAllAuteurs: true})
                      }
                      }/>
    }

    render() {
        const {showAllAuteurs} = this.state
        const {zoekresultaat, localeManager} = this.props

        if (!zoekresultaat.auteurs || zoekresultaat.auteurs.length === 0) return null

        const auteursArray = showAllAuteurs ? zoekresultaat.auteurs : _.take(zoekresultaat.auteurs, 5)
        const label = auteursArray.length > 1 ? 'AUTEURS' : 'AUTEUR'

        return (
            <AbstractZoekresultaatRenderer label={localeManager.getString(label)}>
                {auteursArray.map((auteur, index) => {
                    return <Fragment key={index}>{Parser(auteur)}<br/></Fragment>
                })}
                {this.getShowMoreLabel()}
            </AbstractZoekresultaatRenderer>
        )
    }
}