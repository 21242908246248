import React from 'react'
import {Label} from 'semantic-ui-react'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'
const Parser = require('html-react-parser')

const GenreRenderer = ({zoekresultaat, localeManager, label}) => {

    if (!zoekresultaat.genre || zoekresultaat.genre.length === 0) return null
    return (
        <AbstractZoekresultaatRenderer label={localeManager.getString(label)}>
            {zoekresultaat.genre.map((genre, index) => {
                return <Label basic key={index} style={{marginBottom: '5px'}} as='a' content={Parser(genre)} icon='theme' color='grey'/>
            })}
        </AbstractZoekresultaatRenderer>
    )
}

export default  GenreRenderer