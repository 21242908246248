import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button} from 'semantic-ui-react'

import '../styles.css'

class CheckboxToggler extends Component {


    updateFilterObject = (event) => {
        console.log('UPDATE FILTER', event.type)
        const {filterObject, name, onOptionChange} = this.props
        const fakeTaxonomieItem = filterObject.data[name] ? filterObject.data[name][0] : {label: 'JA', code: name}
        const checked = filterObject.data[name] === undefined //omgekeerde want we togglen

        onOptionChange(null, {name, selecteditem: fakeTaxonomieItem, checked})
    }

    onKeyPresss = (event) => {
        console.log('KEY PRE3SS')
    }

    render() {
        const {title, name, filterObject} = this.props
        const somethingSelected = filterObject.data[name] && filterObject.data[name].length > 0

        return (
            <Button style={{backgroundColor: somethingSelected ? '#21ba45' : 'lightgrey', color: somethingSelected ? 'white' : 'black'}}
                    content={title}
                    onClick={this.updateFilterObject} onKeyDown={this.onKeyPress}
                    icon='check'
                    labelPosition='left'/>
        )
    }
}

CheckboxToggler.propsTypes = {
    localeManager: PropTypes.object.isRequired,
    title: PropTypes.string,
    onOptionChange: PropTypes.func,
    name: PropTypes.string,
    filterObject: PropTypes.object
}

CheckboxToggler.defaultProps = {
    filterObject: {},
}

export default CheckboxToggler