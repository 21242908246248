export class ZoeklijstItem {
	
	_explicitType: string = "ZoeklijstItem"

    id: number = 0
	zoeklijstID: Number = 0
	userID: string = ''
	titelID: string = ''
	
}
