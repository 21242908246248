import React, {useState} from 'react'
import {Button, Segment} from 'semantic-ui-react'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'

const {CopyToClipboard} = require('react-copy-to-clipboard')


const WerkvormenUrlRenderer = ({zoekresultaat, localeManager, isKiosk, werkvormen}) => {

    const [copiedLinks, setCopiedLinks] = useState({})

    const copyLink = (url) => {
        copiedLinks[url] = true
        setCopiedLinks({...copiedLinks})
    }

    if (isKiosk) return null
    if (!werkvormen) return null


    return (
        <AbstractZoekresultaatRenderer label={localeManager.getString('WERKVORMEN')}>

            {werkvormen.map((werkvorm, index) => {
                return <Segment key={index} style={{backgroundColor: '#e1fcff', display: 'flex', alignItems: 'center'}}>
                    <div>
                        <p>{werkvorm.label}</p>
                        <a href={werkvorm.werkvormUrl} target='_blank' rel='noopener noreferrer'>{werkvorm.werkvormUrl}</a></div>
                    <div style={{flexGrow: 1}}/>
                    <CopyToClipboard text={werkvorm.werkvormUrl}
                                     onCopy={() => copyLink(werkvorm.werkvormUrl)}>
                        <Button circular color={copiedLinks[werkvorm.werkvormUrl] ? 'green' : 'grey'} icon={copiedLinks[werkvorm.werkvormUrl] ? 'check' : 'clipboard'}/>
                    </CopyToClipboard>
                </Segment>
            })}

        </AbstractZoekresultaatRenderer>
    )

}

export default WerkvormenUrlRenderer
