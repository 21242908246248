import React, {useEffect, useRef, useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Form, Divider, Image} from "semantic-ui-react";

import 'emoji-mart/css/emoji-mart.css'
import {Picker} from 'emoji-mart'


import {Chatline, ApplicationPath} from 'med-class'
import orderBy from 'lodash/orderBy'
import keyBy from 'lodash/keyBy'
import ChatlineRenderer from "./ChatlineRenderer";
import AdminsAvailable from "./AdminsAvailable";
import IconBar from "./IconBar";
import ChatlineInput from "./ChatlineInput";

const newMessageSound = new Audio(ApplicationPath.mediatheekAssetsFolder + 'sound/message.mp3')

const ChatContentBo = ({
                           client,
                           chat,
                           allChatlines,
                           chatService,
                           loginUser,
                           eigenaar,
                           injectedDefaultAnswer,
                           onRelatedFaqsChange,
                           beschikbaar,
                           onCloseChat,
                           adminsAvailable,
                           voornaam,
                           onSubmitChatline,
                           onSelectFile,
                           playSound
                       }) => {

    const [chatlines, setChatlines] = useState()
    const [whosTyping, setWhosTyping] = useState('')
    const [eigenaarRef, setEigenaarRef] = useState()
    const [isSuperAdmin, setIsSuperAdmin] = useState(false)
    const [chatId, setChatId] = useState() //for safety

    const chatlinesRef = useRef(null)

    useEffect(() => {
        if (!loginUser) return
        const roles = loginUser.roles.split(',')
        setIsSuperAdmin(roles.indexOf('superadmin') > -1)
    }, [loginUser])

    //CHATLINES
    useEffect(() => {
        if (!chat) return
        const fcl = orderBy(allChatlines.filter(cl => cl.data().chat === chat.id), [(cl) => cl.data().timestamp, (cl) => cl.data().order], ['asc', 'asc'])
        setChatlines(fcl)
        handleRelatedFaqs(fcl)
        scrollToLastChatline()
        
            

    }, [chat, allChatlines])

    //EIGENAAR
    useEffect(() => {
        if (!chat) return
        const er = chatService.db.doc('chats/' + chat.id).collection('whosTyping').doc(eigenaar)
        setEigenaarRef(er)
    }, [chat])

    //ISTYPING LISTENER
    useEffect(() => {
        if (!chat) return

        const snapshotListener = chatService.db
            .collection('chats/' + chat.id + '/whosTyping')
            .onSnapshot(docSnapshot => {

                let wT = ''
                const data = keyBy(docSnapshot.docs, doc => doc.id)
                for (const user in data) {
                    if (user !== eigenaar && data[user].data().isTyping)
                        wT = user
                }
                setWhosTyping(wT)
            })

        return () => snapshotListener()
    }, [chat])


    const scrollToLastChatline = () => {
        console.log('REF', chatlinesRef)
        if (chatlinesRef.current) window.setTimeout(() => chatlinesRef.current.scrollTop = chatlinesRef.current.scrollHeight, 300)
    }
    
    const handleRelatedFaqs = (chatlines) => {
        const chatlineString = chatlines.reduce((acc, chatline) => {
            if (chatline.data().bron === 'web' || chatline.data().bron === 'bo') return acc + ' ' + chatline.data().inhoud
            return acc
        }, '')

        chatService.getRelatedFaqs(chatlineString).then(res => {
            onRelatedFaqsChange(res)
        })

    }

    const onSubmit = (chatlineContent) => {
        const eigenaarAlreadyAnswered = chatlines.some(chatline => chatline.data().auteur === eigenaar)

        let status
        //eerste chatline van medewerker
        if (!eigenaarAlreadyAnswered) status = `${voornaam} neemt nu deel aan de chat`

        eigenaarRef.set({isTyping: false})

        //eventueel time stamp in status tonen

        onSubmitChatline(chatlineContent, status)

    }

    if (!chat || !beschikbaar) {
        return <div>Geen chat geselecteerd</div>
    }

    return (
        <Fragment>

            <div ref={chatlinesRef} className='chat-line-container'>

                {chatlines && chatlines.map((chatline, index) => {
                    return <ChatlineRenderer key={index} chatline={chatline} chatService={chatService} eigenaar={eigenaar}/>
                })}

            </div>

            <Divider/>

            <AdminsAvailable adminsAvailable={adminsAvailable}/>

            {whosTyping !== '' && <div className='is-typing'>
                <Image avatar src={ApplicationPath.avatarApi + eigenaar}/>
                {` ${whosTyping} is aan het typen...`}
            </div>}

            <Form>
                <Form.Field>

                    <ChatlineInput client={client}
                                   chatService={chatService}
                                   chat={chat}
                                   eigenaarRef={eigenaarRef}
                                   onSubmitChatline={onSubmit}
                                   onSelectFile={onSelectFile}
                                   injectedDefaultAnswer={injectedDefaultAnswer}
                                   showIconBar={true}/>

                </Form.Field>


                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    {chat && <Form.Button color='red' content='Afsluiten (voorzichtig!)' onClick={() => onCloseChat(chatlines)}/>}
                </div>
            </Form>


        </Fragment>
    )
}

export default ChatContentBo

ChatContentBo.propTypes = {
    allChatlines: PropTypes.array,
    adminsAvailable: PropTypes.array,
    beschikbaar: PropTypes.bool,
    chat: PropTypes.object,
    loginUser: PropTypes.object,
    eigenaar: PropTypes.string,
    voornaam: PropTypes.string,
    client: PropTypes.string, //web or bo
    createChat: PropTypes.func,
    onCloseChat: PropTypes.func,
    chatService: PropTypes.object,
    injectedDefaultAnswer: PropTypes.object,
    onRelatedFaqsChange: PropTypes.func,
    onSubmitChatline: PropTypes.func,
    onSelectFile: PropTypes.func,
    playSound: PropTypes.func
}

ChatContentBo.defaultProps = {}