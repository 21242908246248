import React, {useState, useEffect} from 'react'
import {Label, Icon} from 'semantic-ui-react'
import PropTypes from 'prop-types'

/*const KeyCodes = {
    comma: 188,
    enter: 13,
};*/

//const delimiters = [KeyCodes.comma, KeyCodes.enter];


const SeparatedStringInput = (props) => {

    //const [dataString, setDataString] = useState(props.dataString)
    const [dataprovider, setDataProvider] = useState()
    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        const createDataprovider = ({dataString, separator}) => {
            if (!dataString) return []
            const sa = dataString.split(separator).filter(item => item !== '')
            return sa
        }

        setDataProvider(createDataprovider(props))
    }, [props])

    const onDeleteItem = (event, item) => {
        event.stopPropagation()
        event.preventDefault()
        let dp = dataprovider.filter(dpItem => item !== dpItem)
        let ds = dataprovider.join(props.separator)
        setDataProvider(dp)
        //setDataString(ds)
        setInputValue('')
        props.onChange && props.onChange(ds, dp)
    }

    const onChange = (proxy) => {
        let inputValue = proxy.target.value
        let lastCharacter = inputValue.slice(-1)
        if (lastCharacter === props.separator)
            return

        setInputValue(inputValue)
    }

    const onKeyDown = (event) => {
        if (event.keyCode === 13) {

            let newItem = inputValue
            if (dataprovider.indexOf(newItem) > -1) {
                setInputValue('')
                return
            }
            let dp = [...dataprovider, newItem]
            let ds = dp.join(props.separator)
            setDataProvider(dp)
            //setDataString(ds)
            setInputValue('')
            console.log(dp, ds, event)
            props.onChange && props.onChange(ds, dp)


            event.stopPropagation()
            event.preventDefault() //bloody hell!!!
        }
    }


    if (!dataprovider)
        return <div/>


    return (
        <div className="si-wrapper-div">
            {
                dataprovider.map((item, index) => {
                    return (<Label className='si-label' as='a' key={index} onClick={() => props.onItemClick && props.onItemClick(item)}>
                        {item}
                        <Icon name='delete' onClick={(event) => onDeleteItem(event, item)}/>
                    </Label>)
                })
            }

            <input className="si-input-field" placeholder={props.placeholder} value={inputValue} onChange={onChange} onKeyDown={onKeyDown}/>
        </div>
    )

}

export default SeparatedStringInput

SeparatedStringInput.propTypes = {
    separator: PropTypes.string,
    dataString: PropTypes.string,
    onChange: PropTypes.func,
    onItemClick: PropTypes.func,
    placeholder: PropTypes.string
}

SeparatedStringInput.defaultProps = {
    separator: ',',
    dataString: '',
    placeholder: 'Voeg item toe'
}
