import React, {useState, useRef, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Icon, Segment} from "semantic-ui-react";

const FileInputIcon = ({accept, icon, onFileSelected}) => {

    const fileInputRef = useRef()

    return (
        <Fragment>
            <Icon name={icon} size='large' onClick={() => fileInputRef.current.click()} link/>

            <input
                ref={fileInputRef}
                type="file"
                hidden
                accept={accept}
                onChange={onFileSelected}
            />
        </Fragment>
    )
}

export default  FileInputIcon

FileInputIcon.propTypes = {
    accept: PropTypes.string,
    icon: PropTypes.string,
    onFileSelected: PropTypes.func
}

FileInputIcon.defaultProps = {

}