export class Faq {

    _explicitType: string = 'Faq'

    id: number = 0
    visits: number = 0
    upvotes: number = 0

    teaser: string = ''
    question: string = ''
    answer: string = ''
    date: string = ''
    tags: string = ''
    wdTags: string = ''
    zichtbaarheid: string = ''
    datum_wijziging: string = ''
    user_aanmaak: number = 0
    user_wijziging: number = 0
    restfulLink: string = ''
}
