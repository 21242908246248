import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {Segment, Image, Header, Button, Card, Divider} from "semantic-ui-react"


import Parser, {domToReact} from 'html-react-parser'

const WieIsWie = ({users, localeManager, enableLinks, userLocaleDataService}) => {

    const [selectedUser, setSelectedUser] = useState()
    const [profieltekst, setProfieltekst] = useState('')
    const [zwartWit, setZwartWit] = useState(false)


    const onImageClick = (user) => {
        userLocaleDataService.getLocaleData(user.id).then(() => {
            const profieltekst = userLocaleDataService.getPropertyValue(user.id, 'profieltekst', localeManager.locale)
            setSelectedUser(user)
            setProfieltekst(profieltekst)
        })
    }

    const onSluitButtonClick = () => {
        setSelectedUser(null)
    }


    return (
        <Segment>
            <Header onClick={() => setZwartWit(!zwartWit)}>{localeManager.getString('WIE_IS_WIE')}</Header>

            {!selectedUser &&
            <div className='wie-is-wie'>{users.map((user => {
                return (<Image className='wie-is-wie' style={{marginRight: '10px'}} as='a' key={user.id} size='tiny' src={user.profielFotoUrl} name={user.id} onClick={() => onImageClick(user)}/>)
            }))}
            </div>}

            {selectedUser &&
            <div>

                <Card link={false} as="div" style={{border: 'none'}} fluid>
                    <Card.Content>
                        <Image className='wie-is-wie' floated='left' size='small' bordered src={selectedUser.profielFotoUrl} onError={e => e.target.style.display = 'none'}/>

                        <Card.Header>{selectedUser.naam}</Card.Header>

                        <Card.Meta>
                            {localeManager.getString(selectedUser.affiliatie)}
                        </Card.Meta>

                        <Card.Description>
                            {Parser(profieltekst, {replace: ({attribs, children}) => !enableLinks && attribs && attribs.href && <strong>{domToReact(children)}</strong>})}
                        </Card.Description>

                        <Divider/>
                        {enableLinks &&
                        <Card.Description>
                            <a href={`mailto:${selectedUser.mail}`}>{selectedUser.mail}</a>
                        </Card.Description>
                        }
                    </Card.Content>

                    <Button onClick={onSluitButtonClick}>{localeManager.getString('SLUIT')}</Button>
                </Card>
            </div>}
        </Segment>
    )

}

export default WieIsWie

WieIsWie.propTypes = {
    users: PropTypes.array.isRequired,
    localeManager: PropTypes.object.isRequired,
    userLocaleDataService: PropTypes.object.isRequired,
    enableLinks: PropTypes.bool
}

WieIsWie.defaultProps = {
    enableLinks: true
}