

export class ZoekfilterData {

    data = {}
    filterTekst = ''
    mode
    localeManager
    restoreState
    bewaarZoekopdrachtEnabled = false

    constructor(mode, localeManager) {
        this.mode = mode
        this.localeManager = localeManager
    }

    addItem = (name, item) => {
        if (!this.data[name]) this.data[name] = []
        if (this.data[name].indexOf(item) < 0) this.data[name].push(item)
    }

    removeItem = (name, item) => {
        if (!this.data[name]) return
        if (this.data[name].indexOf(item) > -1) this.data[name] = this.data[name].filter(it => it !== item)
        if (this.data[name].length === 0) delete this.data[name]
    }

    removeAllItems = (name) => {
        this.data[name] = []
    }

    clearProp = (name) => {
        delete this.data[name]
    }

    get taxonomieIdArray() {
        const ra = []
        for (var prop in this.data) {
            for (let item of this.data[prop]) {
                if (item._explicitType === 'TaxonomieItem')
                    ra.push(item.id)
            }
        }
        return ra
    }

    toString = () => {
        let rs = ''
        for (var prop in this.data) {
            let ds = ''
            if (rs !== '') rs += ' | '
            rs += this.localeManager.getString(prop) + ': '

            for (let item of this.data[prop]) {
                if (ds !== '') ds += ', '
                ds += this.localeManager.getString(item.label.replace(/ /g, '_'))
            }
            rs += ds
        }

        return rs
    }

    toZoekOptieString = () => {
        let rs = ''
        for (var prop in this.data) {
            let ds = ''
            if (rs !== '') rs += '|'
            rs += prop + ':'

            for (let item of this.data[prop]) {
                if (ds !== '') ds += ','
                if (item.key) ds += item.key + '='
                ds += item.value
            }
            rs += ds
        }

        return rs
    }

    fromBewaardeZoekopdracht = (bewaardeZoekopdracht, taxonomieService, mediatheekService) => {
        this.mode = bewaardeZoekopdracht.mode
        this.data = {}
        const dataArr = bewaardeZoekopdracht.zoekOptieString.split('|')
        for (const dataArrElement of dataArr) {
            const lijnArr = dataArrElement.split(':')
            const label = lijnArr[0]
            const dataStrArr = lijnArr[1].split(',')
            const dataArr = []

            switch(label){
                case 'mediatheek':
                    for (const dataStrArrElement of dataStrArr) {
                        dataArr.push(mediatheekService.mediatheekCampusCodeLookup[dataStrArrElement])
                    }
                    break
                case 'publicatiejaar':
                    for (const dataStrArrElement of dataStrArr) {
                        const voornaArr = dataStrArrElement.split('=')
                        dataArr.push({key: voornaArr[0], value: parseInt(voornaArr[1]), label: voornaArr.join(' ')})
                    }
                    break
                default:
                    for (const dataStrArrElement of dataStrArr) {
                        dataArr.push(taxonomieService.getTaxonomieItemByLabel(dataStrArrElement))
                    }
                    break
            }
            this.data[label] = dataArr
        }

    }
}