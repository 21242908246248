import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Header, Segment, Popup, Modal, Icon} from "semantic-ui-react";
import {Chatline} from 'med-class'
import Parser from 'html-react-parser'

const ChatDefaultAnswers = ({chatService, onDefaultAnswerClick, chat, config}) => {

    const [sections, setSections] = useState()
    //const [config, setConfig] = useState()

    useEffect(() => {
        const s = []
        for (const section in config.defaultAnswers) {
            s.push(section)
        }
        setSections(s)
    }, [config])

    if (!sections || !config) return null

    if (!chat) return null

    return (
        <div>
            <Header>Snelle antwoorden</Header>
            <div>Klik op een antwoord om het in te voegen in de chat. Het wordt niet onmiddellijk verstuurd dus je kan het eerst nog aanpassen.</div>

            <Segment>{sections.map((section, index) => {
                return <Fragment key={index}>
                    <Header dividing size='small'>{section}</Header>
                    {config.defaultAnswers[section].map((dAnswer, index) => {
                        return <Popup key={index} inverted basic mouseEnterDelay={500} trigger={
                            <div>
                                <p style={{fontSize: '0.9em', marginBottom: '5px'}}><em>{dAnswer.annotation}</em></p>
                                <div style={{flexFlow: 'row', cursor: 'pointer'}} className='chat-line right' onClick={() => onDefaultAnswerClick(dAnswer)}>
                                    <div className='chat-line-content'><p>{dAnswer.title}</p></div>
                                </div>
                            </div>
                        }>
                            {dAnswer.content}
                        </Popup>
                    })}
                </Fragment>
            })}</Segment>

        </div>
    )
}

export default ChatDefaultAnswers

ChatDefaultAnswers.propTypes = {
    chatService: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    onDefaultAnswerClick: PropTypes.func,
    chat: PropTypes.object,
}

ChatDefaultAnswers.defaultProps = {}