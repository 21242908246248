import React from 'react'
import PropTypes from 'prop-types'
import {Button} from "semantic-ui-react";

const Parser = require('html-react-parser')



const InformatieOpMaatDetailTile = ({data, localeManager, arteveldeUser}) => {

    const onTileClick = () => {

    }

    return (
        <div style={{backgroundColor: '#eff6fa', position: 'relative', width: '250px', height: '150px', fontSize: '1.1em', fontWeight: 'bold', cursor: 'pointer'}} onClick={onTileClick}>
            <div className='small-tile-text'>
                {Parser(localeManager.getString(data.label))}
            </div>
            <div style={{display: 'flex', alignItems: 'center', width: '100%', position: 'absolute', bottom: 0, height: '60px', backgroundColor: data.color, padding: '10px', fontSize: '0.0em'}}>
                <a href={data.link} target='_blank' rel='noopener noreferrer'><Button>{localeManager.getString('TOON_CURSUS')}</Button></a>
                {arteveldeUser.loggedIn && <a href={data.secretLink} target='_blank' rel='noopener noreferrer'><Button>{localeManager.getString('INSCHRIJVEN')}</Button></a>}

            </div>
        </div>
    )
}

export default  InformatieOpMaatDetailTile

InformatieOpMaatDetailTile.propTypes = {
    data: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    arteveldeUser: PropTypes.object.isRequired,
}

InformatieOpMaatDetailTile.defaultProps = {

}