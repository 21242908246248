import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'

const Foo = (props) => {

    return (
        <div>Hello Foo</div>
    )
}

export default  Foo

Foo.propTypes = {

}

Foo.defaultProps = {

}
