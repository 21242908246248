import {ApplicationPath} from "../domain/path";
import {convertObjectToClass} from "../util/ClassConvertor";
import {Feedback} from "../class/feedback/Feedback";
import {FeedbackStatus} from "../class/feedback/FeedbackStatus";
const axios = require('axios')
const _ = require('lodash')


export class FeedbackService {

    private readonly FEEDBACK_TABLE: string = 'feedback'

    initialized: boolean = false
    feedback: Array<Feedback>
    feedbackLookup: object
    apiUrl: string
    apiKey: string

    constructor(apiUrl: string, apiKey: string) {
        this.apiUrl = apiUrl
        this.apiKey = apiKey
        this.feedback = []
        this.feedbackLookup = {}
    }

    init = () => {

        let me = this

        return new Promise((resolve) => {

                if (me.initialized) {
                    resolve({status: 'ok', feedback: me.feedback})
                    return
                }

                this.getFeedback().then((response: any) => {

                    me.initialized = true
                    me.feedback = response.feedback
                    me.feedbackLookup = response.feedbackLookup

                    resolve({status: 'ok', feedback: me.feedback})
                }).catch((error) => {
                    console.log('ERROR IN Feedback INIT', error)
                })
            }
        )

    }

    private getFeedback = () => {

        let me = this

        return new Promise(function (resolve, reject) {
            let url = `${me.apiUrl}${me.FEEDBACK_TABLE}?filter[]=status,neq,${FeedbackStatus.AFGEHANDELD}&transform=1&token=${me.apiKey}`
            axios.get(url)
                .then(response => {
                    let rawFeedbackList = response.data[me.FEEDBACK_TABLE];
                    let feedback = rawFeedbackList.map((rt: any) => convertObjectToClass(rt, 'Feedback'))
                    feedback = _.orderBy(feedback, [feedback => feedback.datum], ['desc'])
                    let feedbackLookup = _.keyBy(feedback, feedback => feedback.id)
                    resolve({feedback, feedbackLookup})
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }

    updateFeedback = (feedback) => {

        let me = this

        let url = `${me.apiUrl}${me.FEEDBACK_TABLE}/${feedback.id}?&token=${me.apiKey}`

        return new Promise((resolve, reject) => {
                axios.put(url, feedback)
                    .then(() => {
                        feedback = convertObjectToClass(feedback, 'Feedback')
                        me.feedback = me.feedback.map(t => {
                            if (t.id === feedback.id)
                                return feedback;
                            return t;
                        })
                        me.feedbackLookup = _.keyBy(me.feedback, feedback => feedback.id)
                        resolve({feedback: me.feedback, feedbackLookup: me.feedbackLookup})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    createFeedback = (feedback) => {

        let me = this

        return new Promise((resolve, reject) => {
                let url = `${me.apiUrl}${me.FEEDBACK_TABLE}/?token=${me.apiKey}`
                axios.post(url, feedback)
                    .then(response => {
                        feedback.id = response.data;
                        me.feedback = _.concat(me.feedback, feedback)
                        me.feedback = _.orderBy(me.feedback, [feedback => feedback.datum])
                        me.feedbackLookup = _.keyBy(me.feedback, feedback => feedback.id)
                        resolve({feedbackLookup: me.feedbackLookup, feedback: me.feedback, newFeedback: feedback})

                        //doorsturen naar userApi
                        const formObject = {function: 'submitFeedback', feedback}
                        console.log('SENDING TO USER API', formObject)
                        axios.post(ApplicationPath.userApiUrl, formObject).then(response => {
                            //het feedback object wordt teruggestuurd van de server met een aangepaste status
                            const alteredFeedback = convertObjectToClass(response.data, 'Feedback')
                            console.log('FEEDBACK RESPONSE', alteredFeedback)
                            me.updateFeedback(alteredFeedback)
                        })
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    deleteFeedback = (feedback) => {

        let me = this
        return new Promise((resolve, reject) => {
                const url = `${me.apiUrl}${me.FEEDBACK_TABLE}/${feedback.id}?token=${me.apiKey}`

                axios.delete(url)
                    .then(() => {
                        //remove from feedback and lookup
                        me.feedback = _.filter(me.feedback, t => t.id !== feedback.id)
                        me.feedbackLookup = _.keyBy(me.feedback, feedback => feedback.id)

                        resolve({feedback: me.feedback, feedbackLookup: me.feedbackLookup})
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error);
                    });
            }
        )
    }

    wijsFeedbackToe = (feedback, user) => {

        let me = this

        return new Promise(function (resolve, reject) {
            let url = `${me.apiUrl}${me.FEEDBACK_TABLE}/${feedback.id}/?transform=1&token=${me.apiKey}`
            console.log('URL', url)
            axios.get(url)
                .then(response => {
                    const serverFeedback = response.data
                    const toewijsbaar = serverFeedback.status === FeedbackStatus.NIEUW && serverFeedback.behandelaar === 0
                    console.log('WIJS', serverFeedback, toewijsbaar)
                    if (toewijsbaar){
                        feedback.behandelaar = user.id
                        feedback.status = FeedbackStatus.TOEGEWEZEN
                        me.updateFeedback(feedback).then(() => resolve({toegewezen: true, feedback}))
                    } else {
                        feedback.behandelaar = serverFeedback.behandelaar
                        feedback.status = serverFeedback.status
                        me.updateFeedback(feedback).then(() => resolve({toegewezen: false, feedback}))
                    }
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                })

        })
    }
}
