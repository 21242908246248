import React, {useState, useEffect, useRef, useCallback, Fragment} from 'react'
import PropTypes from 'prop-types'
import Parser from 'html-react-parser'
import {Input, Transition, Header, Button} from "semantic-ui-react";
import {Chatline, SoundPath} from 'med-class'
import ChatContentWeb from "./ChatContentWeb";

import './test.css'

const CLIENT = 'web'
const BOT = 'bot'
const PATIENCE_MESSAGE_INTERVAL = 30000

const patienceMessages = [
    'Het duurt wat langer dan verwacht, nog even geduld aub.',
    'Blijkbaar is je vraag nog niet in behandeling. Er zal spoedig iemand antwoorden.',
    'Je staat nog steeds in de wachtrij. Mogen we je nog even geduld vragen?'
]


const WebComponentChat = ({chatService, userService, arteveldeUser, loggedIn, onFaqLinkClick, onChatlineClick, testMode}) => {

    const [chatVisible, setChatVisible] = useState(false)
    const [adminAvailable, setAdminAvailable] = useState(false)
    const [adminsAvailable, setAdminsAvailable] = useState([])
    const [chatEigenaar, setChatEigenaar] = useState()
    const [chat, setChat] = useState()
    const [chatStatus, setChatStatus] = useState('idle')
    const [voornaam, setVoornaam] = useState('')
    const [soundInitialized, setSoundInitialized] = useState(false)
    const [balloonVisible, setBalloonVisible] = useState(true)
    const [config, setConfig] = useState()
    const [patienceTimer, setPatienceTimer] = useState()
    const [sendPatienceMessage, setSendPatienceMessage] = useState(false)
    const [patienceMessagePointer, setPatienceMessagePointer] = useState(0)
    const [chatAnswered, setChatAnswered] = useState(false)


    useEffect(() => {
        chatService.getChatConfig().then(res => {
            setConfig(res)
        })
    }, [chatService])

    //https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
    const voornaamInputRef = useCallback(node => {
        if (node !== null) {
            setTimeout(() => node.focus(), 100)
        }
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => setBalloonVisible(false), 10000)
        return () => clearTimeout(timeout)
    }, [])

    //ARTEVELDEUSER
    useEffect(() => {
        if (!arteveldeUser) return
        setChatEigenaar(arteveldeUser.loggedIn ? arteveldeUser.emailAddress : null)

        //checken of er nog een open chat is
        checkOpenChat()


    }, [arteveldeUser, arteveldeUser.loggedIn])

    //ONLINE LISTENER
    useEffect(() => {

        chatService.db.collection('admin').onSnapshot(docSnapshot => {
            const admins = docSnapshot.docs.map(doc => doc.data()).filter(admin => admin.beschikbaar === true)
            setAdminsAvailable(admins)
            setAdminAvailable(admins.length > 0)
        })
    }, [chatService])

    useEffect(() => {
        if (!chat) return

        if (sendPatienceMessage && patienceMessagePointer < patienceMessages.length && !chatAnswered){
            createChatLine(chat, patienceMessages[patienceMessagePointer], null, true)
            setSendPatienceMessage(false)
            setPatienceMessagePointer(patienceMessagePointer+1)
        }


    }, [sendPatienceMessage])

    const checkOpenChat = () => {
        if (!arteveldeUser.emailAddress || arteveldeUser.emailAddress === '') return

        chatService.db.collection('chats')
            .where('eigenaar', '==', arteveldeUser.emailAddress)
            .where('status', '==', 'open')
            .get().then(res => {

            if (res && res.docs.length > 0) {
                setChat(res.docs[0].ref)
                setChatStatus('active')
                setChatVisible(true)
            }

        })


    }

    const onChatHeaderClick = () => {
        //init sound
        if (!soundInitialized) {
            let audio = new Audio(SoundPath.silence);
            audio.play()
            setSoundInitialized(true)
        }

        setChatVisible(!chatVisible)
    }

    const playSound = (snd) => {
        let audio = new Audio(SoundPath[snd]);
        if (soundInitialized) audio.play()
    }

    const onSubmitChatline = (chatline) => {
        if (!chat) {
            chatService.createChat(chatEigenaar, arteveldeUser).then(newChat => {
                createChatLine(newChat, chatline, 'Chat gestart')
                setChat(newChat)
                setChatStatus('active')
                const pt = setInterval(handleNoResponse, PATIENCE_MESSAGE_INTERVAL)
                setPatienceTimer(pt)
                setChatAnswered(false)
                setPatienceMessagePointer(0)
            })
        } else {
            createChatLine(chat, chatline)
        }
    }

    const processChatlines = (chatlines) => {

        if (!chatAnswered)
            for (const chatline of chatlines) {
                if (chatline.data().bron === 'bo'){
                    setChatAnswered(true)
                    clearInterval(patienceTimer)
                    setPatienceTimer(null)
                }
            }
    }

    const handleNoResponse = () => {
        setSendPatienceMessage(true)
    }

    const createChatLine = (chat, content, status, isBot) => {
        const chatline = Chatline()

        chatline.chat = chat.id
        chatline.inhoud = content
        chatline.bron = isBot ? BOT : CLIENT
        chatline.auteur = isBot ? BOT : chatEigenaar

        //if (testMode) chatline.inhoud = 'TEST: NIET ANTWOORDEN ' + content

        chatService.createChatline(chat, chatline, status).then(res => {
            console.log('NEW CHATLINE CREATED', res)
        })
    }

    const onCloseChat = (chatlines, rating, eigenaar, kopieNaarEigenaar) => {
        chatService.closeChat(chat, chatlines, rating, userService, eigenaar, kopieNaarEigenaar).then(res => {
            setChat(null)
            setChatStatus('idle')
            setChatVisible(false)
        })

    }

    const onClickFaqLink = () => {
        setChatVisible(false)
        onFaqLinkClick && onFaqLinkClick()
    }

    const onChangeVoornaam = (event, {value}) => {
        if (value.length > 50) return

        setVoornaam(value)
    }

    const onKeyDownVoornaam = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault()
            if (voornaam === '') return

            setChatEigenaar(voornaam)
        }
    }

    const onSelectFile = (file, progressFunction) => {

        if (!chat || !arteveldeUser.loggedIn) return

        return new Promise((resolve) => {
            const chatline = Chatline()
            chatline.chat = chat.id
            chatline.bron = CLIENT
            chatline.auteur = chatEigenaar
            chatService.uploadFile(chatline, file, progressFunction).then(res => {
                resolve(res)
            })
        })
    }


    if (!config) return null

    return (
        <div className='chat-client-container'>

            {/*{adminAvailable && balloonVisible && <div className='new-balloon'>
                <Header>Probeer onze nieuwe chatfunctie!</Header>
            </div>}*/}

            <div style={{
                backgroundColor: `${adminAvailable ? 'green' : 'grey'}`,
                padding: '20px',
                color: 'white',
                borderRadius: '15px 15px 0 0',
                cursor: 'pointer',
                width: '300px',
                right: 0
            }} onClick={onChatHeaderClick}>
                {adminAvailable && <div>{Parser(config.webMessages.online)}</div>}
                {!adminAvailable && <div>{Parser(config.webMessages.offline)}</div>}
            </div>


            <Transition.Group animation='slide up' duration={500}>


                {chatVisible && <div style={{
                    border: '1px solid teal',
                    padding: '20px', width: '100%', backgroundColor: 'white'
                }}>

                    {adminAvailable && chatVisible && !chatEigenaar && chatStatus === 'idle' &&
                    <Fragment>
                        <Header size='small'>Welkom. Je kan inloggen of je mail adres hieronder ingeven om de chat te starten.</Header>
                        <Input ref={voornaamInputRef} style={{marginBottom: '10px'}}
                               fluid placeholder='Typ uw mail adres' name='voornaam'
                               value={voornaam} onChange={onChangeVoornaam} onKeyDown={onKeyDownVoornaam}/>
                    </Fragment>}


                    {adminAvailable && chatEigenaar &&
                    <ChatContentWeb client={CLIENT}
                                    chat={chat}
                                    chatService={chatService}
                                    eigenaar={chatEigenaar}
                                    adminsAvailable={adminsAvailable}
                                    onCloseChat={onCloseChat}
                                    chatStatus={chatStatus}
                                    onChatlineClick={onChatlineClick}
                                    arteveldeUser={arteveldeUser}
                                    onSubmitChatline={onSubmitChatline}
                                    onSelectFile={onSelectFile}
                                    playSound={playSound}
                                    processChatlines={processChatlines}/>
                    }

                    {!adminAvailable &&
                    <div>
                        Er zijn geen medewerkers beschikbaar momenteel. Je kan altijd onze <span style={{cursor: 'pointer', color: 'blue'}} onClick={onClickFaqLink}>Faq</span> raadplegen!
                    </div>}
                </div>}


            </Transition.Group>
        </div>
    )
}

export default WebComponentChat

WebComponentChat.propTypes = {
    chatService: PropTypes.object.isRequired,
    userService: PropTypes.object.isRequired,
    arteveldeUser: PropTypes.object.isRequired,
    loggedIn: PropTypes.bool,
    onFaqLinkClick: PropTypes.func,
    onChatlineClick: PropTypes.func,
    testMode: PropTypes.bool
}

WebComponentChat.defaultProps = {
    testMode: false
}
