export class XMLParser {
	
	
	
	constructor(xml: string | Document) {
		
		if (typeof xml === "string"){
			this.xmlString = xml
			this.xmlDoc = this.parseXml(xml)
		} else if (typeof xml === 'object') {
			this.xmlString = new XMLSerializer().serializeToString(xml)
			this.xmlDoc = this.parseXml(this.xmlString)
		}
		this.serializer = new XMLSerializer()
	}
	
	xmlString: string = ''
	xmlDoc!: Document //https://github.com/kaorun343/vue-property-decorator/issues/81
	private serializer
	
	private parseXml = (xmlString: string) => {
		let parser = new DOMParser()
		return parser.parseFromString(xmlString, "text/xml")
	}
	
	getXmlString = (node?: HTMLElement) => {
		if (node){
			return this.serializer.serializeToString(node)
		} else {
			return this.serializer.serializeToString(this.xmlDoc)
		}
		
	}
	
	getRoot = () => {
		return this.xmlDoc.documentElement
	}
	
	getNodeCollection = (nodeName: string, fromNode?:HTMLElement) => {
		if (!fromNode)
			return this.xmlDoc.getElementsByTagName(nodeName)
		
		return fromNode.getElementsByTagName(nodeName)
	}
	
	getFirstNodeWithName = (nodeName: string, fromNode?: HTMLElement) => {
		if (!fromNode)
			return this.xmlDoc.getElementsByTagName(nodeName)[0]
		
		return fromNode.getElementsByTagName(nodeName)[0]
	}
	
	getNodeAttribute = (node: HTMLElement, attr: string) => {
		let attrVal = node.getAttribute(attr)
		if (attrVal)
			return attrVal
		
		return ''
	}
	
	setNodeAttribute = (node: HTMLElement, attr: string, value: any) => {
		node.setAttribute(attr, value)
	}
	
	getNodeLabel = (node: HTMLElement) => {
		const textNodes = node.getElementsByTagName('label')[0].childNodes
		//wanneer er geen test is, dan is deze lijst leeg
		if (textNodes.length === 0)
			return ''
		
		let label = textNodes[0].nodeValue
		if (label)
			return label
		
		return ''
	}
	
	setNodeLabel = (node: HTMLElement, labelValue: string) => {
		const labelNode = node.getElementsByTagName('label')[0]
		if (labelNode.childNodes.length === 0){
			labelNode.appendChild(this.xmlDoc.createTextNode(labelValue))
		} else {
			node.getElementsByTagName('label')[0].childNodes[0].nodeValue = labelValue
		}
	}
	
	/*getNodeContent = (node: HTMLElement) => {
		return node.innerHTML
	}*/
	
	getFirstNodeWithAttributeValue = (attr: string, attrValue: string, fromNode?: HTMLElement) => {
		for (let node of this.getAllNodes(fromNode)){
			if (node.hasAttribute(attr) && node.getAttribute(attr) === attrValue)
				return node
		}
		
		return null
	}
	
	getAllNodes = (fromNode?: HTMLElement) => {
		let ra: HTMLElement[] = []
		let startNode: HTMLElement = fromNode || this.getRoot()
		ra.push(startNode)
		this.appendAllChildren(startNode, ra)
		return ra
	}
	
	private appendAllChildren = (startNode, collection) => {
		if (startNode.childNodes){
			for (let i=0; i < startNode.childNodes.length; i++){
				let child = startNode.childNodes[i]
				if (child.nodeType !== 3){
					collection.push(child)
					if (child.childNodes)
						this.appendAllChildren(child, collection)
				}
			}
		}
	}
	
	getNodesWithAttributeValue = (attr: string, attrValue: string, fromCollection?: HTMLElement[]) => {
		
		const collection: HTMLElement[] = fromCollection || this.getAllNodes()
		
		return (Array.from(collection).filter(node => {
			return (node.hasAttribute(attr) && node.getAttribute(attr) === attrValue)
		}))
	}
	
	getNodeById = (id: string) => {
		return this.xmlDoc.getElementById(id)
	}
	
	getNodeAsSring = (node: HTMLElement) => {
		return new XMLSerializer().serializeToString(node)
	}
	
	removeChildrenOfNode = (parent: HTMLElement) => {
		for (let child of Array.from(parent.children)){
			parent.removeChild(child)
		}
		return parent
	}
	
	createNode = (nodeName: string) => {
		return this.xmlDoc.createElement(nodeName)
	}
	
	createNodeFromXmlString = (xmlString: string) => {
		let node = this.xmlDoc.createElement('foo')
		node.innerHTML = xmlString
		
		return node.firstChild
	}
	
	appendNode = (parent, node) => {
		parent.append(node)
	}
	
	replaceNode = (oldNode, newNode) => {
		let parent = oldNode.parentNode
		return parent.replaceChild(newNode, oldNode)
	}
	
	removeNode = (node: HTMLElement) => {
		const parent: Node | null = node.parentNode
		if (parent)
			parent.removeChild(node)
	}
	
}