import React, {useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Modal, Button, Divider, Header} from 'semantic-ui-react'
import TijdsslotRenderer from "./TijdsslotRenderer";
import {ZoekresultaatListItem} from "web-component-zoek";
import 'moment/locale/nl'
import moment from 'moment'
import Spacer from "./Spacer";

const KiesTijdsslotModal2 = ({tijdslots, mediatheek, zoekresultaat, defaultSelectedSlot, localeManager, serverResponseObject, mediatheekService, mijnMediatheekService, arteveldeUser, onKiesTijdsslot}) => {

    const [modalOpen] = useState(true)
    const [slotButtonString, setSlotButtonString] = useState('')
    const [selectedSlot, setSelectedSlot] = useState()

    const handleClose = () => {
        onKiesTijdsslot(null)
    }

    const onSlotSelect = (slot) => {
        setSelectedSlot(slot)
        const van = moment(slot.van).locale(localeManager.locale)
        const tot = moment(slot.tot).locale(localeManager.locale)
        //mandje.tijdsslot = slot
        setSlotButtonString(`${localeManager.getString('AFHAALMANDJE')} ${van.format('ddd DD MMM YYYY')} ${localeManager.getString('TUSSEN')} ${van.format('HH:mm')} ${localeManager.getString('EN')} ${tot.format('HH:mm')}`)

    }

    if (!tijdslots || !mediatheek) return null

    return (
        <Modal
            open={modalOpen}
            onClose={handleClose}
            dimmer='inverted'

        >
            <Header icon='shopping basket' content={`${localeManager.getString('AFHAALMANDJE_VOOR_MEDIATHEEK')} ${mediatheek.naam}`}/>
             <Modal.Content>

                <TijdsslotRenderer tijdslots={tijdslots} defaultSelectedSlot={defaultSelectedSlot} localeManager={localeManager} onSlotSelect={onSlotSelect}/>

                <Divider/>

                 {zoekresultaat && <Fragment>
                     {localeManager.getString('TITEL_ZAL_TOEGEVOEGD_WORDEN_AAN_AFHAALMANDJE')}
                     <ZoekresultaatListItem zoekresultaat={zoekresultaat}
                                            arteveldeUser={arteveldeUser} mijnMediatheekService={mijnMediatheekService} localeManager={localeManager}
                                            onCardClick={() => console.log('CARD CLICK')}/>
                 </Fragment>}

            </Modal.Content>

            <Modal.Actions>
                <div>
                    <div><h3>{slotButtonString}</h3></div>
                    <Spacer vertical distance={10}/>
                </div>

                <Button onClick={handleClose}>
                    {localeManager.getString('ANNULEER')}
                </Button>
                <Button color='green' onClick={() => onKiesTijdsslot(selectedSlot)} disabled={slotButtonString === ''}>
                    {localeManager.getString('SELECTEER_DIT_SLOT')}
                </Button>
            </Modal.Actions>
        </Modal>

    )
}

export default KiesTijdsslotModal2

KiesTijdsslotModal2.propTypes = {
    mediatheek: PropTypes.object.isRequired,
    tijdslots: PropTypes.object.isRequired,
    zoekresultaat: PropTypes.object,
    localeManager: PropTypes.object.isRequired,
    mijnMediatheekService: PropTypes.object.isRequired,
    mediatheekService: PropTypes.object.isRequired,
    arteveldeUser: PropTypes.object.isRequired,
    onKiesTijdsslot: PropTypes.func.isRequired,
}

KiesTijdsslotModal2.defaultProps = {}