import React from 'react'
import PropTypes from 'prop-types'
import VERREKIJKER from '../../assets/verrekijker.jpeg'
const Parser = require('html-react-parser')

const InformatieOpMaatTile = ({localeManager, onClick}) => {


    return (
        <div className='small-tile' onClick={onClick}>
            <img src={VERREKIJKER} alt='VERREKIJKER'/>
            <div className='small-tile-text'>
                {Parser(localeManager.getString('INFORMATIE_OP_MAAT'))}
            </div>
        </div>
    )
}

export default InformatieOpMaatTile

InformatieOpMaatTile.propTypes = {
    localeManager: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
}

InformatieOpMaatTile.defaultProps = {}