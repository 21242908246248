import React, {useState, useEffect, useRef, Fragment} from 'react';
import {withCookies} from 'react-cookie'
import {Segment, Grid, Header} from 'semantic-ui-react'
import CookieConsent from "react-cookie-consent"
import ReactGA from 'react-ga';
import axios from 'axios'
import {
    API_KEY,
    API_BASE_URL,
    API_ROOSTERING_BASE_URL,
    ApplicationPath,
    TaxonomieService,
    MediatheekService,
    SpelwijzerService,
    SocialService,
    MijnMediatheekService,
    RoosteringService,
    UserService,
    NieuwsService,
    QuoteService,
    FaqService,
    RubriceringService,
    ZoekService,
    LocaleManager,
    ArteveldeUser,
    Cookie,
    LocaleDataService,
    AdminService,
    ChatService,
    DirectLink
} from 'med-class'

import {WebsiteHeader, WebsiteFooter, NewChangelogModal} from "med-react-component"

//MODULES
import WebComponentDigitaleMediatheek from "web-component-digitale-mediatheek"
import WebComponentMediatheken from "web-component-mediatheken"
import WebComponentNieuws from "web-component-nieuws"
import WebComponentFaq from "web-component-faq"
import WebComponentMijnMediatheek from "web-component-mijn-mediatheek"
import WebComponentZoek from "web-component-zoek"
import {UitgelichtTile} from "web-component-zoek"
import {WebComponentChat} from "med-component-chat"


import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/storage";


//TILES
import QuoteTile from './component/quote/QuoteTile'
import DringendNieuws from "./component/DringendNieuws"
import Tiles from "./component/Tiles";

const ip = require('ip')
const publicIp = require('public-ip')
const version = require('./version')
const arteveldeOnCampusAddresses = [['193.191.137.192', '193.191.137.254', '193.191.137.205'], ' 81.83.4.0', '81.82.199.76']
const isLocalHost = window.location.hostname === "localhost"
const DISPLAY_UITGELICHT = 'uitgelicht'
const DISPLAY_QUOTE = 'quote'
const UITGELICHT_TIMEOUT_ZOEKRESULTAAT = 2 * 60 * 1000
const UITGELICHT_TIMEOUT_QUOTE = 15000


ReactGA.initialize('UA-9057548-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function App({cookies}) {


    //STATE
    //const [PHPSESSID] = useState(cookies.get(Cookie.PHPSESSID))
    const [loginData_upn] = useState(cookies.get(Cookie.LOGINDATA_UPN, {path: '/'}))

    const [chatService, setChatService] = useState()

    const [taxonomieService] = useState(new TaxonomieService(API_BASE_URL, API_KEY))
    const [taxonomieServiceInitialized, setTaxonomieServiceInitialized] = useState(false)

    const [mediatheekService] = useState(new MediatheekService(API_BASE_URL, API_KEY, ApplicationPath.openingsurenApiUrl))
    const [mediatheekServiceInitialized, setMediatheekServiceInitialized] = useState(false)

    const [mijnMediatheekService] = useState(new MijnMediatheekService(API_BASE_URL, API_KEY, cookies.get(Cookie.PHPSESSID)))

    const [spelwijzerService] = useState(new SpelwijzerService(API_BASE_URL, API_KEY))

    const [userService] = useState(new UserService(API_BASE_URL, API_KEY))
    const [userServiceInitialized, setUserServiceInitialized] = useState(false)

    const [socialService] = useState(new SocialService(API_BASE_URL, API_KEY))

    const [roosteringService, setRoosteringService] = useState()
    const [roosteringServiceInitialized, setRoosteringServiceInitialized] = useState(false)

    const [rubriceringService] = useState(new RubriceringService(API_BASE_URL, API_KEY))
    const [rubriceringServiceInitialized, setRubriceringServiceInitialized] = useState(false)

    const [nieuwsService] = useState(new NieuwsService(API_BASE_URL, API_KEY))
    const [quoteService] = useState(new QuoteService(API_BASE_URL, API_KEY))
    const [faqService] = useState(new FaqService(API_BASE_URL, API_KEY))
    //const [feedbackService] = useState(new FeedbackService(API_BASE_URL, API_KEY))
    const [zoekService] = useState(new ZoekService(API_BASE_URL, API_KEY))
    const [adminService] = useState(new AdminService(API_BASE_URL, API_KEY))

    const [nieuwsLocaleDataService] = useState(new LocaleDataService('news', ['caption', 'title']))
    const [faqLocaleDataService] = useState(new LocaleDataService('faq', ['question', 'answer', 'teaser']))

    const [localeManager, setLocaleManager] = useState(new LocaleManager(ApplicationPath.localeFilesFolder))
    const [localeManagerInitialized, setLocaleManagerInitialized] = useState(false)

    //const [loginUserPrefs, setLoginUserPrefs] = useState()
    const [locale, setLocale] = useState('nl')
    //const [selectedZoeklijst, setSelectedZoeklijst] = useState()
    const [display, setDisplay] = useState('main')
    const [selectedFaq, setSelectedFaq] = useState()
    const [directLink, setDirectLink] = useState()
    const [laatsteZoekresultaat, setLaatsteZoekresultaat] = useState()
    const [kioskmode, setKioskmode] = useState(false)
    const [isSuperAdmin, setIsSuperAdmin] = useState(false)
    const [onCampus, setOnCampus] = useState(false)

    const [arteveldeUser, setArteveldeUser] = useState()
    const [validUser, setValidUser] = useState(false)
    //is nodig om MijnMediatheek component opnieuw te doen renderen, wordt verder niet gebruikt, enkel arteveldeUser.loggedIn
    const [loggedIn, setLoggedIn] = useState(false)

    const [uitgelichtDisplay, setUitgelichtDisplay] = useState(DISPLAY_UITGELICHT)
    //const [uitgelichtTimeout, setUitgelichtTimeout] = useState()

    const [serverResponseObject, setServerResponseObject] = useState()

    //CONFIG
    const [config, setConfig] = useState()

    //REFS
    const ZOEK_REF = useRef()

    //EFFECTS

    //CONFIG
    useEffect(() => {
        let url = `https://www.arteveldehogeschool.be/mediatheek/root/__config.json`;
        axios.get(url)
            .then(response => {
                setConfig(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    //CHATSERVICE
    useEffect(() => {
        setChatService(ChatService(firebase))
    }, [])

    //TAXONOMIESERVICE
    useEffect(() => {
        taxonomieService.init().then(res => setTaxonomieServiceInitialized(true))
    }, [taxonomieService])

    //USER SERVICE
    useEffect(() => {
        userService.init().then(res => {
            setUserServiceInitialized(true)
            const roosteringService = new RoosteringService(API_ROOSTERING_BASE_URL, API_KEY, userService)
            roosteringService.init().then(res => {
                setRoosteringService(roosteringService)
                setRoosteringServiceInitialized(true)
            })
        })

    }, [userService])

    //MEDIATHEEKSERVICE
    useEffect(() => {
        mediatheekService.init().then(response => {
            setMediatheekServiceInitialized(true)
        })
    }, [mediatheekService])

    //LOCALE MANAGER
    useEffect(() => {
        localeManager.init(locale).then(response => {
            setLocaleManagerInitialized(true)
        })
    }, [locale, localeManager])

    //RUBRICERINGSERVICE
    useEffect(() => {
        rubriceringService.init().then(() => {
            setRubriceringServiceInitialized(true)
        })
    }, [rubriceringService])

    //ADMINSERVICE
    useEffect(() => {
        adminService.init().then(response => {
            adminService.getSoftwareVersion(AdminService.WEBSITE).then(res => console.log('VERSION', res))
        })
    }, [adminService])

    //MIJNMEDIATHEEKSERVICE
    useEffect(() => {
        if (!arteveldeUser) return

        if (validUser) {
            mijnMediatheekService.getMijnMediatheekData(arteveldeUser).then(res => {
                arteveldeUser.loggedIn = true
                setLoggedIn(true)
            })
        }
    }, [validUser, arteveldeUser, mijnMediatheekService])

    //ARTEVELDEUSER && DIRECT LINK
    useEffect(() => {

        if (!localeManagerInitialized) return;

        const au = new ArteveldeUser()
        const dl = new DirectLink()

        if (!dl.isKiosk()) {
            if (isLocalHost) {
                //au.hasKaart = false


                au.barcode = '860000213883'
                au.emailAddress = "dany.dhondt@arteveldehs.be"
                au.givenName = "Dany"
                au.surname = "Dhondt"
                au.upn = "danydh@arteveldehs.be"

                if (au.hasKaart === false) {
                    window.alert(localeManager.getString('INLOGGEN_GEEN_STUDENTENKAART'))
                } else {
                    setValidUser(true)
                }

            } else if (loginData_upn && loginData_upn !== '') {

                //studenten zonder kaart:

                au.hasKaart = cookies.get(Cookie.LOGINDATA_KAART, {path: '/'}) === 'true'

                au.barcode = cookies.get(Cookie.LOGINDATA_BARCODE, {path: '/'})
                au.emailAddress = cookies.get(Cookie.LOGINDATA_EMAIL_ADDRESS, {path: '/'})
                au.givenName = cookies.get(Cookie.LOGINDATA_GIVENNAME, {path: '/'})
                au.surname = cookies.get(Cookie.LOGINDATA_SURNAME, {path: '/'})
                au.upn = cookies.get(Cookie.LOGINDATA_UPN, {path: '/'})

                if (!au.hasKaart) {
                    window.alert(localeManager.getString('INLOGGEN_GEEN_STUDENTENKAART'))
                } else {
                    setValidUser(true)
                }
            }
        } else {
            setKioskmode(true)
            //kiosk
            //window.addEventListener('mousemove', onMouseMove)
            //setKioskScreensaverTimer(setInterval(activateScreensaver, KIOSK_SCREENSAVER_TIMEOUT))
        }

        setArteveldeUser(au)
        setDirectLink(dl)
    }, [localeManagerInitialized, cookies, localeManager, loginData_upn])

    //DIRECT LINK
    useEffect(() => {
        if (!directLink) return

        if (directLink.isDirectFaq()) {

            faqService.init().then(res => {
                const idOrLink = directLink.params.get('id')
                const selFaq = res.faqs.reduce((acc, faq) => {
                    if (faq.id === parseInt(idOrLink) || faq.restfulLink === idOrLink) return faq
                    return acc
                }, null)

                if (selFaq) {
                    setSelectedFaq(selFaq)
                    setDisplay('faq')
                }
            })
        }

    }, [directLink, faqService])

    //IP RANGE
    useEffect(() => {
        //IP range check
        (async () => {
            const publicIpAddress = await publicIp.v4()

            let oc = false
            const ipcheck = ip.toLong(publicIpAddress)

            arteveldeOnCampusAddresses.forEach(item => {

                if (!oc) {
                    if (Array.isArray(item)) {
                        const ipstart = ip.toLong(item[0]), ipend = ip.toLong(item[1])
                        oc = ipcheck >= ipstart && ipcheck <= ipend
                    } else {
                        oc = ip.toLong(item) === ipcheck
                    }
                }
            })
            //console.log('PIP', publicIpAddress, oc)
            setOnCampus(oc)
        })();
    }, [])

    useEffect(() => {
        if (!arteveldeUser) return
        setIsSuperAdmin(arteveldeUser.barcode === '860000213883' || arteveldeUser.barcode === '860000701412')
    }, [arteveldeUser])

    //UITGELICHT TIMEOUT
    useEffect(() => {


        const handleUitgelichtTimeout = () => {
            setUitgelichtDisplay(uitgelichtDisplay === DISPLAY_UITGELICHT ? DISPLAY_QUOTE : DISPLAY_UITGELICHT)
        }

        let uitgelichtTimeout
        //if (uitgelichtTimeout) clearTimeout(uitgelichtTimeout)

        if (uitgelichtDisplay === DISPLAY_UITGELICHT) {
            uitgelichtTimeout = setTimeout(handleUitgelichtTimeout, UITGELICHT_TIMEOUT_ZOEKRESULTAAT)
        } else {
            uitgelichtTimeout = setTimeout(handleUitgelichtTimeout, UITGELICHT_TIMEOUT_QUOTE)
        }

        return () => {
            clearTimeout(uitgelichtTimeout)
        }

    }, [uitgelichtDisplay])


    const changeLocale = (locale) => {
        localeManager.loadLocale(locale).then((locManager) => {
            setLocale(locale)
            setLocaleManager({...locManager})
        })
    }

    const onAfmelden = () => {
        cookies.remove(Cookie.LOGINDATA_BARCODE, {path: '/'})
        cookies.remove(Cookie.LOGINDATA_EMAIL_ADDRESS, {path: '/'})
        cookies.remove(Cookie.LOGINDATA_GIVENNAME, {path: '/'})
        cookies.remove(Cookie.LOGINDATA_SURNAME, {path: '/'})
        cookies.remove(Cookie.LOGINDATA_UPN, {path: '/'})

        const url = ApplicationPath.mediatheekHost + "logOff.php"
        window.location.replace(url);

        setArteveldeUser(new ArteveldeUser())
        setLoggedIn(false)
        setValidUser(false)
    }

    const onInloggen = () => {

        let url = ApplicationPath.mediatheekHost + "loginLink.php?path=" // + window.location.href + "&callback=mijnMediatheek";
        if (laatsteZoekresultaat && laatsteZoekresultaat.directeLink) {
            url += laatsteZoekresultaat.directeLink
        } else {
            url += window.location.href
        }
        //console.log('LOG IN', url)
        window.location.replace(url);
    }

    const toonZoeklijstResultaten = (zoeklijst) => {
        //directLinkObject maken
        const dl = new DirectLink(DirectLink.buildDirectLinkString('leeslijst', zoeklijst.id))
        dl.injectedObject = zoeklijst
        setDirectLink(dl)
    }

    const toonBewaardeZoekopdrachtResultaten = (bewaardeZoekopdracht) => {
        const dl = new DirectLink(DirectLink.buildDirectLinkString('bewaardezoekopdracht', bewaardeZoekopdracht.id))
        dl.injectedObject = bewaardeZoekopdracht
        setDirectLink(dl)
    }

    const onMijnMediatheekChange = () => {
        //this.setState({mijnMediatheekService: this.state.mijnMediatheekService})
    }

    /* const onZoekresultaatClick = (zoekresultaat) => {
         this.setState({laatsteZoekresultaat: zoekresultaat})
     }*/

    const onLeeslijstClick = (href) => {
        console.log('LL CLICK', ZOEK_REF)
        setDirectLink(new DirectLink(href))
        ZOEK_REF.current.scrollIntoView()
    }

    const onUitgelichtTileClick = (zoekresultaat) => {
        const dl = new DirectLink(zoekresultaat.directeLink)
        console.log('DL', dl, zoekresultaat.directeLink)
        setDirectLink(new DirectLink(zoekresultaat.directeLink))
        ZOEK_REF.current.scrollIntoView()
    }

    const onChatlineClick = (chatline) => {

        console.log('CHATLINE CLICK', chatline)
        if (chatline.data().type === 'faq') {

            faqService.init().then(res => {
                const faq = faqService.faqLookup[chatline.data().faqId]
                setSelectedFaq(faq)
                setDisplay('faq')
            })
        }
    }

    const onReserveerClick = (zoekresultaat, exemplaar, setReserveerButtonVisible) => {
        mijnMediatheekService.voegToeAanAfhaalMandje(zoekresultaat, exemplaar, arteveldeUser).then(res => {
            res.setReserveerButtonVisible = setReserveerButtonVisible
            res.deleteResponseObject = () => setServerResponseObject(null)
            setServerResponseObject(res)
        })
    }


    if (!taxonomieServiceInitialized ||
        !mediatheekServiceInitialized ||
        !userServiceInitialized ||
        !localeManagerInitialized ||
        !roosteringServiceInitialized ||
        !rubriceringServiceInitialized ||
        !chatService ||
        !config)
        return null


    return (
        <Fragment>



            <div style={{display: 'flex', flexDirection: 'column'}}>

                <CookieConsent
                    location="top"
                    buttonText={localeManager.getString('COOKIE_CONSENT_OK')}
                    cookieName="arteveldehogeschoolWebsiteCookie">
                    {localeManager.getString('COOKIE_CONSENT')}
                </CookieConsent>

                <WebsiteHeader locale={locale}
                               changeLocale={changeLocale}
                               localeManager={localeManager}
                    /*mijnMediatheekButtonClick={mijnMediatheekButtonClick}*/
                               arteveldeUser={arteveldeUser}
                               enableLinks={!kioskmode}
                               showOnCampus={false} onCampus={onCampus}/>


                <div style={{width: '100%', maxWidth: '1400px', alignSelf: 'center', padding: '30px'}}>


                    <DringendNieuws nieuwsService={nieuwsService} localeManager={localeManager}/>

                    {display === 'main' &&
                    <div>
                        {!kioskmode &&
                        <WebComponentMijnMediatheek arteveldeUser={arteveldeUser}
                                                    mijnMediatheekService={mijnMediatheekService}
                                                    mediatheekService={mediatheekService}
                                                    localeManager={localeManager}
                                                    onAfmelden={onAfmelden}
                                                    onInloggen={onInloggen}
                                                    onToonZoeklijstResultaten={toonZoeklijstResultaten}
                                                    onToonBewaardeZoekopdrachtResultaten={toonBewaardeZoekopdrachtResultaten}
                                                    onMijnMediatheekChange={onMijnMediatheekChange}
                                                    serverResponseObject={serverResponseObject}/>
                        }


                        <div ref={ZOEK_REF} style={{backgroundColor: 'rgb(238, 238, 238, 0.5)', padding: '20px', marginBottom: '30px', marginTop: '30px'}}>
                            <WebComponentZoek cookies={cookies}
                                              localeManager={localeManager}
                                              userService={userService}
                                              rubriceringService={rubriceringService}
                                              taxonomieService={taxonomieService}
                                              mediatheekService={mediatheekService}
                                              socialService={socialService}
                                              spelwijzerService={spelwijzerService}
                                              mijnMediatheekService={mijnMediatheekService}
                                              zoekService={zoekService}
                                              arteveldeUser={arteveldeUser}
                                              directLink={directLink}
                                              onCampus={onCampus}
                                              config={config}
                                              onZoekresultaatClick={(zoekresultaat) => setLaatsteZoekresultaat(zoekresultaat)}
                                              onReserveerClick={onReserveerClick}/>
                        </div>

                        {!kioskmode && <WebComponentDigitaleMediatheek localeManager={localeManager} onLeeslijstClick={onLeeslijstClick}/>}


                        <Grid>
                            <Grid.Row columns={2} stretched>
                                <Grid.Column width={10}>
                                    <WebComponentNieuws nieuwsService={nieuwsService}
                                                        localeManager={localeManager}
                                                        localeDataService={nieuwsLocaleDataService}
                                                        directLink={directLink}/>
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    {uitgelichtDisplay === DISPLAY_QUOTE &&
                                    <QuoteTile quoteService={quoteService}/>
                                    }
                                    {uitgelichtDisplay === DISPLAY_UITGELICHT &&
                                    <UitgelichtTile zoekService={zoekService}
                                                    taxonomieService={taxonomieService}
                                                    localeManager={localeManager}
                                                    socialService={socialService}
                                                    spelwijzerService={spelwijzerService}
                                                    mijnMediatheekService={mijnMediatheekService}
                                                    onTileClick={onUitgelichtTileClick}/>
                                    }

                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        {!kioskmode &&
                        <Tiles localeManager={localeManager} arteveldeUser={arteveldeUser} faqService={faqService} faqLocaleDataService={faqLocaleDataService}
                               onFaqTileClick={() => setDisplay('faq')}/>
                        }


                        <Segment>
                            <Header>{localeManager.getString('BEZOEK_ONZE_MEDIATHEKEN')}</Header>
                            <WebComponentMediatheken userService={userService}
                                                     config={config}
                                                     rubriceringService={rubriceringService}
                                                     mediatheekService={mediatheekService}
                                                     roosteringService={roosteringService}
                                                     taxonomieService={taxonomieService}
                                                     localeManager={localeManager}
                                                     enableLinks={!kioskmode}/>

                        </Segment>
                    </div>
                    }

                    {display === 'faq' &&
                    <WebComponentFaq faqService={faqService}
                                     localeManager={localeManager}
                                     localeDataService={faqLocaleDataService}
                                     selectedFaq={selectedFaq}
                                     enableLinks={!kioskmode}
                                     onClose={() => setDisplay('main')}/>
                    }

                </div>


                {!kioskmode &&
                <WebsiteFooter version={version}>
                    {isSuperAdmin &&
                    <NewChangelogModal taxonomieService={taxonomieService} adminService={adminService}
                                       software='mediatheek_software_website'
                                       onChange={(modifiedChangelog) => console.log('Modified changelog', modifiedChangelog)}/>}
                </WebsiteFooter>
                }

            </div>
            <WebComponentChat arteveldeUser={arteveldeUser} loggedIn={loggedIn}
                              chatService={chatService}
                              userService={userService}
                              onChatlineClick={onChatlineClick}
                              onFaqLinkClick={() => setDisplay('faq')}/>
        </Fragment>
    );
}

export default withCookies(App)
