export const Chat = () => {
    return {
        timestamp: null,
        eigenaar: '',
        status: 'open'
    }
}

export const Chatline = () => {
    return {
        chat: '',
        inhoud: '',
        timestamp: null,
        bron: '', //bo of web
        auteur: '',
        type: 'chatline'
    }
}