export class Zoeklijst {
	
	_explicitType: string = "Zoeklijst"
	
	id: number = 0
	label: string = ''
	beschrijving: string = ''
	userID: string = ''
	publiek: boolean = false
	aantal: number = 0

}
