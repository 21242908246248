const axios = require('axios')
const _ = require('lodash')


export class TagService {
  
  private readonly TAG_TABLE: string = 'tag'
  
  initialized: boolean = false
	apiUrl: string
	apiKey: string
	tags: object[]
	tagIdLookup: object
	
	constructor(apiUrl: string, apiKey: string) {
		this.apiUrl = apiUrl
		this.apiKey = apiKey
		this.tags = []
		this.tagIdLookup = {}
	}
	
	init = () => {
    let me = this
    let url = `${me.apiUrl}${me.TAG_TABLE}?transform=1&order[]=term,asc&token=${me.apiKey}`
		return new Promise((resolve, reject) => {
				axios.get(url)
					.then(response => {
            me.initialized = true
            me.tags = response.data.tag
            me.tagIdLookup = _.keyBy(me.tags, tag => tag.id)
						resolve(me.tags)
					})
					.catch((error) => {
						console.log(error)
						reject(error)
					})
			}
		)
		
	}
	
	saveTag = (tag) => {
    
    let me = this
		let url = `${this.apiUrl}${me.TAG_TABLE}/${tag.id}?token=${this.apiKey}`;
		
		return new Promise((resolve, reject) => {
				axios.put(url, tag)
					.then(() => {
            me.tags = me.tags.map((t: any) => {
							if (t.id === tag.id)
								return tag
							return t
						})
            me.tagIdLookup[tag.id] = tag
						resolve({tags: me.tags})
					})
					.catch((error) => {
						console.log(error);
						reject(error)
					});
			}
		)
	}
	
	createTag = (tag) => {
    let me = this
		return new Promise((resolve, reject) => {
				let url = `${me.apiUrl}${me.TAG_TABLE}/?token=${me.apiKey}`
				axios.post(url, tag)
					.then(response => {
						tag.id = response.data
            me.tags.push(tag)
            me.tagIdLookup[tag.id] = tag
						resolve(tag)
					})
					.catch((error) => {
						console.log(error)
						reject(error)
					})
			}
		)
	}
}
