import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Form} from 'semantic-ui-react'
import DatePicker, {registerLocale} from 'react-datepicker'
import nl from 'date-fns/locale/nl';
import {format, parse} from 'date-fns'
import "react-datepicker/dist/react-datepicker.css"

registerLocale('nl', nl)
//import styles from '../styles.css'

const styles = {}

const DatePickerInput = ({disabled, dateString, onChange, label, onClick}) => {

    console.info('BREAKING CHANGE: the selected prop must be replaced by the dateString prop which is a mysql datestring')

    const [startDate, setStartDate] = useState(new Date());

    useEffect(() => {
        if (!dateString) return
        const d = parse(dateString, 'yyyy-MM-dd', new Date())
        console.log('SET DATE', d)
        setStartDate(d)
    }, [dateString])

    /*const onDateSelect = (date) => {
        console.log('SELECT', date)
        setStartDate(date)
    }*/
    const onDateChange = (date) => {
        console.warn('Let op: de onChange listener van DatePicker krijgt nu een javascript Date ipv. een moment instance!')
        onChange && onChange(format(date, 'yyyy-MM-dd'))
    }

    return (
        <div className={disabled ? styles.listFilterContentDisabled : ''}
             style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>

            {label && <div style={{marginRight: '10px'}}>{label}</div>}
            {/*<DatePicker
                customInput={<InputComp onClick={onClick} label={selected.format('L')}/>}
                selected={selected}
                locale='nl-be'
                dateFormat='L'
                onChange={(date) => onChange && onChange(date)}
            />*/}

            <DatePicker
                customInput={<InputComp onClick={onClick} label={format(startDate, 'P', {locale: nl})}/>}
                locale={nl}
                selected={startDate}
                onChange={onDateChange}/>

        </div>
    )
}

const InputComp = ({onClick, label}) => (
    <div style={{width: '150px'}}>
        <Form.Input
            error
            action={{color: "teal", icon: "calendar"}}
            actionPosition="left"
            onClick={onClick}
            value={label}
            fluid
        />
    </div>
)

export default DatePickerInput

DatePickerInput.propTypes = {
    disabled: PropTypes.bool,
    dateString: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    onClick: PropTypes.func
}

DatePickerInput.defaultProps = {
    disabled: false,
}
