import React from 'react'
import {Label} from 'semantic-ui-react'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'

const Parser = require('html-react-parser')

const TrefwoordenRenderer = ({zoekresultaat, localeManager, label, onTrefwoordClick}) => {

    if (!zoekresultaat.trefwoorden
        || zoekresultaat.trefwoorden.length === 0
        || zoekresultaat.trefwoorden[0] === '') return null

    return (
        <AbstractZoekresultaatRenderer label={localeManager.getString(label)}>
            {zoekresultaat.trefwoorden.map((trefwoord, index) => {
                return <Label basic key={index} style={{marginBottom: '5px'}} as='a' content={Parser(trefwoord)}
                              onClick={() => onTrefwoordClick(trefwoord)}
                              icon='tag' color='grey'/>
            })}
        </AbstractZoekresultaatRenderer>
    )
}

export default TrefwoordenRenderer