export class PublicatieType {

	_explicitType: string = "Publicatietype";

		static readonly APPARAAT = "apparaat";
		static readonly ARTIKEL = "artikel";
		static readonly AUDIOCASSETTE = "audiocassette";
		static readonly BACHELORPROEF = "bachelorproef";
		static readonly BOEK = "boek";
		static readonly CD = "cd";
		static readonly CDROM = "cdrom";
		static readonly CDTRACK = "cdtrack";
		static readonly DEELTITEL = "deeltitel";
		static readonly DIDACTISCH_MATERIAAL = "didactisch_materiaal";
		static readonly DVD = "dvd";
		static readonly E_BOEK = "ebook";
		static readonly WEBSITE_DATABANK = "website_databank";
		static readonly HANDBOEK_CURUSUS = "handboek_cursus";
		static readonly LOSBLADIG_WERK = "losbladig_werk";
		static readonly NASLAGWERK = "naslagwerk";
		static readonly POSTER = "poster";
		static readonly SPELMATERIAAL = "spelmateriaal";
		static readonly TIJDSCHRIFT = "tijdschrift";
		static readonly TRANSPARANT = "transparant";
		static readonly VIDEO = "video";
		static readonly ERRATUM = "erratum";
		static readonly HOOFDARTIKEL = "hoofdartikel";
		static readonly UNKNOWN = "unknown";

		static isUitleenbaar = (pubType):Boolean => {
			return pubType === PublicatieType.BOEK;

			/*let rv:Boolean = true;
			let nietUitleenbaar:string[] = [
				PublicatieTypes.TIJDSCHRIFT,
				PublicatieTypes.ARTIKEL
			];
			rv = nietUitleenbaar.indexOf(pubType) < 0;

			return rv;*/
		}
}
