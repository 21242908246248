import React, {useState, useEffect, useRef} from 'react'
import moment from "moment";
import {RoosteringContext} from 'med-class'


const SHIFTS_TIMER = 1000 * 60
const MYSQL_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

//==============================================================
//==============================================================
//                      HOOKS
//==============================================================
//==============================================================

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const useWindowSize = () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/


    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
}

const CurrentChatRooster = ({roosteringService, userService}) => {
    const [chatroosterEvents, setChatroosterEvents] = useState()
    const [divWidth, setDivWidth] = useState(0)
    const [divX, setDivX] = useState(0)
    const [slots, setSlots] = useState()
    const [nowOffset, setNowOffset] = useState(0)
    const [showCurrentTimeIndicator, setShowCurrentTimeIndicator] = useState(false)

    const wRef = useRef()

     useInterval(() => {
         // Your custom logic here
         buildSlots()
     }, SHIFTS_TIMER);

    //const windowSize = useWindowSize();


    useEffect(() => {
        window.addEventListener('resize', onResize)
        const to = setTimeout(onResize, 500)

        return () => clearTimeout(to)
    }, [])


    /*useEffect(() => {
        console.log('WSIZE', wRef)
        if (windowSize.width === undefined || windowSize.width === currentWidth) return

        setCurrentWidth(windowSize.width)

    }, [windowSize])*/

    useEffect(() => {
        if (divWidth === 0) return
        if (!chatroosterEvents) return

        buildSlots()
    }, [divWidth, divX, chatroosterEvents])

    //ROOSTERING SERVICE
    useEffect(() => {
        if (!roosteringService) return

        roosteringService.getEvents(RoosteringContext.CHATROOSTER).then(res => {
            const now = moment()
            const nowString = now.format(MYSQL_DATE_FORMAT)
            const nowDay = now.format('YYYY-MM-DD')

            setChatroosterEvents(res.events.filter(event => event.start.substr(0, 10) === nowDay && userService.userIdLookup[event.medewerker] !== undefined))

        })
    }, [roosteringService])

    const onResize = () => {
        if (!wRef.current) return
        setDivWidth(wRef.current.offsetWidth)
        setDivX(wRef.current.offsetLeft)
    }
    
    const buildSlots = () => {
        const start = chatroosterEvents.reduce((s, event) => {
            if (!s) return event
            if (event.start < s.start) return event
            return s
        }, null)
        const einde = chatroosterEvents.reduce((s, event) => {
            if (!s) return event
            if (event.einde > s.einde) return event
            return s
        }, null)

        if (!start || !einde) return

        const now = moment()
        const sMoment = moment(start.start)
        const eMoment = moment(einde.einde)
        const dayDuration = eMoment - sMoment //milliseconds

        const pixelFactor = divWidth / dayDuration
        const slots = chatroosterEvents.map(event => {
            const s = moment(event.start)
            const e = moment(event.einde)
            return {
                start: s - sMoment,
                duration: e - s,
                x: ((s - sMoment) * pixelFactor) + divX,
                w: (e - s) * pixelFactor,
                user: userService.userIdLookup[event.medewerker],
                online: s <= now && e > now,
                uurLabel: `${s.format('HH:mm')}-${e.format('HH:mm')}`}

        })

        const offset = now-sMoment
        setNowOffset(offset*pixelFactor + divX)
        setShowCurrentTimeIndicator(now >= sMoment && now <= eMoment)
        setSlots(slots)
    }


    return (
        <div ref={wRef} style={{display: 'relative', height: '40px', width: '100%'}}>

            {slots && slots.map((slot, index) => {
                const style = {
                    position: 'absolute',
                    padding: '5px', backgroundColor: slot.online ? 'teal' : 'grey   ',
                    left: slot.x + 'px', width: slot.w + 'px', border: '1px solid black', color: 'white'
                }
                return <div key={index} style={style}>{slot.uurLabel} - {slot.user.voornaam}</div>
            })}

            {showCurrentTimeIndicator && <div style={{border: '2px solid red', position: 'absolute', left: nowOffset, height: '40px'}}/>}
        </div>
    )
}

export default CurrentChatRooster

CurrentChatRooster.propTypes = {}

CurrentChatRooster.defaultProps = {}