import React, {Component, createRef} from 'react'
import PropTypes from 'prop-types'
import {Icon} from 'semantic-ui-react'


export default class QuoteTile extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentQuote: null,
            quoteInterval: setInterval(this.parseRandomQuote, 1000 * 60)
        }

        this.quoteDivRef = createRef()

    }

    componentDidMount() {
        this.parseRandomQuote()
    }

    componentWillUnmount() {
        clearInterval(this.state.quoteInterval)
    }

    getDim = () => {

        if (!this.quoteDivRef.current)
            return null

        return {
            width: this.quoteDivRef.current.offsetWidth,
            height: this.quoteDivRef.current.offsetHeight
        }
    }

    parseRandomQuote = () => {
        const { quoteService } = this.props
        quoteService.getRandomQuote().then(quote => {
            this.setState({currentQuote: quote})
        })
    }

    getFontSize = (quote) => {
        const dim = this.getDim()
        if (!dim || !quote)
            return '1.5em'

        const length = Math.max(quote.quote.length, 50)
        const f1 = 30
        const f2 = 17
        const opp = 36120
        const fs = opp / (length * f1) + (length/f2)
        /*console.log("Opp: " + opp + "Height: " + dim.height + ", Width: " + dim.width + ", Length: " + length + ", fs: " + fs);*/
        return fs + 'px'
    }


    testClick = () => {
        this.parseRandomQuote()
    }

    render() {
        const { currentQuote } = this.state

        if (!currentQuote) return null

        const qText = currentQuote.quote
        const aText = currentQuote.auteur !== '' ? currentQuote.auteur : '-'
        const fontSize = this.getFontSize(currentQuote)

        return (
            <div ref={this.quoteDivRef} className="quote-container" onClick={this.testClick}>
                <div className='quote' style={{fontSize: fontSize}}>{qText}
                </div>
                <div>{aText}</div>
                <Icon className='quotesign-left' style={{opacity: '0.2'}} name='quote left' size='big'/>
                <Icon className="quotesign-right" style={{opacity: '0.2'}} name='quote right'size='big'/>
            </div>
        );
    }
}

QuoteTile.propTypes = {
    quoteService: PropTypes.object.isRequired
}

QuoteTile.defaultProps = {}