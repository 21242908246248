const axios = require('axios')
const moment = require('moment')

export class LocaleManager {

    initialized: boolean = false
    locale: string = 'nl'
    localePath: string
    localeContent: object = {}

    constructor(localePath: string){
        this.localePath = localePath
    }

    init = (locale?: string) => {
        return new Promise((resolve) => {
            if (locale) this.locale = locale
            this.loadLocale(this.locale).then(() => {
                this.initialized = true
                resolve('initialized')
            })
        })
    }

    loadLocale = (locale: string) => {
        const url = this.localePath + locale + '.locale'
        this.locale = locale
        moment.locale(locale)
        return new Promise((resolve, reject) => {
            axios.get(url).then(response => {
                this.localeContent[locale] = this.buildLocaleData(response.data)
                resolve(this)
            }).catch(error => {
                reject(error)
            })
        })
    }

    private buildLocaleData = (content: string) => {
        const lineArray = content.split('\n')
        const localeData: object = {}
        for (let line of lineArray) {
            let wordArray: string[] = line.split('=')
            localeData[wordArray[0]] = wordArray[1]
        }
        return localeData
    }

    propertyExists = (property: string): boolean => {
        return this.localeContent[this.locale].hasOwnProperty(property.toUpperCase())
    }

    getString = (input: string): string => {
        if (!input) return ''
        input = input.toUpperCase()
        const rs: string = this.localeContent[this.locale][input]
        if (rs) return rs.replace(/\r/g, "")
        return input.replace(/_/g, ' ').toLowerCase()
    }

    handleLocaleBindings = (source) =>{
        //deze functie zal in source op zoek gaan naar bindings en die vervangen door locale data
        //bindings zitten tussen [[ en ]]
        let found = true;
        while(found){
            let s = source.indexOf("[[");
            let e = source.indexOf("]]");
            let l = e-s;

            if (s >= 0 && e >= 0){
                let str = source.substring(s, e+2);

                let key = str.substr(2, l-2	);

                let tr = this.getString(key);
                if (!tr)
                    tr = key;
                source = source.replace(str, tr);
            } else {
                found = false;
            }
        }

        return source;
    }

    getUitleningStatusBericht = (code: number): string => {
        switch (code) {
            case 0:	return this.getString('VERLENGEN_GESLAAGD')
            case 1:	return this.getString('AWNR_BESTAAT_NIET')
            case 2:	return this.getString('AWNR_NIET_UITGELEEND')
            case 3:	return this.getString('VERLENGEN_NIET_OP_DAG_VAN_UITLENEN')
            case 4:	return this.getString('LENERSGEGEVENS_KUNNEN_NIET_GELEZEN_WORDEN')
            case 5:	return this.getString('AWNR_MAG_NIET_VERLENGD_WORDEN')
            case 6:	return this.getString('MAXIMUM_UITLEENTERMIJN_IS_OVERSCHREDEN')
            case 7:	return this.getString('MAXIMUM_UITLEENTERMIJN_ZAL_OVERSCHREDEN_WORDEN')
            case 8:	return this.getString('AWNR_GERESERVEERD')
            case 9:	return this.getString('TERUGBRENGDATUM_LIGT_VAST')
            case 10: return this.getString('TE_VROEG_OM_TE_VERLENGEN')
            case 11: return this.getString('MAXIMUM_UITLEENTERMIJN_BEREIKT')
        }

        return ''
    }

    parseBidocString = (bidocString: string): string => {
        bidocString = bidocString.replace('NIET UITLEENBAAR', '(' + this.getString('NIET_UITLEENBAAR') + ')')

        return bidocString
    }
}
