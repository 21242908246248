import React, {useState, useEffect, useRef, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Image} from 'semantic-ui-react'
import {NieuwsTextPosition, NieuwsType} from "@ahsmediatheek/core";
import moment from 'moment'
import ReactPlayer from 'react-player'
import Parser, {domToReact} from 'html-react-parser'

const NieuwsViewer = ({nieuws, style, localeContent, locale, autoPlay, onDuration, enableLinks}) => {

    const [nieuwsDivWidth, setNieuwsDivWidth] = useState()
    const [imageLink, setImageLink] = useState()
    const [titleStyle, setTitleStyle] = useState()
    const [captionStyle, setCaptionStyle] = useState()

    const nieuwsDiv = useRef()

    useEffect(() => {

        const onResize = () => {
            const newWidth = nieuwsDiv.current.offsetWidth
            if (newWidth !== nieuwsDivWidth) {
                setNieuwsDivWidth(newWidth)
            }
        }


        window.addEventListener('resize', onResize)
        setImageLink(nieuws.imagePath + '?time=' + moment().milliseconds())

        return () => window.removeEventListener('resize', onResize)
    }, [nieuws, nieuwsDivWidth])

    useEffect(() => {
        const titleFontSize = (nieuwsDivWidth/20).toString() + 'px'

        console.log('DIV WIDTH', nieuwsDivWidth, titleFontSize)
        const titleStyle = {
            fontSize: (nieuwsDivWidth/20).toString() + 'px',
            color: style.titleColor,
            padding: style.titlePadding + 'px'
        }

        if (style.showTitleDropShadow === 1) titleStyle.textShadow = '2px 2px black'
        if (style.showTitleBackground === 1) titleStyle.backgroundColor = style.titleBackgroundColor

        switch(style.titlePosition){
            case NieuwsTextPosition.TOP_LEFT:
                titleStyle.top = 0
                titleStyle.left = 0
                titleStyle.textAlign = 'left'
                break
            case NieuwsTextPosition.TOP_RIGHT:
                titleStyle.top = 0
                titleStyle.right = 0
                titleStyle.textAlign = 'right'
                break
            case NieuwsTextPosition.BOTTOM_LEFT:
                titleStyle.bottom = 0
                titleStyle.left = 0
                titleStyle.textAlign = 'left'
                break
            case NieuwsTextPosition.BOTTOM_RIGHT:
                titleStyle.bottom = 0
                titleStyle.right = 0
                titleStyle.textAlign = 'right'
                break
            case NieuwsTextPosition.MID_LEFT:
                titleStyle.alignSelf = 'center'
                titleStyle.left = 0
                titleStyle.textAlign = 'left'
                break
            case NieuwsTextPosition.MID_RIGHT:
                titleStyle.alignSelf = 'center'
                titleStyle.right = 0
                titleStyle.textAlign = 'right'
                break
            default:
                break
        }

        if (style.justifyTitle === 1){
            titleStyle.right = 0
            titleStyle.left = 0
            titleStyle.textAlign = 'center'
        }

        const captionStyle = {
            fontSize: (nieuwsDivWidth/30).toString() + 'px',
            color: style.captionColor,
            padding: style.captionPadding + 'px'
        }

        if (style.showCaptionDropShadow === 1) captionStyle.textShadow = '2px 2px black'
        if (style.showCaptionBackground === 1) captionStyle.backgroundColor = style.captionBackgroundColor
        if (style.justifyCaption === 1){
            captionStyle.width = '100%'
            captionStyle.textAlign = 'center'
        }

        switch(style.captionPosition){
            case NieuwsTextPosition.TOP_LEFT:
                captionStyle.top = 0
                captionStyle.left = 0
                captionStyle.textAlign = 'left'
                break
            case NieuwsTextPosition.TOP_RIGHT:
                captionStyle.top = 0
                captionStyle.right = 0
                captionStyle.textAlign = 'right'
                break
            case NieuwsTextPosition.BOTTOM_LEFT:
                captionStyle.bottom = 0
                captionStyle.left = 0
                captionStyle.textAlign = 'left'
                break
            case NieuwsTextPosition.BOTTOM_RIGHT:
                captionStyle.bottom = 0
                captionStyle.right = 0
                captionStyle.textAlign = 'right'
                break
            case NieuwsTextPosition.MID_LEFT:
                captionStyle.alignSelf = 'center'
                captionStyle.left = 0
                captionStyle.textAlign = 'left'
                break
            case NieuwsTextPosition.MID_RIGHT:
                captionStyle.alignSelf = 'center'
                captionStyle.right = 0
                captionStyle.textAlign = 'right'
                break
            default:
                break
        }

        if (style.justifyCaption === 1){
            captionStyle.right = 0
            captionStyle.left = 0
            captionStyle.textAlign = 'center'
        }

        setTitleStyle(titleStyle)
        setCaptionStyle(captionStyle)

    }, [nieuwsDivWidth, style])

    useEffect(() => {
        if (!nieuwsDiv.current) return

        const newWidth = nieuwsDiv.current.offsetWidth
        if (newWidth !== nieuwsDivWidth) {
            setNieuwsDivWidth(newWidth)
        }
    }, [nieuwsDivWidth])

    const initNieuwsDivWidth = () => {
        if (nieuwsDivWidth === nieuwsDiv.current.offsetWidth) return
        setNieuwsDivWidth(nieuwsDiv.current.offsetWidth)
    }

    const getLocaleContent = (field) => {

        const nlContent = localeContent.reduce((acc, lc) => {
            if (lc.locale === 'nl' && lc.field === field) return lc.content
            return acc
        }, '')

        if (locale === 'nl') return nlContent

        const fContent = localeContent.reduce((acc, lc) => {
            if (lc.locale === locale && lc.field === field) return lc.content
            return acc
        }, '')

        if (fContent === '') return nlContent

        return fContent
    }

    const getRenderContent = (field) => {
        return Parser(getLocaleContent(field), {replace: ({attribs, children}) => !enableLinks && attribs && attribs.href && <strong>{domToReact(children)}</strong>})
    }

    return (
        <div className='nieuws-wrapper' style={{backgroundColor: style.backgroundColor}} ref={nieuwsDiv}>
            {nieuws.type === NieuwsType.IMAGE &&
            <Fragment>
                <Image className='nieuws-image' width='100%' src={imageLink} onLoad={initNieuwsDivWidth}/>
                {style.showTitle === 1 && <div className='nieuws-title' style={titleStyle}>
                    {getRenderContent('title')}
                </div>}
                {style.showCaption === 1 && <div className='nieuws-title' style={captionStyle}>
                    {getRenderContent('caption')}
                </div>}
            </Fragment>
            }

            {nieuws.type === NieuwsType.VIDEO &&
            <Fragment>
                <ReactPlayer width='100%' controls={true} playing={autoPlay} url={nieuws.link} onDuration={onDuration}
                />
                {style.showTitle === 1 && <div className='nieuws-title' style={titleStyle}>{getRenderContent('title')}</div>}
                {style.showCaption === 1 && <div className='nieuws-title' style={captionStyle}>{getRenderContent('caption')}</div>}
            </Fragment>
            }

            {nieuws.type === NieuwsType.TEKST &&
            <div>
                {style.showTitle === 1 && <div className='nieuws-title' style={titleStyle}>{getRenderContent('title')}</div>}
                {style.showCaption === 1 && <div className='nieuws-title' style={captionStyle}>{getRenderContent('caption')}</div>}
            </div>
            }

        </div>
    )
}

export default NieuwsViewer


NieuwsViewer.propTypes = {
    nieuws: PropTypes.object.isRequired,
    style: PropTypes.object.isRequired,
    localeContent: PropTypes.array.isRequired,
    locale: PropTypes.string,
    autoPlay: PropTypes.bool,
    onDuration: PropTypes.func,
    enableLinks: PropTypes.bool
}

NieuwsViewer.defaultProps = {
    autoPlay: false,
    enableLinks: true
}
