export class Event {

    id: number
    context: string
    balies: string
    start: string
    einde: string
    einddatum: string
    herhaal: number = 0
    rrule: string
    exdates: string = ''
    exrules: string = ''
    rdates: string
    medewerker: number
    mediatheek: string
    beschikbaarheid: number
    isSjabloon: number
}

export class RoosteringContext {
    static readonly BALIE_BESCHIKBAARHEID: string = "beschikbaarheid"
    static readonly CHAT_BESCHIKBAARHEID: string = "chatBeschikbaarheid"
    static readonly WERKROOSTER: string = "werkrooster"
    static readonly OPENINGSUREN: string = "openingsuren"
    static readonly BALIEROOSTER: string = "balierooster"
    static readonly CHATROOSTER: string = "chatrooster"
    static readonly CHATPERMANENTIE: string = "chatpermanentie"
    static readonly BALIEROOSTER_SJABLOON: string = "balieroosterSjabloon"
    static readonly CHATROOSTER_SJABLOON: string = "chatroosterSjabloon"
}

export class RoosteringFilter {
    static readonly GEEN: string = 'geen'
    static readonly MEDEWERKER: string = 'medewerker'
    static readonly MEDIATHEEK: string = 'mediatheek'
}
