import React from 'react'
import PropTypes from 'prop-types'
import GeavanceerdZoekenZoeklijn from "./GeavanceerdZoekenZoeklijn";

const GeavanceerdZoeken = ({localeManager, queryData, onAdvancedSearchInputKeyDown, onQueryDataChange}) => {

    const onChange = (data, prop, value) => {
        data[prop] = value
        onQueryDataChange(queryData)
    }

    const onAdd = () => {
        queryData.addItem('AND')
        console.log('ADD', queryData)
        onQueryDataChange(queryData)
    }

    const onDelete = (data) => {
        queryData.removeItem(data)
        onQueryDataChange(queryData)
    }

    return (
        <div>
            {queryData.data.map((lijn, index) => {
                const isFirstLine = index === 0
                const showAddButton = index === queryData.data.length - 1 && lijn.query !== '' && index < 3
                return <GeavanceerdZoekenZoeklijn key={index} data={lijn}
                                                  localeManager={localeManager}
                                                  onChange={onChange}
                                                  onZoeklijnKeyDown={onAdvancedSearchInputKeyDown}
                                                  onAdd={onAdd}
                                                  onDelete={onDelete}
                                                  isFirstLine={isFirstLine}
                                                  showAddButton={showAddButton}/>
            })
            }
        </div>
    )

}

export default GeavanceerdZoeken

GeavanceerdZoeken.propTypes = {
    localeManager: PropTypes.object.isRequired,
    queryData: PropTypes.object.isRequired,
    onQueryDataChange: PropTypes.func.isRequired,
    onAdvancedSearchInputKeyDown: PropTypes.func
}

GeavanceerdZoeken.defaultProps = {}