import React from 'react'
import PropTypes from 'prop-types'

const ColoredLabel = ({color, children}) => {

    const getContrastYIQ = (hexcolor) => {
        const r = parseInt(hexcolor.substr(0,2),16);
        const g = parseInt(hexcolor.substr(2,2),16);
        const b = parseInt(hexcolor.substr(4,2),16);
        const yiq = ((r*299)+(g*587)+(b*114))/1000;
        return (yiq >= 128) ? 'black' : 'white';
    }


    return <span style={{
        borderRadius: '10px',
        backgroundColor: '#' + color,
        color: getContrastYIQ(color),
        padding: '2px 7px 2px 7px',
        marginRight: '5px',
        marginBottom: '5px'
    }}>{children}</span>
}

export default  ColoredLabel

ColoredLabel.propTypes = {
    color: PropTypes.string,
}
