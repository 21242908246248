import React from 'react'
import PropTypes from 'prop-types'

const Spacer = ({distance, vertical}) => {

    const style = {height: vertical ? distance : 0, width: vertical ? 0 : distance}

    return (
        <div style={style}/>
    )
}

export default  Spacer

Spacer.propTypes = {
    distance: PropTypes.number,
    vertical: PropTypes.bool
}

Spacer.defaultProps = {
    distance: 5
}