import React, {Component} from 'react'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'
const Parser = require('html-react-parser')

class OpmerkingRenderer extends Component {

    state={opmerking: ''}

    componentDidMount() {
        const { zoekresultaat, epubLocaleDataService } = this.props

        if (zoekresultaat.needLocaleContent && !zoekresultaat.localeContentLoaded) {
            epubLocaleDataService.getLocaleData(zoekresultaat.id).then(res => {
                zoekresultaat.localeContentLoaded = true
                zoekresultaat.localeContent = res
                this.setOpmerking()
            })
        } else {
            this.setOpmerking()
        }
    }

    setOpmerking = () => {
        const { zoekresultaat, epubLocaleDataService, localeManager } = this.props
        if (zoekresultaat.needLocaleContent && zoekresultaat.localeContentLoaded) {
            const opmerking = epubLocaleDataService.getPropertyValue(zoekresultaat.id, 'opmerking', localeManager.locale)
            this.setState({opmerking})
        } else {
            this.setState({opmerking: zoekresultaat.opmerking})
        }
    }

    render() {
        const { opmerking } = this.state
        const {localeManager, label} = this.props

        if (!opmerking || opmerking === '') return null

        return (
            <AbstractZoekresultaatRenderer label={localeManager.getString(label)}>
                {Parser(opmerking)}
            </AbstractZoekresultaatRenderer>
        )
    }
}

export default OpmerkingRenderer