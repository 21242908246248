import React from 'react'
import PropTypes from 'prop-types'
import {Button, Form} from 'semantic-ui-react'
import CheckboxDropdown from './CheckboxDropdown'
//import 'rc-slider/assets/index.css';
//import 'rc-tooltip/assets/bootstrap.css';
import PublicatiejaarDropdown from "./PublicatiejaarDropdown"

const Filter = ({taxonomieService, mediatheekService, onFilterChange, filterObject, localeManager, advanced, onBewaarZoekopdracht}) => {

    return (
        <Form size='mini'>

            <Form.Field style={{display: 'flex'}}>
                <CheckboxDropdown localeManager={localeManager}
                                  title={localeManager.getString('COLLECTIE')} name='collectie'
                                  filterObject={filterObject}
                                  dataprovider={taxonomieService.taxonomieItemsLookup[taxonomieService.taxonomieLabelLookup['collectie'].id]}
                                  labelField='naam'
                                  onOptionChange={onFilterChange}/>
                <CheckboxDropdown localeManager={localeManager}
                                  title={localeManager.getString('UITGAVENVORM')} name='uitgavenvorm'
                                  filterObject={filterObject}
                                  dataprovider={taxonomieService.taxonomieItemsLookup[taxonomieService.taxonomieLabelLookup['uitgavenvorm'].id]}
                                  labelField='naam'
                                  onOptionChange={onFilterChange}/>
                <CheckboxDropdown localeManager={localeManager}
                                  title={localeManager.getString('MEDIATHEEK')} name='mediatheek'
                                  filterObject={filterObject}
                                  dataprovider={mediatheekService.mediatheken}
                                  labelField='naam'
                                  exclusive={advanced}
                                  onOptionChange={onFilterChange}/>
                {advanced &&
                <CheckboxDropdown localeManager={localeManager}
                                  title={localeManager.getString('TAAL')} name='taal'
                                  filterObject={filterObject}
                                  dataprovider={taxonomieService.taxonomieItemsLookup[taxonomieService.taxonomieLabelLookup['taal'].id]}
                                  onOptionChange={onFilterChange}/>
                }
                {advanced &&
                <PublicatiejaarDropdown localeManager={localeManager}
                                        title={localeManager.getString('PUBLICATIEJAAR')} name='publicatiejaar'
                                        filterObject={filterObject}
                                        renderMode='direct'
                                        onOptionChange={onFilterChange}/>
                }

                <div style={{flexGrow: 1}}/>

                {filterObject.bewaarZoekopdrachtEnabled &&
                <Button style={{alignSelf: 'center'}} size="small" color='blue' onClick={onBewaarZoekopdracht}>{localeManager.getString('BEWAAR_ZOEKOPDRACHT')}</Button>}
            </Form.Field>


            <p>{filterObject.toString()}</p>
        </Form>
    )
}

Filter.propTypes = {
    localeManager: PropTypes.object.isRequired,
    zoekbronnen: PropTypes.array,
    onZoekbronToggle: PropTypes.func,
    taxonomieService: PropTypes.object,
    mediatheekService: PropTypes.object,
    filterObject: PropTypes.object,
    onFilterChange: PropTypes.func,
    onBewaarZoekopdracht: PropTypes.func,
    advanced: PropTypes.bool,
}

Filter.defaultProps = {
    advanced: false,
}

export default Filter