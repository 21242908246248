import {NieuwsTextPosition} from "./NieuwsTextPosition";

export class Nieuws {

    _explicitType: string = 'Nieuws'

    id: number = 0
    datum_wijziging: string = ''
    user_aanmaak: number = 0
    user_wijziging: number = 0
    online: number = 0

    link: string = ''
    imagePath: string = ''
    type: string = ''
    caption: string = ''
    title: string = ''
    body: string = ''
    date: string = ''
    fromDate: string = ''
    untilDate: string = ''
    priority: number = 0

    style: string = ""

    get styleObject(): object{

        if (!this.style || this.style === '') return this.defaultStyle
        return JSON.parse(this.style)
    }

    set styleObject(value: object){
        this.style = JSON.stringify(value)
    }

    get defaultStyle():object {

        return {
            backgroundColor: "#f0b407ff",

            showTitle: 1,
            titleColor: 'white',
            showTitleDropShadow: 1,
            showTitleBackground: 1,
            titleBackgroundColor: "#00000099",
            titlePosition: NieuwsTextPosition.TOP_LEFT,
            justifyTitle: 0,

            showCaption: 1,
            captionColor: 'white',
            showCaptionDropShadow: 1,
            showCaptionBackground: 1,
            captionBackgroundColor: "#00000099",
            captionPosition: NieuwsTextPosition.BOTTOM_RIGHT,
            justifyCaption: 0
        }
    }

}
