import React, {useState, useRef, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import {TextArea} from "semantic-ui-react";
import IconBar from "./IconBar";
import {ApplicationPath} from 'med-class'

const IDLE_TIME = 1000 * 60 * 15
const COUNTDOWN = 60

const ChatlineInput = ({onSubmitChatline, onSelectFile, eigenaarRef, showIconBar, injectedDefaultAnswer, client, chat, forceCloseChat}) => {

    const [currentChatline, setCurrentChatline] = useState('')
    const [refExists, setRefExists] = useState(false)
    const [idleTimer, setIdleTimer] = useState()
    const [idleWarning, setIdleWarning] = useState('')
    const [timeLeft, setTimeLeft] = useState(-1)

    const chatlineInputRef = useRef()

    useEffect(() => {
        const refTimeout = setTimeout(focusOnRef, 100)

        return () => clearTimeout(refTimeout)
    }, [])

    useEffect(() => {
        if (!injectedDefaultAnswer) return

        setCurrentChatline(injectedDefaultAnswer.content)
        eigenaarRef && eigenaarRef.set({isTyping: true})
        focusOnRef()
    }, [injectedDefaultAnswer, eigenaarRef])

    useEffect(() => {
        if (timeLeft === -1) return

        let timeout

        setIdleWarning(`Let op, uw sessie zal automatisch afgesloten worden binnen ${timeLeft} seconden indien je niet verder gebruik maakt van de chat.`)
        
        if (timeLeft <= 0) {
            setIdleWarning('')
            forceCloseChat && forceCloseChat(IDLE_TIME)
        } else {
            timeout = setTimeout(() => {
                setTimeLeft(timeLeft - 1)
            }, 1000)
        }

        return () => clearTimeout(timeout)
    }, [timeLeft])

    const startIdleTimer = () => {
        if (client !== 'web') return

        clearTimeout(idleTimer)
        setTimeLeft(-1)
        setIdleWarning('')
        setIdleTimer(setTimeout(handleTimeOut, IDLE_TIME))
    }

    const handleTimeOut = () => {
        clearTimeout(idleTimer)
        setTimeLeft(COUNTDOWN)

        console.log('TIME OUT!!!')
    }

    const focusOnRef = () => {
        setRefExists(true)
        if (chatlineInputRef.current) chatlineInputRef.current.focus()
    }

    const handlePaste = (event) => {
        const item = event.clipboardData.items[0]
        if (item.kind === 'file' && item.type.indexOf('image') > -1) {
            const file = item.getAsFile()
            onSelectFile(file)
        }
    }

    const onChangeChatline = (event, {value}) => {
        setCurrentChatline(value)

        if (!eigenaarRef) return

        //typing status
        //wijzig de chatstatus enkel als je BEGINT met typen, dus als de inhoud leeg is
        if (currentChatline === '' && value !== '') {
            eigenaarRef.set({isTyping: true})
        } else if (currentChatline !== '' && value === '') {
            eigenaarRef.set({isTyping: false})
        }

    }

    const onKeyDown = (event) => {

        startIdleTimer()

        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault()
            if (currentChatline === '') return

            //surround with a tags
            const replacedChatLine = currentChatline.split(' ').map(word => {
                if (validURL(word)) {
                    if (word.startsWith('www.')) word = 'https://' + word
                    return `<a href="${word}" target="_blank">${word}</a>`
                }
                return word
            }).join(' ')

            focusOnRef()
            onSubmitChatline && onSubmitChatline(replacedChatLine)
            eigenaarRef && eigenaarRef.set({isTyping: false})
            setCurrentChatline('')
        }
    }

    const onSelectEmoji = (props) => {
        setCurrentChatline(currentChatline + ' ' + props.native + ' ')
        chatlineInputRef.current && chatlineInputRef.current.focus()
    }

    const validURL = (str) => {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    return (
        <Fragment>
            <TextArea ref={chatlineInputRef} style={{minHeight: '100px'}}
                      placeholder={chat && showIconBar ? 'Typ uw bericht of plak een klembord afbeelding' : 'Typ uw bericht'}
                      onPaste={handlePaste}
                      name='chatLine'
                      value={currentChatline}
                      onChange={onChangeChatline} onKeyDown={onKeyDown}/>

            {/*<div contentEditable="true">Type here. You can insert images too
                <img src="http://t2.gstatic.com/images?q=tbn:ANd9GcQCze-mfukcuvzKk7Ilj2zQ0CS6PbOkq7ZhRInnNd1Yz3TQzU4e&t=1" />
            </div>*/}


            {showIconBar && <IconBar onSelectEmoji={onSelectEmoji}
                                     onSelectFile={onSelectFile} showImage={true} showFile={client === 'bo'} showLink={client === 'bo'}/>}

            <p>{idleWarning}</p>

        </Fragment>
    )
}

export default ChatlineInput

ChatlineInput.propTypes = {
    onSubmitChatline: PropTypes.func.isRequired,
    onSelectFile: PropTypes.func.isRequired,
    eigenaarRef: PropTypes.object,
    showIconBar: PropTypes.bool,
    client: PropTypes.string,
    injectedDefaultAnswer: PropTypes.object,
    chat: PropTypes.object,
    forceCloseChat: PropTypes.func
}

ChatlineInput.defaultProps = {
    showIconBar: true
}