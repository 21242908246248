import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {NieuwsViewer} from "@ahsmediatheek/bo-module-nieuws";
import {Icon} from 'semantic-ui-react'
import {Nieuws, NieuwsType, ApplicationPath} from 'med-class'

const MAX_VIDEO_DISPLAY = 60000

export default class NieuwsPlayer extends Component {

    nieuwsPointer = 0

    constructor(props) {
        super(props)
        const displayTime = 20000
        this.state = {
            localeContent: null,
            currentNieuws: null,
            displayTime,
            nieuwsTimer: setInterval(this.autoNext, displayTime),
        }

        this.displayNieuws()
    }

    componentWillUnmount() {
        clearInterval(this.state.nieuwsTimer)
    }

    autoNext = () => {
        this.navigate(1)
    }

    displayNieuws = () => {

        const {nieuws, localeDataService} = this.props


        if (!nieuws) return

        let currentNieuws
        if (nieuws.length === 0) {
            const n = new Nieuws()
            n.id = 1
            n.type = NieuwsType.IMAGE
            n.imagePath = ApplicationPath.nieuwsImagesRootFolder + 'geenNieuws.jpg'
            currentNieuws = n
        } else {
            currentNieuws = nieuws[this.nieuwsPointer]
        }


        localeDataService.getLocaleData(currentNieuws.id)
            .then(({localeContent}) => {
                    this.setState({
                        localeContent: localeContent,
                        currentNieuws,

                    })
                }
            )

    }

    navigate = (offset) => {
        const {nieuwsTimer, displayTime} = this.state
        clearInterval(nieuwsTimer)
        this.setState({nieuwsTimer: setInterval(this.autoNext, displayTime)})

        const {nieuws} = this.props
        let newPointer = this.nieuwsPointer + offset
        if (newPointer < 0) newPointer = nieuws.length - 1
        if (newPointer > nieuws.length - 1) newPointer = 0

        this.nieuwsPointer = newPointer


        this.displayNieuws()
    }

    onDuration = (d) => {
        const {nieuwsTimer} = this.state

        const videoDuration = Math.min(MAX_VIDEO_DISPLAY, d * 1000)

        clearInterval(nieuwsTimer)
        this.setState({nieuwsTimer: setInterval(this.autoNext, videoDuration)})
    }


    render() {
        const {localeContent, currentNieuws} = this.state
        const {toonNavigatie, directLink} = this.props

        const isKiosk = directLink && directLink.isKiosk()

        if (!currentNieuws || !localeContent) return null

        return (
            <div className='player-wrapper'>
                <NieuwsViewer nieuws={currentNieuws} autoPlay={true} localeContent={localeContent} style={currentNieuws.styleObject}
                              onDuration={this.onDuration}
                              enableLinks={!isKiosk}/>
                {toonNavigatie &&
                <Fragment>
                    <div className='player-nav left' onClick={() => this.navigate(-1)}><Icon name='arrow left' size='huge'/></div>
                    <div className='player-nav right' onClick={() => this.navigate(1)}><Icon name='arrow right' size='huge'/></div>
                </Fragment>}
            </div>
        )
    }
}

NieuwsPlayer.propTypes = {
    nieuws: PropTypes.array.isRequired,
    localeDataService: PropTypes.object.isRequired,
    toonNavigatie: PropTypes.bool,
    directLink: PropTypes.object
}

NieuwsPlayer.defaultProps = {
    toonNavigatie: true
}
