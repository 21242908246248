import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Dropdown, Checkbox} from 'semantic-ui-react'
import Slider from 'rc-slider'
import moment from 'moment'

import '../styles.css'
//const createSliderWithTooltip = Slider.createSliderWithTooltip;

//const Range = createSliderWithTooltip(Slider.Range);

class PublicatiejaarDropdown extends Component {

    state = {
        open: false,
        checkedVals: {na: false, voor: false}
    }

    componentDidMount() {
        const today = parseInt(moment().format('YYYY'))

        this.setState({na: today-10, voor: today-5, min: today - 20, max: today})
    }

    componentWillReceiveProps(newProps) {

    }

    mouseEnterHandler = () => {
        this.setState({open: true})
    }

    mouseLeaveHandler = () => {
        this.setState({open: false})
    }

    onChangeNa = (value) => {
        let voor = this.state.voor
        if (value >= voor) voor = value
        this.setState({na: value, voor})
    }

    onChangeVoor = (value) => {
        let na = this.state.na
        if (value <= na) na = value
        this.setState({na, voor: value})
    }

    onAfterChange = () => {
        this.updateFilterObject(this.state.checkedVals)
    }

    onOptionChange = (event, {name, checked}) => {
        const { checkedVals } = this.state
        checkedVals[name] = checked
        this.updateFilterObject(checkedVals)
        this.setState({checkedVals})
    }

    updateFilterObject = (checkedVals) => {
        const { na, voor } = this.state
        const { filterObject, renderMode, localeManager} = this.props
        const objArr = []
        if (checkedVals.na) objArr.push({key: 'na', value: na, label: `${localeManager.getString('NA')} ${na}`})
        if (checkedVals.voor) objArr.push({key: 'voor', value: voor, label: `${localeManager.getString('VOOR')} ${voor}`})
        filterObject.data[this.props.name] = objArr
        if (objArr.length === 0) delete filterObject.data[this.props.name]
        console.log('PJ', filterObject)
        this.props.onOptionChange(null, {name: this.props.name, renderMode})
    }


    render() {
        const {open, min, max, na, voor, checkedVals} = this.state
        const {title, name, filterObject, localeManager} = this.props
        const somethingSelected = filterObject.data[name] && filterObject.data[name].length > 0

        const colWidth = 300

        return (
            <Dropdown open={open}
                      onMouseEnter={this.mouseEnterHandler}
                      onMouseLeave={this.mouseLeaveHandler}
                      style={{marginBottom: '5px', backgroundColor: somethingSelected ? 'green' : 'lightgrey', color: somethingSelected ? 'white' : 'black'}}
                      text={title}
                      labeled
                      button
                      icon='filter'
                      className='icon'>
                <Dropdown.Menu>
                    <div style={{display: 'flex', width: colWidth, padding: '10px', alignItems: 'center'}}>
                        <Checkbox style={{marginRight: '10px', width: '80px'}} label={localeManager.getString('NA')} checked={checkedVals.na} name='na' selecteditem={{na}} onChange={this.onOptionChange}/>
                        <div style={{width: '210px'}}>
                            <Slider disabled={!checkedVals.na} min={min} max={max} value={na} included onChange={this.onChangeNa} onAfterChange={this.onAfterChange}/>
                        </div>
                        <span style={{marginLeft: '10px', color: 'black'}}>{na}</span>
                    </div>
                    <div style={{display: 'flex', width: colWidth, padding: '10px', alignItems: 'center'}}>
                        <Checkbox style={{marginRight: '10px', width: '80px'}} label={localeManager.getString('VOOR')} checked={checkedVals.voor} name='voor' selecteditem={{voor}} onChange={this.onOptionChange}/>
                        <div style={{width: '210px'}}>
                            <Slider disabled={!checkedVals.voor} min={min} max={max} value={voor} included onChange={this.onChangeVoor} onAfterChange={this.onAfterChange}/>
                        </div>
                        <span style={{marginLeft: '10px', color: 'black'}}>{voor}</span>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}

PublicatiejaarDropdown.propsTypes = {
    localeManager: PropTypes.object.isRequired,
    title: PropTypes.string,
    onOptionChange: PropTypes.func,
    name: PropTypes.string,
    filterObject: PropTypes.object,
    renderMode: PropTypes.string
}

PublicatiejaarDropdown.defaultProps = {
    filterObject: {},
}

export default PublicatiejaarDropdown