import {Zoeklijst} from "./Zoeklijst";
import {ZoeklijstItem} from "./ZoeklijstItem";
import {ArteveldeUser} from "../artevelde/ArteveldeUser";

export class ZoeklijstQuery {

    static readonly CREATE_ZOEKLIJST: string = 'createZoeklijst'
    static readonly UPDATE_ZOEKLIJST: string = 'updateZoeklijst'
    static readonly DELETE_ZOEKLIJST: string = 'deleteZoeklijst'
    static readonly CREATE_ZOEKLIJST_ITEM: string = 'createZoeklijstItem'
    static readonly DELETE_ZOEKLIJST_ITEM: string = 'deleteZoeklijstItem'
    static readonly MAAK_ZOEKLIJST_LEEG: string = 'maakZoeklijstLeeg'
    static readonly GET_ZOEKLIJST_ZOEKRESULTATEN: string = 'getZoeklijstZoekresultaten'

    constructor(phpsessid: string = ''){
        this.phpsessid = phpsessid
    }

    _explicitType: string = "ZoeklijstQuery"

    function: string = ''
    zoeklijstLabel: string = ''
    zoekresultaatId: string = ''
    bron: string = ''
    phpsessid: string
    zoeklijst: Zoeklijst
    zoeklijstItem: ZoeklijstItem
    arteveldeUser: ArteveldeUser
}
