import React, {Component, Fragment, createRef} from 'react'
import PropTypes from 'prop-types'
import {Divider, Header, Input, List} from 'semantic-ui-react'

export default class ZoeklijstViewer extends Component {

    inputRef = createRef()

    constructor(props) {
        super(props)

        const items = props.mijnMediatheekService.zoeklijstItemLookup[props.zoekresultaat.id]
        const isFavoriet = items && items.filter(zoeklijstItem => zoeklijstItem.zoeklijstID === 0).length > 0

        this.state = {
            items,
            isFavoriet,
            newListLabel: ''
        }
    }

    componentDidMount() {
        this.inputRef.current.focus()
    }

    onInputChange = (event, {value}) => {
        this.setState({newListLabel: value})
    }

    onInputKeyDown = (event) => {
        if (event.keyCode !== 13) return
        const { newListLabel } = this.state
        const { mijnMediatheekService, localeManager, arteveldeUser, zoekresultaat } = this.props

        if (newListLabel === '') return

        //verbiedt 'favorieten'
        if (newListLabel.toLowerCase() === "favorieten" || newListLabel.toLowerCase() === "favorites"){
            alert(localeManager.getString("ZOEKLIJST_NAAM_NIET_TOEGELATEN"))
            return
        }

        if (mijnMediatheekService.zoeklijstBestaat(newListLabel)){
            alert(localeManager.getString("ZOEKLIJST_NAAM_BESTAAT_AL"))
            return
        }

        //eerst zoeklijst maken, dan zoekresultaat in zoeklijst plaatsen
        mijnMediatheekService.createZoeklijst(newListLabel, arteveldeUser).then(zoeklijst => {

            //de huidigeStatus = false!
            mijnMediatheekService.toggleZoekresultaatInZoeklijst(zoekresultaat, zoeklijst, false, arteveldeUser).then(items => {
                const isFavoriet = items && items.filter(zoeklijstItem => zoeklijstItem.zoeklijstID === 0).length > 0
                this.setState({items, isFavoriet, newListLabel: ''})
            })
        })
    }

    onLeeslijstClick = (zoeklijst, isChecked) => {
        const { mijnMediatheekService, zoekresultaat, onZoeklijstChange, arteveldeUser } = this.props
        mijnMediatheekService.toggleZoekresultaatInZoeklijst(zoekresultaat, zoeklijst, isChecked, arteveldeUser).then(items => {

            const isFavoriet = items && items.filter(zoeklijstItem => zoeklijstItem.zoeklijstID === 0).length > 0
            onZoeklijstChange(items)
            this.setState({items, isFavoriet})
        })
    }

    render() {

        const { newListLabel, items, isFavoriet } = this.state
        const {localeManager, mijnMediatheekService, onMouseLeave} = this.props

        return (
            <div onMouseLeave={onMouseLeave}>
                <Divider/>
                <Header as='h4'>{localeManager.getString('MIJN_LIJSTEN')}</Header>
                <Input ref={this.inputRef} fluid value={newListLabel} placeholder={localeManager.getString('VOEG_LEESLIJST_TOE')}
                       onChange={this.onInputChange} onKeyDown={this.onInputKeyDown}/>

                <List selection>
                    <List.Item onClick={() => this.onLeeslijstClick(mijnMediatheekService.favorietenZoeklijst, isFavoriet)}>
                        {isFavoriet && <List.Icon name='star' color='yellow' verticalAlign='middle'/>}
                        <List.Content>
                            <List.Header style={{color: isFavoriet ? 'black' : 'dimgrey', fontStyle: isFavoriet ? 'normal' : 'italic'}} >{localeManager.getString('FAVORIETEN')}</List.Header>
                        </List.Content>
                    </List.Item>
                </List>

                <Divider/>

                <List selection>

                    {mijnMediatheekService.zoeklijsten.map((zoeklijst, index) => {

                        const isChecked = items && items.filter(zoeklijstItem => zoeklijstItem.zoeklijstID === zoeklijst.id).length > 0
                        //console.log('CHECKED', items, zoeklijstItem)
                        return (
                            <Fragment key={index}>
                                <List.Item onClick={() => this.onLeeslijstClick(zoeklijst, isChecked)}>
                                    {isChecked && <List.Icon name='star' color='yellow' verticalAlign='middle'/>}
                                    <List.Content>
                                        <List.Header  style={{color: isChecked ? 'black' : 'dimgrey', fontStyle: isChecked ? 'normal' : 'italic'}}>{zoeklijst.label}</List.Header>
                                    </List.Content>
                                </List.Item>
                            </Fragment>
                        )
                    })}
                </List>


            </div>
        )
    }
}

ZoeklijstViewer.propTypes = {
    mijnMediatheekService: PropTypes.object.isRequired,
    zoekresultaat: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    arteveldeUser: PropTypes.object.isRequired,
    onMouseLeave: PropTypes.func,
    onZoeklijstChange: PropTypes.func,
}

ZoeklijstViewer.defaultProps = {}