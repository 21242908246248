import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Segment} from "semantic-ui-react";
import Parser from 'html-react-parser'
import {LocaleDataService} from 'med-class'

const DringendNieuws = ({nieuwsService, localeManager}) => {

    const [localeDataService] = useState(new LocaleDataService('urgentNieuws', ['body']))
    const [dringendNieuws, setDringendNieuws] = useState()
    const [body, setBody] = useState()


    useEffect(() => {

        nieuwsService.getUrgentNieuws().then(res => {
            setDringendNieuws(res)

            localeDataService.getLocaleData(res.id).then(({localeContent}) => {
                setBody(localeDataService.getPropertyValue(res.id, 'body', localeManager.locale))
            })

        })
    }, [nieuwsService, localeDataService, localeManager.locale])


    if (!dringendNieuws || dringendNieuws.online === 0 || !body) return null

    return (

        <Segment padded>
            {/*<Icon name='bullhorn' size='large'/>*/}
            {Parser(body)}
        </Segment>
    )
}

export default DringendNieuws

DringendNieuws.propTypes = {
    nieuwsService: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
}

DringendNieuws.defaultProps = {}