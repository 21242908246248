export class BidocKoppeling {


	constructor(bidocId: number | null) {
		this.bidocId = bidocId
	}

	_explicitType: string = 'BidocKoppeling'

	id:number = 0
	bidocId: number | null = 0
	taxonomieItems: string = ""
	minSpelers: number | null = null
	maxSpelers: number | null = null
	taalonafhankelijk: number = 0
	uitgelicht: number = 0
	handleidingUrl: string = ''
	werkvormenUrl: string = ''
    packshotExists: number = 0
}
