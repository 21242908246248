export class Social {

    id: number = 0
    context: string = ''
    bron: string = ''
    ref: number = 0
    user: string = ''
    reviewer: string
    datum: string = ''
    commentaar: string = ''
    status: string = ''

    get commentaarLabel(): string {
        return this.datum + ' | ' + this.user + ' (' + this.id + ')'
    }



    get statusLabel(): string {
        switch (this.status){
            case 'live':
                return 'LIVE (aanvaardt door ' + this.reviewer + ')'
            default:
                return this.status
        }
    }
}
