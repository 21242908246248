import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Button, Card, Icon, Form, Label} from "semantic-ui-react"
import {DirectLink} from 'med-class'
import '../styles.css'

const Parser = require('html-react-parser')
const {CopyToClipboard} = require('react-copy-to-clipboard')

export default class ZoeklijstListItem extends Component {

    constructor(props) {
        super(props)
        this.state = {
            edit: false,
            zoeklijstLabel: props.zoeklijst.label,
            zoeklijstBeschrijving: props.zoeklijst.beschrijving,
            zoeklijstIsPubliek: props.zoeklijst.publiek === 1,
            labelSaveButtonVisible: false,
            linkCopied: false
        }
    }

    componentDidMount() {

    }

    onWijzigButtonClick = (event) => {
        event.stopPropagation()
        const {edit} = this.state
        const {zoeklijst} = this.props
        this.setState({zoeklijstLabel: zoeklijst.label, zoeklijstBeschrijving: zoeklijst.beschrijving, edit: !edit})
    }

    onGereedButtonClick = () => {
        const {zoeklijstLabel, zoeklijstBeschrijving} = this.state
        const {zoeklijst, onZoeklijstEditEnd} = this.props
        this.setState({labelSaveButtonVisible: false})
        zoeklijst.label = zoeklijstLabel
        zoeklijst.beschrijving = zoeklijstBeschrijving
        onZoeklijstEditEnd(zoeklijst)
    }

    onVerwijderLijstButtonClick = () => {
        const {localeManager, onVerwijderLijst, zoeklijst} = this.props
        const warning = window.confirm(localeManager.getString('VERWIJDER_LIJST_WARNING'))
        if (warning === true) {
            onVerwijderLijst(zoeklijst)
            this.setState({labelSaveButtonVisible: false, edit: false})
        }
    }

    onMaakLijstLeegButtonClick = () => {
        const {localeManager, onMaakLijstLeeg, zoeklijst} = this.props
        const warning = window.confirm(localeManager.getString('MAAK_LIJST_LEEG_WARNING'))
        if (warning === true) {
            onMaakLijstLeeg(zoeklijst)
        }
    }

    toggleLijstPubliek = (event, {checked}) => {
        const {zoeklijst, onZoeklijstEditEnd} = this.props
        zoeklijst.publiek = checked ? 1 : 0
        onZoeklijstEditEnd(zoeklijst)
        this.setState({zoeklijstIsPubliek: checked})
    }

    onLabelChange = (event, {name, value}) => {
        this.setState({[name]: value, labelSaveButtonVisible: true})
    }

    render() {
        const {edit, zoeklijstLabel, zoeklijstBeschrijving, labelSaveButtonVisible, zoeklijstIsPubliek, linkCopied} = this.state
        const {zoeklijst, localeManager, isFavoriet, onToonResultaten} = this.props
        const directLink = DirectLink.buildDirectLinkString('leeslijst', zoeklijst.id)

        return (
            <Card link={false} style={{marginBottom: '5px', border: 'none', backgroundColor: isFavoriet ? 'honeydew' : ''}}
                  fluid color={zoeklijst.publiek === 1 ? 'red' : 'blue'}>

                {isFavoriet && <Fragment>
                    <Card.Content style={{display: 'flex', alignItems: 'center'}}>
                        <Card.Header as='a' onClick={() => onToonResultaten(zoeklijst)} style={{fontStyle: 'italic'}}>{localeManager.getString(zoeklijst.label)}</Card.Header>
                        <Label circular style={{marginLeft: '10px'}} size='mini'>{zoeklijst.aantal}</Label>
                        <div style={{flexGrow: 1}}/>
                        <Button size='tiny' icon onClick={this.onWijzigButtonClick}>
                            <Icon name="cog"/>
                        </Button>
                    </Card.Content>
                    {edit && <Card.Content>
                        {zoeklijst.aantal > 0 && <Button onClick={this.onMaakLijstLeegButtonClick}>{localeManager.getString('MAAK_LIJST_LEEG')}</Button>}
                    </Card.Content>}
                </Fragment>}

                {!isFavoriet && <Fragment>
                    {!edit && <Card.Content style={{display: 'flex', alignItems: 'center'}}>
                        <Card.Header as='a' onClick={() => onToonResultaten(zoeklijst)}>{Parser(zoeklijst.label)}</Card.Header>
                        <Label circular style={{marginLeft: '10px'}} size='mini'>{zoeklijst.aantal}</Label>

                        <div style={{flexGrow: 1}}/>

                        <Button size='tiny' icon onClick={this.onWijzigButtonClick}>
                            <Icon name="cog"/>
                        </Button>
                    </Card.Content>}

                    {edit && <Card.Content>
                        <Form>
                            <Form.Input fluid style={{marginRight: '5px'}} label={localeManager.getString('TITEL')} name='zoeklijstLabel' value={zoeklijstLabel} onChange={this.onLabelChange}/>
                            <Form.TextArea label={localeManager.getString('BESCHRIJVING')} name='zoeklijstBeschrijving' value={zoeklijstBeschrijving} onChange={this.onLabelChange}/>


                            <Form.Checkbox checked={zoeklijstIsPubliek} label={localeManager.getString('LIJST_IS_PUBLIEK')} onClick={this.toggleLijstPubliek}/>
                            <Form.Field inline>
                                <label>{localeManager.getString('DIRECTE_LINK')}:</label>
                                <a href={directLink} target='_blank' rel='noopener noreferrer' style={{marginRight: '15px'}}>{directLink}</a>
                                <CopyToClipboard text={directLink}
                                                 onCopy={() => this.setState({linkCopied: true})}>
                                    <Button circular size='mini' color={linkCopied ? 'green' : 'grey'} icon={linkCopied ? 'check' : 'clipboard'}/>
                                </CopyToClipboard>
                            </Form.Field>


                            <Form.Group inline>
                                <Button onClick={this.onVerwijderLijstButtonClick} color='red'>{localeManager.getString('VERWIJDER_LIJST')}</Button>

                                {zoeklijst.aantal > 0 && <Button onClick={this.onMaakLijstLeegButtonClick}>{localeManager.getString('MAAK_LIJST_LEEG')}</Button>}
                            </Form.Group>


                            {!labelSaveButtonVisible && <Button onClick={this.onWijzigButtonClick}>{localeManager.getString('SLUIT')}</Button>}
                            {labelSaveButtonVisible && <Button color='green' onClick={this.onGereedButtonClick}>{localeManager.getString('BEWAAR')}</Button>}

                        </Form>
                    </Card.Content>}
                </Fragment>}

                {/*<Card.Content style={{display: 'flex', alignItems: 'center'}}>

                    {(!edit && !isFavoriet) && <Card.Header as='a' onClick={() => onToonResultaten(zoeklijst)}>{Parser(zoeklijst.label)}</Card.Header>}
                    {(edit && !isFavoriet) &&
                    <Form.Input style={{marginRight: '5px'}} name='zoeklijstLabel' value={zoeklijstLabel} onChange={this.onLabelChange}/>
                    }

                    {labelSaveButtonVisible && <Button size='tiny' icon color='green' onClick={this.onGereedButtonClick}>
                        <Icon name="save"/>
                    </Button>}

                    <Label circular style={{marginLeft: '10px'}} size='mini'>{zoeklijst.aantal}</Label>

                    <div style={{flexGrow: 1}}/>

                    <Button size='tiny' icon onClick={this.onWijzigButtonClick}>
                        <Icon name="cog"/>
                    </Button>

                </Card.Content>*/}

                {/*{!edit && zoeklijst.beschrijving && zoeklijst.beschrijving !== '' &&
                <Card.Content>
                    {zoeklijst.beschrijving}
                </Card.Content>
                }*/}

                {/*{edit &&
                <Card.Content>
                    <Form>
                        {!isFavoriet && <Fragment>
                            <Form.TextArea label={localeManager.getString('BESCHRIJVING')} name='zoeklijstBeschrijving' value={zoeklijst.beschrijving} onChange={this.onLabelChange}/>
                            <Form.Checkbox checked={zoeklijstIsPubliek} label={localeManager.getString('LIJST_IS_PUBLIEK')} onClick={this.toggleLijstPubliek}/>
                            <Form.Field inline>
                                <label>{localeManager.getString('DIRECTE_LINK')}:</label>
                                <a href={directLink} target='_blank' rel='noopener noreferrer' style={{marginRight: '15px'}}>{directLink}</a>
                                <CopyToClipboard text={directLink}
                                                 onCopy={() => this.setState({linkCopied: true})}>
                                    <Button circular size='mini' color={linkCopied ? 'green' : 'grey'} icon={linkCopied ? 'check' : 'clipboard'}/>
                                </CopyToClipboard>
                            </Form.Field>
                        </Fragment>}


                        <Form.Group inline>
                            {!isFavoriet && <Button onClick={this.onVerwijderLijstButtonClick} color='red'>{localeManager.getString('VERWIJDER_LIJST')}</Button>}

                            {zoeklijst.aantal > 0 && <Button onClick={this.onMaakLijstLeegButtonClick}>{localeManager.getString('MAAK_LIJST_LEEG')}</Button>}
                        </Form.Group>
                    </Form>
                </Card.Content>
                }*/}
            </Card>
        )
    }
}

ZoeklijstListItem.propTypes = {
    isFavoriet: PropTypes.bool,
    zoeklijst: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    onToonResultaten: PropTypes.func.isRequired,
    onVerwijderLijst: PropTypes.func,
    onMaakLijstLeeg: PropTypes.func.isRequired,
    onZoeklijstEditEnd: PropTypes.func,
}

ZoeklijstListItem.defaultProps = {
    isFavoriet: false
}