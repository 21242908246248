import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Form, Divider, Button, Modal, Header} from 'semantic-ui-react'

import CheckboxDropdown from './CheckboxDropdown'
import MinMaxSliderDropdown from './MinMaxSliderDropdown'
import ZoeksetBanner from "./ZoeksetBanner"
import _ from 'lodash'
import Parser, {domToReact} from 'html-react-parser'
import CheckboxToggler from "./CheckboxToggler";
import {ORANJE, Feedback, FeedbackContext, ApplicationPath} from 'med-class'

import axios from 'axios'
import moment from 'moment'

const ZoeksetFilter = ({
                           taxonomieService, onFilterChange, filterObject, zoekset, localeManager,
                           filterTekst, onFilterTekstChange, onFilterTekstClear, resetZoeksetFilter, directLink, arteveldeUser
                       }) => {

    const [modalOpen, setModalOpen] = useState(false)
    const [userText, setUserText] = useState('')
    const [feedbackSent, setFeedbackSent] = useState(false)
    const [feedbackTimeout, setFeedbackTimeout] = useState()
    const [resetButtonEnabled, setResetButtonEnabled] = useState(false)
    //const hasGroups = zoekset.hasOwnProperty('optionGroups')

    /*useEffect(() => {
    }, [filterObject])*/

    useEffect(() => {
        if (!feedbackSent) return


        const handleTimeout = () => {
            clearTimeout(feedbackTimeout)
            setFeedbackSent(false)
        }

        setFeedbackTimeout(setTimeout(handleTimeout, 5000))

        return () => {
            clearTimeout(feedbackTimeout)
        }
    }, [feedbackSent, feedbackTimeout])


    const onModalClose = () => {
        setModalOpen(false)
    }

    const onUserTextChange = (event, {value}) => {
        setUserText(value)
    }

    const onSubmitForm = () => {
        const feedback = new Feedback()
        feedback.context = FeedbackContext.SPELWIJZER

        feedback.datum = moment().format("YYYY-MM-DD HH:mm:ss")
        feedback.auteurMail = arteveldeUser.emailAddress
        feedback.feedback = userText

        //doorsturen naar userApi
        const formObject = {function: 'submitFeedback', feedback}
        console.log('SENDING TO USER API', formObject)
        axios.post(ApplicationPath.userApiUrl, formObject).then(response => {
            //het feedback object wordt teruggestuurd van de server met een aangepaste status
            console.log('FEEDBACK SENT', response.data)
            /*const alteredFeedback = convertObjectToClass(response.data, 'Feedback')
            console.log('FEEDBACK RESPONSE', alteredFeedback)
            me.updateFeedback(alteredFeedback)*/
        })


        setFeedbackSent(true)
        setModalOpen(false)
        setUserText('')
    }

    const onOptionChange = (...props) => {
        setResetButtonEnabled(true)
        onFilterChange(...props)
    }

    const resetOptions = () => {
        setResetButtonEnabled(false)
        resetZoeksetFilter()
    }

    const renderOption = (option, index) => {
        let dataprovider

        switch (option.providerKind) {
            case 'taxonomie':
                const taxonomie = taxonomieService.taxonomieLabelLookup[option.providerCode]
                dataprovider = _.orderBy(taxonomieService.taxonomieItemsLookup[taxonomie.id], taxonomie.sorteerOpNummer === 1 ? 'sort' : 'label')
                break
            /*case 'object':
                dataprovider = option.providerValue
                break*/
            default:
            //console.log('DATAPROVIDER', option)
        }

        switch (option.renderer) {
            case 'checkboxDropdown':
                return (
                    <CheckboxDropdown localeManager={localeManager}
                                      key={index}
                                      title={localeManager.getString(option.label)}
                                      name={option.providerCode}
                                      dataprovider={dataprovider}
                                      renderMode={option.providerKind}
                                      displayLabelProp={option.displayLabelProp}
                                      filterObject={filterObject}
                                      onOptionChange={onOptionChange}/>
                )
            case 'minMaxSliderDropdown':
                //console.log('MIN MAX', option.fields, option.range)
                return <MinMaxSliderDropdown localeManager={localeManager}
                                             key={index}
                                             title={localeManager.getString(option.providerCode)} name={option.providerCode}
                                             filterObject={filterObject}
                                             fields={option.fields}
                                             range={option.range}
                                             onOptionChange={onFilterChange}/>
            case 'checkboxToggler':
                return <CheckboxToggler key={index}
                                        localeManager={localeManager}
                                        title={localeManager.getString(option.label)}
                                        name={option.providerCode}
                                        filterObject={filterObject}
                                        onOptionChange={onFilterChange}/>
            default:
                return <Button key={index}>RENDERER {option.renderer}</Button>
        }
    }

    const isKiosk = directLink && directLink.isKiosk()

    return (

        //hier staat een div ipv een Form element. Bij een Form element wordt de enter toets actief of de ChekcBoxToggler (zie GH #268)
        <div size='mini'>


            {zoekset && <ZoeksetBanner zoekset={zoekset} localeManager={localeManager}/>}
            {zoekset.description_nl &&
            <div style={{padding: '15px', fontSize: '1rem'}}>

                {Parser(zoekset['description_' + localeManager.locale], {
                    replace: ({attribs, children}) => {
                        if (!attribs) return
                        if (!isKiosk) return
                        if (attribs.href) {
                            return <strong>{domToReact(children)}</strong>
                        }
                    }
                })}


                {zoekset.contact_form && !feedbackSent && arteveldeUser && arteveldeUser.emailAddress !== '' &&
                <Button style={{marginTop: '10px'}} size='mini' color='green' onClick={() => setModalOpen(true)}>{localeManager.getString('CONTACTFORMULIER')}</Button>}

                {zoekset.contact_email && (!arteveldeUser || arteveldeUser.emailAddress === '') &&
                <p><a href={`mailto:${zoekset.contact_email}`}>{zoekset.contact_email}</a></p>}

                {feedbackSent && <h2 style={{marginTop: '10px'}}>{localeManager.getString('FEEDBACK_BEDANKT')}</h2>}

                <Divider/>
            </div>
            }

            {zoekset.options.map((option, index) => renderOption(option, index))}


            <p>{filterObject.toString()}</p>

            <Form.Input label={localeManager.getString('VERFIJN')} name='tekstfilter' fluid value={filterTekst} onChange={onFilterTekstChange}
                        icon={{name: 'delete', circular: true, link: true, onClick: onFilterTekstClear}}/>


            {resetButtonEnabled && <Form.Button style={{marginTop: '10px'}} content={localeManager.getString('WIS_SELECTIE')} onClick={resetOptions}/>}

            {zoekset.contact_form && arteveldeUser && <Modal dimmer='inverted' open={modalOpen} onClose={onModalClose} closeIcon>

                <div style={{backgroundColor: ORANJE.css, padding: '20px', color: 'white', marginBottom: '20px'}}>
                    <Header inverted>{localeManager.getString('CONTACTFORMULIER')}</Header>
                    <p>{Parser(zoekset.contact_form[localeManager.locale])}</p>
                </div>

                <Modal.Content>
                    <Form onSubmit={onSubmitForm}>
                        <Form.Field>
                            <label>{localeManager.getString('MAIL_ADRES')}</label>
                            <p>{arteveldeUser.emailAddress}</p>

                        </Form.Field>
                        <Form.TextArea
                            autoFocus
                            name='feedback'
                            style={{minHeight: '180px'}}
                            label={localeManager.getString('UW_INPUT')}
                            placeholder={localeManager.getString('UW_INPUT')}
                            value={userText}
                            onChange={onUserTextChange}
                        />
                        <Form.Button disabled={userText.length < 20}>{localeManager.getString('VERSTUUR')}</Form.Button>
                    </Form>
                </Modal.Content>
            </Modal>}


        </div>
    )


}

ZoeksetFilter.propTypes = {
    localeManager: PropTypes.object.isRequired,
    zoekset: PropTypes.object,
    taxonomieService: PropTypes.object,
    mediatheekService: PropTypes.object,
    filterObject: PropTypes.object,
    onFilterChange: PropTypes.func,
    onFilterTekstChange: PropTypes.func,
    onFilterTekstClear: PropTypes.func,
    filterTekst: PropTypes.string,
    arteveldeUser: PropTypes.object
}

export default ZoeksetFilter