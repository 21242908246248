import React, {Component} from 'react'
import { Segment } from 'semantic-ui-react'
import AbstractZoekresultaatRenderer from './AbstractZoekresultaatRenderer'
import {ApaConverter, ApaConverter_v7} from 'med-class'
const Parser = require('html-react-parser')

class ApaRenderer extends Component {

    state = {linkCopied: false}


    render(){
        const { zoekresultaat, localeManager } = this.props

        //TODO niet alle zoekresultaten hebben een relevante APA. Bv. alles weigeren dan EPub is
        const geenApa = ['ZoekresultaatEPub']
        if (geenApa.indexOf(zoekresultaat._explicitType) > -1) return null

        const apa6 = new ApaConverter(zoekresultaat, localeManager).convert()
        const apa7 = new ApaConverter_v7(zoekresultaat, localeManager).convert()

        return (
            <AbstractZoekresultaatRenderer label='APA'>
                <Segment style={{backgroundColor: '#e1fcff'}}>
                    <div>APA 6</div>
                    {Parser(apa6)}
                    <div>APA 7 (in voorbereiding)</div>
                    {Parser(apa7)}
                </Segment>
            </AbstractZoekresultaatRenderer>
        )
    }
}

export default  ApaRenderer
