import {CustomUrlSearchParams} from "../..";
import {ApplicationPath} from "../../domain/path";

export class DirectLink {

    static LEESLIJST: string = 'leeslijst'
    static SEARCH: string = 'search'
    static BEWAARDE_ZOEKOPDRACHT: string = 'bewaardezoekopdracht'
    static FAQ: string = 'faq'
    static SPELWIJZER: string = 'spelwijzer'
    static DATABANKEN: string = 'databanken'
    static KIOSK: string = 'kiosk'

    static buildDirectLinkString(dl: string, id: any, source: string){
        let url = `${ApplicationPath.linkWebportaal}/`
        url += source ? source : dl
        if (id) url += `/${id}`
        return url
    }

    baseUrl: string
    url: URL
    params: CustomUrlSearchParams//URLSearchParams
    injectedObject: any

    private _status: string = 'valid'

    constructor(url = window.location.href){
        this.baseUrl = url
        //this.url = new URL(this.baseUrl)
        //this.params = this.url.searchParams
        this.params = new CustomUrlSearchParams(url)
    }

    isKiosk = () => {
        const dlKiosk = this.params.has('dl') && this.params.get('dl') === DirectLink.KIOSK
        const modeKiosk = this.params.has('mode') && this.params.get('mode') === DirectLink.KIOSK
        return dlKiosk || modeKiosk
    }

    isDirectSearch = () => {
        return this.params.has('dl') && this.params.get('dl') === DirectLink.SEARCH
    }

    isDirectLeeslijst = () => {
        return this.params.has('dl') && this.params.get('dl') === DirectLink.LEESLIJST
    }

    isDirectFaq = () => {
        return this.params.has('dl') && this.params.get('dl') === DirectLink.FAQ
    }

    isDirectBewaardeZoekopdracht = () => {
        return this.params.has('dl') && this.params.get('dl') === DirectLink.BEWAARDE_ZOEKOPDRACHT
    }

    isDirectSpelwijzer = () => {
        return this.params.has('dl') && this.params.get('dl') === DirectLink.SPELWIJZER
    }

    isDirectDatabanken = () => {
        return this.params.has('dl') && this.params.get('dl') === DirectLink.DATABANKEN
    }

    getProp = (prop: string) => {
        return this.params.get(prop)
    }

    invalidate = () => {
        this._status = 'invalid'
    }

    isValid = () => {
        return this._status === 'valid'
    }
}
