import React, {Fragment, useState} from 'react'
import PropTypes from 'prop-types'
import {Modal, Image} from 'semantic-ui-react'
import UIL from '../../assets/uil.jpg'
const Parser = require('html-react-parser')

const InformatievaardigTile = ({localeManager}) => {

    const [modalVisible, setModalVisible] = useState(false)

    /*const toggleModalVisible = (event, visible) => {
        console.log('V', event, visible)
        event.preventDefault()
        event.stopPropagation()
        setModalVisible(visible)
    }*/


    return (
        <Fragment>
            <a className='small-tile' style={{position: 'relative'}} href='//arteveldehogeschool.instructure.com/courses/5136' target='_blank' rel="noopener noreferrer">
            <img src={UIL} alt='uil'/>
            <div className='small-tile-text'>
                {Parser(localeManager.getString('INFORMATIEVAARDIG'))}
            </div>
            {/*<div style={{
                position: 'absolute', top: '-10px', right: '-10px', backgroundColor: BLAUW.css,
                padding: '20px', borderRadius: '50%', transform: 'rotate(20deg)', cursor: 'pointer'
            }} onClick={(event) => toggleModalVisible(event, true)}>Eh...?
            </div>*/}

            <Modal dimmer='inverted' size='tiny' open={modalVisible} onClose={() => setModalVisible(false)} closeIcon>

                <Modal.Header>
                    {localeManager.getString('INFORMATIEVAARDIG')}
                </Modal.Header>
                <Modal.Content image>
                    <Image  size='huge' src={UIL} alt='uil' />
                    <Modal.Description>{Parser(localeManager.getString('INFORMATIEVAARDIG_UITLEG'))}</Modal.Description>
                </Modal.Content>

            </Modal>
        </a></Fragment>
    )
}

export default InformatievaardigTile

InformatievaardigTile.propTypes = {
    localeManager: PropTypes.object.isRequired
}

InformatievaardigTile.defaultProps = {}