import {AbstractZoekresultaat} from "./AbstractZoekresultaat";

export class ZoekresultaatDataWrapper {

    ee: any = null
    ingelogd: boolean = false
    paginanummer: number = 0
    totaal: number = 0
    zoekresultaten: AbstractZoekresultaat[]
    error: string = ''
}
