export class BewaardeZoekopdracht {

    _explicitType: string = "BewaardeZoekopdracht"

    id: number
    mode: string
    label: string
    query: string
    zoekOptieString: string
    userID: string
    aantal: number = 0

}
