import {BidocVerlenging} from "./BidocVerlenging";

export class BidocVerlengData {

	_explicitType: "BidocVerlengData"

	status: number
	lenersnummer: number
	verlengingen: BidocVerlenging[]
}
