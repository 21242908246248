import {AbstractZoekresultaat} from "./AbstractZoekresultaat";
import {ApplicationPath} from "../../domain/path";

export class ZoekresultaatBidoc extends AbstractZoekresultaat {

    _explicitType: string = "ZoekresultaatBidoc"


    /*
    * DIRECTE LINK
    * */
    get directeLink() {
        return `${ApplicationPath.linkWebportaal}/catalogus/${this.id}`
    }

    getProps(){
        return [
            "subtitel",
            "auteurs",
            "jaar",
            "druk",
            "uitgever",
            "jaargang",
            "jaargangnummer",
            "bladzijden",
            "publicatietype",
            "hoofdtitel",
            "deeltitels",
            "opleiding",
            "reeksnummer",
            "reeks",
            "tijdschrift",
            "mediatheek",
            "exemplaar",
            "trefwoorden",
            "genre",
            "beschrijving",
            "annotatie",
            "categorie",
            "isbn",
            "issn",
            "campus",
            "directe_Link",
            "apa"];
    }
}
