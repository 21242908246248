import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Header, Divider} from 'semantic-ui-react'

import 'moment/locale/nl'
import moment from 'moment'
import TijdsslotDagRenderer from "./TijdsslotDagRenderer";
import TijdsslotSlotRenderer from "./TijdsslotSlotRenderer";


const TijdsslotRenderer = ({tijdslots, localeManager, onSlotSelect, defaultSelectedSlot}) => {

    const [dagen, setDagen] = useState()
    const [selectedDag, setSelectedDag] = useState()
    //const [beschikbareSlotsOpDag, setBeschikbareSlotsOpDag] = useState()
    const [tijdsslotLookup, setTijdsslotLookup] = useState()
    //const [dagSlots, setDagSlots] = useState()
    const [selectedSlot, setSelectedSlot] = useState()

    useEffect(() => {
        if (!tijdslots) return
        buildTijdsslots()
    }, [tijdslots])

    /*useEffect(() => {
        if (!selectedDag || !tijdsslotLookup) return
        const dagSlots = tijdsslotLookup[selectedDag]
    }, [selectedDag, tijdsslotLookup])*/

    const buildTijdsslots = () => {

        const tijdsslotArr = []
        for (const tijdsslotsKey in tijdslots) {
            const val = tijdslots[tijdsslotsKey]
            const tot = moment(tijdsslotsKey).add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss')
            const dag = moment(tijdsslotsKey).format('YYYY-MM-DD')
            const slot = {van: tijdsslotsKey, tot, dag, beschikbaar: val}
            tijdsslotArr.push(slot)
        }
        const dagen = []
        const tsLookup = tijdsslotArr.reduce((lookup, slot) => {
            const dag = slot.van.substr(0, 10)
            if (!lookup[dag]) {
                lookup[dag] = []
                dagen.push(dag)
            }
            lookup[dag].push(slot)
            return lookup
        }, {})

        setTijdsslotLookup(tsLookup)
        setDagen(dagen)
        setSelectedDag(dagen[0])

    }

    const getBeschikbareSlotsOpDag = (dag) => {
        return tijdsslotLookup[dag].reduce((aantal, slot) => slot.beschikbaar > 0 ? aantal + 1 : aantal, 0)
    }

    const getBeschikbareMandjesInSlot = (slot) => {
        return slot.beschikbaar
    }

    const onDagClick = (dag) => {
        if (dag === selectedDag) return
        setSelectedDag(dag)
        setSelectedSlot(null)
    }

    const onSlotClick = (slot) => {
        if (slot === selectedSlot) return
        setSelectedSlot(slot)
        console.log('CLICK', slot)
        onSlotSelect(slot)
    }

    if (!dagen) return null


    return (
        <div className="tijdslots-container">
            <Header>{localeManager.getString('KIES_JE_DAG')}</Header>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                {dagen.map((dag, index) => {
                    const beschikbareSlots = getBeschikbareSlotsOpDag(dag)
                    return <TijdsslotDagRenderer key={index} dag={dag}
                                                 localeManager={localeManager}
                                                 selected={dag === selectedDag}
                                                 beschikbareSlots={beschikbareSlots}
                                                 onClick={() => beschikbareSlots > 0 && onDagClick(dag)}/>
                })}
            </div>

            <Divider/>

            <Header>{localeManager.getString('KIES_JE_OPHAAL_SLOT')}</Header>

            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                {tijdsslotLookup[selectedDag].map((slot, index) => {

                    const beschikbareMandjes = getBeschikbareMandjesInSlot(slot)

                    return <TijdsslotSlotRenderer key={index} slot={slot}
                                                  localeManager={localeManager}
                                                  selected={slot === selectedSlot}
                                                  beschikbareMandjes={beschikbareMandjes}
                                                  onClick={() => beschikbareMandjes > 0 && onSlotClick(slot)}/>
                })}
            </div>

        </div>
    )
}

export default TijdsslotRenderer

TijdsslotRenderer.propTypes = {
    tijdslots: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    onSlotSelect: PropTypes.func.isRequired,
    defaultSelectedSlot: PropTypes.string
}

TijdsslotRenderer.defaultProps = {}
