import React, {useState, useEffect} from 'react'
import {Select} from 'semantic-ui-react'
import PropTypes from 'prop-types'

import _ from 'lodash'
//import axios from 'axios'

const TaxonomieKiezer = (props) => {

    const [dataProvider, setDataProvider] = useState()
    const [taxonomieLabel] = useState(props.taxonomie)
    //const [taxonomie, setTaxonomie] = useState()
    const [selectedValue, setSelectedValue] = useState()

    useEffect(() => {
        const createDataProvider = ({taxonomie, taxonomieService}) => {
            //debugger
            let taxonomieItems = taxonomieService.taxonomieItemsLookup[taxonomieService.taxonomieLabelLookup[taxonomie].id]
            return taxonomieItems.map(ti => {
                return {key: ti.id, value: ti.id.toString(), text: ti.label}
            })
        }

        setDataProvider(createDataProvider(props))
    }, [props])

    useEffect(() => {
        const convertItemStringToItemIndex = ({selectedTaxonomieItem, taxonomieService, multiple}) => {

            if (!selectedTaxonomieItem) {
                return multiple ? [] : null
            }

            //input voor selectedTaxonomieItem is ALTIJD een string, ofwel "34", of "34,35,36"
            let rv = false

            let itemArray = selectedTaxonomieItem.split(",") //.filter(idString => isString !== "")
            //console.log('TAXONOMIE ITEM_ARRAY', itemArray)
            rv = itemArray.map(i => {
                //console.log('MAPPING', i)
                if (isNaN(i)) {
                    //converted = true
                    let taxonomieItem = taxonomieService.getTaxonomieItemByLabel(i)
                    i = taxonomieItem ? taxonomieItem.id.toString() : ''
                    //console.log('CONVERTED', i)
                }
                return i
            })

            return multiple ? rv : rv.length > 0 ? rv[0] : null
        }

        setSelectedValue(convertItemStringToItemIndex(props))
    }, [props])


    const onSelectTaxonomieItem = (event, {value}) => {

        if (!props.onSelectTaxonomieItem)
            return;

        //no change, no update
        if (value === selectedValue)
            return

        let selectedValues, selectedItems, selectedValueString

        if (props.multiple) {
            selectedItems = _.filter(props.taxonomieService.taxonomieItems, ti => {
                return (_.indexOf(value, ti.id) > -1)
            })
            selectedValues = value
            selectedValueString = value.join(',')
        } else {
            selectedValues = value
            selectedItems = props.taxonomieService.taxonomieItemLookup[value]
            selectedValueString = value.toString()
        }


        props.onSelectTaxonomieItem && props.onSelectTaxonomieItem({
            taxonomie: props.taxonomie,
            selectedValues,
            selectedItems,
            selectedValueString,
            owner: props.owner,
            ownerProp: props.ownerProp
        })

        setSelectedValue(value)
    }

    if (!dataProvider) return null

    return (
        <div>
            <Select
                disabled={props.disabled}
                fluid={props.fluid}
                multiple={props.multiple}
                search={props.search}
                value={selectedValue}
                placeholder={`Kies een ${taxonomieLabel}`}
                options={dataProvider}
                onChange={onSelectTaxonomieItem}
            />
        </div>
    )
}

export default TaxonomieKiezer

TaxonomieKiezer.propTypes = {
    taxonomieService: PropTypes.object.isRequired,
    selectedTaxonomieItem: PropTypes.string,
    onSelectTaxonomieItem: PropTypes.func,
    onSelectTaxonomieItemReturnObject: PropTypes.func,
    owner: PropTypes.object,
    ownerProp: PropTypes.string,
    taxonomie: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    search: PropTypes.bool,
    fluid: PropTypes.bool,
    disabled: PropTypes.bool
}

TaxonomieKiezer.defaultProps = {
    multiple: false,
    search: false,
    fluid: false,
}
