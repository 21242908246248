import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Label} from 'semantic-ui-react'
import 'moment/locale/nl'
import moment from 'moment'

const TijdsslotSlotRenderer = ({slot, localeManager, selected, beschikbareMandjes, onClick}) => {

    const [vanMoment, setVanMoment] = useState()
    const [totMoment, setTotMoment] = useState()

    useEffect(() => {
        setVanMoment(moment(slot.van, 'YYYY-MM-DD HH:mm:ss'))
        setTotMoment(moment(slot.tot, 'YYYY-MM-DD HH:mm:ss'))

    }, [slot])

    if (!vanMoment || !totMoment) return null

    return (
        <div className={`tijdsslot tijdsslot-uur ${selected ? 'selected' : ''} ${beschikbareMandjes !== false && beschikbareMandjes === 0 ? 'inactive' : ''}`}
             onClick={() => onClick(slot)}>
            <h4 style={{margin: 0}}>{vanMoment.format('HH:mm')}</h4>
            <div>{localeManager.getString('TOT')}</div>
            <h4 style={{margin: 0}}>{totMoment.format('HH:mm')}</h4>
            {beschikbareMandjes !== false && <div className="tijdsslot-beschikbare-slots">
                <Label color={beschikbareMandjes === 1 ? 'red' : 'grey'} circular>
                    {beschikbareMandjes === 1 ? localeManager.getString('LAATSTE') : beschikbareMandjes === 0 ? localeManager.getString('VOLZET') : beschikbareMandjes}
                </Label>
            </div>}
        </div>
    )
}

export default  TijdsslotSlotRenderer

TijdsslotSlotRenderer.propTypes = {
    slot: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,

    selected: PropTypes.bool,
    beschikbareMandjes: PropTypes.number,
    onClick: PropTypes.func
}

TijdsslotSlotRenderer.defaultProps = {
    selected: false,
}
