import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {Icon} from "semantic-ui-react";
import {ApplicationPath} from 'med-class'
import axios from 'axios'
import {CourseService} from 'cocos-lib'

const Parser = require('html-react-parser')
const LEESLIJST_DETECTOR_STRING = '/live/ws/leeslijst/'

const WebComponentDigitaleMediatheek = ({localeManager, onLeeslijstClick}) => {

    const [config, setConfig] = useState()
    const [courseService] = useState(new CourseService())
    const [course, setCourse] = useState()
    const [isOpen, setIsOpen] = useState(true)
    const [selectedTile, setSelectedTile] = useState(null)
    const [mainTitle, setMainTitle] = useState('')
    const [mainContent, setMainContent] = useState('')
    const [mainExtraContent, setMainExtraContent] = useState('')
    const [mainExtraContentVisible, setMainExtraContentVisible] = useState(false)
    const [tileContent, setTileContent] = useState('')
    const [selectedConfigData, setSelectedConfigData] = useState(null)
    const [embleemCocos] = useState(ApplicationPath.mediatheekAssetsFolder + 'logos/cocos/powered_by_cocos.png')

    const contentRef = useRef()

    //INTERCEPT HYPERLINK CLICKS
    useEffect(() => {
        if (!contentRef.current) return
        if (contentRef.current.addEventListener) {
            contentRef.current.addEventListener('click', interceptClickEvent);
        } else if (contentRef.current.attachEvent) {
            contentRef.current.attachEvent('onclick', interceptClickEvent);
        }

        return () => {
            if (contentRef.current.addEventListener) {
                contentRef.current.removeEventListener('click', interceptClickEvent);
            } else if (contentRef.current.attachEvent) {
                contentRef.current.detachEvent('onclick', interceptClickEvent);
            }
        }
    }, [contentRef.current])

    //CONFIG
    useEffect(() => {
        const url = ApplicationPath.mediatheekRoot + 'digitaleMediatheek/config.json'
        axios.get(url).then(res => {
            setConfig(res.data)
        })
    }, [])

    //COURSE
    useEffect(() => {
        if (!config) return
        courseService.getCourseByUuid(config.courseId).then(res => {
            const c = res[0]
            setCourse(c)
        })
    }, [config, courseService])

    //CONTENT BLOCKS
    useEffect(() => {
        if (!config || !course) return
        const mainTitleItem = getOutlineItemById(config.rootOutlineId[localeManager.locale])
        setMainTitle(mainTitleItem.title)

        courseService.getContentBlocks(course, config.rootOutlineId[localeManager.locale]).then(res => {

            courseService.getContentBlockData(res[0]).then(data => {
                setMainContent(replaceLeeslijstHyperlinks(data))
            })
            courseService.getContentBlockData(res[1]).then(data => {
                setMainExtraContent(replaceLeeslijstHyperlinks(data))
            })
        })
    }, [course, config, courseService, localeManager.locale])


    //METHODS
    const replaceLeeslijstHyperlinks = (data) => {
        const pattern = /<a href.+\/live\/ws\/leeslijst\/.+<\/a>/gm
        const pattern2 = /<a href[^>]*>(.*?)<\/a>/gi

        data = data.replace(pattern2, (occur) => {
            if (occur.indexOf('/live/ws/leeslijst/') > -1){
                return '<a class="dm-leeslijst-link"' + occur.substr(2)
            } else {
                return occur
            }
        })


        return data
    }

    const interceptClickEvent = (e) => {
        let href
        let target = e.target || e.srcElement
        if (target.tagName !== 'A') target = target.parentElement

        //e.target kan ook een <span> zijn onder een <a>!
        if (target.tagName === 'A') {
            href = target.getAttribute('href');

            if (href.indexOf(LEESLIJST_DETECTOR_STRING) > -1) {
                e.preventDefault();
                onLeeslijstClick && onLeeslijstClick(href)
            }
        }
    }


    const onTileClick = (tile, configData) => {

        if (tile === selectedTile) {
            setTileContent('')
            setSelectedTile(null)
            return
        }

        courseService.getContentBlocks(course, tile.id).then(res => {
            //setContentBlocks(_.orderBy(res, 'sort'))
            setSelectedTile(tile)
            setSelectedConfigData(configData)

            courseService.getContentBlockData(res[0]).then(data => {
                setTileContent(replaceLeeslijstHyperlinks(data))
            })
        })
    }

    const onHeaderClick = () => {
        setIsOpen(!isOpen)
        setSelectedTile(null)
        setTileContent('')
        setMainExtraContentVisible(false)
    }

    const getOutlineItemById = (id) => {

        const dict = {}

        const addChildren = (coll, childrenFunction) => {
            for (const child of coll) {
                dict[child.id] = child
                if (child.children) childrenFunction(child.children, childrenFunction)
            }
        }
        addChildren(course.outline, addChildren)

        return dict[id]
    }

    if (!config || !course) return null

    return (
        <div className='dm-container' ref={contentRef}>

            <div className={'dm-tile ' + (mainExtraContentVisible ? 'dm-tile-selected' : '')}
                 style={{width: '100%', maxWidth: '98%', color: config.titleColor}}
                 onClick={() => setMainExtraContentVisible(!mainExtraContentVisible)}>
                <Icon name='warning circle' size='big'/>
                {mainTitle}
                <a style={{marginLeft: "auto"}}
                   href='https://www.cocos.education/landing-2/'
                   target='_blank'
                   rel='noopener noreferrer'>
                    <img style={{boxShadow: '0px 0px'}} src={embleemCocos} width='100px'/>
                </a>
            </div>

            {mainExtraContentVisible &&
            <div className='dm-content-block-data'>
                {Parser(mainContent)}
                {Parser(mainExtraContent)}
            </div>
            }

            {isOpen && <div className='dm-tiles'>
                {config.tiles.map((tile, index) => {
                    const oi = getOutlineItemById(tile.outlineId[localeManager.locale])
                    const classes = 'dm-tile ' + (oi === selectedTile ? 'dm-tile-selected' : '')
                    return <div key={index} className={classes} onClick={() => onTileClick(oi, tile)}>
                        <Icon name={tile.icon} size='big'/>
                        {oi.title}
                    </div>
                })}
            </div>
            }

            {isOpen && selectedTile &&
            <div className='dm-content-block-data'>
                {Parser(tileContent)}
            </div>
            }

        </div>
    )
}

export default WebComponentDigitaleMediatheek

WebComponentDigitaleMediatheek.propTypes = {
    localeManager: PropTypes.object.isRequired,
    onLeeslijstClick: PropTypes.func
}

WebComponentDigitaleMediatheek.defaultProps = {}
