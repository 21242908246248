import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Dropdown, Checkbox, Radio, Grid, Icon, Button, Popup} from 'semantic-ui-react'

import '../styles.css'

class CheckboxDropdown extends Component {

    state = {
        open: false,
        dataprovider: null
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.exclusive){
            const data = nextProps.filterObject.data[nextProps.name]
            const wisItem = {label: 'WIS_SELECTIE', clearButton: true, clearButtonEnabled: data !== undefined}

            return ({dataprovider: [wisItem, ...nextProps.dataprovider]})
        }

        return ({dataprovider: nextProps.dataprovider})
    }

    mouseEnterHandler = () => {
        this.setState({open: true})
    }

    mouseLeaveHandler = () => {
        this.setState({open: false})
    }

    onRadioClick = (event, props) => {
        const { filterObject, onOptionChange } = this.props
        console.log('FO', filterObject)
        filterObject.clearProp(props.name)
        onOptionChange(event, props)
    }

    onWisSelectieClick = (event) => {
        const { filterObject, onOptionChange, name } = this.props
        filterObject.clearProp(name)
        onOptionChange(event, {renderMode: 'direct'})
    }

    getDropdownContent = (dataprovider, renderMode, filterObject, displayLabelProp, exclusive) => {

        const {localeManager, name} = this.props
        switch (renderMode) {
            case 'taxonomie':
                break
            case 'group':
                console.log('RENDER MODE', renderMode, dataprovider)
                break
            default:
                break
        }

        //het aantal kolommen wordt berekend op basis van maxColumnItems
        //wanneer er zeer veel items zijn mag dit hoger
        const maxColumnItems = dataprovider.length > 40 ? 12 : 9
        const numColumns = Math.ceil(dataprovider.length / maxColumnItems)

        //const maxChars = 22
        const colWidth = 250
        const gridWidth = colWidth * numColumns
        const colArray = [...Array(numColumns).keys()]
        const itemArray = [...Array(maxColumnItems).keys()]

        const grid = <Grid style={{width: gridWidth + 'px'}} columns={numColumns} divided padded>
            {
                colArray.map(index1 => {
                    return <Grid.Column key={index1}>
                        {itemArray.map((value, index2) => {
                            const dpIndex = index2 + maxColumnItems * index1
                            if (dpIndex < dataprovider.length) {
                                const item = dataprovider[dpIndex]
                                const locLabel = displayLabelProp ? localeManager.getString(item[displayLabelProp]) :
                                    item.code ? localeManager.getString(item.code) : localeManager.getString(item.label)

                                //button voor wissen van exlusive value
                                if (item.clearButton)
                                    return <div key={dpIndex} style={{padding: '5px'}}>
                                        <Button content={locLabel} onClick={this.onWisSelectieClick} disabled={!item.clearButtonEnabled}/>
                                    </div>
                                let checked = filterObject.data[name] && filterObject.data[name].indexOf(item) > -1
                                if (typeof checked === 'undefined') checked = false
                                return <div key={dpIndex} style={{padding: '5px'}}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        {!exclusive &&
                                        <Checkbox className='wordwrap' style={{flexGrow: 1}} label={locLabel} name={this.props.name} checked={checked} selecteditem={item}
                                                  onChange={this.props.onOptionChange}/>
                                        }
                                        {exclusive &&
                                        <Radio className='wordwrap' style={{flexGrow: 1}} label={locLabel} name={this.props.name} checked={checked} selecteditem={item}
                                                  onChange={this.onRadioClick}/>
                                        }
                                        {(item.toelichting && item.toelichting !== '') &&
                                            <Popup
                                            trigger={
                                                <Icon style={{cursor: 'pointer'}} name='info circle' color='teal'/>
                                            }
                                            content={item.toelichting}/>
                                        }
                                    </div>
                                </div>
                            }

                            return null
                        })}
                    </Grid.Column>
                })
            }
        </Grid>

        return grid
    }

    render() {
        const {open, dataprovider} = this.state
        const {title, name, filterObject, renderMode, displayLabelProp, exclusive} = this.props
        const somethingSelected = filterObject.data[name] && filterObject.data[name].length > 0

        if (!dataprovider) return <div/>
        return (
            <Dropdown open={open}
                      onMouseEnter={this.mouseEnterHandler}
                      onMouseLeave={this.mouseLeaveHandler}
                      style={{marginBottom: '5px', backgroundColor: somethingSelected ? '#21ba45' : 'lightgrey', color: somethingSelected ? 'white' : 'black'}}
                      text={title}
                      labeled
                      button
                      icon='filter'
                      className='icon'>
                <Dropdown.Menu>
                    {this.getDropdownContent(dataprovider, renderMode, filterObject, displayLabelProp, exclusive)}
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}

CheckboxDropdown.propsTypes = {
    localeManager: PropTypes.object.isRequired,
    title: PropTypes.string,
    dataprovider: PropTypes.array.isRequired,
    renderMode: PropTypes.string,
    idField: PropTypes.string,
    labelField: PropTypes.string,
    onOptionChange: PropTypes.func,
    name: PropTypes.string,
    filterObject: PropTypes.object,
    displayLabelProp: PropTypes.string,
    exclusive: PropTypes.bool
}

CheckboxDropdown.defaultProps = {
    idField: 'id',
    labelField: 'label',
    filterObject: {},
    dataprovider: [],
    renderMode: 'taxonomie',
    exclusive: false
}

export default CheckboxDropdown