import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Feed, Icon, Label} from 'semantic-ui-react'
import moment from 'moment'
import {ApplicationPath} from 'med-class'

const Parser = require('html-react-parser')

const CommentaarFeed = ({arteveldeUser, comment, localeManager, deleteComment}) => {

    const [dayLabel, setDayLabel] = useState()
    const [isMine, setIsMine] = useState()
    const [loggedIn] = useState(arteveldeUser && arteveldeUser.loggedIn)

    useEffect(() => {
        const diff = moment.duration(moment(comment.datum, 'YYYY-MM-DD HH:mm:ss').diff(moment()))
        setDayLabel(diff.humanize(true))
        setIsMine(arteveldeUser && comment.user === arteveldeUser.emailAddress)
    }, [comment, arteveldeUser])

    const convertNewLines = (source) => {
        return source.replace(/\n/gm, '<br />');
    }

    return (
        <Feed.Event style={{opacity: '0.8'}}>
            {loggedIn &&
            <div className='avatar'>
                <img src={ApplicationPath.avatarApi + comment.user} alt='avatar'/>
            </div>
            }
            <Feed.Content>
                <Feed.Summary>
                    {loggedIn && <span>{comment.user.split('@')[0]}</span>}
                    <Feed.Date>{dayLabel}</Feed.Date>
                </Feed.Summary>
                <Feed.Extra text>
                    {Parser(convertNewLines(comment.commentaar))}
                </Feed.Extra>
                {isMine &&
                <div style={{marginTop: '5px'}}>
                    <Feed.Date as='a' onClick={() => deleteComment(comment)}>
                        <Icon name='trash'/>
                        {localeManager.getString('VERWIJDER')}
                    </Feed.Date>
                    {comment.status === 'nieuw' &&
                    <Label style={{marginLeft: '5px'}}>Uw commentaar is in behandeling. Tot zolang is die enkel voor u zichtbaar.</Label>
                    }
                </div>
                }

            </Feed.Content>
        </Feed.Event>
    )

}

export default CommentaarFeed

CommentaarFeed.propTypes = {
    comment: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    arteveldeUser: PropTypes.object,
    deleteComment: PropTypes.func
}

CommentaarFeed.defaultProps = {}