import React, {useState, useEffect, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Card, Image, Icon} from 'semantic-ui-react'
import {ZoekUtil} from 'med-class'
import _ from 'lodash'

const Parser = require('html-react-parser')

const ZoekresultaatUitgelicht = ({zoekresultaat, taxonomieService, localeManager, onCardClick, epubLocaleDataService}) => {

    const [showAllAuteurs] = useState(false)
    const [auteursArray, setAuteursArray] = useState([])
    const [beschrijving, setBeschrijving] = useState('')

    useEffect(() => {
        setAuteursArray(showAllAuteurs ? zoekresultaat.auteurs : _.take(zoekresultaat.auteurs, 5))
    }, [showAllAuteurs, zoekresultaat])

    useEffect(() => {

        const convertNewLines = (source) => {
            if (!source) return ''
            return source.replace(/\n/gm, '<br />');
        }


        const getLocaleContentFieldValue = (field) => {
            //localeContent zit nu standaard in het zoekresultaat

            if (epubLocaleDataService && zoekresultaat.needLocaleContent && zoekresultaat.localeContentLoaded) {
                const content = epubLocaleDataService.getPropertyValue(zoekresultaat.id, field, localeManager.locale)
                return content
            }
            return zoekresultaat[field]
        }

        const beschrijving = zoekresultaat.bron === 'epub' ?
            convertNewLines(getLocaleContentFieldValue('beschrijving'))
            :
            convertNewLines(zoekresultaat.beschrijving)

        setBeschrijving(beschrijving)
    }, [zoekresultaat, epubLocaleDataService, localeManager.locale])

    const onLocalCardClick = (event) => {
        event.preventDefault()
        onCardClick(zoekresultaat)
    }

    return (
        <Card as='div' style={{cursor: 'pointer', width: '100%', flexGrow: 1}} onClick={onLocalCardClick}>
            <Card.Content>

                <div className='zoekresultaat-uitgelicht-card'>
                    <Icon name="hand point right outline"/>
                    {localeManager.getString('UITGELICHT')}!
                </div>

                {zoekresultaat.coverLink &&
                <Image style={{marginBottom: '10px'}} size='small' src={zoekresultaat.coverLink} onError={e => e.target.style.display = 'none'}/>
                }

                <Card.Header style={{marginBottom: '3px'}}>{Parser(zoekresultaat.titel)}</Card.Header>

                <div style={{fontSize: '0.85em', color: 'grey', marginBottom: '5px'}}>
                    {zoekresultaat.subtitelArray && zoekresultaat.subtitelArray.map((subtitel, index) => {
                        return <Fragment key={index}><em>{subtitel}</em><br/></Fragment>
                    })}
                </div>

                <Card.Meta>
                    <span>{Parser(ZoekUtil.metaString(zoekresultaat, localeManager, taxonomieService))}</span>
                </Card.Meta>

                {(zoekresultaat.auteurs && zoekresultaat.auteurs.length > 0) &&
                <Card.Description>
                    {auteursArray.map((auteur, index) => {
                        return <Fragment key={index}>{Parser(auteur)}<br/></Fragment>
                    })}
                    {/*{getShowMoreLabel()}*/}

                </Card.Description>
                }

                <Card.Meta style={{overflowWrap: 'break-word', marginTop: '5px'}}>{Parser(beschrijving)}</Card.Meta>
            </Card.Content>

        </Card>
    )
}

export default ZoekresultaatUitgelicht

ZoekresultaatUitgelicht.propTypes = {
    localeManager: PropTypes.object.isRequired,
    socialService: PropTypes.object.isRequired,
    mijnMediatheekService: PropTypes.object.isRequired,
    taxonomieService: PropTypes.object.isRequired,
    zoekresultaat: PropTypes.object.isRequired,
    onCardClick: PropTypes.func,
    onCoverClick: PropTypes.func,
    arteveldeUser: PropTypes.object,
    directLink: PropTypes.object
}
