import React, {useState} from 'react'
import ReactSVG from 'react-svg'
import {ApplicationPath} from 'med-class'
import {SvgController} from "../class/SvgController";

const SvgTester = () => {

    const [mapController] = useState(new SvgController())


    const onSvgLoaded = (error, svg) => {
        if (!mapController) return

        console.log('SVG', svg)
        if (error) {
            console.error(error)
            return
        }

        mapController.init(svg)
    }

    return (
        <div>
            <ReactSVG
                src={`${ApplicationPath.mediatheekSvgMapsFolder}test.svg`}
                afterInjection={onSvgLoaded}/>
        </div>
    )
}

export default  SvgTester

