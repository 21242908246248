import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {Header, Divider, Grid, Icon} from 'semantic-ui-react'
import {ApplicationPath, ENGLISH_CONTACT_URL} from 'med-class'
import MediatheekDetailOpeningsuren from "./MediatheekDetailOpeningsuren"
import GoogleMapReact from 'google-map-react'

import GoogleMapMarker from "./GoogleMapMarker";
import WieIsWie from "./WieIsWie";
import {MediatheekMap} from "web-component-mediatheek-map";

import Parser, {domToReact} from 'html-react-parser'
import {PanoramaViewer} from "web-component-panorama-viewer";

const API_KEY = 'AIzaSyD_j-z5emkn65iHJlPrlQ5r_7rf8bOQn9k'

const MediatheekDetail = ({mediatheek, mediatheekService, localeManager, users, userLocaleDataService, rubriceringService, enableLinks, panoramaConfig, config}) => {


    const pinterestUrl = ApplicationPath['pinterest_' + mediatheek.campusCode]
    //const opleidingsgebondenLinks = ApplicationPath.opleidingsgebondenLinks[mediatheek.campusCode]

    const getContactContent = () => {

        if (localeManager.locale === 'nl') {
            return <Fragment>
                {Parser(localeManager.handleLocaleBindings(mediatheek.contact),
                    {replace: ({attribs, children}) => !enableLinks && attribs && attribs.href && <strong>{domToReact(children)}</strong>})}
            </Fragment>
        } else {
            if (!enableLinks) return null
            return <a href={ENGLISH_CONTACT_URL} target='_blank' rel="noopener noreferrer">Contact us</a>
        }
    }

    return (
        <div className='mediatheek-detail'>
            <Header size='huge'>{localeManager.getString('MEDIATHEEK')} {mediatheek.naam}</Header>
            <Header sub>domein {mediatheek.domein}</Header>

            <Divider/>

            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column width={8}>
                        {getContactContent()}
                    </Grid.Column>
                    <Grid.Column width={8}>
                        {enableLinks &&
                        <div className='hyperlink'>
                            <a href={pinterestUrl} target='_blank' rel="noopener noreferrer">
                                <div><Icon name='pinterest' size='big' color='red'/>{localeManager.getString('PINTEREST_LINK')}</div>
                            </a>
                        </div>
                        }

                        {enableLinks &&
                        <div className='hyperlink'>
                            <a href={ApplicationPath.reserveerUrl} target='_blank' rel="noopener noreferrer">
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Icon name='calendar check' size='big' color='green'/>
                                    <div>{Parser(localeManager.getString('RESERVATIE_TILE'))}</div>
                                </div>
                            </a>
                        </div>
                        }
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={16}>
                        <PanoramaViewer medConfig={panoramaConfig[mediatheek.campusCode]}
                                        mainConfig={panoramaConfig.config}
                                        mediatheek={mediatheek.campusCode}
                                        localeManager={localeManager}
                        />
                    </Grid.Column>
                </Grid.Row>


            </Grid>

            <Divider/>

            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <MediatheekDetailOpeningsuren config={config} mediatheekService={mediatheekService} mediatheek={mediatheek} localeManager={localeManager}/>
                    </Grid.Column>
                    {enableLinks && <Grid.Column>
                        <GoogleMapReact
                            bootstrapURLKeys={{key: API_KEY}}
                            center={{lat: parseFloat(mediatheek.lat), lng: parseFloat(mediatheek.lng)}}
                            defaultZoom={14}
                            yesIWantToUseGoogleMapApiInternals={true}
                        >
                            <GoogleMapMarker
                                lat={parseFloat(mediatheek.lat)}
                                lng={parseFloat(mediatheek.lng)}
                                text={mediatheek.naam}/>
                        </GoogleMapReact>

                    </Grid.Column>}
                </Grid.Row>
            </Grid>

            <Grid columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <MediatheekMap rubriceringService={rubriceringService}
                                       localeManager={localeManager}
                                       mediatheek={mediatheek.campusCode}
                                       showLegende={true}/>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                        <WieIsWie users={users}
                                  localeManager={localeManager}
                                  userLocaleDataService={userLocaleDataService}
                                  enableLinks={enableLinks}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </div>
    )
}

export default MediatheekDetail

MediatheekDetail.propTypes = {
    mediatheek: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    mediatheekService: PropTypes.object.isRequired,
    rubriceringService: PropTypes.object.isRequired,
    localeManager: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    userLocaleDataService: PropTypes.object.isRequired,
    panoramaConfig: PropTypes.object.isRequired,
    enableLinks: PropTypes.bool
}

MediatheekDetail.defaultProps = {
    enableLinks: true
}